import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


export interface ProjectTemplatesEditControllerProps {
  navigation: any;
  id: string;
}

interface S {}

interface SS {
  id: any;
}

export default class ProjectTemplatesEditController extends BlockComponent<
ProjectTemplatesEditControllerProps,
  S,
  SS
> {

  constructor(props: ProjectTemplatesEditControllerProps) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {};
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
}