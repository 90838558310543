import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { AddCircleOutlineRounded, DragIndicatorOutlined, RemoveCircle } from "@material-ui/icons";
import { DragDropContext, Draggable, DropResult, Droppable } from "@hello-pangea/dnd";
import GenericError from "./GenericError.web";


const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: 12,
        '& h5': {
            margin: 0,
            fontFamily: "Cairo",
            fontWeight: 700,
            fontSize: 14,
            lineHeight: "22px",
            color: "#64748B",
        },
    },
    addOptionBtn: {
        all: "unset",
        fontSize: 16,
        lineHeight: "24px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: 8,
        fontWeight: 700,
        fontFamily: "Cairo",
        color: "#64748B",
    },
    optionList: {
        display: "flex",
        flexDirection: "column",
        gap: 12,
    },
    option: {
        display: "flex",
        gap: 4,
        alignItems: "center",
        '& button': {
            all: "unset",
            cursor: "pointer",
        },
        '& input': {
            boxSizing: "border-box",
            border: "1px solid #CBD5E1",
            backgroundColor: "#FFFFFF",
            borderRadius: 8,
            padding: "14px 8px",
            width: "100%",
            fontWeight: 400,
            fontFamily: "Cairo",
            fontSize: 16,
            lineHeight: "24px",
            height: 56,
            color: "#64748B",
            '&:focus': {
                outline: 0,
                borderColor: "#51ABB3",
            },
        },
    },
})


type Option = {
    id: number;
    name: string;
    value: string;
}

type OptionsListFormCreationProps = {
    questionId: number;
    optionError?: string;
    questionIndex: number;
    options: Option[];
    showError: boolean;
    handleUpdateOptionsInQuestion: (questionIndex: number, options: Option[]) => void;
    handleCloseError: () => void;
}

export default function QptionsListFormCreation({
    questionId,
    optionError,
    options,
    handleUpdateOptionsInQuestion,
    questionIndex,
    showError,
    handleCloseError
}: OptionsListFormCreationProps) {
    const optionsLengthRef = useRef<number>(options.length)
    const classes = useStyles()

    const handleAddOption = () => {
        optionsLengthRef.current = optionsLengthRef.current + 1
        const option: Option = {
            id: optionsLengthRef.current,
            name: `question_${questionIndex}_option_${optionsLengthRef.current}`,
            value: `Option ${optionsLengthRef.current}`,
        }
        handleUpdateOptionsInQuestion(questionIndex, [...options, option])
    }

    const handleRemoveOption = (optionId: number) => () => {
        const newOptions = options.filter(option => option.id !== optionId)
        handleUpdateOptionsInQuestion(questionIndex, newOptions)
    }

    const handleOptionInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = event.target
        const optionId = +name.split("_")[3]
        const currentOptionIndex = options.findIndex(option => option.id === optionId)
        const newOptions = [...options]
        newOptions[currentOptionIndex] = {
            ...newOptions[currentOptionIndex],
            value: value,
        }
        handleUpdateOptionsInQuestion(questionIndex, newOptions)
    }

    const handlOptionsDragEnd = (result: DropResult) => {
        if(!result.destination) {
          return;
        }
    
        if(result.destination.index === result.source.index) {
          return;
        }
    
        const newOptions = [...options]
        const [removed] = newOptions.splice(result.source.index, 1)
        newOptions.splice(result.destination.index, 0, removed)
    
        handleUpdateOptionsInQuestion(questionIndex, newOptions)
      }

    return (
        <div className={classes.container} >
            <h5>Options*</h5>
            { optionError && showError  && <GenericError handleCloseError={handleCloseError} error={optionError} /> }
            <button onClick={handleAddOption} className={classes.addOptionBtn} >
                <AddCircleOutlineRounded htmlColor="#64748B" />
                Add option
            </button>
            <DragDropContext onDragEnd={handlOptionsDragEnd}>
                <Droppable droppableId={`${questionId}`} >
                    {
                        (droppableProvided) => (
                            <div className={classes.optionList} ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} >
                                {
                                    options.map((option, index) => (
                                        <Draggable key={option.id}  index={index} draggableId={`${questionId}_${option.id}`} >
                                            {(draggableProvided) => (
                                                <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} className={classes.option} >
                                                    <button {...draggableProvided.dragHandleProps} style={{ cursor: "grab" }} >
                                                        <DragIndicatorOutlined htmlColor="#64748B" />
                                                    </button>
                                                    <input
                                                        type="text"
                                                        maxLength={50}
                                                        placeholder="Type your option here"
                                                        name={option.name}
                                                        value={option.value}
                                                        onChange={handleOptionInputChange}
                                                    />
                                                    <button onClick={handleRemoveOption(option.id)} >
                                                        <RemoveCircle htmlColor="#DC2626" />
                                                    </button>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                }
                            </div>
                        )
                    }
                </Droppable>
            </DragDropContext>
        </div>
    )
}
