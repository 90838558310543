// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Contentmanagement1 from '../../blocks/contentmanagement1/src/Contentmanagement1';
import SocialMediaAccountLogin from '../../blocks/social-media-account/src/SocialMediaAccountLogin';
import SocialMediaAccountRegistration from '../../blocks/social-media-account/src/SocialMediaAccountRegistration';
import Adminconsole from '../../blocks/adminconsole/src/Adminconsole';
import Uploadmedia3 from '../../blocks/uploadmedia3/src/Uploadmedia3';
import ImportExportData from '../../blocks/importexportdata/src/ImportExportData';
import CustomProfileHeader from '../../blocks/customprofileheader/src/CustomProfileHeader';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions';
import TermsConditionsDetail from '../../blocks/termsconditions/src/TermsConditionsDetail';
import TermsConditionsUsers from '../../blocks/termsconditions/src/TermsConditionsUsers';
import Translation from '../../blocks/translation/src/Translation';
import Cfconnectorlogic from '../../blocks/cfconnectorlogic/src/Cfconnectorlogic';
import Cfprojecttemplates2 from '../../blocks/cfprojecttemplates2/src/Cfprojecttemplates2';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Chatbot5 from '../../blocks/chatbot5/src/Chatbot5.web';
import Cftoggledraftvspublishedchatbot from '../../blocks/cftoggledraftvspublishedchatbot/src/Cftoggledraftvspublishedchatbot';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import UserProfileBasic from '../../blocks/user-profile-basic/src/UserProfileBasic.web';
import Signuplogin from '../../blocks/signuplogin/src/Signuplogin';
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics.web";
import Catalogue1 from '../../blocks/catalogue1/src/Catalogue1.web';
import Termsandconditions from '../../blocks/termsandconditions/src/Termsandconditions';
import Cfgoogledialogflowapiintegration from '../../blocks/cfgoogledialogflowapiintegration/src/Cfgoogledialogflowapiintegration';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import Cfpowerbiapiintegration from '../../blocks/cfpowerbiapiintegration/src/Cfpowerbiapiintegration';
import VideoEmbeddingYoutube from '../../blocks/videoembeddingyoutube/src/VideoEmbeddingYoutube';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import Dashboard4 from '../../blocks/dashboard4/src/Dashboard4';
import Analytics from '../../blocks/analytics/src/Analytics.web';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import Maps from '../../blocks/maps/src/Maps';
import ThemeBlock from '../../blocks/themeblock/src/ThemeBlock';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import CustomiseInterface from '../../blocks/customiseinterface2/src/Customiseinterface2.web';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.web';
import NewPasswordWeb from '../../blocks/forgot-password/src/NewPassword.web';
import BlogpostsManagement from '../../blocks/blogpostsmanagement/src/BlogpostsManagement.web';
import ChangePasswordWeb from '../../blocks/forgot-password/src/components/ChangePassword.web';
import AutomaticFormCreation from "../../blocks/automaticformcreation/src/AutomaticFormCreation.web"
import "react-toastify/dist/ReactToastify.css";
import 'reactflow/dist/style.css';
import ToastNotification from '../../components/src/ToastNotification.web';
import AutomaticFormLandingPage from '../../blocks/automaticformcreation/src/AutomaticFormLandingPage.web';
import AutomaticEditForm from '../../blocks/automaticformcreation/src/AutomaticEditForm.web';
import ProjectPortfolio from "../../blocks/projectportfolio/src/ProjectPortfolio.web"
import CreateEditProjectPortfolio  from "../../blocks/projectportfolio/src/CreateEditProjectPortfolio.web"
import ProjectTemplatesEdit from "../../blocks/projecttemplates/src/ProjectTemplatesEdit.web"

import QAProjectPortfolio from '../../blocks/projectportfolio/src/QAProjectPortfolio.web';
import CreateEditQA from '../../blocks/projectportfolio/src/CreateEditQA.web';
import ProjectPortfolioDetail from '../../blocks/projectportfolio/src/ProjectPortfolioDetail.web';
import EndUserChatBot from '../../blocks/chatbot5/src/EndUserChatBot.web';
import Chats from "../../blocks/projectportfolio/src/Chats.web";
const routeMap = {
  Contentmanagement1: {
    component: Contentmanagement1,
    path: '/Contentmanagement1',
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: '/SocialMediaAccountLogin',
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: '/SocialMediaAccountRegistration',
  },
  Adminconsole: {
    component: Adminconsole,
    path: '/Adminconsole',
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: '/Uploadmedia3',
  },
  ImportExportData: {
    component: ImportExportData,
    path: '/ImportExportData',
  },
  CustomProfileHeader: {
    component: CustomProfileHeader,
    path: '/CustomProfileHeader',
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen',
  },
  ForgotPassword: {
    component: ForgotPasswordWeb,
    path: '/forgot-password',
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP',
  },
  NewPassword: {
    component: NewPasswordWeb,
    path: '/new-password',
  },
  ChangePassword: {
    component: ChangePasswordWeb,
    path: '/change-password',
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions',
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail',
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers',
  },
  Translation: {
    component: Translation,
    path: '/Translation',
  },
  Cfconnectorlogic: {
    component: Cfconnectorlogic,
    path: '/Cfconnectorlogic',
  },
  Cfprojecttemplates2: {
    component: Cfprojecttemplates2,
    path: '/Cfprojecttemplates2',
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue',
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen',
  },
  Chatbot5: {
    component: Chatbot5,
    path: '/bots/:navigationBarTitleText',
    exact: true,
  },
  Cftoggledraftvspublishedchatbot: {
    component: Cftoggledraftvspublishedchatbot,
    path: '/Cftoggledraftvspublishedchatbot',
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector',
  },
  UserProfileBasic: {
    component: UserProfileBasic,
    path: '/user-profile',
  },
  Signuplogin: {
    component: Signuplogin,
    path: '/login',
  },
  Catalogue1: {
    component: Catalogue1,
    path: '/bots',
    exact: true,
  },
  Termsandconditions: {
    component: Termsandconditions,
    path: '/Termsandconditions',
  },
  Cfgoogledialogflowapiintegration: {
    component: Cfgoogledialogflowapiintegration,
    path: '/Cfgoogledialogflowapiintegration',
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration',
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories',
  },
  Cfpowerbiapiintegration: {
    component: Cfpowerbiapiintegration,
    path: '/Cfpowerbiapiintegration',
  },
  VideoEmbeddingYoutube: {
    component: VideoEmbeddingYoutube,
    path: '/VideoEmbeddingYoutube',
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2',
  },
  Dashboard: {
    component: Dashboard4,
    path: '/dashboard',
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/analytics',
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock',
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu',
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage',
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch',
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile',
  },
  PostCreation: {
    component: PostCreation,
    path: '/user-guides/create',
  },
  PostEdit: {
    component: PostCreation,
    path: '/user-guides/edit/:navigationBarTitleText',
    exact: true,
  },
  Posts: {
    component: Posts,
    path: '/Posts',
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails',
  },
  Maps: {
    component: Maps,
    path: '/Maps',
  },
  ThemeBlock: {
    component: ThemeBlock,
    path: '/ThemeBlock',
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth',
  },

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true,
  },
  Settings: {
    component: CustomiseInterface,
    path: '/bots/:navigationBarTitleText/settings',
    exact: true,
  },
  ProjectTemplates: {
    component: ProjectTemplatesEdit,
    path: '/templates/:navigationBarTitleText',
    exact: true
  },
  BlogpostsManagement: {
    component: BlogpostsManagement,
    path: '/user-guides',
    exact: true,
  },
  AutomaticFormCreation: {
    component: AutomaticFormCreation,
    path: "/forms/create",
    exact: true,
  },
  Forms: {
    component: AutomaticFormLandingPage,
    path: '/forms',
    exact: true,
  },
  AutomaticEditForm: {
    component: AutomaticEditForm,
    path: '/forms/edit/:navigationBarTitleText',
    exact: true,
  },
  Signposting: {
    component: ProjectPortfolio,
    path: '/signposting',
    exact: true,
  },
  SignpostingCreate: {
    component: CreateEditProjectPortfolio,
    path: '/signposting/create',
    exact: true,
  },
  SignpostingEdit: {
    component: CreateEditProjectPortfolio,
    path: '/signposting/edit/:navigationBarTitleText',
    exact: true,
  },
  'Q&A': {
    component: QAProjectPortfolio,
    path: '/qa',
    exact: true,
  },
  'Q&ACreate': {
    component: CreateEditQA,
    path: '/qa/create',
    exact: true,
  },
  'Q&AEdit': {
    component: CreateEditQA,
    path: '/qa/edit/:navigationBarTitleText',
    exact: true,
  },
  TrainingHub: {
    component: ProjectPortfolioDetail,
    path: '/training_hub',
    exact: true,
  },
  Audit: {
    component: Analytics,
    path: '/audit',
  },
  EndUserChatBot: {
    component: EndUserChatBot,
    path: '/bots/:navigationBarTitleText/chat',
    exact: true,
  },
  Chats: {
    component: Chats,
    path: '/chats',
    exact: true,
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View nativeID='container' style={{ height: '100vh', width: '100vw', overflowX: "hidden", overflowY: "auto" }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        <ToastNotification />
      </View>
    );
  }
}

export default App;
