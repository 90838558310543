import { styled } from "@material-ui/core";
import React, { useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

interface Props {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  className?: string;
  toolbar?: any;
  error?: string;
  hasRightAlign: boolean;
}

const icons = Quill.import("ui/icons");
icons[
  "undo"
] = `<svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.4668 0C8.8168 0 6.4168 0.99 4.5668 2.6L2.6768 0.71C2.0468 0.08 0.966797 0.52 0.966797 1.41V7C0.966797 7.55 1.4168 8 1.9668 8H7.5568C8.4468 8 8.8968 6.92 8.2668 6.29L6.3568 4.38C7.7468 3.22 9.5168 2.5 11.4768 2.5C14.6368 2.5 17.3668 4.34 18.6668 7C18.9368 7.56 19.5768 7.84 20.1668 7.64C20.8768 7.41 21.2368 6.6 20.9168 5.92C19.1968 2.42 15.6168 0 11.4668 0Z" fill="#64748B"/>
</svg>
`;
icons[
  "redo"
] = `<svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.4284 2.6C15.5784 0.99 13.1784 0 10.5284 0C6.36836 0 2.78836 2.42 1.08836 5.93C0.76836 6.6 1.12836 7.4 1.83836 7.64C2.42836 7.84 3.06836 7.56 3.33836 7C4.63836 4.34 7.36836 2.5 10.5284 2.5C12.4784 2.5 14.2584 3.22 15.6484 4.38L13.7384 6.29C13.1084 6.92 13.5484 8 14.4384 8H20.0284C20.5784 8 21.0284 7.55 21.0284 7V1.41C21.0284 0.52 19.9484 0.07 19.3184 0.7L17.4284 2.6Z" fill="#64748B"/>
</svg>
`;
icons[
  "code-block"
] = `<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.2005 6.0007L13.3005 9.9007C13.1133 10.0856 13.008 10.3377 13.008 10.6007C13.008 10.8638 13.1133 11.1158 13.3005 11.3007C13.6905 11.6907 14.3105 11.6907 14.7005 11.3007L19.2905 6.7007C19.6805 6.3107 19.6805 5.6807 19.2905 5.2907L14.7005 0.700703C14.3105 0.310703 13.6905 0.310703 13.3005 0.700703C13.1133 0.885557 13.008 1.13765 13.008 1.4007C13.008 1.66375 13.1133 1.91585 13.3005 2.1007L17.2005 6.0007ZM2.80047 6.0007L6.70047 9.9007C7.09047 10.2907 7.09047 10.9107 6.70047 11.3007C6.31047 11.6907 5.69047 11.6907 5.30047 11.3007L0.710469 6.7007C0.320469 6.3107 0.320469 5.6807 0.710469 5.2907L5.30047 0.700703C5.48532 0.513555 5.73742 0.408221 6.00047 0.408221C6.26352 0.408221 6.51562 0.513555 6.70047 0.700703C7.09047 1.0907 7.09047 1.7107 6.70047 2.1007L2.80047 6.0007Z" fill="#64748B"/>
</svg>
`;
icons[
  "bold"
] = `<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.225 6.79C10.195 6.12 10.875 5.02 10.875 4C10.875 1.74 9.125 0 6.875 0H0.625V14H7.665C9.755 14 11.375 12.3 11.375 10.21C11.375 8.69 10.515 7.39 9.225 6.79ZM3.625 2.5H6.625C7.455 2.5 8.125 3.17 8.125 4C8.125 4.83 7.455 5.5 6.625 5.5H3.625V2.5ZM7.125 11.5H3.625V8.5H7.125C7.955 8.5 8.625 9.17 8.625 10C8.625 10.83 7.955 11.5 7.125 11.5Z" fill="#64748B"/>
</svg>
`;
icons[
  "italic"
] = `<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 0V3H6.21L2.79 11H0V14H8V11H5.79L9.21 3H12V0H4Z" fill="#64748B"/>
</svg>
`;
icons[
  "strike"
] = `<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.15 16.9996C8.88333 16.9996 7.75833 16.6246 6.775 15.8746C5.79167 15.1246 5.08333 14.0996 4.65 12.7996L6.85 11.8496C7.08333 12.6496 7.4875 13.3079 8.0625 13.8246C8.6375 14.3413 9.35 14.5996 10.2 14.5996C10.9 14.5996 11.5333 14.4329 12.1 14.0996C12.6667 13.7663 12.95 13.2329 12.95 12.4996C12.95 12.1996 12.8917 11.9246 12.775 11.6746C12.6583 11.4246 12.5 11.1996 12.3 10.9996H15.1C15.1833 11.2329 15.2458 11.4704 15.2875 11.7121C15.3292 11.9538 15.35 12.2163 15.35 12.4996C15.35 13.9329 14.8375 15.0413 13.8125 15.8246C12.7875 16.6079 11.5667 16.9996 10.15 16.9996ZM0 8.99961V6.99961H20V8.99961H0ZM10.05 0.849609C11.15 0.849609 12.1125 1.12044 12.9375 1.66211C13.7625 2.20378 14.4 3.03294 14.85 4.14961L12.65 5.12461C12.5 4.64128 12.2208 4.20794 11.8125 3.82461C11.4042 3.44128 10.8333 3.24961 10.1 3.24961C9.41667 3.24961 8.85 3.40378 8.4 3.71211C7.95 4.02044 7.7 4.44961 7.65 4.99961H5.25C5.28333 3.84961 5.7375 2.87044 6.6125 2.06211C7.4875 1.25378 8.63333 0.849609 10.05 0.849609Z" fill="#64748B"/>
</svg>
`;
icons.list["ordered"] = `<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H1.5V3.5C1.5 3.78 1.72 4 2 4C2.28 4 2.5 3.78 2.5 3.5V0.5C2.5 0.22 2.28 0 2 0H1C0.72 0 0.5 0.22 0.5 0.5C0.5 0.78 0.72 1 1 1ZM18.5 3H6.5C5.95 3 5.5 2.55 5.5 2C5.5 1.45 5.95 1 6.5 1H18.5C19.05 1 19.5 1.45 19.5 2C19.5 2.55 19.05 3 18.5 3ZM6.5 13H18.5C19.05 13 19.5 13.45 19.5 14C19.5 14.55 19.05 15 18.5 15H6.5C5.95 15 5.5 14.55 5.5 14C5.5 13.45 5.95 13 6.5 13ZM18.5 7H6.5C5.95 7 5.5 7.45 5.5 8C5.5 8.55 5.95 9 6.5 9H18.5C19.05 9 19.5 8.55 19.5 8C19.5 7.45 19.05 7 18.5 7ZM3 12C3.28 12 3.5 12.22 3.5 12.5V15.5C3.5 15.78 3.28 16 3 16H1C0.72 16 0.5 15.78 0.5 15.5C0.5 15.22 0.72 15 1 15H2.5V14.5H2C1.72 14.5 1.5 14.28 1.5 14C1.5 13.72 1.72 13.5 2 13.5H2.5V13H1C0.72 13 0.5 12.78 0.5 12.5C0.5 12.22 0.72 12 1 12H3ZM0.5 6.5C0.5 6.22 0.72 6 1 6H3C3.28 6 3.5 6.22 3.5 6.5V6.72C3.5 6.83 3.46 6.95 3.38 7.04L1.7 9H3C3.28 9 3.5 9.22 3.5 9.5C3.5 9.78 3.28 10 3 10H1C0.72 10 0.5 9.78 0.5 9.5V9.28C0.5 9.17 0.54 9.05 0.62 8.96L2.3 7H1C0.72 7 0.5 6.78 0.5 6.5Z" fill="#64748B"/>
</svg>
`;
icons.list[
  "bullet"
] = `<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.25 6.5C1.42 6.5 0.75 7.17 0.75 8C0.75 8.83 1.42 9.5 2.25 9.5C3.08 9.5 3.75 8.83 3.75 8C3.75 7.17 3.08 6.5 2.25 6.5ZM2.25 0.5C1.42 0.5 0.75 1.17 0.75 2C0.75 2.83 1.42 3.5 2.25 3.5C3.08 3.5 3.75 2.83 3.75 2C3.75 1.17 3.08 0.5 2.25 0.5ZM2.25 12.5C1.42 12.5 0.75 13.18 0.75 14C0.75 14.82 1.43 15.5 2.25 15.5C3.07 15.5 3.75 14.82 3.75 14C3.75 13.18 3.08 12.5 2.25 12.5ZM5.25 15H19.25V13H5.25V15ZM5.25 9H19.25V7H5.25V9ZM5.25 1V3H19.25V1H5.25Z" fill="#64748B"/>
</svg>
`;
icons[
  "link"
] = `<svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5 8H8C8.55 8 9 8.45 9 9C9 9.55 8.55 10 8 10H5C2.24 10 0 7.76 0 5C0 2.24 2.24 0 5 0H8C8.55 0 9 0.45 9 1C9 1.55 8.55 2 8 2H5C3.35 2 2 3.35 2 5C2 6.65 3.35 8 5 8ZM15 0H12C11.45 0 11 0.45 11 1C11 1.55 11.45 2 12 2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H12C11.45 8 11 8.45 11 9C11 9.55 11.45 10 12 10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM6 5C6 5.55 6.45 6 7 6H13C13.55 6 14 5.55 14 5C14 4.45 13.55 4 13 4H7C6.45 4 6 4.45 6 5Z" fill="#64748B"/>
</svg>
`;
icons[
  "image"
] = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 14V2C20 0.9 19.1 0 18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14ZM9.4 10.53L11.03 12.71L13.61 9.49C13.81 9.24 14.19 9.24 14.39 9.49L17.35 13.19C17.61 13.52 17.38 14 16.96 14H7C6.59 14 6.35 13.53 6.6 13.2L8.6 10.53C8.8 10.27 9.2 10.27 9.4 10.53ZM0 18V5C0 4.45 0.45 4 1 4C1.55 4 2 4.45 2 5V17C2 17.55 2.45 18 3 18H15C15.55 18 16 18.45 16 19C16 19.55 15.55 20 15 20H2C0.9 20 0 19.1 0 18Z" fill="#64748B"/>
</svg>
`;
icons[
  "bar"
] = `<svg width="1" height="26" viewBox="0 0 1 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1_15161_43820" fill="white">
<path d="M1 1C1 0.723858 0.776142 0.5 0.5 0.5C0.223858 0.5 0 0.723858 0 1H1ZM0 25C0 25.2761 0.223858 25.5 0.5 25.5C0.776142 25.5 1 25.2761 1 25H0ZM0 1V25H1V1H0Z"/>
</mask>
<path d="M1 1.5C1.27614 1.5 1.5 1.27614 1.5 1C1.5 0.723858 1.27614 0.5 1 0.5V1.5ZM0 0.5C-0.276142 0.5 -0.5 0.723858 -0.5 1C-0.5 1.27614 -0.276142 1.5 0 1.5V0.5ZM0 24.5C-0.276142 24.5 -0.5 24.7239 -0.5 25C-0.5 25.2761 -0.276142 25.5 0 25.5V24.5ZM1 25.5C1.27614 25.5 1.5 25.2761 1.5 25C1.5 24.7239 1.27614 24.5 1 24.5V25.5ZM0 1.5H1V0.5H0V1.5ZM1 24.5H0V25.5H1V24.5ZM2 1C2 0.171573 1.32843 -0.5 0.5 -0.5V1.5C0.223857 1.5 0 1.27614 0 1H2ZM0.5 -0.5C-0.328427 -0.5 -1 0.171573 -1 1H1C1 1.27614 0.776143 1.5 0.5 1.5V-0.5ZM-1 25C-1 25.8283 -0.328481 26.5 0.5 26.5V24.5C0.776197 24.5 1 24.7239 1 25H-1ZM0.5 26.5C1.32848 26.5 2 25.8283 2 25H0C0 24.7239 0.223803 24.5 0.5 24.5V26.5ZM-1 1V25H1V1H-1ZM2 25V1H0V25H2Z" fill="#E2E8F0" mask="url(#path-1-inside-1_15161_43820)"/>
</svg>
`;
icons[
  "Left"
] = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4 5C3.45 5 3 4.55 3 4C3 3.45 3.45 3 4 3H20C20.55 3 21 3.45 21 4C21 4.55 20.55 5 20 5H4ZM14 7H4C3.45 7 3 7.45 3 8C3 8.55 3.45 9 4 9H14C14.55 9 15 8.55 15 8C15 7.45 14.55 7 14 7ZM14 15H4C3.45 15 3 15.45 3 16C3 16.55 3.45 17 4 17H14C14.55 17 15 16.55 15 16C15 15.45 14.55 15 14 15ZM20 13H4C3.45 13 3 12.55 3 12C3 11.45 3.45 11 4 11H20C20.55 11 21 11.45 21 12C21 12.55 20.55 13 20 13ZM4 21H20C20.55 21 21 20.55 21 20C21 19.45 20.55 19 20 19H4C3.45 19 3 19.45 3 20C3 20.55 3.45 21 4 21Z" fill="#64748B"/>
</svg>
`;
icons[
  "Right"
] = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4 5C3.45 5 3 4.55 3 4C3 3.45 3.45 3 4 3H20C20.55 3 21 3.45 21 4C21 4.55 20.55 5 20 5H4ZM10 9H20C20.55 9 21 8.55 21 8C21 7.45 20.55 7 20 7H10C9.45 7 9 7.45 9 8C9 8.55 9.45 9 10 9ZM20 13H4C3.45 13 3 12.55 3 12C3 11.45 3.45 11 4 11H20C20.55 11 21 11.45 21 12C21 12.55 20.55 13 20 13ZM10 17H20C20.55 17 21 16.55 21 16C21 15.45 20.55 15 20 15H10C9.45 15 9 15.45 9 16C9 16.55 9.45 17 10 17ZM4 21H20C20.55 21 21 20.55 21 20C21 19.45 20.55 19 20 19H4C3.45 19 3 19.45 3 20C3 20.55 3.45 21 4 21Z" fill="#64748B"/>
</svg>
`;

export default function CustomMDEditor({ value, onChange, className, toolbar, error, hasRightAlign }: Props) {
  const quillRef = useRef<ReactQuill | null>(null);
  const [modules] = useState({
    toolbar: {
      container: toolbar || [
        ["code-block"],
        ["bar"],
        ["undo", "redo"],
        ["bar"],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "strike"],
        ["bar"],

        [{ list: "ordered" }, { list: "bullet" }],
        ["bar"],
        ["Left", "Right"],
        ["bar"],

        ["link"], ["bar"], ["image"], ["bar"]
      ],
      handlers: {
        undo: () => {
          const myEditor = quillRef.current?.getEditor();
          return (myEditor as any)?.history.undo();
        },
        redo: () => {
          const myEditor = quillRef.current?.getEditor();
          return (myEditor as any)?.history.redo();
        },
        Left: () => {
          const myEditor = quillRef.current?.getEditor();
          if(myEditor?.root) {
            myEditor.root.childNodes.forEach(item => {
              if (item instanceof HTMLElement) {
                item.className = "ql-align-left";
              }
            });
          }
        },
        Right: () => {
          const myEditor = quillRef.current?.getEditor();
          if(myEditor?.root) {
            myEditor.root.childNodes.forEach(item => {
              if (item instanceof HTMLElement) {
                item.className = "ql-align-right";
              }
            });
          }
        }       
      },
    },
    history: {
      delay: 2000,
      maxStack: 500,
      userOnly: true
    }
  })

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "code-block",
  ];
  return (
    <CustomEditor id="parent" value={hasRightAlign}>
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        className={className}
        bounds={"#parent"}
      />
      {error && <span className="error" >{error}</span>}
    </CustomEditor>
  );
};

const CustomEditor = styled("div")(({ value }: { value: boolean })=>({
    '& .error': {
      fontFamily: "Cairo",
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "18px",
      color: "#D97706",
    },
    '& .contenterror .ql-toolbar': {
      background: "#FEF3C7 !important",
    },
    "& .quill": {
      width: "100%",
      height: "584px",
      border: "1px solid #CBD5E1 !important",
      borderRadius: "8px",
      overflowY: "auto",
    },
    "& .quill .ql-toolbar": {
      border: "none",
      borderBottom: "1px solid #CBD5E1",
      color: "red",
      position: "sticky",
      top: 0,
      left: 0,
      zIndex: 1,
      background: "#fff",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px"
    },
    "& .quill .ql-container": {
      border: "none",
      height: "536px"
    },
    "& .ql-container .ql-editor": {
      fontFamily: "Cairo !important",
      fontSize: "16px !important",
      fontWeight: 400,
      lineHeight: "24px !important",
      color: "#0F172A",
      padding: "12px",
      textAlign: value ? "right" : "start"
    },
    "& .ql-container .ql-align-left": {
      textAlign: "left"
    },
    "& .ql-formats": {
      margin: "0 !important"
    },
    "& .ql-bar": {
      width: "100% !important",
      margin: "0 4px"
    }
}))