import React from "react";
import { createTheme, ThemeProvider, FormControl, FormLabel, OutlinedInput } from "@material-ui/core";

const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
                display: "flex",
                flexDirection: "column",
                gap: 4,
                '& .error': {
                    fontFamily: "Cairo",
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: "18px",
                    color: "#D97706",
                }
            },
        },
        MuiFormLabel: {
            root: {
                color: "#64748B",
                fontFamily: "Cairo",
                fontSize: 14,
                lineHeight: "22px",
                fontWeight: 700,
                marginBottom: 4,
                '&.Mui-focused': {
                    color: "#64748B"
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 8,
                '&:hover $notchedOutline': {
                    borderColor: "#CBD5E1",
                },
                '&.Mui-Focused $notchedOutline': {
                    borderColor: "#51ABB3",
                },
                "&.Mui-error $notchedOutline": {
                    borderColor: "#F59E0B !important",
                },
            },
            input: {
                padding: 0,
            },
        },
        MuiInputBase: {
            root: {
                padding: "10px 8px",
                backgroundColor: "#FFFFFF"
            },
            input: {
                fontFamily: "Cairo",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
                color: "#0F172A",
                height: 24,
            },
        },
    },
})

type FormInputWithLabelProps = {
    name: string;
    id: string;
    label?: string;
    placeholder?: string;
    type?: string;
    value: string;
    onValueChange: (key: string, value: string) => void;
    formError?: string;
    maxLength?: number;
}

export default function FormInputWithLabel({
    name,
    id,
    label = "Question*",
    placeholder = "Type question here",
    type,
    value,
    onValueChange,
    formError,
    maxLength = 255,
}: FormInputWithLabelProps) {


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        onValueChange(name, value)
    }

    return (
        <ThemeProvider theme={theme} >
            <FormControl>
                <FormLabel htmlFor={id} >{label}</FormLabel>
                <OutlinedInput
                    id={id}
                    name={name}
                    type={type || "text"}
                    error={!!formError}
                    value={value}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    inputProps={{ maxLength: maxLength }}
                    style={{height: "44px"}}
                />
                {formError && <span className="error" >{formError}</span>}
            </FormControl>
        </ThemeProvider>
    )
}

