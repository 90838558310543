import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    tabButtonGroup: {
        borderRadius: 50,
        backgroundColor: "#F8FAFC",
        width: 155,
        display: "flex",
        padding: 4,
        boxSizing: "border-box",
    },
    tabButton: {
        width: "50%",
        display: "flex",
        gap: 5,
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "22px",
        color: "#64748B",
        border: 0,
        backgroundColor: "transparent",
        padding: 0,
        cursor: "pointer",
        boxSizing: "border-box",
        "&:focus": {
            outline: "0",
        },
        "&[data-selected='true']": {
            backgroundColor: "#E5F1FF",
            border: "1px solid #96CED3",
            color: "#51ABB3",
            borderRadius: 50,
        },
    },
})

type TabButtonProps = {
    value: "grid" | "list";
    onValueChange: (newValue: "grid" | "list") => void;
}

export default function TabButton({value, onValueChange}: TabButtonProps){
    const classes = useStyles()
    return (
        <div role="tablist" className={classes.tabButtonGroup} >
            <button role="tab" aria-controls="content-grid" id="trigger-grid" aria-selected={value==="grid"} data-selected={value==="grid"} className={classes.tabButton} onClick={() => onValueChange("grid")} >
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.31185 8.64575H3.18685C2.79727 8.64575 2.47852 8.327 2.47852 7.93742V5.10408C2.47852 4.7145 2.79727 4.39575 3.18685 4.39575H5.31185C5.70144 4.39575 6.02019 4.7145 6.02019 5.10408V7.93742C6.02019 8.327 5.70144 8.64575 5.31185 8.64575ZM5.31185 13.6041H3.18685C2.79727 13.6041 2.47852 13.2853 2.47852 12.8958V10.0624C2.47852 9.67284 2.79727 9.35409 3.18685 9.35409H5.31185C5.70144 9.35409 6.02019 9.67284 6.02019 10.0624V12.8958C6.02019 13.2853 5.70144 13.6041 5.31185 13.6041ZM7.43685 13.6041H9.56185C9.95144 13.6041 10.2702 13.2853 10.2702 12.8958V10.0624C10.2702 9.67284 9.95144 9.35409 9.56185 9.35409H7.43685C7.04727 9.35409 6.72852 9.67284 6.72852 10.0624V12.8958C6.72852 13.2853 7.04727 13.6041 7.43685 13.6041ZM13.8118 13.6041H11.6868C11.2973 13.6041 10.9785 13.2853 10.9785 12.8958V10.0624C10.9785 9.67284 11.2973 9.35409 11.6868 9.35409H13.8118C14.2014 9.35409 14.5202 9.67284 14.5202 10.0624V12.8958C14.5202 13.2853 14.2014 13.6041 13.8118 13.6041ZM7.43685 8.64575H9.56185C9.95144 8.64575 10.2702 8.327 10.2702 7.93742V5.10408C10.2702 4.7145 9.95144 4.39575 9.56185 4.39575H7.43685C7.04727 4.39575 6.72852 4.7145 6.72852 5.10408V7.93742C6.72852 8.327 7.04727 8.64575 7.43685 8.64575ZM10.9785 7.93742V5.10408C10.9785 4.7145 11.2973 4.39575 11.6868 4.39575H13.8118C14.2014 4.39575 14.5202 4.7145 14.5202 5.10408V7.93742C14.5202 8.327 14.2014 8.64575 13.8118 8.64575H11.6868C11.2973 8.64575 10.9785 8.327 10.9785 7.93742Z" fill="currentColor"/>
                </svg>
                Grid
            </button>
            <button role="tab" aria-controls="content-list" id="trigger-list" aria-selected={value==="list"} data-selected={value==="list"} className={classes.tabButton} onClick={() => onValueChange("list")} >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.00065 5.99992H4.33398C4.70065 5.99992 5.00065 5.69992 5.00065 5.33325V3.99992C5.00065 3.63325 4.70065 3.33325 4.33398 3.33325H3.00065C2.63398 3.33325 2.33398 3.63325 2.33398 3.99992V5.33325C2.33398 5.69992 2.63398 5.99992 3.00065 5.99992ZM4.33398 9.33326H3.00065C2.63398 9.33326 2.33398 9.03326 2.33398 8.66658V7.33325C2.33398 6.96659 2.63398 6.66659 3.00065 6.66659H4.33398C4.70065 6.66659 5.00065 6.96659 5.00065 7.33325V8.66658C5.00065 9.03326 4.70065 9.33326 4.33398 9.33326ZM4.33398 12.6666H3.00065C2.63398 12.6666 2.33398 12.3666 2.33398 11.9999V10.6666C2.33398 10.2999 2.63398 9.99992 3.00065 9.99992H4.33398C4.70065 9.99992 5.00065 10.2999 5.00065 10.6666V11.9999C5.00065 12.3666 4.70065 12.6666 4.33398 12.6666ZM13.0006 9.33326H6.33398C5.96732 9.33326 5.66732 9.03326 5.66732 8.66658V7.33325C5.66732 6.96659 5.96732 6.66659 6.33398 6.66659H13.0006C13.3673 6.66659 13.6673 6.96659 13.6673 7.33325V8.66658C13.6673 9.03326 13.3673 9.33326 13.0006 9.33326ZM6.33398 12.6666H13.0006C13.3673 12.6666 13.6673 12.3666 13.6673 11.9999V10.6666C13.6673 10.2999 13.3673 9.99992 13.0006 9.99992H6.33398C5.96732 9.99992 5.66732 10.2999 5.66732 10.6666V11.9999C5.66732 12.3666 5.96732 12.6666 6.33398 12.6666ZM5.66732 5.33325V3.99992C5.66732 3.63325 5.96732 3.33325 6.33398 3.33325H13.0006C13.3673 3.33325 13.6673 3.63325 13.6673 3.99992V5.33325C13.6673 5.69992 13.3673 5.99992 13.0006 5.99992H6.33398C5.96732 5.99992 5.66732 5.69992 5.66732 5.33325Z" fill="currentColor"/>
                </svg>
                List
            </button>
        </div>
    )
}
