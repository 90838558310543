import { PaperClassKey, PopoverClassKey, StyleRules } from "@material-ui/core";

const MuiPopover: Partial<StyleRules<PopoverClassKey>> = {
    paper: {
        minWidth: 0,
        maxWidth: "108px",
        width: 108,
        border: "1px solid #E2E8F0",
        padding: 4,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        backgroundColor: "#FFFFFF",
        '& > button': {
            all: "unset",
            cursor: "pointer",
            display: "flex",
            gap: 8,
            alignItems: "center",
            padding: "9px 16px",
            fontFamily: "Cairo",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "22px",
            color:"#0F172A",
            '&:hover, &[data-active="true"]': {
                backgroundColor: "#F1F5F9",
                borderRadius: 8,
            },
        },
    },
}

const MuiPaper: Partial<StyleRules<PaperClassKey>> = {
    elevation8: {
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    },
    rounded: {
        borderRadius: 8,
    },
}

export const theme = {
    MuiPopover,
    MuiPaper
}