export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const sendImgVisible = require("../assets/send.png");
export const videocamImg = require("../assets/image_videocam.svg");
export const bookImgVisible = require("../assets/image_book.svg");
export const callSplitImgVisible = require("../assets/image_call_split.svg");
export const linkImg = require("../assets/image_link.svg");
export const listImg = require("../assets/image_list.svg");
export const tollImg = require("../assets/image_toll.svg");
export const updateImgVisible = require("../assets/image_update.svg");
export const starImgVisible = require("../assets/image_star_border.svg");
export const copyImgVisible = require("../assets/image_copy.svg");
export const categoryImg = require("../assets/image_category.svg");
export const arrowRightImg = require("../assets/image_arrow_right.svg");
export const allInboxImg = require("../assets/image_all_inbox.svg");
export const viewImg = require("../assets/view_.svg");
export const closeDrawerIcon = require("../assets/button_close.svg");

