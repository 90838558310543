import React from "react";
import {InfoOutlined, CloseOutlined} from '@material-ui/icons'
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    errorContainer: {
        borderLeft: "4px solid #F59E0B",
        backgroundColor: "#FEF3C7",
        display: "flex",
        gap: 8,
        alignItems: "center",
        padding: "8px 8px 8px 16px",
        borderRadius: 4,
    },
    errorText: {
        margin: 0,
        fontFamily: "Cairo",
        fontSize: 12,
        lineHeight: "18px",
        fontWeight: 400,
        color: "#F59E0B",
    },
    closeButton: {
        all: "unset",
        cursor: "pointer",
        marginLeft: "auto",
    },
})

export default function MaximumOptionError({error, handleClose}: {error: string | null; handleClose: () => void;}) {
    const classes = useStyles()

    if(!error) {
        return null
    }

    return (
        <div className={classes.errorContainer} >
            <InfoOutlined htmlColor="#F59E0B" />
            <p className={classes.errorText} >
                {error}
            </p>
            <button aria-label="close error" className={classes.closeButton} onClick={handleClose} >
                <CloseOutlined htmlColor="#F59E0B" />
            </button>
        </div>
    )
}
