import { getStorageData } from "../../framework/src/Utilities";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

interface StaticContext {
    statusCode?: number | undefined;
}

type Props = RouteComponentProps<{
    [x: string]: string | undefined;
}, StaticContext, unknown> & {children: React.ReactNode}

const privateRoutes = new Set(["/login", "/forgot-password", "/change-password"])
const publicChatRouteRegex = /^\/bots\/[^/]+\/chat$/;


export default function ProtectedRoutes(props: Props) {
    const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false)
    useEffect(() => {
        (async() => {
            const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
            const isPrivateRoute = privateRoutes.has(props.location.pathname)
            const isPublicChatRoute = publicChatRouteRegex.test(props.location.pathname);
            if(!token && !isPrivateRoute && !isPublicChatRoute) {
                props.history.push("/login")
                return;
            }
            setIsUserLoggedIn(true)
            if(token && isPrivateRoute) {
                props.history.push("/dashboard")
            }
        })()
    }, [])

    useLayoutEffect(() => {
        const unlisten = props.history.listen(() => {
            document.getElementById("container")?.scrollTo(0,0)
        })

        return () => { 
            unlisten() 
        }
    }, [props.history])

    return (isUserLoggedIn || publicChatRouteRegex.test(props.location.pathname)) ? props.children : null
}
