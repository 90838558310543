Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectTemplates";
exports.labelBodyText = "ProjectTemplates Body";
exports.projectAPiEndPoint = "bx_block_projecttemplates/project_templates";

exports.btnExampleTitle = "CLICK ME";
exports.createCategoryEndpoint = "bx_block_chatbot6/categories";
exports.createTemplateEndpoint = "bx_block_chatbot6/card_templates";
exports.allCategoriesEndpoint = "bx_block_chatbot6/categories";
exports.allTemplatesEndpoint = "bx_block_chatbot6/card_templates";
exports.editCategoryEndpoint = "bx_block_chatbot6/categories";
exports.editTemplateEndpoint = "bx_block_chatbot6/card_templates";
// Customizable Area End