import React from "react";
import { theme as styledTheme } from "../theme.web";
import { styled, InputLabel, Switch, createTheme, ThemeProvider } from "@material-ui/core";
import MultiSelectCheckbox from "../../../../components/src/MultiSelectCheckbox.web";
import { Practice } from "../AutomaticFormCreationController.web";

const { StyledRow } = styledTheme

const settingsTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#475569",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Cairo",
      },
    },
    MuiSwitch: {
      input: {
        left: 0,
        width: 48,
        height: 28,
      },
      root: {
        borderRadius: 40,
        padding: 0,
        width: 48,
        height: 28,
      },
      thumb: {
        width: 24,
        height: 24,
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 8px 0px #00000014",
      },
      switchBase: {
        padding: 0,
        left: 2,
        top: 2,
        '&.Mui-checked': {
          '& input': {
            left: "-24px"
          },
        },
      },
      colorSecondary: {
        '&.Mui-checked + $track': {
          backgroundColor: "#51ABB3",
          opacity: 1,
        },
      },
      track: {
        backgroundColor: "#E2E8F0",
        opacity: 1,
      },
    },
  },
})

const SettingsContainer = styled("div")({
  width: 416,
  boxSizing: "border-box",
  padding: "24px 40px",
  backgroundColor: "#FFFFFF",
  display: 'flex',
  flexDirection: "column",
  gap: 12,
  boxShadow: "0px 2px 8px 0px #00000014",
  '& h5': {
    margin: 0,
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "26px",
    color: "#0F172A",
    marginBottom: 12,
  },
})

type SettingsProp = {
  isGlobal: boolean;
  practices: Practice[];
  showError: boolean;
  handleChangeSelectedPractieValue: (value: number[]) => void;
  selectedPracticeValue: number[];
  selectPracticeError?: string;
  handleGolbalSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCloseError: () => void;
}

export default function Settings({
  practices,
  selectedPracticeValue,
  selectPracticeError,
  isGlobal,
  handleChangeSelectedPractieValue,
  handleGolbalSwitchChange,
  showError,
  handleCloseError,
}: SettingsProp) {
  return (
    <ThemeProvider theme={settingsTheme} >
      <SettingsContainer>
        <h5>Settings</h5>
        <MultiSelectCheckbox
          key={practices.length === 0 ? "no-practice" : "practice"}
          label="Practice"
          placeholder="Select a practice"
          value={selectedPracticeValue}
          hasSearch={true}
          options={practices}
          onApply={handleChangeSelectedPractieValue}
          error={selectPracticeError}
          disabled={isGlobal}
          showError={showError}
          handleCloseError={handleCloseError}
        />
        <StyledRow className="space-between items-center">
          <InputLabel htmlFor="global-switch" >Make this form global</InputLabel>
          <Switch
            id="global-switch"
            disableRipple
            checked={isGlobal}
            onChange={handleGolbalSwitchChange}
          />
        </StyledRow>
      </SettingsContainer>
    </ThemeProvider>
  )
}
