// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { runEngine } from "../../../../framework/src/RunEngine";
import { CroppedImage } from "../Customiseinterface2Controller";

export type ChatbotProfilePictureProps = {
    headerLabel: string;
    handleHeaderLabelChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    headerImageURL: string | null;
    handleHeaderImageChange: (image: CroppedImage | null) => void;
}

interface S {
    isEditProfilePicOpen: boolean;
    isProfilePicAdjustDialogOpen: boolean;
}

interface SS {}

export default class ChatbotProfilePictureController extends BlockComponent<ChatbotProfilePictureProps, S, SS> {

    editBtnRef = React.createRef<HTMLButtonElement>()
    fileInputRef = React.createRef<HTMLInputElement>()
    fileRef: string = ""

    constructor(props: ChatbotProfilePictureProps) {
        super(props)

        this.subScribedMessages = []

        this.state = {
            isEditProfilePicOpen: false,
            isProfilePicAdjustDialogOpen: false
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    handleOpenEditProfile = () => {
        this.setState({isEditProfilePicOpen: true})
    }

    handleCloseEditProfile = () => {
        this.setState({isEditProfilePicOpen: false})
    }
    
    handleCloseProfileAdjustDialog = () => {
        this.setState({isProfilePicAdjustDialogOpen: false})
    }

    handleUploadFile = () => {
        this.fileInputRef.current?.click()
    }

    onFileChange = (name: string, imageURL: string) => {
        this.fileRef = imageURL
        this.setState({isEditProfilePicOpen: false, isProfilePicAdjustDialogOpen: true})
    }

    handleRemovePhoto = () => {
        this.props.handleHeaderImageChange(null)
        this.setState({isEditProfilePicOpen: false})
    }
    
    onApply = (image: CroppedImage) => {
        this.props.handleHeaderImageChange(image)
        this.setState({isProfilePicAdjustDialogOpen: false})
    }

}
// Customizable Area End
