import React from "react"
import { Button, Modal, Typography, styled } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { theme as styledTheme } from "../theme.web";
import GenericError from "../../../../components/src/GenericError.web";
const { StyledRow } = styledTheme

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
type Props = {
    open: boolean;
    onClose: any;
    handleFileChange: any;
    handleViewFile: any;
    handleCloseError: any;
    errorMessage: string;
    fileName: string | null;
    showError: boolean;
    fileUrl: string | null;
    saveCSVFileData: any;
}

export default function BulkUploadModal({ open, onClose, handleFileChange, saveCSVFileData, errorMessage, fileName, handleCloseError, showError, fileUrl, handleViewFile }: Props) {
    return (<ModalBody
        open={open}
        onClose={onClose}
        className="customDialog"
    >
        <div className="dialog">
            <StyledRow className="space-between dialog-header">
                <Typography variant="h4">Bulk upload</Typography>
                <CloseIcon onClick={onClose} style={{cursor: "pointer"}} />
            </StyledRow>
            <StyledRow className="dialog-body flex-column">
                <StyledRow className="items-center space-between" style={{ width: "100%" }}>
                    <Typography style={{
                        fontFamily: "Cairo",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        textAlign: "left"
                    }}>Please specify a file to import from.</Typography>
                    <div>
                        <Button className="bulk-upload-btn" onClick={handleViewFile}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.9998 16C13.2498 16 14.3125 15.5627 15.1878 14.688C16.0625 13.8127 16.4998 12.75 16.4998 11.5C16.4998 10.25 16.0625 9.18733 15.1878 8.312C14.3125 7.43733 13.2498 7 11.9998 7C10.7498 7 9.68714 7.43733 8.8118 8.312C7.93714 9.18733 7.4998 10.25 7.4998 11.5C7.4998 12.75 7.93714 13.8127 8.8118 14.688C9.68714 15.5627 10.7498 16 11.9998 16ZM11.9998 14.2C11.2498 14.2 10.6125 13.9373 10.0878 13.412C9.56247 12.8873 9.2998 12.25 9.2998 11.5C9.2998 10.75 9.56247 10.1123 10.0878 9.587C10.6125 9.06233 11.2498 8.8 11.9998 8.8C12.7498 8.8 13.3875 9.06233 13.9128 9.587C14.4375 10.1123 14.6998 10.75 14.6998 11.5C14.6998 12.25 14.4375 12.8873 13.9128 13.412C13.3875 13.9373 12.7498 14.2 11.9998 14.2ZM11.9998 19C9.68314 19 7.56647 18.3877 5.64981 17.163C3.73314 15.9377 2.28314 14.2833 1.2998 12.2C1.2498 12.1167 1.21647 12.0123 1.1998 11.887C1.18314 11.7623 1.1748 11.6333 1.1748 11.5C1.1748 11.3667 1.18314 11.2373 1.1998 11.112C1.21647 10.9873 1.2498 10.8833 1.2998 10.8C2.28314 8.71667 3.73314 7.06267 5.64981 5.838C7.56647 4.61267 9.68314 4 11.9998 4C14.3165 4 16.4331 4.61267 18.3498 5.838C20.2665 7.06267 21.7165 8.71667 22.6998 10.8C22.7498 10.8833 22.7831 10.9873 22.7998 11.112C22.8165 11.2373 22.8248 11.3667 22.8248 11.5C22.8248 11.6333 22.8165 11.7623 22.7998 11.887C22.7831 12.0123 22.7498 12.1167 22.6998 12.2C21.7165 14.2833 20.2665 15.9377 18.3498 17.163C16.4331 18.3877 14.3165 19 11.9998 19ZM11.9998 17C13.8831 17 15.6125 16.504 17.1878 15.512C18.7625 14.5207 19.9665 13.1833 20.7998 11.5C19.9665 9.81667 18.7625 8.479 17.1878 7.487C15.6125 6.49567 13.8831 6 11.9998 6C10.1165 6 8.38714 6.49567 6.8118 7.487C5.23714 8.479 4.03314 9.81667 3.1998 11.5C4.03314 13.1833 5.23714 14.5207 6.8118 15.512C8.38714 16.504 10.1165 17 11.9998 17Z" fill="#0F172A" />
                            </svg>
                            View CSV template
                        </Button>
                    </div>
                </StyledRow>
                {errorMessage && showError && <GenericError handleCloseError={handleCloseError} error={errorMessage} />}
                <StyledRow className="flex-column upload-btn">
                    <svg width="37" height="27" viewBox="0 0 37 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.31608 26.7565C6.7883 26.7565 4.62886 25.8815 2.83775 24.1315C1.04552 22.3815 0.149414 20.2426 0.149414 17.7148C0.149414 15.5482 0.802192 13.6176 2.10775 11.9232C3.4133 10.2287 5.12164 9.1454 7.23275 8.67318C7.92719 6.11762 9.31608 4.04818 11.3994 2.46484C13.4827 0.881511 15.8439 0.0898438 18.4827 0.0898438C21.7327 0.0898438 24.4894 1.22151 26.7527 3.48484C29.0172 5.74929 30.1494 8.50651 30.1494 11.7565C32.0661 11.9787 33.6566 12.8048 34.9211 14.2348C36.1844 15.666 36.8161 17.3398 36.8161 19.2565C36.8161 21.3398 36.0872 23.111 34.6294 24.5698C33.1705 26.0276 31.3994 26.7565 29.3161 26.7565H20.1494C19.2327 26.7565 18.4483 26.4304 17.7961 25.7782C17.1427 25.1248 16.8161 24.3398 16.8161 23.4232V14.8398L14.1494 17.4232L11.8161 15.0898L18.4827 8.42318L25.1494 15.0898L22.8161 17.4232L20.1494 14.8398V23.4232H29.3161C30.4827 23.4232 31.4689 23.0204 32.2744 22.2148C33.08 21.4093 33.4827 20.4232 33.4827 19.2565C33.4827 18.0898 33.08 17.1037 32.2744 16.2982C31.4689 15.4926 30.4827 15.0898 29.3161 15.0898H26.8161V11.7565C26.8161 9.45095 26.0039 7.4854 24.3794 5.85984C22.7539 4.2354 20.7883 3.42318 18.4827 3.42318C16.1772 3.42318 14.2122 4.2354 12.5877 5.85984C10.9622 7.4854 10.1494 9.45095 10.1494 11.7565H9.31608C7.70497 11.7565 6.32997 12.326 5.19108 13.4648C4.05219 14.6037 3.48275 15.9787 3.48275 17.5898C3.48275 19.201 4.05219 20.576 5.19108 21.7148C6.32997 22.8537 7.70497 23.4232 9.31608 23.4232H13.4827V26.7565H9.31608Z" fill="#51ABB3" />
                    </svg>
                    <Typography component={"h4"}>
                        Upload your file
                    </Typography>
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                    >
                        Browse files
                        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                    </Button>
                    {fileName && <Typography style={{
                        fontFamily: "Cairo",
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        textAlign: "left",
                        color: "#51ABB3"
                    }}>{fileName}</Typography>}
                </StyledRow>
            </StyledRow>
            <StyledRow style={{ justifyContent: "flex-end" }} className="dialog-footer">
                <Button className="Btn" onClick={onClose}>Cancel</Button>
                <Button className="Btn" style={{ background: "#51ABB3", color: "#fff", }} onClick={saveCSVFileData}>Import</Button>
            </StyledRow>
        </div>
    </ModalBody>)
}

const ModalBody = styled(Modal)({
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",
    width: "100%",
    height: "100%",

    "& .dialog": {
        background: "#fff",
        borderRadius: "24px",
        width: "716px",
        minHeight: "553px",
        margin: "0 auto",
        outline: "none"
    },
    "& .dialog-header": {
        padding: "24px 40px 24px 40px",
        gap: "10px",
        borderBottom: "1px solid #CBD5E1"
    },
    "& .dialog-footer": {
        padding: "24px 40px 24px 40px",
        gap: "10px",
        borderTop: "1px solid #CBD5E1",
    },
    "& .dialog-body": {
        padding: "24px 40px 24px 40px",
        gap: "16px",
    },
    "& .bulk-upload-btn": {
        cursor: "pointer",
        background: "#fff",
        color: "#0F172A",
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "14px"
    },
    "& .bulk-upload-btn span": {
        gap: "6px",
    },
    "& .upload-btn": {
        background: "rgba(81, 171, 179, 0.1)",
        borderRadius: "8px",
        padding: "8px",
        height: "265px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        border: "1px dotted #51ABB3"
    },
    "& .upload-btn h4": {
        color: "rgba(81, 171, 179, 1)",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textAlign: "center",
    },
    "& .upload-btn label": {
        color: "#51ABB3",
        textTransform: "capitalize",
        background: "#E5F6FF",
        border: "1px solid #51ABB3",
        boxShadow: "none",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        textAlign: "center",
        borderRadius: "8px"
    },
    "& .dialog-footer button": {
        height: "44px",
        width: "105px",
        padding: "16px",
        borderRadius: "8px",
        textTransform: "capitalize",
    },
    "& .dialog-footer button:nth-child(1)": {
        background: "#E5F6FF",
        color: "#51ABB3"
    },
    "& .dialog-header h4": {
        fontFamily: "Cairo",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.11999999731779099px",
        textAlign: "left",
    },
    "& .dialog-header button": {
        width: "14px",
        height: "14px",
        background: "#fff",
        border: "none",
        color: "#334155"
    },
})