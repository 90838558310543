import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Breadcrumbs,
  FormLabel,
  styled,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { 
  ArrowBack, 
  Close,
} from "@material-ui/icons";
import CustomSelect  from "../../../components/src/CustomSelect.web";
import GeneralSettings from "../../settings2/src/Settings2.web";
import DesignSettings from "./components/DesignSettings.web";
import ChatPreview from "./components/ChatPreview.web";

const SpinnerContainer = styled("div")({
  position: "fixed",
  inset: 0,
  width: "100vw",
  height: "100vh",
  display: "grid",
  placeItems: "center",
  backgroundColor: "rgba(15, 23, 42, 0.4)",
  zIndex: 5,
  backdropFilter: "blur(5px)",
})

const SettingsContainer = styled("div")({
  borderRadius: "8px 8px 0 0",
  backgroundColor: "#FFF",
  padding: 24,
  display: "flex",
  flexDirection: "column",
  gap: 24,
})

const SettingsTabs = styled("div")({
  '&.tabs': {
    width: 270,
    padding: 4,
    backgroundColor: "#F8FAFC",
    borderRadius: 50,
    display: "flex",
    position: "relative",
  },
  '& .activeTab': {
    position: "absolute",
    inset: 4,
    width: 133,
    border: "1px solid #96CED3",
    backgroundColor: "#E5F1FF",
    borderRadius: 50,
    transition: "all 150ms ease-in-out",
  },
})

const SettingsTabButton = styled("button")({
  cursor: "pointer",
  border: 0,
  backgroundColor: "transparent",
  width: 135,
  textAlign: "center",
  fontSize: 16,
  lineHeight: "22px",
  fontWeight: 700,
  fontFamily: "Cairo",
  color: "#64748B",
  padding: "2px 0",
  position: "relative",
  transition: "all 150ms ease-in-out",
  zIndex: 2,
  '&:focus': {
    outline: "none",
  },
  '&[aria-selected="true"]': {
    color: "#51ABB3",
  },
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Cairo",
    h6: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "24px",
      color: "#0F172A",
    },
  },
  overrides: {
    MuiBreadcrumbs: {
      separator: {
        marginLeft: 0,
        marginRight: 0,
      },
      ol: {
        gap: 2,
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        fontFamily: "Cairo",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#51ABB3",
        borderRadius: 8,
        '&:hover': {
            backgroundColor: "transparent",
            boxShadow: 'none',
        },
        '&.text-lg': {
            fontSize: 16,
            lineHeight: '24px',
        },
        '&.inter .MuiButton-label': {
            fontFamily: "Inter",
        },
        '&.Mui-secondary': {
            backgroundColor: "#E5F6FF",
            padding: "10px 16px 10px 16px",
            color: "#51ABB3",
        },
        '&.Mui-primary': {
            color: "#FFFFFF",
            backgroundColor: "#51ABB3",
            padding: "10px 16px 10px 16px"
        },
        '&.Mui-secondary:hover': {
          backgroundColor: "#E5F6FF",
          boxShadow: 'none',
        },
        '&.Mui-primary:hover': {
          backgroundColor: "#51ABB3",
        },
      },
      text: {
        fontFamily: "Cairo",
        fontSize: 24,
        fontWeight: 400,
        lineHeight: "32px",
        padding: "4px 8px",
        color: "#475569",
        letterSpacing: "-0.005em",
        '&.active': {
          fontWeight: 700,
          color: "#0F172A"
        },
      },
      contained: {
        '&.Mui-disabled': {
          backgroundColor: "#51ABB3",
          color: "#FFFFFF",
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-select': {
          fontSize: 16,
          lineHeight: "24px",
          color: "#64748B"
        },
      },
    },
  }
});
// Customizable Area End

import Customiseinterface2Controller, {
  Props,
  configJSON,
} from "./Customiseinterface2Controller";

export default class Customiseinterface2 extends Customiseinterface2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderArrowForward = () => (
    <svg width="24" height="24" id="arrow-forward" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99953 6.70978C8.60953 7.09978 8.60953 7.72978 8.99953 8.11978L12.8795 11.9998L8.99953 15.8798C8.60953 16.2698 8.60953 16.8998 8.99953 17.2898C9.38953 17.6798 10.0195 17.6798 10.4095 17.2898L14.9995 12.6998C15.3895 12.3098 15.3895 11.6798 14.9995 11.2898L10.4095 6.69978C10.0295 6.31978 9.38953 6.31978 8.99953 6.70978Z" fill="#64748B"/>
    </svg>
  )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.loading && (
          <SpinnerContainer data-testid="loader" >
            <CircularProgress style={{height: 40, width: 40, color: "#FFFFFF"}} />
          </SpinnerContainer>
        )}
        <Box sx={webStyle.headerWrapper} >
            <IconButton style={{marginRight: 4}} onClick={this.handleNavigateToSpecificBotPage} >
              <ArrowBack htmlColor="#0F172A" />
            </IconButton>
            <Breadcrumbs data-test-id="breadcrumb" separator={this.renderArrowForward()} >
              <Button variant="text" data-testid="bots" onClick={this.handleNavigateToBotsage} >Chat Bot</Button>
              {this.state.chatbotName && <Button variant="text" onClick={this.handleNavigateToSpecificBotPage} >{this.state.chatbotName}</Button>}
              <Button variant="text" className="active" >Chatbot settings</Button>
            </Breadcrumbs>
            <Button 
              variant="contained" 
              disableElevation 
              style={{marginLeft: "auto", width: 100}} 
              className="text-lg Mui-secondary"
              onClick={this.handleCancelAllChanges}
              data-testid="cancel" 
            >
              Cancel
            </Button>
            <Button data-testid="apply-btn" variant="contained" disabled={this.state.isUpdateSettingsLoading} onClick={this.handleUpdateSettings} disableElevation style={{width: 100}} className="text-lg Mui-primary" >
              { this.state.isUpdateSettingsLoading ? (
                <CircularProgress data-testid="circular-loader" style={{color: "#FFFFFF", height: 24, width: 24}} />
              ) : "Apply" }
            </Button>
            <IconButton data-testid="close-settings" onClick={this.handleNavigateToSpecificBotPage} >
              <Close htmlColor="#0F172A" />
            </IconButton>
        </Box>
        <Box sx={{display: "flex", zIndex: 1}}>
          <Box sx={webStyle.settingsPracticeWrapper} >
              <Box sx={webStyle.practiceAssociationWrapper}>
                <Typography variant="h4">Practice association*</Typography>
                <FormLabel id="practice" htmlFor="practice" className="Mui-select">
                  Select the practice association to this bot
                </FormLabel>
                <CustomSelect
                  key={this.state.practices.length === 0 ? "no-practice" : "practice"}
                  labelId="practice" 
                  id="select" 
                  placeholder="Practice association" 
                  value={this.state.selectedPracticeValue}
                  onChange={this.handlePracticeChange}
                  hasSearch
                  searchPlaceholder="Search practices" 
                  options={this.state.practices}
                  selectBoxWidth={401}
                  data-testid="practice-select"
                />
              </Box>
              <SettingsContainer data-testid="settings" >
                <SettingsTabs className="tabs" >
                  <SettingsTabButton
                    role="tab"
                    data-testid="general"
                    aria-selected={this.state.activeTab === "general"}
                    onClick={this.handleChangeTab("general")}
                  >
                    General settings
                  </SettingsTabButton>
                  <SettingsTabButton
                    role="tab"
                    data-testid="design"
                    aria-selected={this.state.activeTab === "design"}
                    onClick={this.handleChangeTab("design")}
                  >
                    Design settings
                  </SettingsTabButton>
                  <div className="activeTab" style={{ transform: this.state.activeTab === "general" ? "translateX(0)" : "translateX(135px)" }} />
                </SettingsTabs>
                {
                  this.state.activeTab === "general" ? (
                    <GeneralSettings 
                      chatInitiateOnMobile={this.state.chatInitiateOnMobile}
                      chatInitiateOnDesktop={this.state.chatInitiateOnDesktop}
                      responseInterval={this.state.responseInterval}
                      userAction={this.state.userAction}
                      chatbotURL={this.state.chatbotURL}
                      qrCodeImageFile={this.state.qrCodeImageFile}
                      qrCodeImageURL={this.state.qrCodeImageURL}
                      shouldUseOpenAI={this.state.shouldUseOpenAI}
                      navigation={this.props.navigation}
                      id="general-settings"
                      handleGeneralSettingsStateChange={this.handleGeneralSettingsStateChange}
                      data-testid="general-settings"
                    />
                  ) : null
                }
                {
                  this.state.activeTab === "design" ? (
                    <DesignSettings 
                      headerColor={this.state.headerColor}
                      backgroundColor={this.state.backgroundColor}
                      widget={this.state.widget}
                      headerLabel={this.state.headerLabel}
                      headerImageURL={this.state.headerImageURL}
                      handleChangeHeaderColor={this.handleChangeHeaderColor}
                      handleChangeBackgroundColor={this.handleChangeBackgroundColor}
                      handleWidgetChange={this.handleWidgetChange}
                      handleHeaderLabelChange={this.handleHeaderLabelChange}
                      handleHeaderImageChange={this.handleHeaderImageChange}
                      data-testid="design-settings"
                    />
                  ) : null
                }
              </SettingsContainer>
          </Box>
          <ChatPreview 
            headerColor={this.state.headerColor}
            backgroundColor={this.state.backgroundColor}
            headerLabel={this.state.headerLabel}
            data-test-id="chat-preview"
            headerImageURL={this.state.headerImageURL}
            userActions={this.state.userAction}
          />
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  headerWrapper: {
    display: "flex",
    padding: "24px 48px",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 8px 0px #00000014",
    gap: 12,
    alignItems: "center",
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  practiceAssociationWrapper: {
    borderRadius: 8,
    backgroundColor: "#FFF",
    padding: 24,
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  settingsPracticeWrapper: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    padding: "34px 54px 24px 42px",
    backgroundColor: "#FAFAFA",
    gap: 8,
    boxSizing: "border-box",
  },
};
// Customizable Area End
