import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Criteria } from "./components/AudienceCriteria.web";
import { DropResult } from "@hello-pangea/dnd";
import {utils} from "./utils";
import { toast } from "react-toastify";

export type Rule = {
  title: string;
  ruleId?: number;
  criteria: Criteria[];
  id: number;
  nextCardId?: string | null;
}

export type CriteriaRouting = {
  title: string;
  criteria_routing_id?: number;
  rules: Array<{
    rule_id?: number;
    field_name: string;
    comparator: string;
    values: string[];
    operator_with_previous_index: string;
    sub_field?: string;
  }>;
  nextCardId?: string | null;
}

export type CriteriaRoutingAttributes = {
  criteria_routing_id?: number;
  criteria_routing_title: string;
  rules_attributes: Record<string, string | number | string[]>[];
  next_card_id?: number | null;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  cardId?: number;
  onClose: () => void;
  onSaveClick: (args: {name: string, criteriaRoutings: CriteriaRoutingAttributes[], cardId?:number, criteriaRoutingIds: number[], ruleIds: number[]}) => void;
  onDrawerClose: () => void;
  loading: boolean;
  initialCriteriaRoutings: CriteriaRouting[];
  name?: string;
  contextVariables: {subFields: string[],subFieldValue: Record<string, string[]>};
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  nameError: boolean;
  nameValue: string;
  isAudienceCriteria: boolean;
  rules: Rule[];
  selectedCriteria: Criteria[] | undefined;
  deletedRuleIds: number[];
  deletedCriteriaRoutingIds: number[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfconnectorlogicController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  currentRuleId: number | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      nameError: false,
      nameValue: this.props.name ?? "",
      isAudienceCriteria: false,
      rules: [],
      selectedCriteria: undefined,
      deletedRuleIds: [],
      deletedCriteriaRoutingIds: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    if(this.props.initialCriteriaRoutings.length > 0) {
      const newRules: Rule[] = this.props.initialCriteriaRoutings.filter(criteria =>criteria.title !== "Not Matched").map((criteriaRouting) => {
        const allCriterias: Criteria[] = criteriaRouting.rules.map((rule) => {
          const criteria: Criteria = {
            criteriaId: rule.rule_id,
            fieldName: rule.field_name,
            comparator: rule.comparator,
            criteriaOperatorWithPreviousCriteria: rule.operator_with_previous_index,
          }
          if(rule.field_name === "current_page_url") {
            criteria.currentPageURL = rule.values[0]
          } else if (rule.field_name === "operating_system") {
            criteria.selectedOperatingSystems = rule.values
          } else if(rule.field_name === "time_of_the_day") {
            criteria.time = rule.values
          }
          if(rule.field_name ==="bot_contexts") {
            criteria.subField = rule.sub_field
            criteria.selectedSubFieldOptions = rule.values
          }
          return criteria
        })
        
        return {
          title: criteriaRouting.title,
          ruleId: criteriaRouting.criteria_routing_id,
          id: criteriaRouting.criteria_routing_id!,
          criteria: allCriterias,
          nextCardId: criteriaRouting.nextCardId
        }
          
      })
      this.setState({rules: newRules})
    }
  }
  
  handleChangeNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({nameValue: event.target.value, nameError: event.target.value.trim().length === 0})
  }

  handleAddRule = () => {
    this.setState({isAudienceCriteria: true})
  }

  onAddRule = (criteria: Criteria[]) => {
    let newRules = [...this.state.rules]
    if(this.currentRuleId) {
      const currentRuleIndex = newRules.findIndex(rule => rule.id === this.currentRuleId)
      newRules[currentRuleIndex] = {
        ...newRules[currentRuleIndex],
        criteria
      }
    } else {
      const newRule: Rule = {
        title: `Rule ${this.state.rules.length + 1}`,
        id: Date.now(),
        criteria,
        nextCardId: null,
      }
      newRules.push(newRule)
    }
    this.currentRuleId = null
    this.setState({rules: newRules, isAudienceCriteria: false, selectedCriteria: undefined})
  }

  onDeleteRule = (ruleId: number, originalRuleId?: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    const newRules = this.state.rules.filter(rule => rule.id !== ruleId)
    this.currentRuleId = null
    const newDeletedRuleIds = [...this.state.deletedRuleIds]
    if(originalRuleId) {
      newDeletedRuleIds.push(originalRuleId)
    }
    this.setState({rules: newRules, selectedCriteria: undefined, deletedRuleIds: newDeletedRuleIds})
  }

  onDeleteCriteria = (criteriaId: number) => {
    const newDeletedCriteriaIds = [...this.state.deletedCriteriaRoutingIds, criteriaId]
    this.setState({deletedCriteriaRoutingIds: newDeletedCriteriaIds})
  }

  onDragEnd = (result: DropResult) => {
    if(!result.destination) {
        return;
    }

    if (result.destination.index === result.source.index) {
        return;
    }
    const newRules = [...this.state.rules]
    const [removed] = newRules.splice(result.source.index, 1);
    newRules.splice(result.destination.index, 0, removed);
    this.setState({rules: newRules})
  }

  onRuleAndCriteriaClick = (ruleIndex: number) => (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    const {id: ruleId, criteria} = this.state.rules[ruleIndex]
    this.currentRuleId = ruleId
    this.setState({selectedCriteria: criteria, isAudienceCriteria: true})
  }

  onSave = () => {
    if(this.state.isAudienceCriteria || this.state.nameError) {
      return;
    }
    const hasNameError = this.state.nameValue.trim().length === 0
    if(hasNameError) {
      this.setState({nameError: hasNameError})
      return;
    }
    this.setState({nameError: false})

    if(this.state.rules.length === 0) {
      toast.error("Please add a rule", {className: "error__toast"})
      return;
    }

    const criteriaRoutings = utils.transfromRulesToCriteriaRoutings(this.state.rules)

    this.props.onSaveClick({name: this.state.nameValue, criteriaRoutings, cardId: this.props.cardId, criteriaRoutingIds: this.state.deletedRuleIds, ruleIds: this.state.deletedCriteriaRoutingIds})
  }
  // Customizable Area End
}
