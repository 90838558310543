import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities"
import { toast } from "react-toastify";
import { eachDayOfInterval, format } from "date-fns";

export type PatientsData = {
  id: number;
  date: string;
  patients: number;
  question: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  info: any;
  data: any;
  token: string;
  googleChartData: any;
  mobilePercentage: number;
  desktopPercentage: number;
  mostPopularHour: string;
  totalChats: number;
  loading: boolean;
  patientsData: PatientsData[];
  chartLabels: string[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
        },
      },
      googleChartData: ["Title", "Value"],
      mobilePercentage: 0,
      desktopPercentage: 0,
      mostPopularHour: "00:00 - 00:00",
      totalChats: 0,
      loading: false,
      patientsData: [],
      chartLabels: [],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleRestApiResponse(message)
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({token})
    this.getChatbotAggregateData(token)
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start

  isLastYearSelected: boolean = false;

  handleRestApiResponse = (message: Message) => {
    const responseHandlers: Record<string, (newMsg: Message) => void> = {}
    const {title} = message.getData(getName(MessageEnum.NavigationPropsMessage))

    if(title === "CHATBOT_AGGREGATE_DATA") {
      responseHandlers["CHATBOT_AGGREGATE_DATA"] = this.handleChatbotAggregateDataResponse
    }
    
    const handler = responseHandlers[title]
    if(handler) {
      handler(message)
    }
  }

  handleChatbotAggregateDataResponse = async(message: Message) => {
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({loading: false})
    if(!response) {
      toast.error("Failed to fetch data", {className: "error__toast"})
      return;
    }

    if(response.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(response.monthly_counts) {
      const {patientStackData, chartLabelDataSet} = this.formatPatientsData(response.monthly_counts, this.isLastYearSelected)
      this.setState({patientsData: patientStackData, chartLabels: [...chartLabelDataSet]})
    }

    if(response.mobile_percentage) {
      const { 
        mobile_percentage, 
        desktop_percentage, 
        most_popular_hour, 
        message_count,
        daily_keyword_counts 
      } = response

      const {patientStackData, chartLabelDataSet} = this.formatPatientsData(daily_keyword_counts)

      this.setState({
        mobilePercentage: mobile_percentage,
        desktopPercentage: desktop_percentage,
        mostPopularHour: most_popular_hour,
        totalChats: message_count,
        patientsData: patientStackData,
        chartLabels: [...chartLabelDataSet]
      })
    }
  }

  getChatbotAggregateData = (token: string) => {
    this.setState({loading: true})

    const reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage))

    const header = {
      "Content-Type": "application/json",
      token: token
    }

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_visual_analytics/audiences/aggregate_data"
    )

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )

    reqMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {title: "CHATBOT_AGGREGATE_DATA"}
    )

    this.send(reqMsg)
  }

  onLastYearSelect = (dates: string[]) => {
    this.isLastYearSelected = true
    this.getKeywordCountsForDates(dates)
  }

  onDateRangeSelect = (startDate: Date, endDate: Date) => {
    this.isLastYearSelected = false
    const dates = eachDayOfInterval({start: startDate, end: endDate})
    const formattedDates = dates.map(date => format(date, "yyyy-MM-dd"))
    this.getKeywordCountsForDates(formattedDates)
  }

  getKeywordCountsForDates = (dates: string[]) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    }

    const body = {
      dates: dates
    }

    this.setState({loading: true})

    const newMsg = new Message(getName(MessageEnum.RestAPIRequestMessage))

    newMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )

    newMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_visual_analytics/audiences/keyword_counts_for_dates"
    )

    newMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    newMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    newMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {title: "CHATBOT_AGGREGATE_DATA"}
    )

    this.send(newMsg)
  }

  formatPatientsData = (keywordCounts: Record<string,Record<string, number>>, isYearFormat?: boolean) => {
    let patientStackData: PatientsData[] = []
    let patientStackDataIndex = 1;
    let chartLabelDataSet = new Set<string>([])

    Object.keys(keywordCounts).forEach((label) => {
      const questionData = keywordCounts[label]
      let options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
      }

      if(isYearFormat) {
        options = {
          year: 'numeric',
          month: 'short'
        }
      }

      const formattedLabel =  new Intl.DateTimeFormat('en', options).format(new Date(label))
      chartLabelDataSet.add(formattedLabel)
      Object.keys(questionData).forEach((question) => {
        if(question !== "total count"){
          patientStackData.push({
            id: patientStackDataIndex,
            date: formattedLabel,
            patients: questionData[question],
            question: question
          })
          patientStackDataIndex++;
        }
      })
    })

    return {
      patientStackData,
      chartLabelDataSet
    }
  }

  handleLogout = async() => {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()

    const navigationMesaage = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
    navigationMesaage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {navigation: this.props.navigation}
    )
    this.send(navigationMesaage)
  }

  formatNumber = (value: number) => {
    return new Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 }).format(value);
  }
  // Customizable Area End
}
