import React from "react";
import {
    InputLabel,
    OutlinedInput,
    ThemeProvider,
    Typography,
    createTheme,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import { useFormik } from "formik";
import {
    theme as cardTheme
} from "../theme.web";
import UncheckedIcon from "./UncheckedIcon.web";
import CheckedIcon from "./CheckedIcon.web";
import CardHeader from "./CardHeader.web";
import MessageTextArea from "./MessageTextArea.web";
import CardFooter from "./CardFooter.web";
import { schema } from "./schema"

const {
    MuiTypographyStyle,
    MuiFormLabelRootStyle,
    MuiInputBaseRootStyle,
    MuiOutlinedInputRootStyle,
    drawerCard,
} = cardTheme

export type RatingFormValues = {
    name: string,
    message: string;
    saveIntoBotContext: boolean;
    fieldName: string;
    id?: number;
}

export type RatingProps = {
    onClose: () => void;
    onSave: (values: RatingFormValues) => void;
    loading: boolean;
    title?: string;
    message?: string;
    fieldName?: string;
    id?: number;
    onDrawerClose: () => void;
    botContext: string[];
}

const useStyles = makeStyles({
    container: drawerCard.container,
    drawerContent: drawerCard.drawerContent,
    formControl: drawerCard.formControl,
    formErrorOrInfo: {
        display: "flex",
        justifyContent: "space-between",
        gap: 4,
        '&.error': {
            color: "#D97706",
        },
    },
    botContainer: {
        padding: "16px 8px 16px 16px",
        display: "flex",
        flexDirection: "column",
        gap: 12,
        borderRadius: 8,
        backgroundColor: "#F6F7FF",
    },
    starRatingTitle: {
        display: "flex",
        gap: 8,
        alignItems: "center",
        color: "#21272A",
        fontFamily: "Cairo",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "20px",
    },
    starRating: {
        width: 195,
        display: "flex",
        gap: 8,
        justifyContent: "flex-end",
    },
    checkboxBotContainer: {
        display: "flex",
        gap: 8,
        alignItems: "center",
    },
})

const ratingTheme = createTheme({
    typography: MuiTypographyStyle,
    overrides: {
        MuiFormLabel: {
            root: {
                ...MuiFormLabelRootStyle,
                '&.Mui-bot': {
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "24px",
                    color: "#0F172A",
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                ...MuiOutlinedInputRootStyle,
            },
            notchedOutline: {
                borderWidth: 1,
                borderColor: "#CBD5E1",
            },
            input: {
                padding: 0,
            },
        },
        MuiInputBase: MuiInputBaseRootStyle,
    }
})

export default function Rating({ 
    onClose, 
    onSave, 
    loading,
    title="",
    message="",
    fieldName="",
    id,
    onDrawerClose, 
    botContext,
}: RatingProps) {
    const filteredBotContext = botContext.filter(ctxName => ctxName !== fieldName)
    const { handleSubmit, values, errors, getFieldProps, touched, handleChange, setFieldValue } = useFormik({
        initialValues: {
            name: title,
            message: message,
            saveIntoBotContext: !!fieldName,
            fieldName: fieldName,
        },
        validationSchema: schema.ratingValidationSchema(filteredBotContext),
        onSubmit: (values) => onSave({id, ...values})
    })
    const classes = useStyles()

    const nameError = !!errors.name && !!touched.name
    const messageError = !!errors.message && !!touched.message
    const fieldNameError = !!errors.fieldName && !!touched.fieldName

    return (
        <ThemeProvider theme={ratingTheme} >
            <div className={classes.container}>
                <CardHeader data-testid="cardHeader" title="Rating" id={id} onClose={onClose} />
                <div className={classes.drawerContent}>
                    <div className={classes.formControl} >
                        <InputLabel htmlFor="title" >Name*</InputLabel>
                        <OutlinedInput
                            placeholder="Rating card"
                            fullWidth
                            id="name"
                            name="name"
                            value={values.name}
                            inputProps={{ ...getFieldProps("name"), maxLength: 50 }}
                            error={nameError}
                        />
                        <div className={classes.formErrorOrInfo}>
                            {
                                nameError && <Typography data-test-id="nameError" className="error" variant="subtitle2" >{errors.name}</Typography>
                            }
                            <Typography style={{ marginLeft: "auto" }} variant="subtitle2" >{values.name.trimStart().trimEnd().length}/50</Typography>
                        </div>
                    </div>
                    <MessageTextArea 
                        getFieldProps={getFieldProps}
                        hasMessageError={messageError}
                        messageErrorText={errors.message}
                        botContext={botContext}
                        value={values.message}
                        setFieldValue={setFieldValue}
                    />
                    <div className={classes.botContainer} >
                        <div className={classes.checkboxBotContainer} >
                            <Checkbox
                                style={{ padding: 0 }}
                                icon={<UncheckedIcon />}
                                checkedIcon={<CheckedIcon />}
                                id="saveIntoBotContext"
                                name="saveIntoBotContext"
                                checked={values.saveIntoBotContext}
                                onChange={(event) => {
                                    handleChange(event)
                                    if(!event.target.checked) {
                                        setFieldValue("fieldName", "")
                                    }
                                }}
                                data-testid="saveIntoBotCheckbox"
                                disableRipple
                            />
                            <InputLabel htmlFor="saveIntoBotContext" className="Mui-bot" >Save in bot context</InputLabel>
                        </div>
                        {values.saveIntoBotContext && (
                            <div className={classes.formControl} >
                                <InputLabel htmlFor="fieldName" >Field name*</InputLabel>
                                <OutlinedInput
                                    placeholder="Type here"
                                    fullWidth
                                    id="fieldName"
                                    name="fieldName"
                                    className="secondary"
                                    inputProps={{ ...getFieldProps("fieldName"), maxLength: 50 }}
                                    error={fieldNameError}
                                    data-testid="txtFieldName"
                                />
                                <div className={classes.formErrorOrInfo} >
                                    {
                                        fieldNameError && <Typography data-test-id="fieldNameError" className="error" variant="subtitle2" >{errors.fieldName}</Typography>
                                    }
                                    <Typography style={{ marginLeft: "auto" }} variant="subtitle2" >{values.fieldName.trimStart().trimEnd().length}/50</Typography>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.formControl} >
                        <InputLabel>Type</InputLabel>
                        <div className={classes.starRatingTitle} >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.99935 1.6665C5.39935 1.6665 1.66602 5.39984 1.66602 9.99984C1.66602 14.5998 5.39935 18.3332 9.99935 18.3332C14.5993 18.3332 18.3327 14.5998 18.3327 9.99984C18.3327 5.39984 14.5993 1.6665 9.99935 1.6665ZM9.16602 5.83317V7.49984H10.8327V5.83317H9.16602ZM10.8327 13.3332C10.8327 13.7915 10.4577 14.1665 9.99935 14.1665C9.54102 14.1665 9.16602 13.7915 9.16602 13.3332V9.99984C9.16602 9.5415 9.54102 9.1665 9.99935 9.1665C10.4577 9.1665 10.8327 9.5415 10.8327 9.99984V13.3332ZM3.33268 9.99984C3.33268 13.6748 6.32435 16.6665 9.99935 16.6665C13.6743 16.6665 16.666 13.6748 16.666 9.99984C16.666 6.32484 13.6743 3.33317 9.99935 3.33317C6.32435 3.33317 3.33268 6.32484 3.33268 9.99984Z" fill="#475569"/>
                            </svg>
                            <span>Rating type: Star Rating (5)</span>
                        </div>
                        <div className={classes.starRating} >
                            {
                                [1,2,3,4,5].map((_,index) => (
                                    <svg key={index} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.99956 11.6795L10.7662 13.3529C11.2729 13.6595 11.8929 13.2062 11.7596 12.6329L11.0262 9.48619L13.4729 7.36619C13.9196 6.97952 13.6796 6.24619 13.0929 6.19952L9.87289 5.92619L8.61289 2.95285C8.38623 2.41285 7.61289 2.41285 7.38623 2.95285L6.12623 5.91952L2.90623 6.19285C2.31956 6.23952 2.07956 6.97285 2.52623 7.35952L4.97289 9.47952L4.23956 12.6262C4.10623 13.1995 4.72623 13.6529 5.23289 13.3462L7.99956 11.6795Z" fill="#F59E0B"/>
                                    </svg>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <CardFooter onClose={onDrawerClose} onSaveClick={() => handleSubmit()} loading={loading} />
            </div>
        </ThemeProvider>
    )
}
