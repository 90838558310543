import React, { useState } from "react";
import {
    ThemeProvider,
    createTheme,
    FormControl,
    FormLabel,
    OutlinedInput,
    Button,
    InputAdornment,
    IconButton,
    Typography,
    styled,
    CircularProgress
} from "@material-ui/core";
import { theme } from "../theme.web";
import PasswordCriteria from "./PasswordCriteria.web";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useFormik } from "formik";
import * as yupObjectSchema from "yup";

const PasswordFormFieldsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 16,
})

const ErrorContainer = styled("div")({
  borderLeft: "4px solid #DC2626",
  borderRadius: 4,
  backgroundColor: "#FEE2E2",
  padding: "12px 16px",
  boxSizing: "border-box",
  '& > p': {
    fontFamily: "Cairo",
    fontWeight: 400,
    margin: 0,
    color: "#DC2626",
    fontSize: 12,
    lineHeight: "18px",
  },
})

type PasswordFormFieldsProps = {
    onSubmit: (values: {
      newPassword: string;
      confirmNewPassword: string;
  }) => void;
  error: string | null;
  loading: boolean;
}

type PasswordInputEndorementProps = {
  ariaLabel: string;
  show: boolean;
  onClick: () => void;
}

const validationSchema = yupObjectSchema.object().shape({
  newPassword: yupObjectSchema.string()
    .required("Please enter new password")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-+={}[\]|:";'<>?,./~`])[A-Za-z\d!@#$%^&*()\-+={}[\]|:";'<>?,./~`]{8,}$/, "Please enter valid password"),
  confirmNewPassword: yupObjectSchema.string().required("Please enter confirm new password")
    .oneOf([yupObjectSchema.ref("newPassword"), null], "Passwords do not match")
})

const passwordTheme = createTheme({
  palette: {
    primary: {
      main: "#51ABB3",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      color: "#F87171",
      fontFamily: "Cairo",
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "18px",
    },
  },
  overrides: {
    MuiFormLabel: theme.formLabel,
    MuiButton: theme.button,
    MuiOutlinedInput: theme.outlinedInput,
    MuiInputBase: theme.inputBase,
    MuiFormControl: theme.formControl,
  }
});

const PasswordInputEndorement = ({ariaLabel, show, onClick}: PasswordInputEndorementProps) => (
  <InputAdornment position="end">
      <IconButton
          aria-label={ariaLabel}
          disableRipple
          onClick={onClick}
          className="visibility-button"
      >
          {show ? <Visibility /> : <VisibilityOff />}
      </IconButton>
  </InputAdornment>
)

export default function PasswordFormFields({onSubmit, error, loading}: PasswordFormFieldsProps) {
    const [showVisibility, setShowVisibility] = useState({password: false, confirmPassword: false})
    const {getFieldProps, handleSubmit, errors, touched, values, isValid} = useFormik({
      initialValues: {
        newPassword: "",
        confirmNewPassword: "",
      },
      validationSchema,
      onSubmit,
    })

    const handleTogglePasswordVisibility = (stateKey: keyof typeof showVisibility) => () => {
      setShowVisibility({...showVisibility, [stateKey]: !showVisibility[stateKey]})
    }

    return (
        <ThemeProvider theme={passwordTheme} >
          <PasswordFormFieldsContainer>
              {error && (
                <ErrorContainer>
                  <p>{error}</p>
                </ErrorContainer>
              )}
              <FormControl>
                <FormLabel htmlFor="new-password" >New Password</FormLabel>
                <OutlinedInput 
                  type={showVisibility.password ? "text" : "password"}
                  id="new-password"
                  placeholder="Your new password"
                  inputProps={{...getFieldProps("newPassword")}}
                  endAdornment={
                    <PasswordInputEndorement 
                      onClick={handleTogglePasswordVisibility("password")}
                      show={showVisibility.password}
                      ariaLabel="toggle new password visibility" 
                    />
                  }
                  error={!!errors.newPassword && touched.newPassword}
                />
                {errors.newPassword && touched.newPassword && <Typography variant="subtitle1" >{errors.newPassword}</Typography>}
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="confirm-new-password" >Confirm New Password</FormLabel>
                <OutlinedInput 
                  type={showVisibility.confirmPassword ? "text" : "password"}
                  id="confirm-new-password"
                  placeholder="Confirm your new password"
                  inputProps={{...getFieldProps("confirmNewPassword")}}
                  endAdornment={
                    <PasswordInputEndorement 
                      onClick={handleTogglePasswordVisibility("confirmPassword")}
                      show={showVisibility.confirmPassword}
                      ariaLabel="toggle confirm password visibility" 
                    />
                  }
                  error={!!errors.confirmNewPassword && touched.confirmNewPassword}
                />
                {errors.confirmNewPassword && touched.confirmNewPassword && <Typography variant="subtitle1" >{errors.confirmNewPassword}</Typography>}
              </FormControl>
            </PasswordFormFieldsContainer>
            <PasswordCriteria password={values.newPassword} />
            <Button 
              disabled={!isValid || values.newPassword.trim().length === 0 || values.confirmNewPassword.trim().length === 0 || loading}
              aria-disabled={!!errors.newPassword || !!errors.confirmNewPassword || loading}
              variant="contained" 
              className="mt-4"
              onClick={() => handleSubmit()}
              data-testid="setNewPassword"
            >
              {
                loading ? (
                  <CircularProgress style={{height: 24, width: 24, color: "#51ABB3"}} />
                ) : "Set New Password"
              }
            </Button>
        </ThemeProvider>
    )
}
