import React, {useEffect, useRef} from "react"

type Props = {
    children: React.ReactNode;
    onOutsideClickCallback: () => void;
}

export default function CriteriaFieldsWrapper({children, onOutsideClickCallback}: Props) {
    const criteriaRef = useRef<HTMLDivElement>(null)

    useEffect(()=>{
        const handler = (event: MouseEvent | TouchEvent) => {
            if(!criteriaRef.current?.contains(event.target as Node)) {
                const roleOptionElements = document.querySelectorAll('[role="option"]')
                if(roleOptionElements.length === 0) {
                    onOutsideClickCallback()
                }    
            }
        }

        document.addEventListener("mousedown", handler)
        document.addEventListener("touchstart", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
            document.removeEventListener("touchstart", handler)
        }
    },[onOutsideClickCallback,criteriaRef])

    return (
        <div ref={criteriaRef} >{children}</div>
    )
}