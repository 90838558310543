import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { DropdownForm } from "./Dropdown.web"
import { SingleOrMultiChoiceFormValues } from "./SingleOrMultiChoice.web";
import { ShareUserGuideOrEmbedFormValues } from "./ShareUserGuideOrEmbedFormController";
import { ShareVideoFormValues } from "./ShareVideo.web";
import { NodeData } from "../../../../components/src/CardNode.web";
import { SendMessageForm } from "./SendMessage.web";
import { RatingFormValues } from "./Rating.web";
import { SliderScaleForm } from "./SliderScale.web";
import { ShareLinkFormValues } from "./ShareLink.web";
import { removeStorageData } from "../../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { configJSON } from "../Chatbot5Controller";
import { Option } from "../../../../components/src/CustomSelect.web";
import {utils} from "../utils.web";
import { GoToTileSaveArgs } from "./GoToTile.web";
import { CriteriaRoutingAttributes } from "../../../cfconnectorlogic/src/CfconnectorlogicController";
import { OpenAISaveArgs } from "../../../cfmicrosoftazureopenaiintegration1/src/Cfmicrosoftazureopenaiintegration1Controller";

export type SelectedCard = "send_message" | "share_user_guide" | "share_video" | "share_link" | "single_choice" | "multi_choice" | "dropdown" | "end_chat" | "go_to_tile" | "criteria_routing" | "slider_scale" | "rating" | "openai" | "embed_form" | "add_node";
export type SelectedCardData = Omit<NodeData,"status" | "onClick"> | undefined;

type AddOrUpdateConnectorCardArgs = {
  id?: number;
  criteriaRoutingIds?: number[];
  ruleIds?: number[];
  body: Record<string, unknown>;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
  navigation: {
    navigate: (to: string, params: Record<string, string | number>) => void;
    getParam: (param: string, alternative?: string) => string;
    goBack: () => void;
  };
  selectedCard: SelectedCard | null;
  handleAddCard: (card: NodeData) => void;
  selectedCardData: SelectedCardData;
  token: string;
  source: string[];
  targetCards: Option[];
  sidebarTop: number | undefined;
  isTemplate: boolean;
  handleConnectionTypeCard: (nodeId: number, criteriaRoutingId?: number | undefined) => void;
  handleCloseSelectedCard: () => void;
  handleOpenSelectedCard: (value: SelectedCard) => void;
  previousCardId?: number;
  followUpActionId?: number;
  botContext: string[];
  handleAddConnectorCardsFromTemplateToChatbot: (nodeIds: number[]) => void;
  contextVariables: {subFields: string[],subFieldValue: Record<string, string[]>};
  allActionCards: Option[];
  isUserEdatt: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  tab: "cards" | "templates";
  cardUpdateTime: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatbotSidePanelController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  perPage: number = 15;
  targetCard: number | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      tab: "cards",
      cardUpdateTime: Date.now()
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleLogout = this.handleLogout.bind(this)
    this.handleRestApiResponse = this.handleRestApiResponse.bind(this)
    this.handleUpdateSourceInConnectorCard = this.handleUpdateSourceInConnectorCard.bind(this)
    this.handleAddOrUpdateConnectorCardResponse = this.handleAddOrUpdateConnectorCardResponse.bind(this)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const handlers = {
      [getName(MessageEnum.RestAPIResponceMessage)]: this.handleRestApiResponse
    }

    const handler = handlers[message.id]
    if(handler) {
      handler(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleRestApiResponse(message: Message) {
    const responseHandlers: Record<string, (message: Message) => void> = {}
    const {title} = message.getData(getName(MessageEnum.NavigationPropsMessage))

    if(title === "AddOrUpdatConnectorCard") {
      responseHandlers["AddOrUpdatConnectorCard"] = this.handleAddOrUpdateConnectorCardResponse
    }

    if(title === "UpdateSourceInConnectorCard") {
      responseHandlers["UpdateSourceInConnectorCard"] = this.handleUpdateSourceInConnectorCard
    }

    const responseHandler = responseHandlers[title]
    if(responseHandler) {
      responseHandler(message)
    }

  }

  async handleAddOrUpdateConnectorCardResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({loading: false})
    if(!responseJson) {
      toast.error("Internal server error", {className: "error__toast"})
      return;
    }
    if(responseJson.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(responseJson.data) {
      const connectorCard = utils.getNodeDataFromConnectorCardAttributes(responseJson.data)
      this.props.handleAddCard(connectorCard)
      this.setState({cardUpdateTime: Date.now()})
    }
  }

  async handleUpdateSourceInConnectorCard(message: Message) {
    this.setState({loading: false})
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!responseJson) {
      toast.error("Internal server error", {className: "error__toast"})
      return;
    }

    if(responseJson.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(responseJson.message && this.targetCard) {
      this.props.handleConnectionTypeCard(this.targetCard, this.props.previousCardId)
    }
  }

  handleAddOrUpdateConnectorCard = (args: AddOrUpdateConnectorCardArgs) => {
    this.setState({loading: true})

    let method = "POST";
    let endpoint = "bx_block_chatbot6/connector_cards";

    const reqHeader = {
      token: this.props.token,
      "Content-Type": configJSON.validationApiContentType,
    }

    const reqBody: {
      criteria_routing_ids?: number[];
      rule_ids?: number[];
      connector_card: Record<string, unknown>;
    } = {
      connector_card: {
        ...args.body,
      },
    }

    if(args.ruleIds) {
      reqBody["rule_ids"] = args.ruleIds
    }

    if(args.criteriaRoutingIds) {
      reqBody["criteria_routing_ids"] = args.criteriaRoutingIds
    }

    if(this.props.isTemplate) {
      reqBody.connector_card["card_template_id"] = this.props.navigation.getParam("navigationBarTitleText")
      reqBody.connector_card["connector_card_type"] = "template"
    } else {
      reqBody.connector_card["chatbot_id"] = this.props.navigation.getParam("navigationBarTitleText")
      reqBody.connector_card["connector_card_type"] = "chatbot"
    }

    if(args.id) {
      method = "PUT";
      endpoint = `bx_block_chatbot6/connector_cards/${args.id}`
    }

    if(this.props.previousCardId) {
      reqBody.connector_card["previous_card_id"] = this.props.previousCardId
    }

    if(this.props.followUpActionId) {
      reqBody.connector_card["follow_up_action_id"] = this.props.followUpActionId
    }

    if(!args.id) {
      reqBody.connector_card["source"] = this.props.source[0];
    }

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqBody)
    )
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(reqHeader)
    )
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    reqMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {title: "AddOrUpdatConnectorCard"}
    )

    this.send(reqMessage)
  }

  handleCloseDrawer = () => {
    this.props.onDrawerClose()
    this.setState({tab: "cards"})
  }

  handleAddBotIdToFormData = (formData: FormData) => {
    if(this.props.isTemplate) {
      formData.append("connector_card[card_template_id]", this.props.navigation.getParam("navigationBarTitleText"))
      formData.append("connector_card[connector_card_type]", "template")
    } else {
      formData.append("connector_card[chatbot_id]", this.props.navigation.getParam("navigationBarTitleText"))
      formData.append("connector_card[connector_card_type]", "chatbot")
    }
  }

  handleShareLinkSave = (values: ShareLinkFormValues) => {
    const linkKeyBasicAttributes = "connector_card[connector_card_links_attributes]"
    const formData = new FormData()
    const linkIds: number[] = []
    values.links.forEach((link) => {
      if(!link.icon && !link.iconFile && link.id) {
        linkIds.push(link.id)
      }
    })
    formData.append("connector_card_link_ids", linkIds.join(","))
    formData.append("connector_card[name]", values.name.trimStart().trimEnd())
    if(!values.id) {
      formData.append("connector_card[source]",`${this.props.source[0]}`)
    }
    if(this.props.previousCardId) {
      formData.append("connector_card[previous_card_id]", `${this.props.previousCardId}`)
    }
    formData.append("connector_card[card_type]","share_link")
    formData.append("connector_card[message]", values.message.trimStart().trimEnd())
    values.links.forEach((link, linkIndex) => {
      formData.append(`${linkKeyBasicAttributes}[${linkIndex}][link_name]`, link.name)
      formData.append(`${linkKeyBasicAttributes}[${linkIndex}][link_type]`, link.linkType)
      formData.append(`${linkKeyBasicAttributes}[${linkIndex}][open_link]`, link.openLink)
      if(link.linkType === "regular" && link.url) {
        formData.append(`${linkKeyBasicAttributes}[${linkIndex}][link_url]`, link.url)
      } else {
        formData.append(`${linkKeyBasicAttributes}[${linkIndex}][destination]`, `${link.destination}`)
      }
      if(link.iconFile) {
        formData.append(`${linkKeyBasicAttributes}[${linkIndex}][connector_icon_image]`, link.iconFile)
      }
      if(link.id) {
        formData.append(`${linkKeyBasicAttributes}[${linkIndex}][id]`, `${link.id}`)
      }
    })

    this.handleAddBotIdToFormData(formData)
    const header = {
      token: this.props.token
    }

    this.setState({loading: true})

    let method = "POST";
    let endpoint = "bx_block_chatbot6/connector_cards";

    if(values.id) {
      endpoint = `bx_block_chatbot6/connector_cards/${values.id}`
      method = "PUT";
    }

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )
    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    reqMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {title: "AddOrUpdatConnectorCard"}
    )

    this.send(reqMessage)
  }

  handleDropdownCardSave = (values: DropdownForm) => {
    const body = {
      message: values.message.trimStart().trimEnd(),
      card_type: "dropdown",
      field_name: values.fieldName.trimStart().trimEnd(),
      name: values.title.trimStart().trimEnd(),
      option: values.options,
    }

    this.handleAddOrUpdateConnectorCard({id: values.id,body})
  }
  
  handleSingleChoiceSave = (values: SingleOrMultiChoiceFormValues) => {
    const body = {
      name: values.name.trimStart().trimEnd(),
      message: values.message.trimStart().trimEnd(),
      field_name: values.fieldName.trimStart().trimEnd(),
      card_type: values.type,
      option: values.options,
    }

    this.handleAddOrUpdateConnectorCard({id: values.id, body})
  }

  handleShareGuideOrEmbedFormSave = (values: ShareUserGuideOrEmbedFormValues) => {
    const body: Record<string, unknown> = {
      name: values.name.trimStart().trimEnd(),
      message: values.message.trimStart().trimEnd(),
      card_type: values.type
    }

    if(values.type === "share_user_guide") {
      body["user_guides"] = values.selectedOptions.map(value => +value)
    } else {
      body["embeded_forms"] =  values.selectedOptions.map(value => +value)
    }

    this.handleAddOrUpdateConnectorCard({id: values.id, body: {...body}})
  }

  handleShareVideoSave = (values: ShareVideoFormValues) => {
    const body = {
      name: values.name.trimStart().trimEnd(),
      message: values.message.trimStart().trimEnd(),
      card_type: "share_video",
      video_link: values.videoURL
    }
    this.handleAddOrUpdateConnectorCard({id: values.id, body})
  }

  handleSendMessageSave = (values: SendMessageForm) => {
    const body: Record<string, unknown> = {
      name: values.name.trimStart().trimEnd(),
      message: values.message.trimStart().trimEnd(),
      card_type: values.type,
      card_wait_time: values.cardWaitTimeChecked ? values.waitTime : 0,
    }

    this.handleAddOrUpdateConnectorCard({
      id: values.id,
      body,
    })
  }

  handleRatingSave = (values: RatingFormValues) => {
    const body = {
      name: values.name.trimStart().trimEnd(),
      message: values.message.trimStart().trimEnd(),
      card_type: "rating",
      field_name: values.fieldName.trimStart().trimEnd(),
    }
    this.handleAddOrUpdateConnectorCard({id: values.id, body})
  }

  handleSliderScaleSave = (values: SliderScaleForm) => {
    const body = {
      name: values.title.trimStart().trimEnd(),
      message: values.message.trimStart().trimEnd(),
      card_type: "slider_scale",
      field_name: values.fieldName.trimStart().trimEnd(),
      minimum_selection: +values.minValue,
      maximum_selection: +values.maxValue,
    }
    this.handleAddOrUpdateConnectorCard({id: values.id, body})
  }

  handleGoToTileSave = (values: GoToTileSaveArgs) => {
    if(values.selectedCardType === "individual") {
      const body = {
        name: values.cardName.trimStart().trimEnd(),
        connection_type: values.selectedCardType,
        target_card: +values.targetCard,
        card_type: "go_to_tile",
      }
      this.handleAddOrUpdateConnectorCard({id: values.id, body})
      return;
    } 
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.props.token,
    }
    const newReq = new Message(getName(MessageEnum.RestAPIRequestMessage))
    newReq.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    const body: Record<string, unknown> = {
      card_id: values.targetCard,
      connector_card_ids: +this.props.source[0]
    }

    if(this.props.previousCardId) {
      body["previous_card_id"] = this.props.previousCardId
    }

    newReq.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    newReq.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    )
    newReq.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateSource
    )

    newReq.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {title: "UpdateSourceInConnectorCard"}
    )

    this.targetCard = +values.targetCard

    this.send(newReq)
  }

  handleCriteriaRoutingSave = (args: {name: string, criteriaRoutings: CriteriaRoutingAttributes[], cardId?:number, criteriaRoutingIds: number[], ruleIds: number[]}) => {
    this.handleAddOrUpdateConnectorCard({
      id: args.cardId,
      criteriaRoutingIds: args.criteriaRoutingIds,
      ruleIds: args.ruleIds,
      body: {
        name: args.name,
        message: "",
        card_type: "criteria_routing",
        criteria_routings_attributes: args.criteriaRoutings,
      }
    })
  }

  handleOpenAiSave = (args:OpenAISaveArgs) => {
    this.handleAddOrUpdateConnectorCard({
      id: args.cardId,
      body: {
        ...args,
        card_type: "openai"
      }
    })
  }

  async handleLogout() {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const message = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
    message.addData(getName(MessageEnum.NavigationPropsMessage), {navigation: this.props.navigation})
    this.send(message)
  }

  onTabChange = (event: React.ChangeEvent<{}>, tab: "cards" | "templates") => {
    this.setState({tab})
  }
  // Customizable Area End
}
