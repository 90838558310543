import React from "react";
import {
    theme as cardTheme,
} from "../theme.web";
import {
    ThemeProvider,
    Typography,
    createTheme,
    makeStyles,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

const {
    drawerCard,
    MuiTypographyStyle,
} = cardTheme

const theme = createTheme({
    typography: MuiTypographyStyle,
})

const useStyles = makeStyles({
    stickyHeader: drawerCard.stickyHeader,
    drawerHeaderOrFooter: drawerCard.drawerHeaderOrFooter,
})

type CardHeaderProps = {
    onClose: () => void;
    title: string;
    id?: number;
}

export default function CardHeader({onClose, title, id}: CardHeaderProps) {
    const classes = useStyles()

    return (
        <ThemeProvider theme={theme} >
            <div className={`${classes.drawerHeaderOrFooter} ${classes.stickyHeader}`} >
                { !id && (
                    <button aria-label="back to panel lists" onClick={onClose} >
                        <ArrowBack htmlColor="#334155" />
                    </button>
                ) }
                <Typography variant="h4" >{title}</Typography>
            </div>
        </ThemeProvider>
    )
}
