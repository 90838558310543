import React from "react";
import { styled } from "@material-ui/core";
import { CheckCircle, RemoveCircleRounded } from "@material-ui/icons";


type PasswordCriteriaConditionProps = {
    text: string;
    password: string;
    regex: string | RegExp;
    id: string;
}

const CriteriaContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 8,
})

const CriterialTitle = styled("p")({
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    color: "#0F172A",
    margin: 0,
})

const CriteriaTextContainer = styled("div")({
    display: "flex",
    gap: 6,
    alignItems: "center",
})

const CriteriaText = styled("p")({
    margin: 0,
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "18px",
    color: "#000000",
})

const atLeastOneCapitalLetterRegexp = "[A-Z]{1,}";
const atLeastOnelowercaseRegexp = "[a-z]{1,}";
const atLeastOneNumberRegexp = "\\d{1,}";
const atLeastOneSymbolRegexp = /[!@#$%^&*()\-+={}[\]|\:";'<>?,.\/~`]+/;
const minEightCharLengthRegexp = "^.{8,}";

const PasswordCriteriaCondition = ({text, password, regex, id}: PasswordCriteriaConditionProps) => {

    const isMatching = password.trim().length === 0 ? undefined : new RegExp(regex).test(password)

    return (
        <CriteriaTextContainer>
            { isMatching === undefined && <CheckCircle aria-labelledby={id} htmlColor="#94A3B8" style={{width: 12, height: 12}} /> }
            { isMatching === true && <CheckCircle data-testid="checked" aria-labelledby={id} htmlColor="#059669" style={{width: 12, height: 12}} /> }
            { isMatching === false && <RemoveCircleRounded data-testid="error-icon" aria-labelledby={id} htmlColor="#DC2626" style={{width: 12, height: 12}} /> }
            <CriteriaText id={id} >{text}</CriteriaText>
        </CriteriaTextContainer>
    )
}


export default function PasswordCriteria({ password }: { password: string; }) {

    return (
        <CriteriaContainer>
            <CriterialTitle>Your password must contain</CriterialTitle>
            <PasswordCriteriaCondition 
                text="At least one capital letter"
                password={password}
                regex={atLeastOneCapitalLetterRegexp}
                id="capitalLetter"
            />
            <PasswordCriteriaCondition 
                text="At least one lowercase letter"
                password={password}
                regex={atLeastOnelowercaseRegexp}
                id="lowercaseLetter"
            />
            <PasswordCriteriaCondition 
                text="At least one number"
                password={password}
                regex={atLeastOneNumberRegexp}
                id="number"
            />
            <PasswordCriteriaCondition 
                text="At least one special characters like $, @, #"
                password={password}
                regex={atLeastOneSymbolRegexp}
                id="symbol"
            />
            <PasswordCriteriaCondition 
                text="Minimum character length is 8 characters"
                password={password}
                regex={minEightCharLengthRegexp}
                id="charLength"
            />
        </CriteriaContainer>
    )
}
