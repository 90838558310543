// Customizable Area Start
import React from "react";

import ChatsController, { ChatsProps, ChatMessage } from "./ChatsController";
import { 
  Box, 
  BoxProps, 
  IconButton, 
  Popover, 
  ThemeProvider, 
  Typography, 
  createTheme, 
  styled,
  Avatar,
  CircularProgress 
} from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import CustomAppHeader from "../../../components/src/CustomAppHeader.web";
import { MoreVert, Share } from "@material-ui/icons";
import { format } from "date-fns";
import { endUserChat } from "../../../components/src/endUserChat.web";
import InfiniteScrollChatLists from "./components/InfiniteScrollChatLists.web";


const { ShareUserGuideCard, ShareLinkCard, ShareVideoCard } = endUserChat

const theme = createTheme({
  typography: {
    h2: {
      fontFamily: "Cairo",
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "28px",
      color: "#0F172A",
      '&.mt-8': {
        marginTop: 8,
      },
    },
    h3: {
      fontFamily: "Cairo",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "22px",
      colr: "#334155",
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: 4,
        height: 32,
        width: 32,
        boxSizing: "border-box",
        color: "#475569",
        '&:hover': {
          backgroundColor: "transparent",
        },
        '&[aria-expanded="true"]': {
          backgroundColor: "#0F172A8C",
          color: "#FFFFFF"
        },
      },
    },
    MuiPopover: {
      paper: {
        width: 156,
        maxWidth: 156,
        border: "1px solid #E2E8F0",
        backgroundColor: "#FFFFFF",
        padding: 4,
        borderRadius: 8,
        boxShadow: "0px 2px 8px 0px #00000014",
        '& .export-btn': {
          all: "unset",
          width: "100%",
          cursor: "pointer",
          padding: "9px 16px",
          boxSizing: "border-box",
          display: "flex",
          gap: 8,
          alignItems: "center",
          fontFamily: "Cairo",
          fontWeight: 400,
          fontSize: 14,
          lineHeight: "22px",
          color: "#0F172A"
        },
      },
    },
  },
})

const Container = styled("div")({
    display: "flex",
    backgroundColor: "#FAFAFA"
})

const ChatsContentContainer = styled("main")({
  boxSizing: "border-box",
  margin: "16px 48px 24px",
  backgroundColor: "#FFFFFF",
  borderRadius: 8,
  boxShadow: "0px 2px 8px 0px #00000014",
  display: "flex",
})

const ChatsListOuterContainer = styled("section")({
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  gap: 12,
  height: "calc(100vh - 132px)",
  overflowY: "auto",
  scrollbarWidth: "none",
  '&::-webkit-scrollbar': {
    width: 0,
  },
})

const ChatsTabButton = styled("button")({
  cursor: "pointer",
  boxSizing: "border-box",
  border: 0,
  backgroundColor: "transparent",
  textAlign: "center",
  fontSize: 16,
  lineHeight: "22px",
  fontWeight: 700,
  fontFamily: "Cairo",
  color: "#64748B",
  padding: "3px 12px",
  '&:focus': {
    outline: "none",
  },
  '&[aria-selected="true"]': {
    color: "#51ABB3",
    border: "1px solid #96CED3",
    backgroundColor: "#E5F1FF",
    borderRadius: 50,
  },
})

const ChatsTabs = styled("div")({
  '&.tabs': {
    backgroundColor: "#F8FAFC",
    borderRadius: 50,
    width: "max-content",
    position: "relative",
    display: "flex",
    padding: 4,
    boxSizing: "border-box",
  },
})

const ChatList = styled("div")({
  display: "flex",
  gap: 8,
  cursor: "pointer",
  '& .chatbot-detail-container': {
    padding: "8px 0",
    '& p': {
      margin: 0,
      fontFamily: "Cairo",
    },
    '& .bot-name': {
      color: "#475569",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "18px"
    },
    '& .user-name': {
      color: "#334155",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "22px"
    },
  },
  '& .bot-time': {
    color: "#64748B",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "18px",
    margin: "0 0 0 auto",
    paddingTop: 8,
    fontFamily: "Cairo",
    textTransform: "uppercase",
  },
})

const ChatSeparator = styled("div")({
  borderTop: "1px solid #E2E8F0"
})

const ChatMessageContainer = styled("section")({
  display: "flex",
  flexDirection: "column",
  width: "67%",
})

const ChatMessageHeader = styled("div")({
  padding: "24px",
  display: "flex",
  justifyContent: "space-between",
  boxSizing: "border-box",
  borderBottom: "1px solid #CBD5E1",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
})

const chatListBoxSx: BoxProps["sx"] = {
  boxSizing: "border-box",
  width: "33%",
  padding: "24px",
  borderRight: "1px solid #CBD5E1",
}

const chatTabsContainerSx: BoxProps["sx"] = {
  width: "100%",
  position: "sticky",
  top: 0,
  zIndex: 2
}

const ChatMessageLists = styled("div")({
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  gap: 8,
  height: "calc(100vh - 190px)",
  overflowY: "auto",
  scrollbarWidth: "none",
  "--card-border": "#E2E8F0",
  "--card-background": "#E2E8F0",
  "--card-text":  "#0F172A",
  "--font-size": "10px",
  "--body-background": "#FFFFFF",
  "--card-selected-button-text": "#51ABB3",
  "--card-button-background": "#FFFFFF",
  '&::-webkit-scrollbar': {
    width: 0,
  },
  '& *': {
    boxSizing: "border-box",
    margin: 0,
  },
  '& .text-base': {
    '& p, & span': {
      fontSize: "16px !important",
    },
  },
  '& .card-width': {
    width: "max(40%, 283px)",
    '& .w-full': {
      width: "100%",
    },
  },
})

const Message = styled("div")({
  maxWidth: "50%",
  display: "inline-block",
  width: "max-content",
  padding: "12px 14px",
  boxSizing: "border-box",
  borderRadius: 12,
  backgroundColor: "#E2E8F0",
  fontFamily: "Cairo",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "24px",
  color: "#0F172A",
  '&[data-type="user"]': {
    backgroundColor: "#C4E0E2",
    marginLeft: "auto",
  },
})

const ChatMessageTime = styled("div")({
  padding: "0px 16px",
  fontFamily: "Cairo",
  fontWeight: 400,
  fontSize: 12,
  lineHeight: "18px",
  color: "#94A3B8",
  textTransform: "uppercase",
  '&[data-type="user"]': {
    marginLeft: "auto",
  },
})

const StyledAvatar = styled(Avatar)({
  boxSizing: "border-box",
  height: 56,
  width: 56,
  border: "2px solid #E5E6FF",
  borderRadius: 100,
  backgroundColor: "#F0E5FF",
  display: "grid",
  placeItems: "center",
  '& > span': {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "26px",
    color: "#A666FF",
  },
})

const SpinnerContainer = styled("div")({
  height: "100%",
  width: "100%",
  display: "grid",
  placeItems: "center"
})

const NoChatsFoundContainer = styled("div")({
  height: "100%",
  width: "100%",
  display: "grid",
  placeItems: "center",
  '& > h4': {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 700,
    color: "#0F172A",
    margin: 0,
    fontFamily: "Cairo",
  },
})

export default class Chats extends ChatsController {

  constructor(props: ChatsProps) {
      super(props)
  }

  renderChatMessage = (chat: ChatMessage) => {
    if(chat.video_data) {
      const { video_url, ...rest } = chat.video_data
      return <ShareVideoCard className="text-base card-width" message={chat.message} videoURL={video_url} videoData={rest} />
    }

    if(chat.connector_card_links) {
      const newConnectorCardLinks = chat.connector_card_links.map((link) => ({...link, connector_icon_image_link: null}))
      return <ShareLinkCard className="text-base card-width" linkClassName="card-width" message={chat.message} links={newConnectorCardLinks} />
    }

    if(chat.user_guides) {
      return <ShareUserGuideCard message={chat.message} userGuides={chat.user_guides} width="max(40%, 283px)" className="text-base card-width" headerClassName="text-base" />
    }

    return <Message data-type={chat.sender} >{chat.message}</Message>
  }

  renderUserChatLists = () => {
    return (
      this.state.userChatLists.length > 0 ? this.state.userChatLists.map((chat, index) => (
        <React.Fragment key={chat.id}>
          <ChatList onClick={this.onSelectChat(chat)} >
            <StyledAvatar>
              <span>{chat.chat_name[0]}</span>
            </StyledAvatar>
            <div className="chatbot-detail-container" >
              <p className="user-name" >{chat.chat_id}</p>
              <p className="bot-name" >{chat.chat_name}</p>
            </div>
            <p className="bot-time">{format(new Date(chat.created_at), 'hh:mm a')}</p>
          </ChatList>
          {index < this.state.userChatLists.length - 1 && <ChatSeparator />}
        </React.Fragment>
      )) : (
        <NoChatsFoundContainer>
          <h4>No {this.state.chatStatus} chats found</h4>
        </NoChatsFoundContainer>
      )
    )
  }

  renderChatList = () => {
    return this.state.userChatListsLoading ? (
      <SpinnerContainer>
          <CircularProgress style={{height: 40,width: 40, color: "#51ABB3"}}  />
      </SpinnerContainer>
    ) : this.renderUserChatLists()
  }

  renderUserChatMessagesWithBot = () => {
    return this.state.selectedChat ? (
      <>
        <ChatMessageHeader>
          <Typography variant="h3" >User {this.state.selectedChat?.chat_id}</Typography>
          <IconButton aria-label="show export popup" aria-expanded={Boolean(this.state.exportAnchorEl)} onClick={this.onShowExportPopover} >
            <MoreVert htmlColor="currentColor" />
          </IconButton>
        </ChatMessageHeader>
        <ChatMessageLists>
          {
            this.state.chatMessages.map((chat, chatIndex) => {
              return (
                <React.Fragment key={chatIndex}>
                  {this.renderChatMessage(chat)}
                  {
                    chat.sender !== this.state.chatMessages[chatIndex + 1]?.sender && (
                      <ChatMessageTime data-type={chat.sender} >{chat.time}</ChatMessageTime>
                    )
                  }
                </React.Fragment>
              )
            })
          }
        </ChatMessageLists>
      </>
    ) : (
      <NoChatsFoundContainer>
        <h4>No messages</h4>
      </NoChatsFoundContainer>
    )
  }

  render() {

    return (
      <ThemeProvider theme={theme}>
        <Container>
            <Sidebar activeMenu="chats" navigation={this.props.navigation}/>
            <Box sx={{width: "100%"}} >
                <CustomAppHeader title="Chats" navigation={this.props.navigation} />
                <ChatsContentContainer>
                    <Box sx={chatListBoxSx} >
                      <ChatsListOuterContainer>
                        <Box sx={chatTabsContainerSx} style={{backgroundColor: "#FFFFFF", paddingBottom: 12}} >
                          <ChatsTabs className="tabs" >
                            <ChatsTabButton role="tab" aria-selected={this.state.chatStatus === "open"} onClick={this.onToggleChatTab("open")} >Open Chats</ChatsTabButton>
                            <ChatsTabButton role="tab"  aria-selected={this.state.chatStatus === "closed"} onClick={this.onToggleChatTab("closed")} >Closed Chats</ChatsTabButton>
                          </ChatsTabs>
                        </Box>
                        <Typography variant="h2" className="mt-8" >Chats</Typography>
                        { this.renderChatList() }
                        {(this.state.userChatLists.length < this.state.totalUserChatLists) ? <InfiniteScrollChatLists handleFetchMore={this.handleFetchMoreChatLists} /> : null}
                      </ChatsListOuterContainer>
                    </Box>
                    <ChatMessageContainer data-testid="chat-messages-box" >
                      {
                        this.state.selectedChatLoading ? (
                          <SpinnerContainer data-testid="selected-chat-loader" >
                            <CircularProgress style={{height: 40,width: 40, color: "#51ABB3"}}  />
                          </SpinnerContainer>
                        ) : this.renderUserChatMessagesWithBot()
                      }
                    </ChatMessageContainer>
                </ChatsContentContainer>
            </Box>
        </Container>
        <Popover 
          id="export"
          open={Boolean(this.state.exportAnchorEl)}
          anchorEl={this.state.exportAnchorEl}
          onClose={this.onCloseExportPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <button className="export-btn" onClick={this.onExportChatMessages} >
            <Share style={{height: 18, width: 18}} />
            Export
          </button>
        </Popover>
      </ThemeProvider>
    );
  }
}

// Customizable Area End
