// Customizable Area Start
import React from "react";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import QAProjectPortfolioController, {
  Props
} from "./QAProjectPortfolioController";
import { Button, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, Menu, MenuItem } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import CustomAppHeader from "../../../components/src/CustomAppHeader.web";
import Sidebar from "../../../components/src/Sidebar.web";
import { CustomSwitch, DialogBox, SearchContainer, SearchInput } from "./theme.web";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { closePopup } from './assets'

export default class QAProjectPortfolio extends QAProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={Theme}>
        <LandingPage>
          <Sidebar navigation={this.props.navigation} activeMenu="qa" />
          <div className="rightside-form-content">
            <CustomAppHeader title="Q&As" navigation={this.props.navigation} showSearchBox={false} />
            <div className="form-post-header">
              <Typography variant="h2">Q&As
                ({this.state.pagination.total || ("0")})
              </Typography>
              <SearchContainer>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15.1872 14.4715H15.9772L20.2172 18.7315C20.6272 19.1415 20.6272 19.8115 20.2172 20.2215C19.8072 20.6315 19.1372 20.6315 18.7272 20.2215L14.4772 15.9715V15.1815L14.2072 14.9015C12.8072 16.1015 10.8972 16.7215 8.86719 16.3815C6.08719 15.9115 3.86719 13.5915 3.52719 10.7916C3.00719 6.56158 6.56719 3.00157 10.7972 3.52157C13.5972 3.86157 15.9172 6.08158 16.3872 8.86158C16.7272 10.8916 16.1072 12.8016 14.9072 14.2015L15.1872 14.4715ZM5.47719 9.97157C5.47719 12.4616 7.48719 14.4716 9.97719 14.4716C12.4672 14.4716 14.4772 12.4616 14.4772 9.97157C14.4772 7.48157 12.4672 5.47157 9.97719 5.47157C7.48719 5.47157 5.47719 7.48157 5.47719 9.97157Z" fill="#94A3B8" />
                </svg>
                <SearchInput type="text" value={this.state.query} onChange={e => {
                  this.handleSearchQuery(e.target.value)
                }} placeholder="Search Q&As" />
              </SearchContainer>
              <Button className="create-button" onClick={this.navigateToCreatePage}>add</Button>
            </div>
            <div className="form-post-table">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Associations</TableCell>
                    <TableCell style={{ minWidth: "100px", height: "65px" }}>Last update</TableCell>
                    <TableCell style={{ minWidth: "100px", height: "65px" }}></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    !this.state.isLoading && (
                      this.state.qaListing.length > 0 ? (
                        this.state.qaListing.map((item: any) => (
                          <TableRow key={item.id}>
                            <TableCell>
                              <CustomTooltip
                                title={
                                  <Typography component={"h1"}>
                                    {item.attributes.question}
                                  </Typography>
                                }
                                interactive
                                disableHoverListener={item.attributes.question?.length < 20}
                                placement={"top"}
                              >
                                <span style={{ fontWeight: 700,whiteSpace: "nowrap",display: "inline-block",width: "20ch",overflow: "hidden",textOverflow: "ellipsis" }}>{item.attributes.question}</span>
                              </CustomTooltip>
                            </TableCell>
                            <TableCell>
                              <CustomTooltip
                                title={
                                  <Typography component={"h1"}>
                                    {this.getPracticeData(item.attributes.practice)}
                                  </Typography>
                                }
                                interactive
                                disableHoverListener={this.getPracticeLabel(item.attributes.practice, item.attributes.is_global).length < 30}
                                placement={"top"}
                              >
                                <span  
                                  style={{
                                    whiteSpace: "nowrap",
                                    display: "inline-block",
                                    width: "30ch",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}
                                >
                                  {this.getPracticeLabel(item.attributes.practice, item.attributes.is_global)}
                                </span>
                              </CustomTooltip>
                            </TableCell>
                            <TableCell>
                              <Typography style={{
                                display: "block",
                                fontFamily: "Cairo",
                                fontSize: "14px"
                              }}>
                                {item.attributes.last_update}
                                <span style={{ display: "block" }}>{item.updated_at}</span>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <CustomSwitch
                                checked={item.attributes.active}
                                onChange={this.handleSwitchChange(item.id)}
                                name="blogStatus"
                                color="default"
                              />
                              {item.attributes.active ? "Active" : "Inactive"}
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={(event) => this.handleDeleteRow(event, Number(item.id))}>
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                            No data found
                          </TableCell>
                        </TableRow>
                      ))
                  }
                </TableBody>
              </Table>
            </div>
            <div className="form-post-table-pagination">
              <Pagination
                count={Math.ceil(this.state.pagination.total / this.state.itemsPerPage) || 1}
                page={this.state.currentPage}
                onChange={this.handlePageChange}
                color="primary"
                style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
              />
            </div>
          </div>
        </LandingPage>
        <DialogBox
          open={this.state.openPopup}
          onClose={this.handleClose}
          className="customDialog"
        >
          <div className="dialog-body">
            <Typography variant="h4">Delete Form</Typography>
            <button className="closePopup" onClick={this.handleClose}><img src={closePopup} /></button>
            <Typography>Are you sure you want to delete this Form? It is used in chatbot(s)</Typography>
            <Button className="Btn" onClick={this.handleClose}>Cancel</Button>
            <Button className="Btn" style={{ background: "#51ABB3", color: "#fff", }} onClick={this.deleteFormData}>Delete</Button>
          </div>
        </DialogBox>
        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.showMenu}
          onClose={this.closeMenuPopup}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={this.navigateToEditPage}>
            <EditOutlined style={{ fontSize: "17px", marginRight: "6px", fontFamily: "Cairo" }} />
            <span style={{
              fontFamily: "Cairo",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "22px"
            }}>Edit Q&A</span>
          </MenuItem>
          <MenuItem onClick={this.showPopup}>
            <DeleteOutlineIcon style={{ fontSize: "17px", marginRight: "6px", fontFamily: "Cairo" }} />
            <span style={{
              fontFamily: "Cairo",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "22px"
            }}>Delete Q&A</span>
          </MenuItem>
        </Menu>
      </ThemeProvider>
    );
  }
}
const Theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        '&.Mui-primary': {
          backgroundColor: "#51ABB3",
          borderRadius: 8,
          padding: "6px 10px",
          marginLeft: "auto",
          textTransform: "none",
        },
        '&.Mui-primary $label': {
          color: "#FFFFFF",
          fontWeight: 700,
          fontSize: 16, fontFamily: "Cairo",
          lineHeight: "24px",
        },
      },
    },
    MuiMenu: {
      paper: {
        width: "149px",
        boxShadow: "0px 2px 8px 0px #00000014",
      },
      list: {
        fontFamily: "Cairo",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "left",
      }
    },
    MuiTooltip: {
      popper: {
        backgroundColor: "#fff !important",
        display: "flex",
        alignItems: "center"
      },
      tooltip: {
        color: "#0F172A",
        margin: "0 !important",
        "& h1": {
          fontSize: "14px",
          borderRadius: "8px",
          fontFamily: "Cairo",
          boxShadow: "0px 2px 8px 0px #00000014",
          textTransform: "capitalize",
          padding: "6px 10px",
        }
      }
    }
  },
});
const LandingPage = styled("section")({
  display: "flex",
  alignItems: "stretch",
  justifyContent: "stretch",
  backgroundColor: "#FAFAFA",

  "& .rightside-form-content": {
    width: "calc(100% - 100px)",
  },
  "& .form-post-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "20px 48px",
    gap: 24,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    padding: "19px 24px",
    boxShadow: "0px 2px 8px 0px #00000014",

    "@media(max-width:767px)": {
      alignItems: "flex-start",
      marginBottom: "20px",
      flexDirection: "column",
      gap: "15px"
    },

  },
  "& .form-post-header h2": {
    fontSize: "24px",
    fontFamily: "Cairo",
    lineHeight: "32px",
    color: "#000",
    fontWeight: 700
  },
  "& .form-post-header button.create-button": {
    width: "122px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    background: "#51ABB3",
    fontSize: "16px",
    fontFamily: "Cairo",
    fontWeight: 700,
    color: "#fff",
    lineHeight: "24px",
    textTransform: "capitalize",
    cursor: 'pointer',
  },
  "& .form-post-table": {
    minHeight: '440px',
    margin: "20px 48px",
    gap: 24,
    backgroundColor: "#FFFFFF",
    // boxShadow: "0px 2px 8px 0px #00000014",
    "@media(max-width:920px)": {
      overflowX: 'auto'
    },
  },
  "& .form-post-table .MuiTable-root": {
    boxShadow: "0px 2px 8px 0px #00000014",
    "@media(max-width:920px)": {
      width: '920px'
    },
  },
  "& .form-post-table .MuiSwitch-root": {
    padding: '8px',
  },
  "& .form-post-table .MuiSwitch-track": {
    borderRadius: '27px',
  },
  "& .form-post-table .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: '#51ABB3',
    opacity: 1,
  },
  "& .form-post-table .MuiSwitch-thumb": {
    width: "12px",
    height: "12px"
  },
  "& .form-post-table .MuiIconButton-root": {
    padding: "13px"
  },
  "& .form-post-table .MuiTableCell-head": {
    fontSize: "14px",
    fontFamily: "Cairo",
    fontWeight: 700,
    color: "#64748B",
    padding: "19px 24px !important",
  },
  "& .form-post-table .MuiTableCell-root": {
    padding: 8,
  },
  "& .form-post-table .MuiTableCell-body": {
    fontSize: "14px",
    fontFamily: "Cairo",
    fontWeight: 400,
    color: "#0F172A",
    position: 'relative',
    padding: "16px 24px"
  },
  "& .form-post-table .MuiTableCell-body button": {
    background: "transparent",
    border: "none",
    padding: "3px",
    display: "inline-block",
    cursor: "pointer",
    color: "#0F172A",
    position: "relative",
    fontSize: "14px",
    fontFamily: "Cairo",
    fontWeight: 400
  },

  "& .form-post-table .MuiTableCell-body .MuiIconButton-root": {
    "&.MoreVertIcon:hover": { background: "rgba(15, 23, 42, 0.55)", color: '#fff' },
  },
  "& .form-post-table .MuiTableCell-body .MoreVertBox": {
    position: "relative",
  },
  "& .form-post-table .MuiTableCell-body .deleteRow": {
    position: "absolute",
    top: "30px",
    right: "39px",
    display: 'none',
    zIndex: 10,
    border: "1px solid #E2E8F0",
    width: "129px",
    background: "#fff",
    borderRadius: "8px",
    padding: "5px 10px",
    boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.1)",
  },
  "& .form-post-table .MuiTableCell-body .deleteRow.active": {
    display: 'block',
  },
  "& .form-post-table .MuiTableCell-body .deleteRow button": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontFamily: "Cairo",
    color: "#0F172A",
    fontWeight: 400,
    cursor: "pointer",

    "&:hover": {
      color: '#c00',
    },
  },
  "& .form-post-table-pagination": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "25px 0",
    padding: '0 48px',
  },
  "& .form-post-table-pagination .MuiPaginationItem-textPrimary.Mui-selected": {
    color: "#51ABB3",
    backgroundColor: "transparent"
  },
  "& .form-post-table-pagination .MuiPaginationItem-page:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline"
  },

});
const CustomTooltip = styled(Tooltip)({
  textTransform: "capitalize",
  fontFamily: "Cairo",
})

// Customizable Area End
