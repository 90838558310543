import { InputBaseClassKey, OutlinedInputClassKey, StyleRules } from "@material-ui/core";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import { CSSProperties, CreateCSSProperties, PropsFunc } from "@material-ui/styles";


type CSSStyle = CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>>

const MuiFormLabelRootStyle: CSSStyle = {
    fontFamily: "Cairo",
    fontSize: 14,
    lineHeight: "22px",
    fontWeight: 700,
    color: "#475569",
}

const MuiButtonRootStyle: CSSStyle = {
    fontFamily: "Cairo",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#51ABB3",
    textTransform: "none",
    borderRadius: 8,
    width: 110,
    textAlign: "center",
    "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "none"
    },
    '&.Mui-secondary': {
        backgroundColor: "#E5EBFF",
    },
    '&.Mui-primary': {
        color: "#FFFFFF",
        backgroundColor: "#51ABB3",
    },
}

const MuiOutlinedInputRootStyle: CSSStyle = {
    borderRadius: 8,
    '&:hover $notchedOutline': {
        borderColor: "#CBD5E1",
    },
    '&.Mui-Focused $notchedOutline': {
        borderColor: "#51ABB3",
    },
    '&.secondary $notchedOutline': {
        borderColor: "#51ABB3",
    },
    '&.secondary:not(:has(.Mui-error)):hover $notchedOutline': {
        borderColor: "#51ABB3",
    },
    '&.secondary.Mui-Focused $notchedOutline': {
        borderColor: "#51ABB3",
        borderWidth: 1,
    },
    "&.Mui-error $notchedOutline": {
        borderColor: "#F59E0B !important",
    },
}

const MuiInputBaseRootStyle: Partial<StyleRules<InputBaseClassKey>> = {
    root: {
        backgroundColor: "#FFFFFF",
        padding: "16px 8px",
    },
    input: {
        fontFamily: "Cairo",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#64748B",
        height: 24,
        '&[type="number"]': {
            '-moz-appearance': 'textfield',
        },
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            "-webkit-appearance": "none",
        },
    },
}

const MuiTypographyStyle: TypographyOptions = {
    fontFamily: "Cairo",
    h4: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: "26px",
        color: "#0F172A",

    },
    subtitle2: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "18px",
        color: "#94A3B8",
        '&.error': {
            color: "#D97706",
        },
        '&.ml-auto': {
            marginLeft: "auto",
        },
    },
    body2: {
        color: "#64748B",
        fontFamily: "Cairo",
        fontSize: 14,
        lineHeight: "22px",
        fontWeight: 400,
        '&.primary': {
            color: "#51ABB3",
        },
    },
    body1: {
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "22px",
        color: "#475569",
    },
}

const MuiOutlinedInputStyle:  Partial<StyleRules<OutlinedInputClassKey>> = {
    root: {
        borderRadius: 8,
        '&:hover $notchedOutline': {
            borderColor: "#CBD5E1",
        },
        '&.Mui-Focused $notchedOutline': {
            borderColor: "#51ABB3",
            borderWidth: 1,
        },
        "&.Mui-error $notchedOutline": {
            borderColor: "#F59E0B",
        },
    },
    notchedOutline: {
        borderWidth: 1,
        borderColor: "#CBD5E1",
    },
    input: {
        padding: 0,
    },
}

const drawerCard: Record<string, CSSStyle> = {
    container: {
        width: 440,
        backgroundColor: "#fff",
        display: "flex",
        height: "100%",
        overflowY: "auto",
        flexDirection: "column",
        scrollbarWidth: "none",
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    drawerHeaderOrFooter: {
        padding: "24px 48px",
        display: "flex",
        gap: 16,
        position: "sticky",
        backgroundColor: "#fff",
        zIndex: 2,
    },
    stickyHeader: {
        top: 0,
        '& button': {
            all: "unset",
            height: 24,
            cursor: "pointer",
        },
    },
    stickyFooter: {
        bottom: 0,
    },
    drawerContent: {
        padding: "0px 48px 24px",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: 12,
        backgroundColor: "#FFFFFF",
    },
    formControl: {
        display: "flex",
        flexDirection: "column",
        gap: 4,
        '&. gap-8': {
            gap: 8,
        },
    },
    textArea: {
        width: "100%",
        boxSizing: "border-box",
        border: "1px solid #CBD5E1",
        borderRadius: 8,
        padding: 8,
        fontFamily: "Cairo",
        color: "#697077",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "22.4px",
        outline: "none",
        '&:focus': {
            borderColor: "#51ABB3",
        },
        '&.error': {
            border: "1px solid #F59E0B"
        },
        '&::placeholder': {
            opacity: 0.5,
        },
    },
    botContainer: {
        padding: "16px 8px 16px 16px",
        display: "flex",
        flexDirection: "column",
        gap: 12,
        borderRadius: 8,
        backgroundColor: "#F6F7FF",
    },
    addLinkOrOptionButton: {
        all: "unset",
        fontFamily: "Inter",
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 700,
        color: "#64748B",
        cursor: "pointer",
        display: "flex",
        gap: 8,
        alignItems: "center",
        width: "max-content",
        '&[aria-disabled="true"]': {
            pointerEvents: "none",
            color: "#94A3B8"
        },
    },
    iconButton: {
        all: "unset",
        cursor: "grab",
        '&.pointer': {
            cursor: "pointer"
        },
    },
    formErrorOrInfo: {
        display: "flex",
        justifyContent: "space-between",
        gap: 4,
        '&.error': {
            color: "#D97706",
        },
    }
}

export const theme = {
    drawerCard,
    MuiOutlinedInputStyle,
    MuiTypographyStyle,
    MuiInputBaseRootStyle,
    MuiOutlinedInputRootStyle,
    MuiButtonRootStyle,
    MuiFormLabelRootStyle,
}
