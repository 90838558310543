import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
    activities: any;
    history: any;
    txtInputValue: string;
    token: string;
    globalSearch: string;
    isLoading: boolean;
    loadMoreLoading: boolean;
    pagination: {
      total: number;
      itemPerPage: number;
      currentPage: number;
    }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAuditLogId: string = "";
  timeout: ReturnType<typeof setTimeout> | undefined = undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activities: [],
      history: [],
      txtInputValue: "",
      token: "",
      isLoading: false,
      loadMoreLoading: false,
      globalSearch: "",
      pagination: {
        total: 0,
        itemPerPage: 20,
        currentPage: 1
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.handleResponseForGetAllActivities(message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  onSearchInputChange = (event: any) => {
    sessionStorage.setItem("search", event.target.value)
    this.setState({globalSearch: event.target.value})   
  }
  handleLoadMore = () => {
    this.setState((prev) => ({pagination: {...prev.pagination, currentPage: ++prev.pagination.currentPage}, loadMoreLoading: true}), () => this.getAllActivities())
  }
  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({ token, isLoading: true })
    this.getAllActivities();
  }
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (prevState.globalSearch !== this.state.globalSearch) {
      this.timeout = setTimeout(this.navigateToBotPage, 500)
    }
  }
  navigateToBotPage = () => {
    const navigationMessage = new Message(getName(MessageEnum.NavigationBotsMessage))
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {navigation: this.props.navigation}
    )
    this.send(navigationMessage)
  }
  getFormatDate = (date: string) => {
    const [day, month, year] = date.split('/').map(Number);
    const auditDate = new Date(year, month - 1, day);

    const today = moment()
    if (moment(auditDate).isSame(today, 'day')) {
      return "Today"
    }
    const yesterday = moment().subtract(1, 'day')
    if (moment(auditDate).isSame(yesterday, 'day')) {
      return "Yesterday"
    }
    return moment(auditDate).format("dddd, MMMM DD YYYY");
  }
  getFormatTime = (date:string) => {
    const auditDate = new Date(date);
    return moment(auditDate).format("dddd, MMMM DD YYYY, hh:mm A");
  }
  validateDate = (date1: string, date2: string) => {
    const updated_at = moment(date2).format('DD/MM/YYYY');
    if(date1 === updated_at ) return true
    return false
  }
  
  handleLastUpdate(date: string) {
    const currentDate = new Date();
    const updatedAt = new Date(date);
    const minutesDiff = Math.floor((currentDate.getTime() - updatedAt.getTime()) / (1000 * 60));
    const hoursDiff = Math.floor(minutesDiff / 60);

    if (minutesDiff < 1) {
      return "Just now";
    } else if (minutesDiff < 60) {
      return `${minutesDiff}m ago`;
    } else if (hoursDiff < 24) {
      return `${hoursDiff}h ago`;
    }  
    return moment(updatedAt).format("hh:mm")
  }
  getAllActivities = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    const newFormDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAuditLogId = newFormDataMessage.messageId;

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFeedDataEndPoint}?page=${this.state.pagination.currentPage}&per_page=${this.state.pagination.itemPerPage}`
    );

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(newFormDataMessage.id, newFormDataMessage);
  };
  handleResponseForGetAllActivities = async (message: Message) => {
    if (
      this.getAuditLogId !== null &&
      this.getAuditLogId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({isLoading: false, loadMoreLoading: false})
      if (responseJson?.data) {
        const {total_count: total, current_page: currentPage, } = responseJson.meta
        const activities = responseJson.data.map((data: any) => data.data).flat();
        const dates = responseJson.data.map((data: any) => data.date);

        const set = new Set([...this.state.history, ...dates])
        this.setState((prev) => ({activities: [...prev.activities, ...activities], history: [...set], pagination: {...prev.pagination, total, currentPage}}))
      } else if (responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
      }
    }
  }
  async handleLogout() {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const message: Message = new Message(
      getName(MessageEnum.NavigationSignupLoginMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
