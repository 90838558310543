import React from "react";
import { createPortal } from "react-dom";
import { ToastContainer } from "react-toastify";

const ErrorInfoIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM13 8C13 7.45 12.55 7 12 7C11.45 7 11 7.45 11 8V12C11 12.55 11.45 13 12 13C12.55 13 13 12.55 13 12V8ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM11 15V17H13V15H11Z"
            fill="#F59E0B"
        />
    </svg>
)

const SuccessIcon = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.9996 21.4001C16.7434 21.4001 21.3996 16.7439 21.3996 11.0001C21.3996 5.25634 16.7434 0.600098 10.9996 0.600098C5.25585 0.600098 0.599609 5.25634 0.599609 11.0001C0.599609 16.7439 5.25585 21.4001 10.9996 21.4001ZM15.8188 9.31934C16.3265 8.81166 16.3265 7.98854 15.8188 7.48086C15.3112 6.97318 14.488 6.97318 13.9804 7.48086L9.69961 11.7616L8.01885 10.0809C7.51117 9.57318 6.68805 9.57318 6.18037 10.0809C5.67269 10.5885 5.67269 11.4117 6.18037 11.9193L8.78037 14.5193C9.28805 15.027 10.1112 15.027 10.6188 14.5193L15.8188 9.31934Z" fill="#34D399"/>
    </svg>
)

const WarningIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#F59E0B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.98073 17.6684C5.95407 17.6684 5.31407 16.5551 5.8274 15.6684L10.8474 6.99508C11.3607 6.10841 12.6407 6.10841 13.1541 6.99508L18.1741 15.6684C18.6874 16.5551 18.0474 17.6684 17.0207 17.6684H6.98073ZM17.0207 16.3351L12.0007 7.66174L6.98073 16.3351H17.0207ZM11.3341 11.0017V12.3351C11.3341 12.7017 11.6341 13.0017 12.0007 13.0017C12.3674 13.0017 12.6674 12.7017 12.6674 12.3351V11.0017C12.6674 10.6351 12.3674 10.3351 12.0007 10.3351C11.6341 10.3351 11.3341 10.6351 11.3341 11.0017ZM12.6674 15.6684V14.3351H11.3341V15.6684H12.6674Z" fill="white"/>
    </svg>
)

export default function ToastNotification() {
    return createPortal(
        <ToastContainer
            position="top-center"
            theme="colored"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            icon={({ type }) => {
                if (type === "error") {
                    return <ErrorInfoIcon />
                }
                if(type === "success") {
                    return <SuccessIcon />
                }
                if(type === "warning") {
                    return <WarningIcon />
                }
                return null;
            }}
        />,
        document.body
    )
}