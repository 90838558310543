import React from "react";
import { makeStyles } from "@material-ui/core"

type RowProps = {
    children: React.ReactNode;
    className?: string;
}

const useStyles = makeStyles({
    row: {
        display: "flex",
        gap: 8,
        '& .gap-16': {
            gap: 16,
        },
    },
})

export default function Row({children, className = ""}: RowProps) {
    const classes = useStyles()
    return (
        <div className={`${classes.row} ${className}`} >{children}</div>
    )
}