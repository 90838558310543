import React, { useState } from "react";
import { makeStyles, createTheme, ThemeProvider, Popover } from "@material-ui/core";
import { Category, MoveTemplateFromCategoryToAnotherCategory } from "../ProjectTemplatesController";
import { theme } from "../theme.web";
import { DeleteOutlineRounded, Edit, MoreVert, ExpandMoreRounded, ExpandLessRounded } from "@material-ui/icons";
import TemplateCard from "../../../../components/src/TemplateCard.web";

const useStyles = makeStyles({
    accordianContainer: {
        borderTop: "1px solid #CBD5E1",
    },
    accordianSummary: {
        padding: "24px 48px",
        display: "flex",
        gap: 4,
        alignItems: "center",
        cursor: "pointer",
        '& > button': {
            all: "unset",
            cursor: "pointer",
            width: 32,
            height: 32,
            padding: 4,
            borderRadius: 24,
            color: "#475569",
            boxSizing: "border-box",
            '&[aria-haspopup="true"]': {
                color: '#FFFFFF',
                backgroundColor: "#0F172A8C",
            },
        },
        '& > .icon': {
            display: "inline-block",
            marginLeft: "auto",
        },
        '& > .catgory-title': {
            fontFamily: "Cairo",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "28px",
            color: "#0F172A",
            margin: 0,
        },
    },
    accordianDetails: {
        display: "none",
        flexWrap: "wrap",
        gap: "24px 17px",
        padding: "0px 48px 24px",
        minHeight: 148,
        boxSizing: "border-box",
        '&[aria-expanded="true"]': {
            display: "flex",
        },
    },
})

const categoryTheme = createTheme({
    overrides: {
        MuiPopover: theme.MuiPopover,
        MuiPaper: theme.MuiPaper
    },
})

type CategoryProps = Category & {
    onEditCategory: (category: Omit<Category, "templates">) => void;
    onDeleteCategory: (categoryId: number) => void;
    onDeleteTemplate: (templateId: number, categoryId: number) => void;
    onEditTemplate: (templateId: number) => void;
    categories: Category[];
    onMoveTemplateFromCategory: (args: MoveTemplateFromCategoryToAnotherCategory) => void;
    handleAddConnectorCardsFromTemplateToChatbot: (nodeIds: number[]) => void;
}

export default function CategoryList({
    id,
    name,
    templates,
    onEditCategory,
    onDeleteCategory,
    onDeleteTemplate,
    onEditTemplate,
    categories,
    onMoveTemplateFromCategory,
    handleAddConnectorCardsFromTemplateToChatbot
}: CategoryProps) {
    const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const classes = useStyles()

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setCategoryAnchorEl(event.currentTarget)
    }

    const handleClosePopover = () => {
        setCategoryAnchorEl(null)
    }

    const handleCategoryDelete = () => {
        onDeleteCategory(id)
        handleClosePopover()
    }

    const handleDeleteTemplate = (templateId: number) => {
        onDeleteTemplate(templateId, id)
    }

    const onTemplateMoveToAnotherCategory = (args: Omit<MoveTemplateFromCategoryToAnotherCategory, "fromCategory">) => {
        const fromCategory: Category = {id,name,templates}
        onMoveTemplateFromCategory({...args, fromCategory})
    }

    const toggleAccordian = () => setIsExpanded(!isExpanded)

    const open = Boolean(categoryAnchorEl)
    const moveToCategories = categories.filter((category) => category.id !== id)
    return (
        <ThemeProvider theme={categoryTheme} >
            <div className={classes.accordianContainer} >
                <div className={classes.accordianSummary} data-testid={`${name} accordian summary`} onClick={toggleAccordian} >
                    <button
                        aria-controls={`category-${id}`}
                        aria-haspopup={open}
                        aria-label={`show ${name} action list`}
                        onClick={handleOpenPopover}
                        data-testid={`show ${name}`}
                    >
                        <MoreVert htmlColor="currentColor" />
                    </button>
                    <span className="catgory-title" >{name}</span>
                    <span className="icon" >
                        {
                            isExpanded ? <ExpandLessRounded htmlColor="#64748B" /> : <ExpandMoreRounded htmlColor="#64748B" />
                        }
                    </span>
                </div>
                {
                    templates?.length > 0 && <div data-testid={`${name} accordian detail`} className={classes.accordianDetails} aria-expanded={isExpanded}>
                        {
                            templates.map((template) => {
                                return (
                                    <TemplateCard
                                        key={template.id}
                                        {...template}
                                        onEditTemplate={onEditTemplate}
                                        onDeleteTemplate={handleDeleteTemplate}
                                        categories={moveToCategories}
                                        data-testid={`Template ${template.id}`}
                                        handleAddConnectorCardsFromTemplateToChatbot={handleAddConnectorCardsFromTemplateToChatbot}
                                        onTemplateMoveToAnotherCategory={onTemplateMoveToAnotherCategory}
                                    />
                                )
                            })
                        }
                    </div>
                }
            </div>
            <Popover
                open={open}
                id={`category-${id}`}
                onClose={handleClosePopover}
                anchorEl={categoryAnchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
                PaperProps={{ style: { maxWidth: 212, width: 212 } }}
                data-testid={`${name} popover`}
            >
                <button
                    onClick={() => {
                        onEditCategory({ id, name })
                        handleClosePopover()
                    }}
                    data-testid={`edit ${name}`}
                >
                    <Edit htmlColor="#0F172A" />
                    Edit category
                </button>
                <button data-testid={`delete ${name}`} onClick={handleCategoryDelete} >
                    <DeleteOutlineRounded htmlColor="#0F172A" />
                    Delete category
                </button>
            </Popover>
        </ThemeProvider>
    )
}