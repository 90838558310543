import React from "react";

import ProjectTemplatesEditController, { ProjectTemplatesEditControllerProps } from "./ProjectTemplatesEditController";
import Chatbot5 from "../../../blocks/chatbot5/src/Chatbot5";

export default class ProjectTemplatesEdit extends ProjectTemplatesEditController {
  constructor(props: ProjectTemplatesEditControllerProps) {
    super(props);
  }

  render() {
    return (
      <Chatbot5 id="Chatbot5" navigation={this.props.navigation} isTemplate={true} />
    );
  }
}
