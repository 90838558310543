import React, { forwardRef } from "react";

type ImageFileInputProps = {
    onImageLoad: (name: string,imageUrl: string, imageFile?: File) => void;
    name: string
}

const ImageFileInput = forwardRef<HTMLInputElement,ImageFileInputProps>(({onImageLoad, name}, ref) => {
    
    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) {
            return;
        }
        const reader = new FileReader();

        reader.onload = (e) => {
            if (!e.target?.result) {
                return;
            }
            if (typeof e.target.result === "string") {
                onImageLoad(name, e.target.result, file)
            }
        };
        reader.readAsDataURL(file);
    }

    return (
        <input 
            ref={ref} 
            type="file" 
            hidden 
            accept="image/png,image/jpeg" 
            name={name}
            onChange={onFileChange} 
            data-testid="file-input"
        />
    )
})

export default ImageFileInput