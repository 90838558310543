// Customizable Area Start
import React from "react";

import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
  styled
} from "@material-ui/core";


import ProjectPortfolioController, {
  Props,
} from "./ProjectPortfolioController";
import Sidebar from "../../../components/src/Sidebar.web";
import CustomAppHeader from "../../../components/src/CustomAppHeader.web";
import { theme as styledTheme } from "./theme.web";
const { SearchContainer, SearchInput, DialogBox } = styledTheme
import { closePopup } from "./assets";
import { Pagination } from "@material-ui/lab";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { EditOutlined } from "@material-ui/icons";

export default class ProjectPortfolio extends ProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={Theme}>
        <LandingPage>
          <Sidebar navigation={this.props.navigation} activeMenu="signposting" />
          <div className="rightside-content">
            <CustomAppHeader title="Signposting" navigation={this.props.navigation} showSearchBox={false} />
            <div className="post-header">
              <Typography variant="h2">Signposting
                ({this.state.pagination?.total || ("0")})
              </Typography>
              <SearchContainer>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15.1872 14.4715H15.9772L20.2172 18.7315C20.6272 19.1415 20.6272 19.8115 20.2172 20.2215C19.8072 20.6315 19.1372 20.6315 18.7272 20.2215L14.4772 15.9715V15.1815L14.2072 14.9015C12.8072 16.1015 10.8972 16.7215 8.86719 16.3815C6.08719 15.9115 3.86719 13.5915 3.52719 10.7916C3.00719 6.56158 6.56719 3.00157 10.7972 3.52157C13.5972 3.86157 15.9172 6.08158 16.3872 8.86158C16.7272 10.8916 16.1072 12.8016 14.9072 14.2015L15.1872 14.4715ZM5.47719 9.97157C5.47719 12.4616 7.48719 14.4716 9.97719 14.4716C12.4672 14.4716 14.4772 12.4616 14.4772 9.97157C14.4772 7.48157 12.4672 5.47157 9.97719 5.47157C7.48719 5.47157 5.47719 7.48157 5.47719 9.97157Z" fill="#94A3B8" />
                </svg>
                <SearchInput type="text" placeholder="Search signposting" onChange={this.handleSearchQuery} />
              </SearchContainer>
              <Button className="create-button" onClick={this.navigateToSignPostingCreatePage}>add new service</Button>
            </div>
            <div className="post-table">
              <CustomTableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="frozen frozenLeft" style={{ minWidth: '120px', minHeight: "64px", position: 'sticky', top: 0, zIndex: 20 }}>Id</TableCell>
                      <TableCell style={{ minWidth: '180px', height: "64px", position: 'sticky', left: 140, borderRight: "3px solid #CBD5E1", top: 0, zIndex: 20 }} className="frozen frozenLeft">Organisation Name</TableCell>
                      <TableCell style={{ minWidth: '180px', height: "64px" }}>Organisation Code</TableCell>
                      <TableCell style={{ minWidth: '170px', height: "64px" }}>Service Type</TableCell>
                      <TableCell style={{ minWidth: '200px', height: "64px" }}>Access Route</TableCell>
                      <TableCell style={{ minWidth: '200px', height: "64px" }}>Website URL</TableCell>
                      <TableCell style={{ minWidth: '200px', height: "64px" }}>Contact Number</TableCell>
                      <TableCell style={{ minWidth: '160px', height: "64px" }}>Street</TableCell>
                      <TableCell style={{ minWidth: '160px', height: "64px" }}>Town</TableCell>
                      <TableCell style={{ minWidth: '160px', height: "64px" }}>Postcode</TableCell>
                      <TableCell style={{ minWidth: '160px', height: "64px" }}>Country</TableCell>
                      <TableCell style={{ minWidth: '160px', height: "64px" }}>Criteria</TableCell>
                      <TableCell style={{ minWidth: '120px', height: "64px" }}>Opening Times</TableCell>
                      <TableCell className="frozen frozenRight" style={{ position: 'sticky', top: 0, zIndex: 20 }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!this.state.isLoading && (
                      this.state.signPostingData.length > 0 ? this.state.signPostingData.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell className="frozen frozenLeft" style={{ minWidth: '120px', height: "64px", position: 'sticky', fontWeight: 700 }}>
                          <CustomTooltip
                              title={
                                <Typography component={"h1"}>
                                  {row.attributes.type_id}
                                </Typography>
                              }
                              interactive
                              disableHoverListener={row.attributes.type_id?.length > 20 ? false : true}
                              placement={"top"}
                            >
                              <span>{this.getAbbreviatedText(row.attributes.type_id)}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell style={{ minWidth: '180px', height: "64px", position: 'sticky', left: 140, borderRight: "3px solid #CBD5E1" }} className="frozen frozenLeft">
                            <CustomTooltip
                              title={
                                <Typography component={"h1"}>
                                  {row.attributes.organisation_name}
                                </Typography>
                              }
                              interactive
                              disableHoverListener={row.attributes.organisation_name?.length > 20 ? false : true}
                              placement={"top"}
                            >
                              <span>{this.getAbbreviatedText(row.attributes.organisation_name)}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell style={{ minWidth: '180px', height: "64px" }}>
                          <CustomTooltip
                              title={
                                <Typography component={"h1"}>
                                  {row.attributes.organisation_code}
                                </Typography>
                              }
                              interactive
                              disableHoverListener={row.attributes.organisation_code?.length > 20 ? false : true}
                              placement={"top"}
                            >
                              <span>{this.getAbbreviatedText(row.attributes.organisation_code)}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell style={{ minWidth: '170px', height: "64px" }}>
                          <CustomTooltip
                              title={
                                <Typography component={"h1"}>
                                  {row.attributes.service_type}
                                </Typography>
                              }
                              interactive
                              disableHoverListener={row.attributes.service_type?.length > 20 ? false : true}
                              placement={"top"}
                            >
                              <span>{this.getAbbreviatedText(row.attributes.service_type)}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell style={{ minWidth: '200px', height: "64px", }}>
                          <CustomTooltip
                              title={
                                <Typography component={"h1"}>
                                  {row.attributes.access_route}
                                </Typography>
                              }
                              interactive
                              disableHoverListener={row.attributes.access_route?.length > 20 ? false : true}
                              placement={"top"}
                            >
                              <span>{this.getAbbreviatedText(row.attributes.access_route)}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell style={{ minWidth: '200px', height: "64px", }}>
                          <CustomTooltip
                              title={
                                <Typography component={"h1"}>
                                  {row.attributes.website_url}
                                </Typography>
                              }
                              interactive
                              disableHoverListener={row.attributes.website_url?.length > 20 ? false : true}
                              placement={"top"}
                            >
                              <span>{this.getAbbreviatedText(row.attributes.website_url)}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell style={{ minWidth: '200px', height: "64px", }}>{row.attributes.contact_number}</TableCell>
                          <TableCell style={{ minWidth: '160px', height: "64px", }}>
                          <CustomTooltip
                              title={
                                <Typography component={"h1"}>
                                  {row.attributes.street}
                                </Typography>
                              }
                              interactive
                              disableHoverListener={row.attributes.street?.length > 20 ? false : true}
                              placement={"top"}
                            >
                              <span>{this.getAbbreviatedText(row.attributes.street)}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell style={{ minWidth: '160px', height: "64px", }}>
                          <CustomTooltip
                              title={
                                <Typography component={"h1"}>
                                  {row.attributes.town}
                                </Typography>
                              }
                              interactive
                              disableHoverListener={row.attributes.town?.length > 20 ? false : true}
                              placement={"top"}
                            >
                              <span>{this.getAbbreviatedText(row.attributes.town)}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell style={{ minWidth: '160px', height: "64px", }}>
                          <CustomTooltip
                              title={
                                <Typography component={"h1"}>
                                  {row.attributes.postcode}
                                </Typography>
                              }
                              interactive
                              disableHoverListener={row.attributes.postcode?.length > 20 ? false : true}
                              placement={"top"}
                            >
                              <span>{this.getAbbreviatedText(row.attributes.postcode)}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell style={{ minWidth: '160px', height: "64px", }}>
                          <CustomTooltip
                              title={
                                <Typography component={"h1"}>
                                  {row.attributes.country}
                                </Typography>
                              }
                              interactive
                              disableHoverListener={row.attributes.country?.length > 20 ? false : true}
                              placement={"top"}
                            >
                              <span>{this.getAbbreviatedText(row.attributes.country)}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell style={{ minWidth: '160px', height: "64px", }}>
                          <CustomTooltip
                              title={
                                <Typography component={"h1"}>
                                  {row.attributes.criteria}
                                </Typography>
                              }
                              interactive
                              disableHoverListener={row.attributes.criteria?.length > 20 ? false : true}
                              placement={"top"}
                            >
                              <span>{this.getAbbreviatedText(row.attributes.criteria)}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell style={{ minWidth: '120px', height: "64px" }}>{row.attributes.opening_times}</TableCell>
                          <TableCell className="frozen frozenRight" style={{ position: 'sticky', borderLeft: "3px solid #CBD5E1", padding: "16px" }}>
                            <IconButton onClick={(event) => this.handleDeleteRow(event, Number(row.id))}>
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )) : <TableRow>
                        <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                          No data found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </CustomTableContainer>
            </div>
            <div className="post-table-pagination">
              <Pagination
                count={Math.ceil(this.state.pagination.total / this.state.itemsPerPage) || 1}
                page={this.state.currentPage}
                onChange={this.handlePageChange}
                color="primary"
                style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
              />
            </div>
          </div>
        </LandingPage>
        <DialogBox
          open={this.state.openPopup}
          onClose={this.handleClose}
          className="customDialog"
        >
          <div className="dialog-body">
            <Typography variant="h4">Delete Form</Typography>
            <button className="closePopup" onClick={this.handleClose}><img src={closePopup} /></button>
            <Typography>Are you sure you want to delete this Signposting? It is used in chatbot(s)</Typography>
            <Button className="Btn" onClick={this.handleClose}>Cancel</Button>
            <Button className="Btn" style={{ background: "#51ABB3", color: "#fff", }} onClick={this.deleteSignPostingData}>Delete</Button>
          </div>
        </DialogBox>
        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.showMenu}
          onClose={this.closeMenuPopup}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={this.navigateToSignPostingEditPage}>
            <EditOutlined style={{ fontSize: "17px", marginRight: "6px", fontFamily: "Cairo" }} />
            <span style={{
              fontFamily: "Cairo",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "22px"
            }}>Edit</span>
          </MenuItem>
          <MenuItem onClick={this.showPopup}>
            <DeleteOutlineIcon style={{ fontSize: "17px", marginRight: "6px", fontFamily: "Cairo" }} />
            <span style={{
              fontFamily: "Cairo",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "22px"
            }}>Delete service</span>
          </MenuItem>
        </Menu>
      </ThemeProvider>
    );
  }
}

const CustomTooltip = styled(Tooltip)({
  textTransform: "capitalize",
  fontFamily: "Cairo",
})
const Theme = createTheme({
  zIndex: {
    modal: 1300
  },
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        '&.Mui-primary': {
          backgroundColor: "#51ABB3",
          borderRadius: 8,
          padding: "6px 10px",
          marginLeft: "auto",
          textTransform: "none",
        },
        '&.Mui-primary $label': {
          color: "#FFFFFF",
          fontWeight: 700,
          fontSize: 16, fontFamily: "Cairo",
          lineHeight: "24px",
        },
      },
    },
    MuiMenu: {
      paper: {
        width: "149px",
        boxShadow: "0px 2px 8px 0px #00000014",
      },
      list: {
        fontFamily: "Cairo",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "left",
      }
    },
    MuiTooltip: {
      popper: {
        backgroundColor: "#fff !important",
        display: "flex",
        alignItems: "center"
      },
      tooltip: {
        color: "#0F172A",
        margin: "0 !important",
        "& h1": {
          fontSize: "14px",
          borderRadius: "8px",
          fontFamily: "Cairo",
          boxShadow: "0px 2px 8px 0px #00000014",
          textTransform: "capitalize",
          padding: "6px 10px",
        }
      }
    }
  },
});
const CustomTableContainer = styled(TableContainer)({
  width: "100%", 
  overflowX: "auto",
  scrollbarWidth: "none",
  scrollbarColor: "#D9D9D9 #F3F4F6",
  minHeight: "440px",

  "&::-webkit-scrollbar": {
    width: "0px",
  },
  
  "&::-webkit-scrollbar-track": {
    background: "#F8FAFC",
   },
   
  "&::-webkit-scrollbar-thumb": {
    background: "#D9D9D9",
    borderRadius: "10px",
  },
})

const LandingPage = styled("section")({
  display: "flex",
  alignItems: "stretch",
  justifyContent: "stretch",
  zIndex: 1,

  "& .rightside-content": {
    width: "calc(100% - 100px)",
    backgroundColor: "#FAFAFA"
  },
  "& .frozen": {
    position: 'sticky', backgroundColor: '#fff', zIndex: 10
  },
  "& .frozenRight": { right: 0 },
  "& .frozenLeft": { left: 0 },
  "& .post-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "20px 48px",
    gap: 24,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    padding: "19px 24px",
    boxShadow: "0px 2px 8px 0px #00000014",

    "@media(max-width:767px)": {
      alignItems: "flex-start",
      marginBottom: "20px",
      flexDirection: "column",
      gap: "15px"
    },

  },
  "& .post-header h2": {
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000",
    fontWeight: 700,
    fontFamily: "Cairo"
  },
  "& .post-table": {
    margin: "20px 48px",
    gap: 24,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    boxShadow: "0px 2px 8px 0px #00000014",
    "@media(max-width:920px)": {
      overflowX: 'auto'
    },
  },
  "& .post-table .MuiTable-root": {
    "@media(max-width:920px)": {
      width: '920px'
    },
  },
  "& .post-table .MuiTable-root .MuiTableRow-root td": {
    padding: "21px 0 21px 24px"
  },
  "& .post-table .MuiTable-root .MuiTableRow-root th": {
    padding: "21px 0 21px 24px"
  },
  "& .post-table .MuiSwitch-root": {
    padding: '8px',
  },
  "& .post-table .MuiSwitch-track": {
    borderRadius: '27px',
  },
  "& .post-table .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: '#51ABB3',
    opacity: 1,
  },
  "& .post-table .MuiSwitch-thumb": {
    width: "12px",
    height: "12px"
  },
  "& .post-table .MuiIconButton-root": {
    padding: "13px"
  },
  "& .post-table tr td": {
    borderBottom: "1px solid #E2E8F0"
  },
  "& .post-table th": {
    fontSize: "14px",
    fontWeight: 700,
    padding: "10px 24px",
    fontFamily: "Cairo",
    borderBottom: "1px solid #E2E8F0",
    color: "#64748B"
  },
  "& .post-table .MuiTableCell-root": {
    padding: 8,
  },
  "& .post-table td": {
    fontSize: "14px",
    padding: "10px 24px",
    fontWeight: 400,
    color: "#0F172A",
  },
  "& .post-header .create-button": {
    width: "164px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    background: "#51ABB3",
    fontSize: "16px",
    fontWeight: 700,
    color: "#fff",
    lineHeight: "24px",
    textTransform: "capitalize",
    cursor: 'pointer',
  },
  "& .post-table .MuiTableCell-head": {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Cairo",
    color: "#64748B"
  },
  "& .post-table .MuiTableCell-body": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A",
    position: 'relative',
  },
  "& .post-table .MuiTableCell-body button": {
    background: "transparent",
    border: "none",
    padding: "3px",
    display: "inline-block",
    cursor: "pointer",
    color: "#0F172A",
    position: "relative",
    fontSize: "14px",
    fontWeight: 400
  },

  "& .post-table .MuiTableCell-body .MuiIconButton-root": {
    "&.MoreVertIcon:hover": { background: "rgba(15, 23, 42, 0.55)", color: '#fff' },
  },
  "& .post-table .MuiTableCell-body .MoreVertBox": {
    position: "relative",
  },
  "& .post-table .MuiTableCell-body .deleteRow": {
    position: "absolute",
    top: "43px",
    right: "0",
    display: 'none',
    // zIndex: 10,
    border: "1px solid #E2E8F0",
    width: "129px",
    background: "#fff",
    borderRadius: "8px",
    padding: "5px 10px",
    boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.1)",
  },
  "& .post-table .MuiTableCell-body .deleteRow.active": {
    display: 'block',
  },
  "& .post-table .MuiTableCell-body .deleteRow button": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    color: "#0F172A",
    fontWeight: 400,
    cursor: "pointer",

    "&:hover": {
      color: '#c00',
    },
  },
  "& .post-table-pagination": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "25px",
    padding: '0 48px',
  },
  "& .post-table-pagination .MuiPaginationItem-textPrimary.Mui-selected": {
    color: "#51ABB3",
    backgroundColor: "transparent"
  },
  "& .post-table-pagination .MuiPaginationItem-page:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline"
  },

})
// Customizable Area End
