import React from "react";
import { Avatar, makeStyles, createTheme, ThemeProvider, Switch } from "@material-ui/core";
import {
    CodeRounded,
    LinkRounded,
} from '@material-ui/icons';
import { Chatbot } from "../Catalogue1Controller";
import ChatbotMoreActionsMenuButton  from "./ChatbotMoreActionsMenuButton.web";
import { utils } from "../utils.web";
import QRCode from "./QRCode.web";

const {copytoClipboard} = utils

const tableTheme = createTheme({
    overrides: {
        MuiAvatar: {
            root: {
                boxSizing: "border-box",
                width: 44,
                height: 44,
                border: "2px solid #34D399",
                '& > span': {
                    color: "#059669",
                    lineHeight: "26px",
                    fontWeight: 700,
                    fontSize: 18,
                    fontFamily: "Inter",
                },
            },
            colorDefault: {
                color: "#059669",
                backgroundColor: "#D1FAE5",
            },
        },
        MuiSwitch: {
            root: {
                padding: 0,
                width: 32,
                height: 16,
                borderRadius: 33,
            },
            input: {
                left: 0,
                width: 32,
                height: 12,
            },
            switchBase: {
                padding: 0,
                top: 1.6,
                left: 2,
                '&.Mui-checked': {
                    transform: "none",
                    right: -13.5
                },
            },
            thumb: {
                width: 12,
                height: 12,
                backgroundColor: "#FFFFFF",
                boxShadow: "none",
            },
            track: {
                backgroundColor: "#E2E8F0",
                opacity: 1,
            },
            colorSecondary: {
                '&.Mui-checked + $track': {
                    backgroundColor: "#51ABB3",
                    opacity: 1,
                },
            },
        },
    },
})

const useStyles = makeStyles({
    tableContainer: {
        height: 576,
        width: "100%",
        overflow: "auto",
        position: "relative",
        boxSizing: "border-box",
        scrollbarWidth: "none",
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    table: {
        width: "100%",
        textIndent: 0,
        borderColor: "inherit",
        borderCollapse: "collapse",
        captionSide: "bottom",
    },
    tableHead: {
        backgroundColor: "#FFFFFF",
        '& tr': {
            backgroundColor: "#FFFFFF",
            borderBottom: "1px solid #E2E8F0",
            position: "sticky",
            top: 0,
            zIndex: 2,
        },
    },
    tableHeading: {
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "22px",
        color: "#64748B",
        padding: "24px 24px 8px",
        textAlign: "left",
        width: "max-content",
        verticalAlign: "bottom",
        height: 54,
        boxSizing: "border-box",
        '&:last-child': {
            padding: 0,
        },
    },
    tableCell: {
        '&.embedOptions': {
            '& button': {
                all: "unset",
                cursor: "pointer",
            },
        },
    },
    tableBody: {
        '& tr': {
            boxSizing: "border-box",
            borderBottom: "1px solid #E2E8F0",
            '& td': {
                height: 78,
                padding: "12px 24px",
                boxSizing: "border-box",
                width: "max-content",
                verticalAlign: "bottom",
                '& .data-cell': {
                    height: 44,
                    display: "flex",
                    alignItems: "center",
                },
            },
            '&:last-child': {
                borderBottom: 0,
            },
            '&:first-child': {
                '& td': {
                    height: 90,
                },
            },
        }
    },
    embedOptionsCell: {
        height: 44,
        display: "flex",
        alignItems: "center",
        gap: 10,
        '& > button': {
            all: "unset",
            cursor: "pointer",
        },
    },
    chatbotDetailCell: {
        height: 44,
        display: "flex",
        gap: 10,
        alignItems: "center",
        '& p': {
            margin: 0,
            fontFamily: "Cairo",
            lineHeight: "22px",
        },
        '& p:first-child': {
            fontSize: 16,
            fontWeight: 700,
            color: "#1E293B",
        },
        '& p:last-child': {
            fontWeight: 400,
            color: "#64748B",
            fontSize: 14,
        },
    },
    memebersCell: {
        height: 44,
        display: "flex",
        alignItems: "center",
        gap: 10,
        '& > span': {
            fontFamily: "Cairo",
            lineHeight: "22px",
            fontWeight: 400,
            color: "#0F172A",
            fontSize: 14,
        },
    },
    moreActionsCell: {
        textAlign: "end",
        '& > button': {
            all: "unset",
            cursor: "pointer",
            display: "inline-block",
            boxSizing: "border-box",
            padding: 4,
            width: 32,
            height: 32,
            color: "#475569",
            borderRadius: 24,
            '&[aria-haspopup="true"]': {
                color: '#FFFFFF',
                backgroundColor: "#0F172A8C",
            },
        },
    },
})

type ChatbotTableProps = {
    chatbots: Chatbot[];
    navigateToEditPage: any;
    handleDeleteBot: any;
    handleDuplicateBot: any;
    handleBotStatusChange: any;
}

const ChatbotTable = ({ chatbots, navigateToEditPage, handleDeleteBot, handleDuplicateBot, handleBotStatusChange }: ChatbotTableProps) => {
    const classes = useStyles()
    
    return (
        <ThemeProvider theme={tableTheme} >
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div role="tabpanel" data-value="list" className={classes.tableContainer} >
                    <table className={classes.table} >
                        <thead className={classes.tableHead} >
                            <tr>
                                <th scope="col" className={classes.tableHeading} >Bot Name</th>
                                <th scope="col" colSpan={2} className={classes.tableHeading} >
                                    <span style={{ display: "inline-block", width: "max-content" }} >Total number of chats</span>
                                </th>
                                <th scope="col" className={classes.tableHeading} >Embed Options</th>
                                <th scope="col" className={classes.tableHeading} >Status</th>
                                <th scope="col" className={classes.tableHeading} ></th>
                            </tr>
                        </thead>
                        <tbody className={classes.tableBody} >
                            {
                                chatbots.map((bot) => (
                                    <tr key={bot.id} >
                                        <td>
                                            <div className={classes.chatbotDetailCell} >
                                                <Avatar alt={bot.name} src={bot.image ?? undefined} >
                                                    <span>{`${bot.name.charAt(0)}`}</span>
                                                </Avatar>
                                                <div>
                                                    <p>{bot.name}</p>
                                                    <p>{bot.organisation}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td colSpan={2} >
                                            <div className={classes.memebersCell} >
                                                <MembersIcon />
                                                <span>00</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={classes.embedOptionsCell} >
                                                <button onClick={() => copytoClipboard(bot.code, "iFrame code copied to clipboard")} aria-label={`copy iframe code of ${bot.name}`} >
                                                    <CodeRounded htmlColor="#475569" />
                                                </button>
                                                <button onClick={() => copytoClipboard(bot.link, "URL copied to clipboard")} data-testid={`copy link of ${bot.name}`} aria-label={`copy link of ${bot.name}`} >
                                                    <LinkRounded htmlColor="#475569" />
                                                </button>
                                                {bot.qrcode && bot.active && <QRCode id={bot.id} botName={bot.name} botQRCodeLink={bot.qrcode} />}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="data-cell" >
                                                <Switch
                                                    disableRipple
                                                    id={`change status of ${bot.name}`}
                                                    checked={bot.active}
                                                    onClick={() => handleBotStatusChange(bot.id)}
                                                />
                                            </div>
                                        </td>
                                        <td className={classes.moreActionsCell} style={{width: "auto"}} >
                                            <div className="data-cell" >
                                                <ChatbotMoreActionsMenuButton
                                                    id={bot.id}
                                                    navigateToEditPage={navigateToEditPage}
                                                    handleDeleteBot={handleDeleteBot}
                                                    handleDuplicateBot={handleDuplicateBot}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </ThemeProvider>
    )
}

function MembersIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.2455 13.3026C17.4909 14.1483 18.3636 15.2941 18.3636 16.8219V19.55H22V16.8219C22 14.8394 18.7545 13.6663 16.2455 13.3026Z" fill="#475569" />
            <path d="M14.7273 12.275C16.7364 12.275 18.3636 10.6472 18.3636 8.6375C18.3636 6.62778 16.7364 5 14.7273 5C14.3 5 13.9 5.09094 13.5182 5.21825C14.2727 6.15491 14.7273 7.34619 14.7273 8.6375C14.7273 9.92881 14.2727 11.1201 13.5182 12.0568C13.9 12.1841 14.3 12.275 14.7273 12.275Z" fill="#475569" />
            <path d="M9.27273 12.275C11.2818 12.275 12.9091 10.6472 12.9091 8.6375C12.9091 6.62778 11.2818 5 9.27273 5C7.26364 5 5.63636 6.62778 5.63636 8.6375C5.63636 10.6472 7.26364 12.275 9.27273 12.275ZM9.27273 6.81875C10.2727 6.81875 11.0909 7.63719 11.0909 8.6375C11.0909 9.63781 10.2727 10.4563 9.27273 10.4563C8.27273 10.4563 7.45455 9.63781 7.45455 8.6375C7.45455 7.63719 8.27273 6.81875 9.27273 6.81875Z" fill="#475569" />
            <path d="M9.27273 13.1844C6.84545 13.1844 2 14.4029 2 16.8219V19.55H16.5455V16.8219C16.5455 14.4029 11.7 13.1844 9.27273 13.1844ZM14.7273 17.7312H3.81818V16.831C4 16.1762 6.81818 15.0031 9.27273 15.0031C11.7273 15.0031 14.5455 16.1762 14.7273 16.8219V17.7312Z" fill="#475569" />
        </svg>
    )
}

export default ChatbotTable
