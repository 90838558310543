import React from "react";
import * as yup from "yup";
import {
    InputLabel,
    OutlinedInput,
    ThemeProvider,
    Typography,
    createTheme,
    makeStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import {
    theme as cardTheme
} from "../theme.web";
import CustomSelect from "../../../../components/src/CustomSelect.web";
import CardHeader from "./CardHeader.web";
import CardFooter from "./CardFooter.web";

const {
    MuiTypographyStyle,
    MuiFormLabelRootStyle,
    MuiInputBaseRootStyle,
    MuiOutlinedInputStyle,
    drawerCard,
} = cardTheme

export type GoToTileSaveArgs = {
    cardName: string,
    targetCard: string;
    selectedCardType: string;
    id: number | undefined;
    source: string[] | undefined;
}

type Option = {
    label: string;
    value: string;
}

export type GoToTileProps = {
    onClose: () => void;
    onSave: (values: GoToTileSaveArgs) => void;
    targetCardOptions: Option[];
    loading: boolean;
    title?: string;
    cardType?: string;
    targetCard?: string;
    id?: number;
    onDrawerClose: () => void;
    source?: string[];
}


const useGoToTileStyles = makeStyles({
    container: drawerCard.container,
    drawerContent: drawerCard.drawerContent,
    formControl: drawerCard.formControl,
    formErrorOrInfo: {
        display: "flex",
        justifyContent: "space-between",
        gap: 4,
        '&.error': {
            color: "#D97706",
        },
    },
    formTileGroup: {
        display: "flex",
        gap: 32,
    },
    formTileControl: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
    },
    formTileLabel: {
        color: "#21272A",
        fontFamily: "Cairo",
        fontSize: 14,
        lineHeight: "20px",
        margin: 0,
    },
    formTileButton: {
        width: 76,
        height: 50,
        boxSizing: "border-box",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 1.53px 6.1px 0px #00000014",
        display: "grid",
        placeItems: "center",
        border: 0,
        borderRadius: 8,
        cursor: "pointer",
        '&:focus': {
            outline: "none",
        },
        '&[data-selected="true"]': {
            border: "1px solid #51ABB3"
        },
        '&[aria-disabled="true"]': {
            pointerEvents: "none",
        },
    },
})

const tileTheme = createTheme({
    typography: MuiTypographyStyle,
    overrides: {
        MuiFormLabel: {
            root: MuiFormLabelRootStyle,
        },
        MuiOutlinedInput: MuiOutlinedInputStyle,
        MuiInputBase: MuiInputBaseRootStyle,
    }
})

const goToTileValidationSchema = yup.object().shape({
    cardName: yup.string().trim().required("Please add a name"),
    targetCard: yup.string().trim().required("Please choose a target card")
})

export default function GoToTile({
    onClose,
    onSave,
    targetCardOptions,
    loading,
    title = "",
    targetCard = "",
    id,
    onDrawerClose,
    source
}: GoToTileProps) {
    const { handleSubmit, values, errors, getFieldProps, touched, setFieldValue } = useFormik({
        initialValues: {
            cardName: title,
            targetCard,
            selectedCardType: "individual"
        },
        validationSchema: goToTileValidationSchema,
        onSubmit: (values) => onSave({ ...values, id, source })
    })
    const classes = useGoToTileStyles()

    const onOptionSelect = (value: string) => {
        setFieldValue("targetCard", value)
    }

    const onTileCardTypeClick = (type: string) => () => {
        setFieldValue("selectedCardType", type)
    }

    const wireType = values.selectedCardType === "wire";
    const individualType = values.selectedCardType === "individual";

    const cardNameError = !!errors.cardName && !!touched.cardName
    const targetCardError = !!errors.targetCard && !!touched.targetCard

    return (
        <ThemeProvider theme={tileTheme} >
            <div className={classes.container}>
                <CardHeader data-testid="cardHeader" id={id} onClose={onClose} title="Go to tile" />
                <div className={classes.drawerContent}>
                    <div className={classes.formControl} >
                        <InputLabel htmlFor="title" >Name*</InputLabel>
                        <OutlinedInput
                            placeholder="Go to tile card"
                            fullWidth
                            id="cardName"
                            name="cardName"
                            value={values.cardName}
                            inputProps={{ ...getFieldProps("cardName"), maxLength: 50 }}
                            error={cardNameError}
                        />
                        <div className={classes.formErrorOrInfo}>
                            {
                                cardNameError && <Typography data-test-id="nameError" className="error" variant="subtitle2" >{errors.cardName}</Typography>
                            }
                            <Typography style={{ marginLeft: "auto" }} variant="subtitle2" >{values.cardName.trimStart().trimEnd().length}/50</Typography>
                        </div>
                    </div>
                    {
                        !id && (
                            <div className={classes.formTileGroup}>
                                <div className={classes.formTileControl}>
                                    <label id="individual" className={classes.formTileLabel}>Individual card</label>
                                    <button aria-labelledby="individual" data-selected={individualType} onClick={onTileCardTypeClick("individual")} className={classes.formTileButton}>
                                        <svg width="62" height="14" viewBox="0 0 62 14" fill={individualType ? "#51ABB3" : "#CBD5E1"} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.0475 7.38086C19.0475 8.50447 19.9584 9.41534 21.082 9.41534C22.2056 9.41534 23.1165 8.50447 23.1165 7.38086C23.1165 6.25725 22.2056 5.34638 21.082 5.34638C19.9584 5.34638 19.0475 6.25725 19.0475 7.38086ZM38.8838 7.38086C38.8838 8.50447 39.7946 9.41534 40.9182 9.41534C42.0419 9.41534 42.9527 8.50447 42.9527 7.38086C42.9527 6.25725 42.0419 5.34638 40.9182 5.34638C39.7946 5.34638 38.8838 6.25725 38.8838 7.38086ZM21.082 7.76232H40.9182V6.99939H21.082V7.76232Z" />
                                            <mask id="path-2-inside-1_14464_9317" fill="white">
                                                <path d="M0.48291 3.56641H21.082V11.9586C21.082 12.8014 20.3989 13.4845 19.5562 13.4845H2.00877C1.16606 13.4845 0.48291 12.8014 0.48291 11.9586V3.56641Z" />
                                            </mask>
                                            <path d="M0.48291 3.56641H21.082V11.9586C21.082 12.8014 20.3989 13.4845 19.5562 13.4845H2.00877C1.16606 13.4845 0.48291 12.8014 0.48291 11.9586V3.56641Z" fill="white" />
                                            <path d="M0.48291 3.56641H21.082H0.48291ZM21.845 11.9586C21.845 13.2227 20.8203 14.2474 19.5562 14.2474H2.00877C0.744707 14.2474 -0.280021 13.2227 -0.280021 11.9586H1.24584C1.24584 12.38 1.58742 12.7216 2.00877 12.7216H19.5562C19.9775 12.7216 20.3191 12.38 20.3191 11.9586H21.845ZM2.00877 14.2474C0.744707 14.2474 -0.280021 13.2227 -0.280021 11.9586V3.56641H1.24584V11.9586C1.24584 12.38 1.58742 12.7216 2.00877 12.7216L2.00877 14.2474ZM21.845 3.56641V11.9586C21.845 13.2227 20.8203 14.2474 19.5562 14.2474V12.7216C19.9775 12.7216 20.3191 12.38 20.3191 11.9586V3.56641H21.845Z" fill="#CBD5E1" mask="url(#path-2-inside-1_14464_9317)" />
                                            <path d="M0.48291 2.04051C0.48291 1.1978 1.16606 0.514648 2.00877 0.514648H19.5562C20.3989 0.514648 21.082 1.1978 21.082 2.04051V3.56637H0.48291V2.04051Z" />
                                            <mask id="path-5-inside-2_14464_9317" fill="white">
                                                <path d="M40.918 3.56641H61.5171V11.9586C61.5171 12.8014 60.834 13.4845 59.9912 13.4845H42.4438C41.6011 13.4845 40.918 12.8014 40.918 11.9586V3.56641Z" />
                                            </mask>
                                            <path d="M40.918 3.56641H61.5171V11.9586C61.5171 12.8014 60.834 13.4845 59.9912 13.4845H42.4438C41.6011 13.4845 40.918 12.8014 40.918 11.9586V3.56641Z" fill="white" />
                                            <path d="M40.918 3.56641H61.5171H40.918ZM62.28 11.9586C62.28 13.2227 61.2553 14.2474 59.9912 14.2474H42.4438C41.1798 14.2474 40.155 13.2227 40.155 11.9586H41.6809C41.6809 12.38 42.0225 12.7216 42.4438 12.7216H59.9912C60.4126 12.7216 60.7542 12.38 60.7542 11.9586H62.28ZM42.4438 14.2474C41.1798 14.2474 40.155 13.2227 40.155 11.9586V3.56641H41.6809V11.9586C41.6809 12.38 42.0225 12.7216 42.4438 12.7216L42.4438 14.2474ZM62.28 3.56641V11.9586C62.28 13.2227 61.2553 14.2474 59.9912 14.2474V12.7216C60.4126 12.7216 60.7542 12.38 60.7542 11.9586V3.56641H62.28Z" fill="#CBD5E1" mask="url(#path-5-inside-2_14464_9317)" />
                                            <path d="M40.918 2.04051C40.918 1.1978 41.6011 0.514648 42.4438 0.514648H59.9912C60.834 0.514648 61.5171 1.1978 61.5171 2.04051V3.56637H40.918V2.04051Z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className={classes.formTileControl}>
                                    <label id="wire" className={classes.formTileLabel}>Wire connection</label>
                                    <button aria-labelledby="wire" data-selected={wireType} onClick={onTileCardTypeClick("wire")} className={classes.formTileButton} >
                                        <svg width="44" height="14" viewBox="0 0 44 14" fill={wireType ? "#51ABB3" : "#CBD5E1"} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.747744 7.38086C0.747744 8.50447 1.65861 9.41534 2.78223 9.41534C3.90584 9.41534 4.81671 8.50447 4.81671 7.38086C4.81671 6.25725 3.90584 5.34638 2.78223 5.34638C1.65861 5.34638 0.747744 6.25725 0.747744 7.38086ZM20.584 7.38086C20.584 8.50447 21.4948 9.41534 22.6184 9.41534C23.742 9.41534 24.6529 8.50447 24.6529 7.38086C24.6529 6.25725 23.742 5.34638 22.6184 5.34638C21.4948 5.34638 20.584 6.25725 20.584 7.38086ZM2.78223 7.76232H22.6184V6.99939H2.78223V7.76232Z" />
                                            <mask id="path-2-inside-1_14464_9328" fill="white">
                                                <path d="M22.6187 3.56641H43.2178V11.9586C43.2178 12.8014 42.5346 13.4845 41.6919 13.4845H24.1445C23.3018 13.4845 22.6187 12.8014 22.6187 11.9586V3.56641Z" />
                                            </mask>
                                            <path d="M22.6187 3.56641H43.2178V11.9586C43.2178 12.8014 42.5346 13.4845 41.6919 13.4845H24.1445C23.3018 13.4845 22.6187 12.8014 22.6187 11.9586V3.56641Z" fill="white" />
                                            <path d="M22.6187 3.56641H43.2178H22.6187ZM43.9807 11.9586C43.9807 13.2227 42.956 14.2474 41.6919 14.2474H24.1445C22.8804 14.2474 21.8557 13.2227 21.8557 11.9586H23.3816C23.3816 12.38 23.7232 12.7216 24.1445 12.7216H41.6919C42.1133 12.7216 42.4549 12.38 42.4549 11.9586H43.9807ZM24.1445 14.2474C22.8804 14.2474 21.8557 13.2227 21.8557 11.9586V3.56641H23.3816V11.9586C23.3816 12.38 23.7232 12.7216 24.1445 12.7216L24.1445 14.2474ZM43.9807 3.56641V11.9586C43.9807 13.2227 42.956 14.2474 41.6919 14.2474V12.7216C42.1133 12.7216 42.4549 12.38 42.4549 11.9586V3.56641H43.9807Z" mask="url(#path-2-inside-1_14464_9328)" />
                                            <path d="M22.6187 2.04051C22.6187 1.1978 23.3018 0.514648 24.1445 0.514648H41.6919C42.5346 0.514648 43.2178 1.1978 43.2178 2.04051V3.56637H22.6187V2.04051Z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    <div className={classes.formControl} >
                        <InputLabel id="targetCard" >Choose target card*</InputLabel>
                        <CustomSelect
                            labelId="targetCard"
                            id="targetcard-select"
                            placeholder="Select card"
                            value={values.targetCard}
                            onChange={onOptionSelect}
                            options={targetCardOptions}
                            selectBoxWidth={344}
                            disabled={targetCardOptions.length === 0}
                            error={targetCardError}
                        />
                        {
                            targetCardError && <Typography data-test-id="nameError" className="error" variant="subtitle2" >{errors.targetCard}</Typography>
                        }
                    </div>
                </div>
                <CardFooter onClose={onDrawerClose} loading={loading} onSaveClick={() => handleSubmit()} />
            </div>
        </ThemeProvider>
    )
}
