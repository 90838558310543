import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { configJSON } from "../Chatbot5Controller"
import { getStorageData } from "../../../../framework/src/Utilities";
import { toast } from "react-toastify";


export interface ChatbotNameProps {
  navigation: {
    navigate: (to: string, params: Record<string, string | number>) => void;
    getParam: (param: string, alternative?: string) => string;
    goBack: () => void;
  };
  handleLogout: () => Promise<void>;
  isTemplate: boolean;
  handleSetEdattWithChatbotSettingsError: (isEdatt: boolean, hasErrorInChatbotSettings: boolean) => void;
}

interface S {
  isEditingName: boolean;
  chatbotName: string;
  token: string;
}

interface SS {
  id: any;
}

export default class ChatbotNameController extends BlockComponent<
  ChatbotNameProps,
  S,
  SS
> {

  chatbotCallId: string | null = null;
  chatbotUpdateCallId: string | null = null;
  botId: string = "";
  initialChatbotName: string = "";
  templateCallId: string | null = null;
  templateNameUpdateCallId: string | null = null;


  constructor(props: ChatbotNameProps) {
    super(props);
    this.receive = this.receive.bind(this);

    this.botId =  this.props.navigation.getParam("navigationBarTitleText")

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      chatbotName: "",
      isEditingName: false,
      token: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.handleRestApiResponse = this.handleRestApiResponse.bind(this)
    this.handleFetchChatbotDetail = this.handleFetchChatbotDetail.bind(this)
    this.handleChatbotDetailResponse = this.handleChatbotDetailResponse.bind(this)
    this.handleChatbotUpdateResponse = this.handleChatbotUpdateResponse.bind(this)
  }

  async receive(from: string, message: Message) {
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleRestApiResponse(message)
    }
  }

  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({token: token})
    this.handleFetchChatbotDetail(token, this.props.isTemplate)
  }

  handleRestApiResponse(message: Message) {
    const responseHandlers: Record<string, (newMsg: Message) => void> = {}
    const messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

    if(this.chatbotCallId && this.chatbotCallId === messageId) {
      responseHandlers[this.chatbotCallId] = this.handleChatbotDetailResponse
    }

    if(this.chatbotUpdateCallId && this.chatbotUpdateCallId === messageId) {
      responseHandlers[this.chatbotUpdateCallId] = this.handleChatbotUpdateResponse
    }
    
    const handler = responseHandlers[messageId]
    if(handler) {
      handler(message)
    }
  }
  
  async handleChatbotDetailResponse(message: Message) {
    const chatbotResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(chatbotResponse.data) {
      this.initialChatbotName = chatbotResponse.data.attributes.name
      this.setState({chatbotName: chatbotResponse.data.attributes.name})
      const { chatbot_url, qr_code, edatt } = chatbotResponse.general_setting.data.attributes
      const hasErrorInChatSettings = !chatbot_url || !qr_code
      this.props.handleSetEdattWithChatbotSettingsError(edatt, hasErrorInChatSettings)
    }
  }

  async handleChatbotUpdateResponse(message: Message) {
    const chatbotUpdateResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!chatbotUpdateResponse) {
        toast.error("Failed to update chatbot details", {className: "error__toast"})
        return;
    }
    if(chatbotUpdateResponse.errors?.[0]?.token) {
        await this.props.handleLogout()
        return;
    }

    if(chatbotUpdateResponse.message) {
        toast.success(chatbotUpdateResponse.message, {className: "success__toast"})
    }
  }

  handleFetchChatbotDetail(token: string, isTemplate: boolean) {
    const chatbotMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.chatbotCallId = chatbotMessage.messageId
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    }
    chatbotMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    chatbotMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isTemplate ? `${configJSON.createTemplateEndpoint}/${this.botId}` : `${configJSON.chatbotEndpoint}/${this.botId}`
    )
    chatbotMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )
    chatbotMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {title: "ChtabotName"}
    )
    this.send(chatbotMessage)
  }

  handleEditName = () => {
    this.setState({isEditingName: true})
  }

  handleChatbotNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({chatbotName: event.target.value})
  }

  handleChatbotNameBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    this.setState({isEditingName: false})
    if(value === this.initialChatbotName) {
        return;
    }

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    }

    let body: unknown = {
        data: {
            attributes: {
                name: value
            }
        },
    }

    let endpoint = `${configJSON.chatbotEndpoint}/${this.botId}`

    if(this.props.isTemplate) {
      body = {
        card_template: {
            name: value,
        }
      }
      endpoint = `${configJSON.createTemplateEndpoint}/${this.botId}`
    }

    const updateMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    updateMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
    )
    updateMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    )
    updateMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
    )
    updateMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
    )

    this.chatbotUpdateCallId = updateMessage.messageId
    this.send(updateMessage)
  }
  
}
