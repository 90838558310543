// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface Item {
  title: string;
  blogs: {
    id: number;
    bx_block_blogpostsmanagement_blogpost_categories_id: number;
    title: string;
    content: string;
    content_show: boolean;
  }[];
}
// Customizable Area End

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  blogData: {
    title: string;
    blogs: {
      id: number;
      bx_block_blogpostsmanagement_blogpost_categories_id: number;
      title: string;
      content: string;
      content_show: boolean;
    }[];
  }[];
  blogData1: {
    title: string;
    blogs: {
      id: number;
      bx_block_blogpostsmanagement_blogpost_categories_id: number;
      title: string;
      content: string;
      content_show: boolean;
    }[];
  }[];
  isLoading: boolean;
  content: boolean;
  blogContent: string | null;
  blogStatus:boolean;
  openPopup:boolean;
  BlogPostMgmtPagedata:any;
  switchStates:any;
  showDeleteRowbtn:any;
  searchBlog: string;
  currentPage: number;
  itemsPerPage: number;
  rowToDelete:any;
  token:string;
  pagination: {
    total: number
  };
  initialRender: boolean;
  anchorEl: any;
  showMenu: boolean;
  guideCount: number;
  // Customizable Area End
}

interface SS {
  id: string | number;
  // Customizable Area Start
  // Customizable Area End
}

export interface StoreInfo {
  data?: string;
  type?: string;
  name?: string;
  attribs: {
    src: string;
    height: string;
  };
}
export interface Types {
  item: {
    content: string;
    title:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  };
}
export interface AttributesInfo {
  // Customizable Area Start
  title: string;
  content: string;
  blogs: {
    map(
      arg0: (item: {
        content: string;
        title:
        | boolean
        | import("react").ReactChild
        | import("react").ReactFragment
        | import("react").ReactPortal
        | null
        | undefined;
      }) => JSX.Element
    ): string | number | boolean | {} | null | undefined;
  };

  // Customizable Area End
}

export default class BlogPostsManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBlogsApiCallId: Message | string = "";
  getBlogPostDataApiCallID: string = "";
  getDeteleBlogPostDataApiCallID: string = "";
  getViewCountApiCallID: string = "";
  apiBlogStatusCallId: string = "";
  apiGuideCountCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseToken)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      blogData: [],
      isLoading: false,
      content: false,
      blogContent: "",
      blogData1: [],
      blogStatus:true,
      openPopup:false,
      BlogPostMgmtPagedata:[],
      switchStates:{},
      showDeleteRowbtn: {},
      searchBlog: '',
      currentPage: 1,
      itemsPerPage: 20,
      rowToDelete:null,
      token:"",
      initialRender: false,
      pagination: { total: 0 },
      anchorEl: null,
      showMenu: false,
      guideCount: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getBlogsApiCallId) {
          let store: {
            title: string;
            blogs: {
              id: number;
              bx_block_blogpostsmanagement_blogpost_categories_id: number;
              title: string;
              content: string;
              content_show: boolean;
            }[];
          }[] = [];
          for (let blogs in responseJson) {
            store.push({
              title: blogs,
              blogs: responseJson[blogs],
            });
          }

          let blogsData: {
            title: string;
            blogs: {
              id: number;
              bx_block_blogpostsmanagement_blogpost_categories_id: number;
              title: string;
              content: string;
              content_show: boolean;
            }[];
          }[] = [];
          for (let blogs in responseJson) {
            blogsData.push({ title: blogs, blogs: responseJson[blogs] });
          }
          this.setState({
            blogData: store,
            blogData1: blogsData,
            isLoading: false,
          });
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    this.handleResponseForGetBlogPost(from, message);
    this.handleResponseForDeleteRow(from, message);
    this.handleResponseForActiveStatus(from, message);
    this.handleGetGuideCountResponse(from, message)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({token})
    this.getBlogPost()
  }

  goToDashbordPage = (item: string) => {
    this.props.navigation.navigate("BlogContent", { itemcontent: item });
  };

  setData = (data: string) => {
    this.setState({
      blogContent: data,
    });
  };
  getBlogPost = async () =>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBlogPostDataApiCallID = requestMessage.messageId;

    if(!this.state.searchBlog){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getBlogpostEndPoint}?page=${this.state.currentPage}&per_page=${this.state.itemsPerPage}`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getBlogpostEndPoint}/search?title=${this.state.searchBlog}&page=${this.state.currentPage}&per_page=${this.state.itemsPerPage}`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResponseForGetBlogPost = async (from: string, message: Message) =>{
    if (
      this.getBlogPostDataApiCallID !== null &&
      this.getBlogPostDataApiCallID ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.guides) {
        const allFormData = responseJson.guides.data.map((data: { attributes: { updated_at: string; }; }) => ({
          ...data,
          updated_at: data.attributes.updated_at ? this.handleLastUpdate(data.attributes.updated_at) : null
        }))
        this.setState({ BlogPostMgmtPagedata: allFormData, pagination: {total: responseJson.meta.total_count}, isLoading: false, initialRender: false });
      } else if (responseJson.data) {
        const allFormData = responseJson.data.map((data: { attributes: { updated_at: string; }; }) => ({
          ...data,
          updated_at: data.attributes.updated_at ? this.handleLastUpdate(data.attributes.updated_at) : null
        }))
        this.setState({ BlogPostMgmtPagedata: allFormData, pagination:  {total: allFormData.length}, isLoading: false, initialRender: false });
      }
      else if(responseJson?.error){
        this.setState({BlogPostMgmtPagedata: [], pagination:  {total: 0}})
      }else this.handleLogout()

    }
  }
  handleLastUpdate(date: string) {
    const currentDate = new Date();
    const updatedAt = new Date(date);
    if (updatedAt.toLocaleDateString() === currentDate.toLocaleDateString()) {
      return this.handleFormatTimeString(updatedAt)
    } else if (currentDate.getDay() - updatedAt.getDay() === 1) {
      return `Yesterday ${this.handleFormatTimeString(updatedAt)}`
    } else {
      return `${this.handleFormatDateString(updatedAt)} ${this.handleFormatTimeString(updatedAt)}`
    }
  }
  handleFormatDateString(date: Date) {
    return date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear()
  }

  handleFormatTimeString(date: Date) {
    const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
    const formattedTime = date.toLocaleTimeString('en-US', options);
    return formattedTime; // Output: 05:24 AM
  }
  handleLogout = async() => {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const message = new Message(
      getName(MessageEnum.NavigationSignupLoginMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  getPracticeLabel = (association: string | Array<{id: number, name: string}>) => {
    let label: string = "-"
    if(typeof association === "string" && association === "global") {
      label = "Global"
    }
    if(Array.isArray(association)) {
      label = association.map((practice) => practice.name).join(", ")
    }
    label = label.length > 0 ? label : "-"
    return label
  };
  getFullAssociationText = (association: any) => {
     if (Array.isArray(association)) {
      const names = association.map(item => item.name);
      return names.join(', ')
    }
    return ""
  };
  handleResponseForDeleteRow = (from: string, message: Message) =>{
    if (
      this.getDeteleBlogPostDataApiCallID !== null &&
      this.getDeteleBlogPostDataApiCallID ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        let page = this.state.currentPage
        if (this.state.BlogPostMgmtPagedata.length === 1 && this.state.currentPage > 1) {
          page = this.state.currentPage - 1
        }
        this.apiGuideCountCallId = ""
        this.setState({
          openPopup: false,
          showDeleteRowbtn: false,
          currentPage: page,
          guideCount: 0,
        })
        toast.success("User Guide successfully deleted", {className: "success__toast"}) 
        this.getBlogPost();
      }
    }
  }
  handleSwitchChange = (id:any) => (event:any) => {
    const checked = event.target.checked;
    this.setState((prevState) => ({
      switchStates: {
        ...prevState.switchStates,
        [id]: checked,
      },
      BlogPostMgmtPagedata: prevState.BlogPostMgmtPagedata.map((data: { id: any; attributes: any; }) => {
        if (data.id === id) {
          return { ...data, attributes: { ...data.attributes, active: checked } }
        }
        return data
      })
    }), ()=>{
      this.sendSwitchStateToAPI(id, checked);
    });
  };
  sendSwitchStateToAPI = async (id:any, checked:any) =>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const httpBody = {
      active: checked,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiBlogStatusCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfinteractivewhiteboardtoolforchatbotmaker2/guides/${id}/update_active_status`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.viewCountMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResponseForActiveStatus = (from: string, message: Message) =>{
    if (
      this.apiBlogStatusCallId !== null &&
      this.apiBlogStatusCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.getBlogPost();
      }
    }
  }

  handleDeleteRow(event: any, id: any) {
    this.setState({
      anchorEl: event.currentTarget,
      showMenu: true,
      rowToDelete: id,
    });
  };

  showMenuPopup = (id: number)=>{
    this.setState({
      showMenu: true,
    })
  }
  closeMenuPopup = ()=>{
    this.apiGuideCountCallId = ""
    this.setState({
      showMenu: false,
    })
  }
  
  showPopup = () =>{
      this.setState({
        openPopup:true,
        showDeleteRowbtn:false,
        showMenu: false,
      })
  }
  handleClose = () =>{
    this.apiGuideCountCallId = ""
    this.setState({
      openPopup:false,
      rowToDelete: null,
      guideCount: 0,
    })
  }
  deleteDataRow = async () =>{
    
    if(this.state.rowToDelete !== null){
      const token = this.state.token;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getDeteleBlogPostDataApiCallID = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteBlogpostEndPoint}${this.state.rowToDelete}?token=${token}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteApiMethodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      
    }
  }
  handleSearch = (event:any) =>{
      this.setState({
        searchBlog:event.target.value,
        currentPage: 1,
      }, ()=> this.getBlogPost())
  }
  handlePageChange = (event: any, value: number) => {
    this.setState({ currentPage: value }, () => this.getBlogPost());
  };
  getAbbreviatedText = (text: string) => {
    if (text?.length > 20) {
      return text.slice(0, 20).padEnd(23, "...")
    }
    return text
  }

  handlenavigate = (id:any) =>{
    const navigationMessage = new Message(getName(MessageEnum.NavigationEditUserGuideMessage))
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), { navigation: this.props.navigation })
    navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), id)
    this.send(navigationMessage)
  }
  handleCount = async (id:any) =>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getViewCountApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfinteractivewhiteboardtoolforchatbotmaker2/guides/${id}/increment_view`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.viewCountMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleNavigate2 = () =>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PostCreation");
    msg.addData( getName(MessageEnum.NavigationPropsMessage), this.props );
    this.send(msg);
  }

  getUserGuideCount = () => {
    if(this.apiGuideCountCallId) {
      return;
    }

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify({
      "Content-Type": "application/json",
      token:this.state.token,   
    }))

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ "guide_id": this.state.rowToDelete })
    )

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userGuideCountEndPoint
    )

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )

    this.apiGuideCountCallId = reqMessage.messageId

    this.send(reqMessage)
  }

  handleGetGuideCountResponse = async (from: string, message: Message) => {
    if(this.apiGuideCountCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      if(responseJson.errors?.[0]?.token) {
        await this.handleLogout()
        return;
      }

      if(responseJson.chat_bot_count !== undefined) {
        this.setState({guideCount: responseJson.chat_bot_count})
      }
    }
  }

  // Customizable Area End
}
// Customizable Area End
