import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  AppBar, Toolbar, Grid, Paper, Popover,
  TextField, Avatar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import "react-image-crop/dist/ReactCrop.css";
import { SketchPicker } from 'react-color'
import ReactCrop from "react-image-crop";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CustomProfileHeaderController, {
  Props,
  configJSON,
} from "./CustomProfileHeaderController";

export default class CustomProfileHeader extends CustomProfileHeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.imageRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
   getAvatar = (message:any) => {
    let avatarContent;
    
    if (message === "Go Back") {
      avatarContent = (
        <Avatar>
          <IconButton>
            <ArrowBackIosIcon />
          </IconButton>
        </Avatar>
      );
    } else if (message === "Retry") {
      avatarContent = (
        <Avatar>
          <IconButton>
            <RefreshIcon />
          </IconButton>
        </Avatar>
      );
    } else {
      avatarContent = <Avatar>M</Avatar>;
    }
  
    return avatarContent;
  };
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedTab } = this.state;
    const suggestedMessages = ["Go Back", "Retry"];
    return (
      <ThemeProvider theme={theme}>
        <div style={{ backgroundColor: '#F8F8F7' }}>
          <AppBar position="static">
            <Toolbar>
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                  <IconButton style={{ width: 0, height: 0, color: 'grey' }} aria-label="set alarm">
                    <ArrowBackIosIcon />
                  </IconButton>
                  <div>
                    <Typography style={{ color: 'grey' }}>Chat Bot</Typography>
                  </div>
                  <IconButton style={{ width: 0, height: 0, color: 'grey' }} aria-label="set alarm">
                    <ArrowForwardIosIcon />
                  </IconButton>
                  <div>
                    <Typography style={{ color: 'grey' }}>Answer bot</Typography>
                  </div>
                  <IconButton style={{ width: 0, height: 0, color: 'grey' }} aria-label="set alarm">
                    <ArrowForwardIosIcon />
                  </IconButton>
                  <div>
                    <Typography style={{ color: 'black', fontWeight: 'bold' }}>Chatbot settings</Typography>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', }}>
                  <Button style={{ backgroundColor: '#E4F6FF', color: '#51abb4', marginRight: 10, width: 100, height: 40, fontWeight: 'bold' }} variant="contained">
                    cancel
                  </Button>
                  <Button style={{ backgroundColor: '#51abb3', color: 'white', width: 100, height: 40, marginRight: 10, fontWeight: 'bold' }}>Apply</Button>

                  <IconButton style={{ width: 0, height: 0, color: 'grey', marginTop: 5 }} aria-label="set alarm" >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Grid container spacing={2} style={{ backgroundColor: '#F8F7F7' }}>
            <Grid item xs={8} style={{ marginTop: 10, backgroundColor: 'white' }}>
              <div>
                <div style={{ flexDirection: 'row', width: '40%', }}>
                  <Button
                    data-test-id={"HeaderButtonId"}
                    style={{
                      ...webStyle.TernaryButtonStyle,
                      background: selectedTab === 1 ? '#E5F1FF' : '#F9FAFC',
                      color: selectedTab === 1 ? '#51abb4' : '#64748b',
                      borderRadius: selectedTab === 1 ? 15 : 0,
                      border: selectedTab === 1 ? '1px solid #96CED3' : 'none',
                      borderWidth: selectedTab === 1 ? 1 : 0,
                      width: '50%'
                    }}
                    onClick={() => this.setState({ selectedTab: 1 })}
                  >
                    General settings
                  </Button>
                  <Button
                    data-test-id={"BackGroundButtonId"}
                    style={{
                      ...webStyle.TernaryButtonStyle,
                      background: selectedTab === 2 ? '#E5F1FF' : '#F9FAFC',
                      color: selectedTab === 2 ? '#51abb3' : '#64748b',
                      borderRadius: selectedTab === 2 ? 15 : 0,
                      border: selectedTab === 2 ? '1px solid #96CED3' : 'none',
                      borderWidth: 1,
                      width: '50%',
                    }}
                    onClick={() => this.setState({ selectedTab: 2 })}
                  >
                    Design settings
                  </Button>
                </div>
                {selectedTab === 1 ? (
                  <div>
                    <Typography></Typography>
                  </div>
                ) : null}
                {selectedTab === 2 ? (
                  <Box style={{ marginTop: 15, borderWidth: 1, marginLeft: 10, borderColor: 'grey' }}>
                    <Grid>
                      <Grid item xs={6} style={{ borderWidth: 2, borderColor: 'black' }}>
                        <Typography
                          variant='h6'
                          style={{
                            fontWeight: 'bold', color: '#0F172A',
                            fontFamily: 'Cairo',
                            fontSize: '16px'
                          }}>Pick a color for header</Typography>
                        <div style={{ marginTop: 15 }}>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                              {["#7d5aec", "#2dd67b", "#ff606b", "#3bc2e0", "#51ABB4"].map((color) => (
                                <div
                                  key={color}
                                  style={{
                                    ...webStyle.headerDiv,
                                    backgroundColor: color,
                                    position: 'relative'

                                  }}
                                  onClick={() =>
                                    this.setState({ selectHeaderColor: color })
                                  }
                                >
                                  {this.state.selectHeaderColor === color && (
                                    <span
                                      style={{
                                        ...webStyle.headerSelected,
                                        position: 'absolute',

                                      }}
                                    >
                                      ✓
                                    </span>
                                  )}
                                </div>
                              ))}
                            </div>
                            <IconButton
                              data-test-id={"HeaderIconId"}
                              style={{
                                ...webStyle.HeaderIconButton,
                                backgroundColor: this.state.selectHeaderColor
                              }}
                              onClick={() => this.handleHexPickerToggle()}
                            ><AddIcon /></IconButton>
                          </div>
                          <Box style={{ display: 'flex' }}>
                            <Popover
                              open={this.state.setHexPickerOpen}
                              anchorEl={this.state.anchorEl}
                              onClose={this.handleCloseColorPicker}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                            >
                              <Box p={2} style={{ position: 'relative' }}>
                                <Typography style={{
                                  fontWeight: 'bold',
                                  fontFamily: 'Cairo',
                                  fontSize: '14px'
                                }}>Select color</Typography>
                                <SketchPicker color={this.state.selectHeaderColor}
                                  onChange={this.handleColorChange}
                                />
                                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-around', marginTop: 10 }}>
                                  <Button style={{
                                    ...webStyle.CancelHeaderButton
                                  }}
                                    data-test-id={"HeaderClosePickerId"}
                                    onClick={() => this.handleCloseColorPicker()}
                                  >Cancel</Button>
                                  <Button style={{
                                    ...webStyle.DoneHeaderButton
                                  }}
                                    data-test-id={"HeaderDoneButtonId"}
                                    onClick={() => this.handleDoneButtonClick()}
                                  >Done</Button>
                                </div>
                              </Box>
                            </Popover>
                          </Box>
                        </div>

                        <Typography
                          variant='h6'
                          style={{
                            ...webStyle.BackGroundHeader
                          }}>Pick a color for background</Typography>
                        <div style={{ marginTop: 15 }}>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                            <div>
                              {["#E3EBF2", "#D1FAE6", "#F6F0FF", "#F8FAFC", "#FFFFFF"].map((color) => (
                                <div
                                  key={color}
                                  style={{
                                    ...webStyle.BackGroundDiv,
                                    backgroundColor: color,
                                    position: 'relative',
                                  }}
                                  onClick={() =>
                                    this.setState({ selectBackGroundColor: color })
                                  }
                                >
                                  {this.state.selectBackGroundColor === color && (
                                    <span
                                      style={{
                                        ...webStyle.BackGroundTick,
                                        position: 'absolute',
                                      }}
                                    >
                                      ✓
                                    </span>
                                  )}
                                </div>
                              ))}
                            </div>
                            <IconButton
                              data-test-id={"BackGroundpickerButtonId"}
                              onClick={() => this.handleHexPickerBackGround()}
                              style={{
                                ...webStyle.BackGroundAddIcon,
                                backgroundColor: this.state.selectBackGroundColor,
                              }}
                            ><AddIcon /></IconButton>
                          </div>

                        </div>
                        <Typography
                          variant='h6'
                          style={{
                            ...webStyle.widgetHeader,
                          }}>Widget</Typography>
                        <Typography
                          variant='h6'
                          style={{
                            ...webStyle.widgetHeaderDesc
                          }}>The widget will float along as the visitors scroll through</Typography>

                        <Popover
                          open={this.state.setHexPickerOpenBackground}
                          anchorEl={this.state.anchorEl1}
                          onClose={this.handleCloseColorPickerBackground}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "center",
                            horizontal: "right",
                          }}
                        >
                          <Box p={2} style={{ position: 'relative' }}>
                            <Typography style={{
                              fontWeight: 'bold',
                              fontFamily: 'Cairo',
                              fontSize: '14px'
                            }}>Select color</Typography>
                            <SketchPicker color={this.state.selectBackGroundColor}
                              onChange={this.handleColorBackGround}
                              disableAlpha={true}

                            />
                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-around', marginTop: 10 }}>
                              <Button style={{
                                ...webStyle.BackGroundCancelButton
                              }}
                                data-test-id={"BackGroundClosepickerButtonId"}
                                onClick={() => this.handleCloseColorPickerBackground()}
                              >Cancel</Button>
                              <Button style={{
                                ...webStyle.BackGroundDoneButton
                              }}
                                data-test-id={"BackGroundDoneButtonId"}
                                onClick={() => this.handleDoneButtonBackGroundClick()}
                              >Done</Button>
                            </div>
                          </Box>
                        </Popover>
                      </Grid>

                    </Grid>
                  </Box>

                ) : null}
              </div>
            </Grid>
            <Grid item xs={4} style={{ borderWidth: 2, borderColor: 'black', maxHeight: '100%', border: 'black' }}>
              <Paper style={{ height: '100%' }}>
                <div style={{ backgroundColor: this.state.selectHeaderColor, padding: '10px', height: "20%" }}>
                  <Typography
                    style={{ color: 'white', fontSize: '20px', fontFamily: 'Cairo', fontWeight: '400' }}>Chat with</Typography>
                  <Typography variant="subtitle1" style={{ color: 'white', fontSize: '20px', fontFamily: 'Cairo', fontWeight: '700' }}>Max Support Chatbot </Typography>
                </div>
                <div style={{ padding: '16px', backgroundColor: this.state.selectBackGroundColor }}>
                  <Grid container direction="row" spacing={1} style={{ justifyContent: 'center', marginTop: 150 }}>
                    {suggestedMessages.map((message: any, index) => (
                      <Grid item key={index}>
                       {this.getAvatar(message)}
                      </Grid>
                    ))}
                  </Grid>
                  <div style={{ display: 'flex', marginTop: '16px' }}>
                    <TextField
                      placeholder="Type here"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        style: {
                          borderRadius: 15,
                          border: "1px solid grey",
                          padding: "5px"
                        },
                      }}
                    />
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </ThemeProvider >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  cropModalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column" as "column",
  },
  cropModal: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column" as "column",
  },
  previewImage: {
    width: 320,
    height: 180,
    margin: 20,
  },
  boxUpperSpace: {
    marginTop: 10
  },
  TernaryButtonStyle: {
    height: 30,
    fontWeight: 'bold',
  },
  headerDiv: {
    width: '50px',
    height: '50px',
    borderRadius: '30px',
    display: 'inline-block',
    margin: '5px',
    cursor: 'pointer',
    border: `2px solid "black"`,
    borderWidth: '20px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
  },
  headerSelected: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontWeight: 'bold'
  },
  HeaderIconButton: {
    width: '50px',
    height: '50px',
    borderRadius: '30px',
    cursor: 'pointer',
    border: `2px solid "black"`,
    borderWidth: '20px',
    marginLeft: 10,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
  },
  CancelHeaderButton: {
    fontWeight: 'bold',
    fontFamily: 'Cairo',
    fontSize: '14px',
    backgroundColor: 'E5EBFF',
    color: '#51abb4',
    width: '45%'
  },
  DoneHeaderButton: {
    fontWeight: 'bold',
    fontFamily: 'Cairo',
    fontSize: '14px',
    backgroundColor: '51ABB3',
    color: 'white',
    width: '45%'
  },
  BackGroundHeader: {
    fontWeight: 'bold',
    color: '#0F172A',
    fontFamily: 'Cairo',
    fontSize: '16px',
    marginTop: 20
  },
  BackGroundDiv: {
    width: '50px',
    height: '50px',
    borderRadius: '30px',
    display: 'inline-block',
    margin: '5px',
    cursor: 'pointer',
    border: `2px solid "black"`,
    borderWidth: '20px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
  },
  BackGroundTick: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontWeight: 'bold'
  },
  BackGroundAddIcon: {
    width: '50px',
    height: '50px',
    borderRadius: '30px',
    cursor: 'pointer',
    border: `2px solid "black"`,
    borderWidth: '20px',
    marginLeft: 10,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
  },
  widgetHeader: {
    fontWeight: 'bold', color: '#0F172A',
    fontFamily: 'Cairo',
    fontSize: '16px',
    marginTop: 20
  },
  widgetHeaderDesc: {
    fontWeight: 'bold', color: 'grey',
    fontFamily: 'Cairo',
    fontSize: '13px',
    marginTop: 20
  },
  BackGroundCancelButton: {
    fontWeight: 'bold',
    fontFamily: 'Cairo',
    fontSize: '14px',
    backgroundColor: 'E5EBFF',
    color: '#51abb4',
    width: '45%'
  },
  BackGroundDoneButton: {
    fontWeight: 'bold',
    fontFamily: 'Cairo',
    fontSize: '14px',
    backgroundColor: '51ABB3',
    color: 'white',
    width: '45%'
  },
};
// Customizable Area End
