import { FormikErrors } from "formik";
import { FormQuestion } from "./AutomaticFormCreationController.web";
import { Question } from "./components/EmbedForm.web"


const getFormQuestionsWithError = (formQuestions: FormQuestion[]) => {
    let hasQuestionError = false;
    let hasOptionError = false;
    const newFormQuestionsWithError = formQuestions.map(field => {
        const newField: FormQuestion = {...field, questionError: undefined}

        newField["questionError"] = ""

        if(field.question.trim().length === 0) {
            newField["questionError"] = "Please add a question"
            hasQuestionError = true
        }

        if(field.options) {
            newField["optionError"] = ""
            field.options.forEach(option => {
                if(option.value.trim().length === 0) {
                    newField["optionError"] = "Please fill all options"
                    hasOptionError = true
                }
            })
        }


        return newField
    })

    return {
        hasQuestionError,
        hasOptionError,
        newFormQuestionsWithError,
    }
}

const getErroMessageForEndUserForms = (values: Record<string, string | string[]>, errors: FormikErrors<Record<string, string | string[]>>, questions: Question[]) => {
    const noOfFieldUnanswered = Object.keys(values).reduce((count, current) => {
        const currentValue = values[current]

        if(typeof currentValue === "string" && currentValue.trim().length === 0) {
            count += 1
        } else if(currentValue.length === 0) {
            count += 1
        }

        return count
    }, 0)

    if(noOfFieldUnanswered > 0) {
        return `You still have ${noOfFieldUnanswered} question to answer before you can continue`
    }

    let errorMessage: string = ""

    questions.forEach((question) => {
        const questionName = `question_${question.id}`
        const error = errors[questionName]
        if(question.question_type === "numerical" && error) {
            errorMessage = `Please add a valid answer for question - ${question.question}`
        }
    })

    return errorMessage
}

export const utils = {
    getFormQuestionsWithError,
    getErroMessageForEndUserForms
}
