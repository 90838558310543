import React, { useCallback, useEffect, useRef, useState } from "react";
import { CircularProgress, Popover } from "@material-ui/core"
import { CheckRounded, KeyboardArrowDown, Search } from "@material-ui/icons";
import { Option, useCustomSelectStyles } from "./CustomSelect.web";


const paperPropsStyle: React.CSSProperties = {
    overflow: "hidden",
    maxHeight: "none",
    border: "1px solid #CBD5E1",
    backgroundColor: "#FFF",
    boxShadow: "0px 2px 8px 0px #00000014",
    boxSizing: "border-box",
    borderRadius: 8,
}

type NoSearchSelect = {
    hasSearch?: false;
    searchPlaceholder?: string;
}

type SearchSelect = {
    hasSearch: true;
    searchPlaceholder: string;
}

export type MultipleCustomSelectProps = {
    labelId?: string;
    id: string;
    placeholder: string;
    value: string[];
    onChange: (value: string[]) => void;
    containerStyle?: React.CSSProperties;
    options: Option[];
    selectBoxWidth: string | number;
    renderOption?: (option: Option) => React.ReactNode;
    renderSelectedValue?: (selectedOption: Option) => React.ReactNode;
    selectClassName?: string;
    optionClassName?: string;
    disabled?: boolean;
    error?: boolean;
    handleFetchMore?: () => void;
    hasNextPage?: boolean;
} & (NoSearchSelect | SearchSelect)


const MultipleCustomSelect = ({
    labelId,
    id,
    placeholder,
    value,
    hasSearch,
    searchPlaceholder,
    options,
    onChange,
    selectBoxWidth,
    renderOption,
    renderSelectedValue,
    optionClassName = "",
    selectClassName = "",
    disabled = false,
    error = false,
    handleFetchMore,
    hasNextPage=false,
}: MultipleCustomSelectProps) => {
    const classes = useCustomSelectStyles()
    const comboboxRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState<boolean>(false)
    const [customSelectOptions, setCustomSelectOptions] = useState<Array<Option>>(options)
    const intersectionObserverRef = useRef<IntersectionObserver>();
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        const searchText = inputRef.current?.value ?? ""
        let newCustomSelectOptions = options
        if(searchText) {
            newCustomSelectOptions = customSelectOptions.filter(option => option.label.toLowerCase().includes(searchText.toLowerCase()))
        }
        setCustomSelectOptions(newCustomSelectOptions)
    }, [options])

    const handleOpenPopover = () => {
        if (disabled) {
            return;
        }
        setOpen(true)
    }
    const handleClose = () => {
        if(inputRef.current) {
            inputRef.current.value = "";
        }
        setOpen(false)
        setCustomSelectOptions(options)
    }

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = e.target.value;
        if (searchText.trim().length === 0) {
            setCustomSelectOptions(options)
            return;
        }
        const newOptions = customSelectOptions.filter(option => option.label.toLowerCase().includes(searchText.toLowerCase()))
        setCustomSelectOptions(newOptions)
    }

    const onListBoxClick = (option: Option) => () => {
        const valueSet = new Set([...value])
        if(valueSet.has(option.value)) {
            valueSet.delete(option.value)
        } else {
            valueSet.add(option.value)
        }
        const newValue = [...valueSet]
        onChange(newValue)
    }

    const instersectionTriggerRef = useCallback(async (node: HTMLDivElement | null) => {
        if (!node) {
          return;
        }
        if (intersectionObserverRef.current) {
          intersectionObserverRef.current.disconnect();
        }
    
        intersectionObserverRef.current = new IntersectionObserver(
          async (entries) => {
            if (!entries[0].isIntersecting) {
              return;
            }
            handleFetchMore?.()
          },
        );
    
        intersectionObserverRef.current.observe(node);
      }, [hasNextPage]);

    const isIconDistanceGreaterThan241 = comboboxRef.current
        ? (window.innerHeight - comboboxRef.current.getBoundingClientRect().bottom) > 241
        : false;

    let selectedValue = undefined;

    const valueSet = new Set(value)
    const selectedOptions = options.filter(item => valueSet.has(item.value))
    selectedValue = selectedOptions.length === 0 ? undefined : selectedOptions.length === 1 ? selectedOptions[0] : {label: `${selectedOptions.length} Selected`, value: `${selectedOptions.length} Selected`}
    
    return (
        <div id={id} className={classes.root} style={{ width: selectBoxWidth }} >
            <div ref={comboboxRef} data-testid={`${id}-combobox`} data-error={error} aria-disabled={disabled} role="combobox" aria-expanded={open} aria-labelledby={labelId} aria-haspopup="listbox" className={`${classes.select} ${selectClassName} ${error ? classes.error : ""}`} onClick={handleOpenPopover} >
                {placeholder && !selectedValue && <span className={classes.value}>{placeholder}</span>}
                {selectedValue && (
                    <span id="selected-value" className={classes.value}>
                        {renderSelectedValue ? renderSelectedValue(selectedValue) : selectedValue.label}
                    </span>
                )}
                <KeyboardArrowDown htmlColor="#64748B" />
            </div>
            <Popover
                id={"popover" + "-" + id}
                open={open}
                anchorEl={comboboxRef.current}
                anchorOrigin={{
                    vertical: isIconDistanceGreaterThan241 ? 'bottom' : 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: isIconDistanceGreaterThan241 ? 'top' : 'bottom',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                PaperProps={{ style: { width: selectBoxWidth, ...paperPropsStyle } }}
                aria-expanded={open}
            >
                <div className={classes.listWithSearchboxContainer} >
                    {
                        hasSearch && (
                            <div className={classes.searchBoxList} >
                                <div className={classes.searchBox}>
                                    <Search htmlColor="#94A3B8" />
                                    <input
                                        type="text"
                                        className={classes.searchInput}
                                        placeholder={searchPlaceholder}
                                        onChange={handleSearchInputChange}
                                        aria-label="search"
                                        ref={inputRef}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <ul className={classes.listBox} role="listbox">
                        {
                            customSelectOptions.map((option) => {
                                const isOptionSelected = value.includes(option.value)
                                return (
                                    <li
                                        key={option.value}
                                        onClick={onListBoxClick(option)}
                                        data-value={option.value}
                                        className={`${classes.option} ${optionClassName}`}
                                        role="option"
                                        aria-selected={isOptionSelected}
                                    >
                                        {renderOption ? renderOption(option) : option.label}
                                        {isOptionSelected && <CheckRounded htmlColor="#51ABB3" />}
                                    </li>
                                )
                            })
                        }
                        {
                            hasNextPage && (
                                <div ref={instersectionTriggerRef} style={{margin: "10px auto"}} >
                                    <CircularProgress style={{height: 24, width: 24, color: "#51ABB3"}} />
                                </div>
                            )
                        }
                    </ul>
                </div>
            </Popover>
        </div>
    )
}

export default MultipleCustomSelect
