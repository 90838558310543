import React, { useState } from "react";
import { 
    Button, 
    CircularProgress, 
    createTheme, 
    Dialog, 
    DialogActions, 
    InputLabel, 
    makeStyles, 
    Slider, 
    ThemeProvider, 
    Typography 
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Cropper from "react-easy-crop";
import { Area, Point } from "react-easy-crop/types";

const theme = createTheme({
    typography: {
        h3: {
            fontFamily: "Cairo",
            fontWeight: 700,
            fontSize: 24,
            lineHeight: "32px",
            color: "#0F172A",
        },
        h4: {
            fontFamily: "Cairo",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "32px",
            color: "#0F172A",
        },
        subtitle1: {
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "24px",
            color: "#94A3B8",
        },
    },
    overrides: {
        MuiFormLabel: {
            root: {
                fontSize: 14,
                '&.zoom': {
                    fontWeight: 500,
                    lineHeight: "24px",
                    fontFamily: "Roboto",
                    color: "#51ABB3",
                },
            },
        },
        MuiButton: {
            root: {
              textTransform: "none",
              borderRadius: 8,
              padding: "16px",
              width: 105,
              flex: 1,
              '&.dialogFooterBtn': {
                flex: "0 0 105px",
                padding: 16,
              },
              '&:hover': {
                  backgroundColor: "transparent",
                  boxShadow: 'none',
              },
              '&.Mui-secondary': {
                  backgroundColor: "#E5F6FF",
                  color: "#51ABB3",
              },
              '&.Mui-primary': {
                  color: "#FFFFFF",
                  backgroundColor: "#51ABB3",
              },
              '&.Mui-secondary:hover': {
                backgroundColor: "#E5F6FF",
                boxShadow: 'none',
              },
              '&.Mui-primary:hover': {
                backgroundColor: "#51ABB3",
              },
            },
            label: {
                fontFamily: "Cairo",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "24px",
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(15, 23, 42, 0.4)',
            },
        },
        MuiPaper: {
            root: {
                backgroundCoor: "#FFFFFF",
                width: 568,
                scrollbarWidth: "none",
                '&::-webkit-scrollbar': {
                    width: 0,
                },
            },
            elevation24: {
                boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017"
            },
            rounded: {
                borderRadius: 24,
            }
        },
        MuiDialogActions: {
            root: {
                padding: "24px 40px",
                gap: 10,
                borderTop: "1px solid #CBD5E1",
                position: "sticky",
                zIndex: 2,
                bottom: 0, 
                backgroundColor: "#FFFFFF",
            },
            spacing: {
                '& > :not(:first-child)': {
                    marginLeft: 0
                }
            }
        },
        MuiSlider: {
            root: {
                color: "#51ABB3",
                padding: 0,
            },
            rail: {
                backgroundColor: "#94A3B8",
                height: 4,
                borderRadius: 100,
            },
            track: {
                height: 4,
                backgroundColor: "#51ABB3",
                borderRadius: 100,
            },
            thumb: {
                boxShadow: "none",
                '&:hover': {
                    boxShadow: "none",
                },
                '&.Mui-focusVisible': {
                    boxShadow: "none",
                },
            },
            active: {
                boxShadow: "none",
            },
        },
        MuiAvatar: {
            root: {
                width: "100%",
                height: "100%",
            },
        },
    },
})

const useStyles = makeStyles({
    dialogHeader: {
        padding: "24px 40px",
        borderBottom: "1px solid #CBD5E1",
        display: "flex",
        justifyContent: "space-between",
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: "#FFFFFF",
    },
    dialogContent: {
        padding: "0 38.6px 23.21px",
        display: "flex",
        flexDirection: "column",
    },
    zoomFormControl: {
        display: "flex",
        flexDirection: "column",
        gap: 4,
    },
    zoomLabel: {
        display: "flex",
        justifyContent: "space-between",
    },
    imageOuterContainer: {
        height: 333,
        position: "relative",
        '& .reactEasyCrop_CropArea': {
            border: "4px solid #51ABB3",
            color: "#B0B9C6",
        },
    },
    profileImage: {
        width: "100%",
        height: "100%"
    },
})

type ImageCropDialogProps = {
    open: boolean;
    handleClose: () => void;
    selectedImage: string;
    onApply: (image: CroppedImage) => void;
    profileUpdateLoading?: boolean;
}

type CroppedImage = {
    url: string; 
    file: File;
}

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
})

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
): Promise<CroppedImage | null> {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return null
  }
  const {width, height} = image;

  canvas.width = width
  canvas.height = height

  ctx.drawImage(image, 0, 0)

  const croppedCanvas = document.createElement('canvas')

  const croppedCtx = croppedCanvas.getContext('2d')

  if (!croppedCtx) {
    return null
  }

  croppedCanvas.width = pixelCrop.width
  croppedCanvas.height = pixelCrop.height

  croppedCtx!.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((blob) => {
        if(!blob) {
            return reject(null)
        }
        const file = new File([blob], "test.jpg", {type: 'image/*'})
        resolve({url: URL.createObjectURL(blob), file})
    }, 'image/png')
  })
}

export default function ImageCropDialog({
    open,
    handleClose,
    selectedImage,
    onApply,
    profileUpdateLoading=false,
}: ImageCropDialogProps) {
    const [scale,setScale] = useState<number>(1)
    const [crop, setCrop] = useState<Point>({x: 0, y: 0})
    const [croppedAreaPixel, setCroppedAreaPixel] = useState<Area | null>(null)
    const classes = useStyles()

    const handleCropImage = async() => {
        if(!croppedAreaPixel) {
            return;
        }
        const croppedImageURL = await getCroppedImg(selectedImage, croppedAreaPixel)
        if(!croppedImageURL) {
            return;
        }
        onApply(croppedImageURL)
    };

    const onZoomChange = (zoom: number) => {
        setScale(+zoom.toFixed(1))
    }

    const onCropComplete = (_croppedArea: Area, croppedAreaPixel: Area) => {
        setCroppedAreaPixel(croppedAreaPixel)
    }

    return (
        <ThemeProvider theme={theme} >
            <Dialog aria-labelledby="adjustPhoto" open={open} onClose={handleClose} PaperProps={{ className: "confirmDialog" }} BackdropProps={{ style: { opacity: "0.4" } }} >
                <div className={classes.dialogHeader} >
                    <Typography id="adjustPhoto" variant="h3" >Ajust position of photo</Typography>
                    <button aria-label="close image crop dialog" onClick={handleClose} style={{all: "unset", cursor: "pointer"}} >
                        <Close htmlColor="#334155" />
                    </button>
                </div>
                <div className={classes.dialogContent}>
                    <div className={classes.imageOuterContainer} >
                            <Cropper 
                                image={selectedImage}
                                cropShape="round"
                                aspect={1}
                                zoom={scale}
                                onZoomChange={onZoomChange}
                                minZoom={1}
                                maxZoom={3}
                                showGrid={false}
                                zoomSpeed={0.1}
                                crop={crop}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                style={{
                                    mediaStyle: {
                                        width: 288,
                                        height: 288,
                                    },
                                }}
                            />
                    </div>
                    <Typography variant="h4" style={{textAlign: "center"}} >Drag to reposition photo</Typography>
                    <div className={classes.zoomFormControl} >
                        <div className={classes.zoomLabel} >
                            <InputLabel className="zoom" >Zoom</InputLabel>
                            <Typography variant="subtitle1">{scale}</Typography>
                        </div>
                        <Slider 
                            value={scale} 
                            onChange={(e,newValue) => setScale(newValue as number)}
                            min={1}
                            max={3}
                            step={0.1}
                            name="zoom"
                            id="zoom"
                        />
                    </div>
                </div>
                <DialogActions data-testid="dialog-action" >
                    <Button onClick={handleClose} data-test-id="cancel-edit-profile" type="button" className="Mui-secondary dialogFooterBtn" >Cancel</Button>
                    <Button 
                        data-test-id="apply-edit-profile" 
                        onClick={() => handleCropImage()} 
                        type="button" 
                        className="Mui-primary dialogFooterBtn"
                        disabled={profileUpdateLoading} 
                    >
                        {profileUpdateLoading ? <CircularProgress style={{height: 24, width: 24, color: "#FFFFFF"}} /> : "Apply"}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}
