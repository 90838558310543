import React, { useEffect, useState } from "react";
import { CircularProgress, Dialog, makeStyles } from "@material-ui/core";
import { Clear } from "@material-ui/icons"
import { toast } from "react-toastify";

const useCatgeoryTemplateStyle = makeStyles({
    categoryTemplatePaper: {
        backgroundColor: "#FFFFFF",
        borderRadius: "24px !important",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06),0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09) !important",
        maxWidth: 669,
        width: 669,
        maxHeight: 563,
        scrollbarWidth: "none",
        padding: 0,
        margin: 0,
        '&::-webkit-scrollbar': {
            width: 0,
        }
    },
    backdropProps: {
        backgroundColor: "rgba(15, 23, 42, 0.4)",
    },
    header: {
        padding: "24px 40px",
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: "#FFFFFF",
        display: "flex",
        justifyContent: "space-between",
        gap: 10,
        alignItems: "center",
        borderBottom: "1px solid #CBD5E1",
        width: "100%",
        boxSizing: "border-box",
        '& > h3': {
            margin: 0,
            fontFamily: "Cairo",
            fontWeight: 700,
            fontSize: 24,
            lineHeight: "32px",
            color: "#0F172A"
        },
        '& > button': {
            all: "unset",
            cursor: "pointer",
        },
    },
    footer: {
        borderTop: "1px solid #CBD5E1",
        position: "sticky",
        bottom: 0,
        zIndex: 2,
        padding: "24px 40px",
        backgroundColor: "#FFFFFF",
        display: "flex",
        gap: 10,
        justifyContent: "flex-end",
        width: "100%",
        boxSizing: "border-box",
    },
    footerBtn: {
        all: "unset",
        cursor: "pointer",
        width: 105,
        textAlign: "center",
        padding: 16,
        borderRadius: 8,
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
        color: "#51ABB3",
        backgroundColor: "#E5F6FF",
        '&.primary': {
            backgroundColor: "#51ABB3",
            color: "#FFFFFF",
        },
        '&[aria-disabled="true"]': {
            pointerEvents: "none",
        },
    },
    dialogBody: {
        padding: "24px 40px",
        height: 379,
        display: "flex",
        flexDirection: "column",
        gap: 12,
        width: "100%",
        boxSizing: "border-box",
    },
    label: {
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "22px",
        color: "#475569",
    },
    input: {
        boxSizing: "border-box",
        padding: "16px 8px",
        border: "1px solid #CBD5E1",
        borderRadius: 8,
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px",
        color: "#64748B",
        '&:focus': {
            outline: 0,
            borderColor: "#51ABB3"
        },
        '&:placeholder': {
            opacity: 0.7,
        },
        '&[aria-invalid="true"]': {
            borderColor: "#F59E0B",
        },
    },
    error: {
        color: "#D97706",
        fontSize: 14,
        lineHeight: "18px",
        fontWeight: 400,
        margin: 0,
    },
})

const initialElementTextObj: Record<"category" | "template", Record<string, string>> = {
    category: {
        button: "Create",
        error: "Please enter a category name",
        title: "Create new Category",
    },
    template: {
        button: "Go to",
        error: "Please enter a template name",
        title: "Create new Template",
    },
}

const editedinitialElementTextObj: Record<"category" | "template", Record<string, string>> = {
    template: {
        title: "Edit Template",
        button: "Save",
        error: "Please enter a template name",
    },
    category: {
        button: "Save",
        title: "Edit Category",
        error: "Please enter a category name",
    },

}

type CategoryTemplatePopupProps = {
    type: "category" | "template";
    isEdit?: boolean;
    open: boolean;
    handleClose: () => void;
    onSubmit: (value: string) => void;
    initialName?: string;
    isCreateOrUpdateLoading: boolean;
}


export default function CategoryTemplatePopup({
    type,
    open,
    handleClose,
    isEdit = false,
    onSubmit,
    initialName = "",
    isCreateOrUpdateLoading,
}: CategoryTemplatePopupProps) {
    const classes = useCatgeoryTemplateStyle()
    const [name, setName] = useState<string>(initialName)
    const [isNameInvalid, setIsNameInvalid] = useState<boolean>(false)
    const elementText = isEdit ? editedinitialElementTextObj[type] : initialElementTextObj[type]

    useEffect(() => {
        if(!open) {
            setName("")
        }
    }, [open])

    const handleNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const isError = value.trim().length === 0
        setName(event.target.value)
        setIsNameInvalid(isError)
    }

    const handleSubmit = () => {
        if (name.trim().length === 0) {
            toast.error(elementText.error, { className: "error__toast" })
            setIsNameInvalid(true)
            return;
        }
        onSubmit(name)
    }


    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.categoryTemplatePaper }}
            BackdropProps={{ className: classes.backdropProps }}
            disableEnforceFocus
            onClose={handleClose}
        >
            <div className={classes.header}>
                <h3>{elementText.title}</h3>
                <button onClick={handleClose} >
                    <Clear htmlColor="#334155" />
                </button>
            </div>
            <div className={classes.dialogBody} >
                <label className={classes.label} >Name*</label>
                <input
                    autoFocus
                    type="text"
                    placeholder="Type the name here"
                    className={classes.input}
                    value={name}
                    onChange={handleNameInputChange}
                    aria-invalid={isNameInvalid}
                    data-testid="name-input"
                />
                {isNameInvalid && <p data-testid="error" className={classes.error} >{elementText.error}</p>}
            </div>
            <div className={classes.footer} >
                <button data-testid="cancel-btn" className={classes.footerBtn} onClick={handleClose} >Cancel</button>
                <button aria-disabled={isCreateOrUpdateLoading} data-testid="createOrSaveBtn" className={`${classes.footerBtn} primary`} onClick={handleSubmit} >
                    {isCreateOrUpdateLoading ? <CircularProgress data-testid="loader" style={{ height: 24, width: 24, color: "#FFFFFF" }} /> : elementText.button}
                </button>
            </div>
        </Dialog>
    )
}
