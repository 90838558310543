import * as yupSchema from "yup";

type SchemaType = yupSchema.StringSchema<string | undefined>

const ratingValidationSchema = (botCtx: string[]) => yupSchema.object().shape({
    name: yupSchema.string().trim().required("Please add a name"),
    message: yupSchema.string().trim().required("Please add a message"),
    saveIntoBotContext: yupSchema.boolean().notRequired(),
    fieldName: yupSchema.string().when("saveIntoBotContext",{
        is: true,
        then: (schema: SchemaType) => schema.trim().required("Please enter a valid name").notOneOf(botCtx, "Please enter a unique name"),
        otherwise: (schema: SchemaType) => schema.notRequired() 
    }),
})

const dropdownValidationSchema = (botContext: string[]) => yupSchema.object().shape({
    title: yupSchema.string().trim().required("Please add a name"),
    message: yupSchema.string().trim().required("Please add a message"),
    saveIntoBotContext: yupSchema.boolean().notRequired(),
    fieldName: yupSchema.string().when("saveIntoBotContext", {
        is: true,
        then: (schema: SchemaType) => schema.trim().required("Please enter a valid name").notOneOf(botContext, "Plese enter a unique name"),
        otherwise: (schema: SchemaType) => schema.notRequired()
    }),
    options: yupSchema.string().required("Please add options")
})

const sliderScaleValidationSchema = (botContext: string[]) => yupSchema.object().shape({
    title: yupSchema.string().trim().required("Please add a name"),
    message: yupSchema.string().trim().required("Please add a message"),
    saveIntoBotContext: yupSchema.boolean().notRequired(),
    fieldName: yupSchema.string().when("saveIntoBotContext",{
        is: true,
        then: (schema: SchemaType) => schema.trim().required("Please enter a valid name").notOneOf(botContext, "Please enter a unique name"),
        otherwise: (schema: SchemaType) => schema.notRequired() 
    }),
    minValue: yupSchema.string().required("Please enter a valid range").test(
        "should be minimum of 0",
        "Please enter a valid range",
        function (value) {
            return +value >= 0 && +value <= 100
        }
    ),
    maxValue: yupSchema.string().required("Please enter a valid range").min(0, "Please enter a valid range").test(
        "is-valid-range",
        "Please enter a valid range",
        function (value) {
            const { minValue } = this.parent;
            return +value <= 100 && +minValue < +value;
        }
    ),
})

export const schema = {
    ratingValidationSchema,
    dropdownValidationSchema, 
    sliderScaleValidationSchema
}

