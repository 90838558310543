import React, { useState } from "react";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { ReplayRounded, ZoomInRounded, ZoomOutRounded, BlurOnRounded, TextFieldsRounded, FormatUnderlinedRounded } from "@material-ui/icons"

const useStyles = makeStyles({
    paperStyle: {
        borderRadius: 12,
        boxSizing: "border-box",
        padding: "0px",
        minWidth: 180,
        border: "2px solid #F5F5F5",
        boxShadow: "0px 2px 4px 0px #00000026",
    },
    accessibilityButton: {
        all: "unset",
        width: "100%",
        boxSizing: "border-box",
        cursor: "pointer",
        padding: "12px 16px",
        display: "flex",
        gap: 8,
        fontFamily: "Roboto",
        fontSize: 14,
        lineHeight: "16px",
        fontWeight: 400,
        color: "#1E293B",
    },
    separator: {
        margin: "0px 16px",
        borderTop: "1px solid #CBD5E1",
    },
    formLabelRoot: {
        margin: 0,
        width: "100%",
        boxSizing: "border-box",
        padding: "12px 16px",
        alignItems: "center",
        gap: 8,
    },
    formLabel: {
        fontFamily: "Roboto",
        fontSize: 14,
        lineHeight: "16px",
        fontWeight: 400,
        color: "#1E293B",
        display: "flex",
        gap: 8,
        alignItems: "center",
    },
    formCheckboxRoot: {
        padding: 0,
    },
    paperFooter: {
        display: "flex",
        gap: 6,
        padding: "12px 16px",
    },
    paperFooterBtn: {
        all: "unset",
        flex: 1,
        cursor: "pointer",
        textAlign: "center",
        padding: "8px 10px",
        borderRadius: 8,
        backgroundColor: "#F8FAFC",
        color: "#51ABB3",
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
        '&.primary': {
            backgroundColor: "#51ABB3",
            color: "#FFFFFF"
        },
    },
})

type AccessibilitySettingsProps = {
    onClose: () => void;
    headerColor: string;
    backgroundColor: string;
    initialAccessibilitySettings: string[];
    setAccessibilitySettings: (accessibilitySettings: string[]) => void;
}

const grayscale: Record<string, string> = {
    "--header-background": "#888888",
    "--header-text": "#FFFFFF",
    "--border-seperator": "#CBD5E1",
    "--body-background": "#FFFFFF",
    "--card-background": "#F4F5F7",
    "--card-border": "#F4F5F7",
    "--card-text": "#0F172A",
    "--card-button-border": "#F2F2F2",
    "--card-selected-button-border": "#51ABB3",
    "--card-button-background": "#FFFFFF",
    "--card-selected-button-background": "#E4F1F2",
    "--card-button-text": "#64748B",
    "--card-selected-button-text": "#51ABB3",
    "--chat-name-text": "#94A3B8",
    "--chevron-background": "#888888",
}

const highContrast: Record<string, string> = {
    "--header-background": "#3CFFF3",
    "--header-text": "#000000",
    "--border-seperator": "#3CFFF3",
    "--body-background": "#000000",
    "--card-background": "#000000",
    "--card-border": "#3CFFF3",
    "--card-text": "#FFFFFF",
    "--card-button-border": "#3CFFF3",
    "--card-selected-button-border": "#3CFFF3",
    "--card-button-background": "#000000",
    "--card-selected-button-background": "#000000",
    "--card-button-text": "#FFFFFF",
    "--card-selected-button-text": "#FFFFFF",
    "--chat-name-text": "#FFFFFF",
    "--chevron-background": "#3CFFF3",
}

const negativeContrast: Record<string, string> = {
    "--header-background": "#FFFFFF",
    "--header-text": "#000000",
    "--border-seperator": "#FFFFFF",
    "--body-background": "#000000",
    "--card-background": "#000000",
    "--card-border": "#FFFFFF",
    "--card-text": "#FFFFFF",
    "--card-button-border": "#FFFFFF",
    "--card-selected-button-border": "#FFFFFF",
    "--card-button-background": "#000000",
    "--card-selected-button-background": "#000000",
    "--card-button-text": "#FFFFFF",
    "--card-selected-button-text": "#FFFFFF",
    "--chat-name-text": "#FFFFFF",
    "--chevron-background": "#FFFFFF",
}

const lightBackground: Record<string, string> = {
    "--border-seperator": "#CBD5E1",
    "--header-text": "#FFFFFF",
    "--card-background": "#E2E8F0",
    "--card-border": "#E2E8F0",
    "--card-text": "#0F172A",
    "--card-button-border": "#F2F2F2",
    "--card-selected-button-border": "#51ABB3",
    "--card-button-background": "#FFFFFF",
    "--card-selected-button-background": "#E4F1F2",
    "--card-button-text": "#64748B",
    "--card-selected-button-text": "#51ABB3",
    "--chat-name-text": "#94A3B8",
    "--chevron-background": "#475569",
}

const accesibilityWithColorVariables: Record<string, Record<string, string>> = {
    grayscale: grayscale,
    high_contrast: highContrast,
    negative_contrast: negativeContrast,
    light_background: lightBackground
}

const accessibiltyCheckboxGroups: Array<{ label: React.ReactNode, name: string }> = [
    {
        label: <>
            <BlurOnRounded htmlColor="#334155" style={{ width: 16, height: 16 }} />
            Grayscale
        </>,
        name: "grayscale"
    },
    {
        label: <>
            <HighContrastIcon />
            High Contrast
        </>,
        name: "high_contrast"
    },
    {
        label: <>
            <TextFieldsRounded htmlColor="#334155" style={{ width: 16, height: 16 }} />
            Readable Font
        </>,
        name: "readable_font"
    },
    {
        label: <>
            <FormatUnderlinedRounded htmlColor="#334155" style={{ width: 16, height: 16 }} />
            Links Underline
        </>,
        name: "links_underline"
    },
    {
        label: <>
            <LightBackgroundIcon />
            Light Background
        </>,
        name: "light_background"
    },
    {
        label: <>
            <NegativeContrastIcon />
            Negative Contrast
        </>,
        name: "negative_contrast"
    },
]

export default function AccessibilitySettings({
    onClose,
    headerColor,
    backgroundColor,
    initialAccessibilitySettings,
    setAccessibilitySettings
}: AccessibilitySettingsProps) {
    const classes = useStyles()
    const [selectedAccessibiity, setSelectedAccessibility] = useState<Set<string>>(new Set(initialAccessibilitySettings))

    const onIncreaseDecreaseText = (increase: boolean) => () => {
        const chatPopupElement = document.getElementById("chatMessagesPopupContainer")!
        const fontSizeValue = getComputedStyle(chatPopupElement).getPropertyValue("--font-size")
        const parsedFontSizeValue = parseFloat(fontSizeValue.slice(0, fontSizeValue.length - 2))
        const newFontSizeValue = increase ? parsedFontSizeValue + 2.5 : parsedFontSizeValue - 2.5
        chatPopupElement.style.setProperty("--font-size", `${Math.min(Math.max(newFontSizeValue, 7.5), 15)}px`)
    }

    const onReset = () => {
        const chatPopupElement = document.getElementById("chatMessagesPopupContainer")!
        chatPopupElement.setAttribute("style", `--font-size: 10px; --header-background: ${headerColor}; --body-background: ${backgroundColor};`)
    }

    const onAccessibilityChange = (value: string) => () => {
        const newSelectedAccessbility = new Set(selectedAccessibiity)
        if(newSelectedAccessbility.has(value)) {
            newSelectedAccessbility.delete(value)
        } else {
            newSelectedAccessbility.add(value)
        }
        setSelectedAccessibility(newSelectedAccessbility)
    }

    const onApply = () => {
        const chatPopupElement = document.getElementById("chatMessagesPopupContainer")!
        const muiPaperProp = document.getElementById("mui-paperprop")
        const fontSizeValue = getComputedStyle(chatPopupElement).getPropertyValue("--font-size")
        let style: Record<string, string> = {
            "--font-size": fontSizeValue,
            "--header-background": headerColor,
            "--body-background": backgroundColor
        };

        const accessibilitySettings = [...selectedAccessibiity]

        accessibilitySettings.forEach((accessibility) => {
            if(accesibilityWithColorVariables[accessibility]) {
                style = {...style, ...accesibilityWithColorVariables[accessibility]}
            }    
        })

        if(selectedAccessibiity.size === 0) {
            style = {
                ...style,
                "--header-background": headerColor,
                "--body-background": backgroundColor
            }
        }

        if(selectedAccessibiity.has("links_underline")) {
            style["--text-decoration"] = "underline"
        } else {
            style["--text-decoration"] = "none"
        }

        if(selectedAccessibiity.has("readable_font")) {
            style["--font-family"] = "Arial"
            if(muiPaperProp) {
                muiPaperProp.style.setProperty("--font-family", "Arial")
            }
        } else {
            if(muiPaperProp) {
                muiPaperProp.style.removeProperty("--font-family")
            }
        }

        const styleAttribute = Object.entries(style).map((value) => `${value[0]}: ${value[1]};`).join(" ")

        chatPopupElement.setAttribute("style", styleAttribute)

        setAccessibilitySettings(accessibilitySettings)
    }

    return (
        <>
            <button className={classes.accessibilityButton} onClick={onReset} >
                <ReplayRounded htmlColor="#334155" style={{ width: 16, height: 16 }} />
                Reset
            </button>
            <button className={classes.accessibilityButton} onClick={onIncreaseDecreaseText(true)} >
                <ZoomInRounded htmlColor="#334155" style={{ width: 16, height: 16 }} />
                Increase Text
            </button>
            <button className={classes.accessibilityButton} onClick={onIncreaseDecreaseText(false)} >
                <ZoomOutRounded htmlColor="#334155" style={{ width: 16, height: 16 }} />
                Decrease Text
            </button>
            <div className={classes.separator} />
            {
                accessibiltyCheckboxGroups.map((accessibilityForm) => {
                    return (
                        <FormControlLabel
                            key={accessibilityForm.name}
                            className={classes.formLabel}
                            classes={{ root: classes.formLabelRoot, label: classes.formLabel, }}
                            label={accessibilityForm.label}
                            value={accessibilityForm.name}
                            control={<Checkbox onChange={onAccessibilityChange(accessibilityForm.name)} checked={selectedAccessibiity.has(accessibilityForm.name)} classes={{ root: classes.formCheckboxRoot }} checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />} name={accessibilityForm.name} disableRipple disableFocusRipple disableTouchRipple />}
                        />
                    )
                })
            }
            <div className={classes.paperFooter} >
                <button className={classes.paperFooterBtn} onClick={onClose} >Cancel</button>
                <button className={`${classes.paperFooterBtn} primary`} onClick={onApply} >Apply</button>
            </div>
        </>
    )
}

function UncheckedIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="#64748B" />
        </svg>
    )
}

function CheckedIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="6" fill="#51ABB3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.061 5.42238C15.5182 5.73229 15.6375 6.35411 15.3276 6.81124L10.243 14.3112C10.077 14.5562 9.81098 14.715 9.51659 14.745C9.2222 14.7749 8.92965 14.673 8.71765 14.4665L4.80221 10.6536C4.40654 10.2683 4.39814 9.6352 4.78345 9.23953C5.16876 8.84386 5.80187 8.83546 6.19754 9.22077L9.25759 12.2007L13.6722 5.68895C13.9821 5.23182 14.6039 5.11247 15.061 5.42238Z" fill="white" />
        </svg>
    )
}

function HighContrastIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.33331 7.9987C1.33331 4.3187 4.31998 1.33203 7.99998 1.33203C11.68 1.33203 14.6666 4.3187 14.6666 7.9987C14.6666 11.6787 11.68 14.6654 7.99998 14.6654C4.31998 14.6654 1.33331 11.6787 1.33331 7.9987ZM2.66665 7.9987C2.66665 10.7187 4.70665 12.9587 7.33331 13.2854V2.71203C4.69998 3.0387 2.66665 5.2787 2.66665 7.9987Z" fill="#334155" />
        </svg>
    )
}

function LightBackgroundIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.99998 1.33203C5.42665 1.33203 3.33331 3.42536 3.33331 5.9987C3.33331 7.58536 4.12665 8.9787 5.33331 9.82537V11.332C5.33331 11.6987 5.63331 11.9987 5.99998 11.9987H9.99998C10.3666 11.9987 10.6666 11.6987 10.6666 11.332V9.82537C11.8733 8.9787 12.6666 7.58536 12.6666 5.9987C12.6666 3.42536 10.5733 1.33203 7.99998 1.33203ZM5.99998 13.9987C5.99998 14.3654 6.29998 14.6654 6.66665 14.6654H9.33331C9.69998 14.6654 9.99998 14.3654 9.99998 13.9987V13.332H5.99998V13.9987ZM9.33331 9.13203L9.89998 8.73203C10.8 8.10537 11.3333 7.08536 11.3333 5.9987C11.3333 4.1587 9.83998 2.66536 7.99998 2.66536C6.15998 2.66536 4.66665 4.1587 4.66665 5.9987C4.66665 7.08536 5.19998 8.10537 6.09998 8.73203L6.66665 9.13203V10.6654H9.33331V9.13203Z" fill="#0F172A" />
        </svg>
    )
}

function NegativeContrastIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.33331 5.9987C3.33331 3.42536 5.42665 1.33203 7.99998 1.33203C10.5733 1.33203 12.6666 3.42536 12.6666 5.9987C12.6666 7.58536 11.8733 8.9787 10.6666 9.82537V11.332C10.6666 11.6987 10.3666 11.9987 9.99998 11.9987H5.99998C5.63331 11.9987 5.33331 11.6987 5.33331 11.332V9.82537C4.12665 8.9787 3.33331 7.58536 3.33331 5.9987ZM6.66665 14.6654C6.29998 14.6654 5.99998 14.3654 5.99998 13.9987V13.332H9.99998V13.9987C9.99998 14.3654 9.69998 14.6654 9.33331 14.6654H6.66665Z" fill="#1E293B" />
        </svg>
    )
}
