import React from "react";
import { makeStyles } from "@material-ui/core"

type ChatbotInputProps = {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles({
    input: {
        width: 400,
        padding: "12px 8px",
        border: "1px solid #CBD5E1",
        backgroundColor: "#FFFFFF",
        fontFamily: "Cairo",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px",
        color: "#64748B",
        borderRadius: 8,
        '&:focus': {
            outline: 0,
            borderColor: "#51ABB3",
        },
        '&:placeholder': {
            opacity: 0.7,
        },
    },
})

export default function ChatbotInput({ value, onChange }: ChatbotInputProps) {
    const classes = useStyles()
    return (
        <input type="text" id="chatbot-url" placeholder="Type your url here" value={value} onChange={onChange} className={classes.input} />
    )
}
