import { Edge, MarkerType, Node } from "reactflow";
import { NodeData } from "../../../components/src/CardNode.web";

type TreeNode = {
    node: Node<NodeData>;
    children: TreeNode[];
};

type GetNewNodesWithNewEdges = {
    nodes: Node<NodeData>[];
    onAddNodeClick: (data: Omit<NodeData, "status" | "onClick">) => void;
  checkForError: boolean;
}

type FollowUpAction = {
    id: number;
    redirectCardId: string | null;
    buttonText: string;
}

type OpenAIRedirectCardEdgeArgs = {
    action: FollowUpAction;
    sourceId: string;
    followUpActionVisitedSet: Set<string>;
    hasErrorInSourceNode: string | undefined;
    edgeClassName: string;
    actionIndex: number;
    edges: Edge[];
}

function buildChain(startNode: Node<NodeData>, sourceToNode: Map<string | number, Node<NodeData>[]>, visited: Set<string>) {
    const chain = [startNode];
    let queue = [startNode];
    visited.add(startNode.id)


    while (queue.length > 0) {
        const currentNode = queue.shift();
        const currentId = currentNode!.id;

        if (sourceToNode.has(currentId)) {
            sourceToNode.get(currentId)!.forEach((nextNode) => {
                if (!visited.has(nextNode.id)) {
                    const newNextNode = {...nextNode,position: {...nextNode.position}, data: {...nextNode.data}}
                    chain.push(newNextNode);
                    queue.push(newNextNode);
                    visited.add(nextNode.id)
                }
            });
        }
    }

    return chain;
}

function buildChains(nodes: Node<NodeData>[], sourceToNode: Map<string | number, Node<NodeData>[]>, idToNodeMap: Map<string, Node<NodeData>>) {
    const chains: Node<NodeData>[][] = [];
    const visited = new Set<string>();

    nodes.forEach((node) => {
        if (!visited.has(node.id)) {
            let startNode = node;
      
            for (const sourceId of node.data.source) {
              if (!visited.has(sourceId)) {
                startNode = idToNodeMap.get(sourceId)!;
                break;
              }
            }
      
            const chain = buildChain(startNode, sourceToNode, visited);
            chains.push(chain);
        }
    });

    return chains;
}

const addEndNodes = (node: TreeNode, onAddNodeClick: GetNewNodesWithNewEdges["onAddNodeClick"], sourceToNode: Map<string | number, Node<NodeData>[]>) => {
    const endNodes: NodeData["type"][] = ["go_to_tile", "end_chat", "criteria_routing", "add_node"]
    
    if (
        node.children.length === 0 &&
        !endNodes.includes(node.node.data.type)
    ) {
        const allSourcesWhereNodeExists = sourceToNode.get(node.node.id)
        if(!allSourcesWhereNodeExists){
            const endNode: Node<NodeData> = {
                id: `end-${node.node.id}`,
                type: "addNode",
                position: { x: 0, y: 0 },
                data: {
                    id: node.node.data.id * 1234,
                    title: "Add node",
                    message: "Add node message",
                    type: "add_node",
                    status: "connected",
                    source: [node.node.id],
                    height: 44,
                    connectorCardType: node.node.data.connectorCardType,
                    onClick: onAddNodeClick
                },
            };
            if (!node.node.data.error) {
                node.node.className = "primary";
            }
            node.children.push({ node: endNode, children: [] });
        }
        
    } else if (
        node.node.data.type === "go_to_tile" ||
        node.node.data.type === "end_chat"
    ) {
        node.node.className = "no-handle";
    } else {
        node.children.forEach((child) => addEndNodes(child, onAddNodeClick, sourceToNode));
    }
};

const checkAndMarkIncompleteBranches = (tree: TreeNode) => {
    const visitedNodes = new Set<string>()
    const traverse = (node: TreeNode): boolean => {
        if (node.node.data.type === "end_chat" || node.node.data.type === "go_to_tile" || visitedNodes.has(node.node.id)) {
            return true;
        }

        visitedNodes.add(node.node.id)

        let foundEndChatInChildren = false;
        let foundAddNodeChild = false;
        node.children.forEach((child) => {
            if (child.node.id.startsWith("end")) {
                foundAddNodeChild = true;
            } else {
                if (traverse(child)) {
                    foundEndChatInChildren = true;
                }
            }
        });

        node.node.data.error = "";
        node.node.className = "";

        const noEndChatInChildren = node.children.length === 0 && !foundEndChatInChildren

        if (noEndChatInChildren || foundAddNodeChild) {
            node.node.data.error =
                "This branch is incomplete, please add a 'end chat' tile before publication";
            node.node.className = "danger";
        }

        if(node.node.id === "start") {
            node.node.className = "";
            node.node.data.error = "";
        }

        return foundEndChatInChildren;
    };

    traverse(tree);
};

const markUnconnectedNodes = (
    tree: TreeNode,
) => {
    const traverse = (node: TreeNode) => {
        node.node.data.error =
            "This tile is unconnected to the flow, please connect or delete it";
        node.node.className = "danger";

        node.children.forEach((child) => traverse(child));
    };
    traverse(tree);
};

const setNodeXPosition = (node: TreeNode, xPos: number = 157, xIncrement: number = 478) => {
    let hasMoreThanOneChild = false
    if (node.node.id !== "start") {
        node.node.position.x = xPos;
        xPos += xIncrement;
        if(node.children.length > 1) {
            hasMoreThanOneChild = true
        }
    }

    node.children.forEach((child, childIndex) => {
        let childXPos = xPos
        if(hasMoreThanOneChild && childIndex > 0) {
            childXPos = childXPos - (30 * childIndex)
        }
        setNodeXPosition(child, childXPos, xIncrement);
    });
};

function setNodeYPositions(root: TreeNode, initialY: number) {
    let nextTreeY = initialY
    let maxHeight = 0

    function traverseAndSetY(node: TreeNode, y: number): number {
        if (node.node.id !== "start") {
            node.node.position.y = y;
            maxHeight = Math.max(maxHeight, node.node.position.y)
            nextTreeY = Math.max(nextTreeY, node.node.position.y + node.node.data.height!)
        }

        let currentY = y;
        node.children.forEach((child, index) => {
            let childY = currentY;
            if (index > 0) {
                childY += 70;
            }

            const childHeight = traverseAndSetY(child, childY);
            currentY = maxHeight + childHeight;
        });

        return node.node.data.height!;
    }

    traverseAndSetY(root, initialY);

    return nextTreeY
}

const getMarkerEndColor = (isEndNode: boolean, hasErrorInSourceNode: string | undefined) => {
    let color = "#51ABB3";
    if(!isEndNode) {
        color = "#334155"
    }

    if(hasErrorInSourceNode) {
        color = "#DC2626"
    }
    return color
}; 

const getOpenAIRedirectCardEdge = ({
    action,
    sourceId,
    followUpActionVisitedSet,
    hasErrorInSourceNode,
    actionIndex,
    edgeClassName,
    edges,
}: OpenAIRedirectCardEdgeArgs) => {
    let actionStrokeStyle = "dashed"
    let target = `end-${sourceId}-${action.id}`;
    if(action.redirectCardId) {
        target = action.redirectCardId
        actionStrokeStyle = "solid"
    }
    const edgeId = `e-${sourceId}-${target}-follow-up-action-${action.id}`
    if(!followUpActionVisitedSet.has(edgeId)) {
        const edge: Edge = {
            id: edgeId,
            source: sourceId,
            target: target,
            type: "smoothDashEdge",
            markerEnd: {
                type: MarkerType.ArrowClosed,
                color: getMarkerEndColor(!action.redirectCardId, hasErrorInSourceNode),
            },
            data: {
                strokeStyle: actionStrokeStyle,
                hasError: hasErrorInSourceNode,
                followUpAction: {
                    id: action.id,
                    title: `Follow-up action ${actionIndex+1}`
                },
            },
            className: edgeClassName,
            sourceHandle: `${action.id}`,
        };
        followUpActionVisitedSet.add(edgeId)
        edges.push(edge)
    }
}



const getEdgesFromNodes = (idToNodeMap: Map<string, Node<NodeData>>, tree: TreeNode) => {
    const edges: Edge[] = [];

    const criteriaRoutingVisitedSet = new Set<string>();
    const followUpActionVisitedSet = new Set<string>();
    function getEdges(node: TreeNode) {
        const isEndNode = node.node.id.startsWith("end")
        const edgeClassName = !isEndNode ? "smooth-edge" : ""
        const strokeStyle = !isEndNode ? "solid" : "dashed"
        node.node.data.source.forEach((sourceId) => {
            const sourceNode = idToNodeMap.get(sourceId)
            let hasErrorInSourceNode: string | undefined = sourceNode?.data?.error
            if(sourceNode?.data.criteriaRoutings && sourceNode.data.criteriaRoutings.length > 0) {
                const criteriaRoutings = sourceNode.data.criteriaRoutings!
                criteriaRoutings.forEach((criteriaRouting) => {
                    let criteriaStrokeStyle = "dashed"
                    let target = `end-${sourceId}-${criteriaRouting.criteria_routing_id}`;
                    if(criteriaRouting.nextCardId) {
                        target = criteriaRouting.nextCardId
                        criteriaStrokeStyle = "solid"
                    }
                    const edgeId = `e-${sourceId}-${target}-criteria-${criteriaRouting.criteria_routing_id}`
                    if(!criteriaRoutingVisitedSet.has(edgeId)) {
                        const edge: Edge = {
                            id: edgeId,
                            source: sourceId,
                            target: target,
                            type: "smoothDashEdge",
                            markerEnd: {
                                type: MarkerType.ArrowClosed,
                                color: getMarkerEndColor(!criteriaRouting.nextCardId, hasErrorInSourceNode),
                            },
                            data: {
                                strokeStyle: criteriaStrokeStyle,
                                hasError: hasErrorInSourceNode,
                                rule: criteriaRouting,
                            },
                            className: edgeClassName,
                            sourceHandle: `${criteriaRouting.criteria_routing_id}`,
                        };
                        criteriaRoutingVisitedSet.add(edgeId)
                        edges.push(edge)
                    }
                })
            }  
            else if(sourceNode?.data.type === "openai") {
                const followUpActions = sourceNode.data.followUpActions!
                followUpActions.forEach((action, index) => {
                    getOpenAIRedirectCardEdge({
                        action,
                        actionIndex: index,
                        followUpActionVisitedSet: followUpActionVisitedSet,
                        edgeClassName,
                        edges,
                        hasErrorInSourceNode,
                        sourceId,
                    })
                })
            }                    
            else {
                const edge: Edge = {
                    id: `e-${sourceId}-${node.node.id}`,
                    source: sourceId,
                    target: node.node.id,
                    type: "smoothDashEdge",
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        color: getMarkerEndColor(isEndNode, hasErrorInSourceNode),
                    },
                    data: {
                        strokeStyle: strokeStyle,
                        hasError: hasErrorInSourceNode,
                    },
                    className: edgeClassName,
                };
                edges.push(edge)
            }
        })

        node.children.forEach(child => getEdges(child))
    }

    getEdges(tree)

    return edges
}

const tranformNodeTreeIntoArrayOfNodes = (tree: TreeNode) => {
    const nodes: Node<NodeData>[] = []
    const visited = new Set<string>()
    function convert(root: TreeNode) {
        if (!visited.has(root.node.id)) {
            nodes.push({ ...root.node, position: {...root.node.position}, data: { ...root.node.data } })
            visited.add(root.node.id)
        }
        root.children.forEach((child) => convert(child))
    }
    convert(tree)
    return nodes
}

const deepCloneTree = (tree: TreeNode, idToNodeMap: Map<string, Node<NodeData>>): TreeNode => {
    const cloneNode = (node: TreeNode): TreeNode => {
      idToNodeMap.set(node.node.id, node.node)  
      const newNode = { 
        node: { ...node.node, position: { ...node.node.position }, data: { ...node.node.data } },
        children: [] as TreeNode[]
      };
      newNode.children = node.children.map(cloneNode);
      return newNode;
    };
  
    return cloneNode(tree);
}

const getOpenAIChildren = (node: Node<NodeData>, idToNodeMap: Map<string, Node<NodeData>>, onAddNodeClick: (data: Omit<NodeData, "status" | "onClick">) => void) => {
    const childrenNodes: Node<NodeData>[] = []
    node.data.followUpActions?.map((action) => {
        const endNode: Node<NodeData> = {
            id: `end-${node.id}-${action.id}`,
            type: "addNode",
            position: { x: 0, y: 0 },
            data: {
                id: node.data.id * 1235,
                title: "Add node",
                message: "Add node",
                type: "add_node",
                status: "connected",
                source: [node.id],
                followUpActionId: action.id,
                height: 44,
                connectorCardType: node.data.connectorCardType,
                onClick: onAddNodeClick
            },
        };
        if (action.redirectCardId === null) {
            childrenNodes.push(endNode)
        } else {
            const node = idToNodeMap.get(action.redirectCardId)
            if(node) {
                childrenNodes.push(node)
            } else {
                childrenNodes.push(endNode)
            }
        }
    })
    return childrenNodes
}

function buildTree(
    nodes: Node<NodeData>[],
    startNode: Node<NodeData>,
    onAddNodeClick: GetNewNodesWithNewEdges["onAddNodeClick"],
    idToNodeMap: Map<string, Node<NodeData>>
): TreeNode {
    function buildSubTree(node: Node<NodeData>, visited: Set<string>): TreeNode {
        const nodeHeight = document.getElementById(node.id)?.offsetHeight ?? 96
        node.data.height = node.data.height ? node.data.height : nodeHeight

        if (visited.has(node.id)) {
            return {
                node: {
                    ...node,
                    position: { x: 0, y: 0 },
                    data: {
                        ...node.data,
                        type: "go_to_tile",
                        source: [],
                    },
                },
                children: [],
            };
        }
        visited.add(node.id);
        let childrenNodes: Node<NodeData>[] = []
        if (node.data.type === "criteria_routing") {
            node.data.criteriaRoutings?.map((criteriaRouting) => {
                const endNode: Node<NodeData> = {
                    id: `end-${node.id}-${criteriaRouting.criteria_routing_id}`,
                    type: "addNode",
                    position: { x: 0, y: 0 },
                    data: {
                        id: node.data.id * 1235,
                        title: "Add node",
                        message: "Add node",
                        type: "add_node",
                        status: "connected",
                        source: [node.id],
                        criteriaRoutingId: criteriaRouting.criteria_routing_id,
                        height: 44,
                        connectorCardType: node.data.connectorCardType,
                        onClick: onAddNodeClick
                    },
                };
                if (criteriaRouting.nextCardId === null) {
                    childrenNodes.push(endNode)
                } else {
                    const node = idToNodeMap.get(criteriaRouting.nextCardId!)
                    if(node) {
                        childrenNodes.push(node)
                    } else {
                        childrenNodes.push(endNode)
                    }
                }
            })
        }
        else if(node.data.type === "openai") {
            childrenNodes = getOpenAIChildren(node, idToNodeMap, onAddNodeClick)
        }
        else {
            childrenNodes = nodes.filter((n) => n.data.source.includes(node.id));
        }
        const children = childrenNodes.map((n) => buildSubTree(n, visited));
        return { node, children };
    }

    return buildSubTree(startNode, new Set<string>());
}

const getNewNodesWithNewEdges = ({nodes, onAddNodeClick, checkForError}: GetNewNodesWithNewEdges) => {
    const sourceToNode = new Map<string | number, Node<NodeData>[]>();
    const resultEdges: Edge[][] = []
    const resultNodes: Node<NodeData>[][] = []
    const idToNodeMap = new Map<string, Node<NodeData>>()
    const idToNodeMapUpdated = new Map<string, Node<NodeData>>()
    const clonedNodes: Node<NodeData>[] = nodes.map(node => ({...node, position: {...node.position}, className: "", data: {...node.data, error: ""}}))
    clonedNodes.forEach((node) => {
        idToNodeMap.set(node.id, node)
        node.data.source.forEach((src) => {
            if (!sourceToNode.has(src)) {
                sourceToNode.set(src, []);
            }
            sourceToNode.get(src)!.push(node);
        });
    });

    let curentY = 138;

    const chains = buildChains(clonedNodes, sourceToNode,idToNodeMap);
    chains.forEach((chain,chainIndex) => {
        const tree = buildTree(
            chain, 
            chain[0], 
            onAddNodeClick,
            idToNodeMap
        );
        if(checkForError) {
            if (chainIndex === 0) {
                checkAndMarkIncompleteBranches(tree);
            } else {
                markUnconnectedNodes(tree);
            }
        }
        addEndNodes(tree, onAddNodeClick, sourceToNode);
        setNodeXPosition(tree, 157, 478)
        const nextTreeY = setNodeYPositions(tree, curentY)
        curentY = nextTreeY + 50
        const deepClonedtree = deepCloneTree(tree, idToNodeMapUpdated)
        resultNodes.push(tranformNodeTreeIntoArrayOfNodes(deepClonedtree))
        resultEdges.push(getEdgesFromNodes(idToNodeMapUpdated, deepClonedtree))
    });
    return {
        newNodes: resultNodes.flatMap(node => node),
        newEdges: resultEdges.flatMap(edge => edge)
    }
};

export const nodeHelpers = {
    getNewNodesWithNewEdges
}
