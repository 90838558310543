import React, { Fragment } from "react";
import {styled} from "@material-ui/core";
import { configJSON } from "../UserProfileBasicController";
import { Cancel, CheckCircle } from "@material-ui/icons";


const CriteriaText = styled("p")({
    margin: 0,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "18px",
    color: "#000000",
    fontFamily: "Cairo",
})


const passwordConditions = [
    {
        text: "At least one capital letter",
        regex: configJSON.atLeastOneCapitalLetterRegexp,
        ariaDescribedById: "capital",
    },
    {
        text: "At least one lowercase letter",
        regex: configJSON.atLeastOnelowercaseRegexp,
        ariaDescribedById: "lowercase",
    },
    {
        text: "At least one number",
        regex: configJSON.atLeastOneNumberRegexp,
        ariaDescribedById: "number",
    },
    {
        text: "At least one special characters like $, @, #",
        regex: configJSON.atLeastOneSymbolRegexp,
        ariaDescribedById: "symbol",
    },
    {
        text: "Minimum character length is 8 characters",
        regex: configJSON.minEightCharLengthRegexp,
        ariaDescribedById: "minLength",
    },
]

export default function PasswordCriteria({password}: {password: string}) {

    return (
        <Fragment>
            {
                passwordConditions.map((condition) => {
                    const matching = password.trim().length === 0 ? undefined : new RegExp(condition.regex).test(password)
                    return (
                        <div key={condition.ariaDescribedById} style={{ display: "flex", gap: 6, alignItems: "center" }} >
                            {matching === undefined && <CheckCircle aria-describedby={condition.ariaDescribedById} data-testid="initialCheckCircle" htmlColor="#94A3B8" style={{ height: 12, width: 12 }} /> }
                            {matching === true && <CheckCircle aria-describedby={condition.ariaDescribedById} data-testid="greenCheckCircle" htmlColor="#059669" style={{ height: 12, width: 12 }} />}
                            {matching === false && <Cancel aria-describedby={condition.ariaDescribedById} htmlColor="#DC2626" data-testid="redCircle" style={{ height: 12, width: 12 }} />}
                            <CriteriaText id={condition.ariaDescribedById} >
                                {condition.text}
                            </CriteriaText>
                        </div>
                    )
                })
            }
        </Fragment>
    )
}
