import React from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { styled } from "@material-ui/core";

const StyleHomeContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: 8,
    padding: "10px 16px",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 8px 0px #00000014",
    '& .title': {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
        color: "#0F172A",
        fontFamily: "Cairo",
    },
})

export default function HomeNode({ data }: NodeProps) {

    return (
        <>
            <Handle
                type="source"
                position={Position.Bottom}
                style={{ opacity: 0, bottom: 0, zIndex: -1 }}
            />
            <StyleHomeContainer>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 21C5.45 21 4.97933 20.8043 4.588 20.413C4.196 20.021 4 19.55 4 19V10C4 9.68333 4.071 9.38333 4.213 9.1C4.35433 8.81667 4.55 8.58333 4.8 8.4L10.8 3.9C10.9833 3.76667 11.175 3.66667 11.375 3.6C11.575 3.53333 11.7833 3.5 12 3.5C12.2167 3.5 12.425 3.53333 12.625 3.6C12.825 3.66667 13.0167 3.76667 13.2 3.9L19.2 8.4C19.45 8.58333 19.646 8.81667 19.788 9.1C19.9293 9.38333 20 9.68333 20 10V19C20 19.55 19.8043 20.021 19.413 20.413C19.021 20.8043 18.55 21 18 21H13V15H11V21H6Z" fill="#059669" />
                </svg>
                <span className="title" >{data.title}</span>
            </StyleHomeContainer>
        </>
    )
}
