Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "blogpostsmanagement";
exports.labelBodyText = "blogpostsmanagement Body";
exports.BlogpostEndPoint = "bx_block_blogpostsmanagement/blogposts";
exports.btnExampleTitle = "CLICK ME";
exports.getBlogpostEndPoint = "bx_block_cfinteractivewhiteboardtoolforchatbotmaker2/guides";
exports.deleteBlogpostEndPoint = "bx_block_cfinteractivewhiteboardtoolforchatbotmaker2/guides/"
exports.deleteApiMethodType = "DELETE";
exports.viewCountMethodType = "PUT";
exports.userGuideCountEndPoint = "bx_block_cfinteractivewhiteboardtoolforchatbotmaker2/guides/guide_count";
// Customizable Area End