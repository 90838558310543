import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as yupSchema from "yup";
import { toast } from "react-toastify";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities"
import React from "react"
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  firstNameError: string | null;
  lastNameError: string | null;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  showLogoutConfirmModal: boolean;
  showUpdatePasswordModal: boolean;

  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showConfirmNewPassword: boolean;

  saveButtonDisable: boolean;

  token: string;
  userId: string;

  profileImageURL: string | undefined;
  showCropDialog: boolean;

  updatePasswordLoading: boolean;
  saveProfileLoading: boolean;
  profileUpdateLoading: boolean;

  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;
  firstNameRef: string = "";
  lastNameRef: string = "";
  organisation: string = "";
  email: string = "";
  role: string = "";
  profileImageUrlRef: string | undefined= undefined;
  filePickerImageRef: string="";

  showUserProfileApiId: string | null = null;
  saveUserProfileApiId: string | null = null;
  updatePasswordApiId: string | null = null;
  profilePicUpdateApiId: string | null = null;
  profilePicDeleteApiId: string | null = null;
  fileInputRef = React.createRef<HTMLInputElement>() 

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      firstNameError: null,
      lastNameError: null,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,

      showLogoutConfirmModal: false,
      showUpdatePasswordModal: false,

      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,

      token: "",
      userId: "",

      updatePasswordLoading: false,
      saveProfileLoading: false,
      profileUpdateLoading: false,

      profileImageURL: undefined,
      showCropDialog: false,
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;

    this.handleLogout = this.handleLogout.bind(this)
    this.handleGetUserProfile = this.handleGetUserProfile.bind(this)
    this.handleRestApiResponse = this.handleRestApiResponse.bind(this)
    this.handleShowUserProfileResponse = this.handleShowUserProfileResponse.bind(this)
    this.handleSaveUserProfileResponse = this.handleSaveUserProfileResponse.bind(this)
    this.handleUpdatePasswordApiResponse = this.handleUpdatePasswordApiResponse.bind(this)
    this.handleProfilePicDeleteResponse = this.handleProfilePicDeleteResponse.bind(this)
    this.handleProfilePictureUpdateResponse = this.handleProfilePictureUpdateResponse.bind(this)
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    const handlers = {
      [getName(MessageEnum.RestAPIResponceMessage)]: this.handleRestApiResponse
    }

    const handler = handlers[message.id]
    if(handler) {
      handler(message)
    }

    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  
  async componentDidMount(): Promise<void> {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    const userId = await getStorageData("userId") ?? sessionStorage.getItem("userId")
    this.setState({token, userId}, () => this.handleGetUserProfile())
  }

  handleRestApiResponse(message: Message) {
    const responseId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

    const responseHandlers: Record<string, (newMsg: Message) => void> = {}

    if(this.showUserProfileApiId !== null) {
      responseHandlers[this.showUserProfileApiId] = this.handleShowUserProfileResponse
    }

    if(this.saveUserProfileApiId !== null) {
      responseHandlers[this.saveUserProfileApiId] = this.handleSaveUserProfileResponse
    }

    if(this.updatePasswordApiId !== null) {
      responseHandlers[this.updatePasswordApiId] = this.handleUpdatePasswordApiResponse
    }

    if(this.profilePicUpdateApiId !== null) {
      responseHandlers[this.profilePicUpdateApiId] = this.handleProfilePictureUpdateResponse
    }

    if(this.profilePicDeleteApiId !== null) {
      responseHandlers[this.profilePicDeleteApiId] = this.handleProfilePicDeleteResponse
    }

    const responseHandler = responseHandlers[responseId];
    if (responseHandler) {
      responseHandler(message);
    }
  }

  async handleShowUserProfileResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(responseJson === undefined) {
      return toast.error("Failed to fetch the data", {className: "error__toast"})
    }
    if(responseJson?.errors?.[0]?.token) {
      return await this.handleLogout()
    }

    const attributes = responseJson.data.attributes

    this.firstNameRef = attributes.first_name
    this.lastNameRef = attributes.last_name 
    this.email = attributes.email 
    this.organisation = attributes.organisation
    this.role = attributes.role
    this.profileImageUrlRef = attributes.profile_image

    const user = {
      role: attributes.role,
      name: attributes.first_name + " " + attributes.last_name,
      profile_picture: attributes.profile_image
    }

    this.setState({
      firstName: attributes.first_name,
      lastName: attributes.last_name,
      profileImageURL: attributes.profile_image ?? undefined
    })

    sessionStorage.setItem("user", JSON.stringify(user))
  }

  async handleSaveUserProfileResponse(message: Message) {
    this.setState({saveProfileLoading: false})
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

    if(responseJson === undefined) {
      return toast.error("Failed to update profile", {className: "error__toast"})
    }

    if(responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
      return await this.handleLogout()
    }

    if(responseJson?.meta?.message) {
      toast.success(responseJson?.meta?.message, {className: "success__toast"})
      this.firstNameRef = responseJson.data.attributes.first_name 
      this.lastNameRef = responseJson.data.attributes.last_name
      const attributes = responseJson.data.attributes
      const updateUserData = {
          profile_picture: attributes.profile_image,
          role: attributes.role,
          name: this.firstNameRef + " " + this.lastNameRef,
      }
      sessionStorage.setItem("user", JSON.stringify(updateUserData))
    }
  }

  async handleUpdatePasswordApiResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({updatePasswordLoading: false})
    if(!responseJson) {
      return toast.error("Failed to update password", {className: "error__toast"})
    }
    
    if(responseJson?.errors === "current password and new password should not be the same.") {
      return toast.error("Current password and new password should not be same.", {className: "error__toast"})
    }
    
    if(responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.profile) {
      return toast.error("Please enter correct current password", { className: "error__toast" })
    }

    if(responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
      return await this.handleLogout()
    }

    if(responseJson?.meta?.message) {
      this.setState({showUpdatePasswordModal: false})
      return toast.success(responseJson.meta.message, { className: "success__toast" })
    }
  }

  async handleProfilePictureUpdateResponse(message: Message) {
    const profilePicUpdateResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({profileUpdateLoading: false})
    if(profilePicUpdateResponse === undefined) {
      return toast.error("Failed to update profile picture", {className: "error__toast"})
    }

    if(profilePicUpdateResponse?.errors?.[0]?.token) {
      return await this.handleLogout()
    }

    if(profilePicUpdateResponse?.meta?.message) {
      toast.success(profilePicUpdateResponse.meta.message, {className: "success__toast"})
      this.filePickerImageRef = ""
      const attributes = profilePicUpdateResponse.data.attributes
      const user = {
          name: attributes.first_name + " " + attributes.last_name,
          role: attributes.role,
          profile_picture: attributes.profile_image
      }
      this.setState({
        profileImageURL: profilePicUpdateResponse.data.attributes.profile_image,
        showCropDialog: false,
      })
      sessionStorage.setItem("user", JSON.stringify(user))
    }
  }

  async handleProfilePicDeleteResponse(message: Message) {
    const profilePicDeleteResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(profilePicDeleteResponse === undefined) {
      toast.error("Failed to delete profile picture", {className: "error__toast"})
      this.setState({profileImageURL: this.profileImageUrlRef})
      return;
    }

    if(profilePicDeleteResponse?.errors?.[0]?.token) {
      return await this.handleLogout()
    }

    if(profilePicDeleteResponse?.message) {
      toast.success(profilePicDeleteResponse.message, {className: "success__toast"})
    }
  }
  
  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
     onChangeText: (text:string) => 
     {
       if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })
     
        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
       }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType:"tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps  = {
    value: "",
    editable: true,
    onChangeText: (text:string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps  = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
  ? this.txtInputEmailWebProps
  : this.txtInputEmailMobileProps;
  
  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text:string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => { 
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text:string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ 
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry });
        this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
        this.imgNewPasswordShowhideProps.source =  this.txtInputNewPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText:(text:string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({ 
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry });
        this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
        this.imgReTypePasswordShowhideProps.source =  this.txtInputNewPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }

  handleGetUserProfile() {
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showUserProfileApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showUserProfileApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onFirstNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ "firstName": event.target.value })
  }

  onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ "lastName": event.target.value })
  }

  toggleConfirmLogoutModal = () => {
    this.setState({ showLogoutConfirmModal: !this.state.showLogoutConfirmModal })
  }

  toggleUpatePasswordModal = () => {
    this.setState({ showUpdatePasswordModal: !this.state.showUpdatePasswordModal })
  }

  toggleCurrentPasswordVisibility = () => {
    this.setState({showCurrentPassword: !this.state.showCurrentPassword})
  }

  toggleNewPasswordVisibility = () => {
    this.setState({showNewPassword: !this.state.showNewPassword})
  }

  toggleConfirmNewPasswordVisibility = () => {
    this.setState({showConfirmNewPassword: !this.state.showConfirmNewPassword})
  }

  updatePasswordSchema = () => {
    return yupSchema.object().shape({
      currentPassword: yupSchema.string().required("Please enter your current password"),
      newPassword: yupSchema.string()
        .required("Please enter new password")
        .matches(new RegExp(configJSON.newPasswordRegexp), "Please enter valid password"),
      confirmNewPassword: yupSchema.string().required("Please enter confirm new password")
        .oneOf([yupSchema.ref("newPassword"), null], "Passwords do not match")
    })
  }

  updatePassword = (values: {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  }) => {
    this.setState({updatePasswordLoading: true})
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.state.token,
    };

    const httpBody = {
      data: {
        "current_password": values.currentPassword,
        "new_password": values.newPassword,
        "confirmation_password": values.confirmNewPassword
      },
    }

    const updatePasswordRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatePasswordApiId = updatePasswordRequestMessage.messageId;

    updatePasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/passwords/${this.state.userId}`
    );

    updatePasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    updatePasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    updatePasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    runEngine.sendMessage(updatePasswordRequestMessage.id, updatePasswordRequestMessage);
  }

  saveUserProfileInfo = () => {
    const firstNameEmpty = this.isNonNullAndEmpty(this.state.firstName)
    const lastNameEmpty = this.isNonNullAndEmpty(this.state.lastName)
    let firstNameError: string | null = null;
    let lastNameError: string | null = null;
    const spaceRegex = /\s/;

    if(!firstNameEmpty) {
      toast.error("Please enter a first name", {className: "error__toast"})
      firstNameError = "Please enter a first name"
    }

    if(spaceRegex.test(this.state.firstName)) {
      firstNameError = "First name should not contain space"
    }

    if(!lastNameEmpty) {
      toast.error("Please enter a last name", {className: "error__toast"})
      lastNameError = "Please enter a last name"
    }

    if(spaceRegex.test(this.state.lastName)) {
      lastNameError = "Last name should not contain space"
    }

    this.setState(
      { firstNameError, lastNameError }
    )

    if(firstNameError || lastNameError) {
      return;
    }
    this.setState({saveProfileLoading: true})
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.state.token,
    };

    const body = {
      data: {
        "first_name": this.state.firstName,
        "last_name": this.state.lastName
      },
    }

    const updateProfileRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveUserProfileApiId = updateProfileRequestMessage.messageId;

    updateProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}`
    );

    updateProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    updateProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    updateProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    runEngine.sendMessage(updateProfileRequestMessage.id, updateProfileRequestMessage);

  }

  async handleLogout() {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const message: Message = new Message(
      getName(MessageEnum.NavigationSignupLoginMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleCancel = () => {
    this.setState({
      firstName: this.firstNameRef,
      lastName: this.lastNameRef,
      profileImageURL: this.profileImageUrlRef,
    })
  }

  handleOpenFilePicker = () => {
    this.fileInputRef.current?.click()
  }

  onImageLoad = (name: string, imageURL: string) => {
    this.filePickerImageRef = imageURL
    this.setState({showCropDialog: true})
  }

  onImageApply = (croppedImage: {url: string, file: File}) => {
    this.setState({profileUpdateLoading: true})

    const header = {
      token: this.state.token,
    };

    const formData = new FormData()
    formData.append("profile_image", croppedImage.file)

    const profilePicDeleteRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profilePicUpdateApiId = profilePicDeleteRequestMessage.messageId;

    profilePicDeleteRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}/upload_profile_picture`
    );

    profilePicDeleteRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    profilePicDeleteRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )

    profilePicDeleteRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    runEngine.sendMessage(profilePicDeleteRequestMessage.id, profilePicDeleteRequestMessage);
  }

  handleCropDialogClose = () => {
    this.filePickerImageRef = ""
    this.setState({showCropDialog: false, profileImageURL: this.profileImageUrlRef})
  }

  handleDeleteProfilePicture = () => {

    if(!this.state.profileImageURL) {
      return;
    }

    this.setState({profileImageURL: undefined})

    const body = {
      id: this.state.userId
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.state.token,
    };

    const profilePicDeleteRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profilePicDeleteApiId = profilePicDeleteRequestMessage.messageId;

    profilePicDeleteRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}/delete_profile_picture`
    );

    profilePicDeleteRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    profilePicDeleteRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    profilePicDeleteRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(profilePicDeleteRequestMessage.id, profilePicDeleteRequestMessage);
  }


  // Customizable Area End

}
