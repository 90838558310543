// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";


export type Template = {
  id: number;
  name: string;
  nodes: Array<{id: string, source: string[]}>;
}


export type Category = {
  id: number;
  name: string;
  templates: Template[];
}

export type MoveTemplateFromCategoryToAnotherCategory = {
  template: Template;
  fromCategory?: Category;
  toCategory: Category;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleAddConnectorCardsFromTemplateToChatbot: (nodeIds: number[]) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  productList: string[];
  showLoader: boolean;
  isTemplateModalOpen: boolean;
  isCategoryModalOpen: boolean;
  showMaxTemplateError: boolean;
  categories: Category[];
  templates: Template[];
  isCreateTemplateButtonDisabled: boolean;
  token: string;
  isCreateTemplateOrCategoryLoading: boolean;
  showCategoriesLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTemplatesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProjectApiCallId: string='';
  selectedCategoryName: string = "";
  selectedCategoryId: number | null = null;
  selectedTemplateId: number | null = null;
  createCategoryCallId: string | null = null;
  createTemplateCallId: string | null = null;
  allCategoriesCallId: string | null = null;
  allTemplatesCallId: string | null = null;
  editCategoryCallId: string | null = null;
  deleteCategoryCallId: string | null = null;
  deleteTemplateCallId: string | null = null;
  selectedCategory: Category | undefined = undefined;
  selectedCategoryIndex: number | null = null;
  templatesInDeletedCategory: Template[] = [];
  deletedTemplate: Template | undefined = undefined;
  deletedTemplateIndex: number | null = null;
  categoryOfDeletedTemplate: Category | undefined = undefined;
  categoryIndexOfDeletedTemplate: number | null = null;
  currentMovedTemplate: Template | undefined = undefined;
  currentMovedTemplateIndex: number = -1;
  templateMovedFromCategory: Category | undefined = undefined;
  templateMovedToCategory: Category | undefined = undefined;
  isTemplateBeingMoved: boolean = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      showLoader: false,
      // Customizable Area Start
      productList: [],
      isCategoryModalOpen: false,
      isTemplateModalOpen: false,
      showMaxTemplateError: false,
      templates: [],
      categories: [],
      isCreateTemplateButtonDisabled: false,
      token: "",
      isCreateTemplateOrCategoryLoading: false,
      showCategoriesLoading: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleLogout = this.handleLogout.bind(this)
    this.handleRestApiResponse = this.handleRestApiResponse.bind(this)
    this.handleAllCategoriesResponse = this.handleAllCategoriesResponse.bind(this)
    this.handleAllTemplatesResponse = this.handleAllTemplatesResponse.bind(this)
    this.handleDeleteCategoryResponse = this.handleDeleteCategoryResponse.bind(this)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleRestApiResponse(message)
    }
    // Customizable Area End
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({token})
    this.getAllCategoriesOrTemplates(token, false)
    this.getAllCategoriesOrTemplates(token,true)
  }

  async handleRestApiResponse(message: Message) {
    const responseHandlers: Record<string, (newMsg: Message) => void> = {}
    const responseId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    if(this.allCategoriesCallId !== null && this.allCategoriesCallId === responseId) {
      responseHandlers[this.allCategoriesCallId] = this.handleAllCategoriesResponse
    }

    if(this.allTemplatesCallId !== null && this.allTemplatesCallId === responseId) {
      responseHandlers[this.allTemplatesCallId] = this.handleAllTemplatesResponse
    }

    if(this.createCategoryCallId !== null && this.createCategoryCallId === responseId) {
      responseHandlers[this.createCategoryCallId] = this.handleCreateCategoryResponse
    }

    if(this.createTemplateCallId !== null && this.createTemplateCallId === responseId) {
      responseHandlers[this.createTemplateCallId] = this.handleCreateTemplateResponse
    }

    if(this.editCategoryCallId !== null && this.editCategoryCallId === responseId) {
      responseHandlers[this.editCategoryCallId] = this.handleEditCategoryResponse
    }

    if(this.deleteCategoryCallId !== null && this.deleteCategoryCallId === responseId) {
      responseHandlers[this.deleteCategoryCallId] = this.handleDeleteCategoryResponse
    }

    if(this.deleteTemplateCallId !== null && this.deleteTemplateCallId === responseId) {
      responseHandlers[this.deleteTemplateCallId] = this.handleDeleteTemplateResponse
    }

    const handler = responseHandlers[responseId]
    if(handler) {
      handler(message)
    }
  }

  handleCreateCategoryResponse = async (message: Message) => {
    const createCategoryResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({isCreateTemplateOrCategoryLoading: false})

    if(!createCategoryResponse) {
      toast.error("Failed to create category!", {className: "error__toast"})
      return;
    }

    if(createCategoryResponse.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(createCategoryResponse.data) {
      const {attributes} = createCategoryResponse.data
      const newCategory: Category = {
        id: attributes.id,
        name: attributes.name,
        templates: attributes.template_categories
      }
      this.setState({categories: [...this.state.categories, newCategory], isCategoryModalOpen: false})
    }
  }

  handleCreateTemplateResponse = async (message: Message) => {
    this.setState({isCreateTemplateOrCategoryLoading: false})
    const createTemplateResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!createTemplateResponse) {
      toast.error("Failed to create template!", {className: "error__toast"})
      return;
    }

    if(createTemplateResponse.errors?.[0] === "Card templates cannot exceed 12 card templates") {
      toast.error("You already added the maximum number of templates", {className: "error__toast"})
      return;
    }

    if(createTemplateResponse.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(createTemplateResponse.data) {
      const navigationMessage = new Message(getName(MessageEnum.NavigationProjectTemplatesEditMessage))
      navigationMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      )
      navigationMessage.addData(
        getName(MessageEnum.NavigationScreenNameMessage),
        createTemplateResponse.data.id
      )
      this.send(navigationMessage)
    }
  
  }

  handleEditCategoryResponse = async(message: Message) => {
    this.setState({isCreateTemplateOrCategoryLoading: false})
    const editCategoryRes = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

    if(!editCategoryRes) {
      toast.error("Failed to update category!", {className: "error__toast"})
      if(!this.isTemplateBeingMoved) {
        return;
      }
      let newCategories = [...this.state.categories]
      let newTemplates = [...this.state.templates]
      if(this.currentMovedTemplateIndex !== -1 && this.currentMovedTemplate){
        newTemplates.splice(this.currentMovedTemplateIndex,0,this.currentMovedTemplate)
      }
      if(this.templateMovedFromCategory) {
        const categoryIndex = newCategories.findIndex(category => category.id === this.templateMovedFromCategory?.id)
        newCategories[categoryIndex] = this.templateMovedFromCategory
      }
      if(this.templateMovedToCategory) {
        const categoryIndex = newCategories.findIndex(category => category.id === this.templateMovedToCategory?.id)
        newCategories[categoryIndex] = this.templateMovedToCategory
      }
      this.setState({templates: newTemplates, categories: newCategories})
      this.currentMovedTemplateIndex = -1
      this.currentMovedTemplate = undefined
      this.templateMovedFromCategory = undefined
      this.templateMovedToCategory = undefined
      return;
    }

    if(editCategoryRes.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(editCategoryRes.message) {
      const {attributes} = editCategoryRes.category.data
      const newTemplates: Template[] = attributes.template_categories.map((template) => ({
        id: template.data.attributes.id,
        name: template.data.attributes.name
      }))
      const updatedCategory: Category = {
        id: attributes.id,
        name: attributes.name,
        templates: newTemplates
      }
      const editedCategoryIndex = this.state.categories.findIndex(category => category.id === updatedCategory.id)
      
      const newCategories = [...this.state.categories]
      newCategories[editedCategoryIndex] = updatedCategory
      
      this.selectedCategoryId = null;
      this.selectedCategoryName = "";
      this.currentMovedTemplate = undefined
      this.currentMovedTemplateIndex = -1
      this.templateMovedToCategory = undefined
      this.templateMovedFromCategory = undefined

      this.setState({categories: newCategories, isCategoryModalOpen: false})

    }
  }

  async handleDeleteCategoryResponse(message: Message) {
    const deleteCategoryRes = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!deleteCategoryRes) {
      toast.error("Failed to delete category!", {className: "error__toast"})
      if(this.selectedCategory && this.selectedCategoryIndex !== null) {
        const templatesWithIds = this.templatesInDeletedCategory.map(template => template.id)
        const newTemplates = this.state.templates.filter(template => !templatesWithIds.includes(template.id))
        const newCategories = [...this.state.categories]
        newCategories.splice(this.selectedCategoryIndex, 0, this.selectedCategory)
        this.setState({categories: [...newCategories], templates: newTemplates})
      }
      return;
    }

    if(deleteCategoryRes.errors?.[0]?.token) {
      await this.handleLogout()
    }

    if(deleteCategoryRes.message) {
      this.selectedCategory = undefined
      this.selectedCategoryIndex = null
      this.selectedCategoryId = null
    }
  }

  handleDeleteTemplateResponse = async (message: Message) => {
    const deleteTemplateRes = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!deleteTemplateRes) {
      toast.error("Failed to delete template!", {className: "error__toast"})
      if(this.deletedTemplate && this.deletedTemplateIndex !== null) {
        const newTemplates = [...this.state.templates]
        newTemplates.splice(this.deletedTemplateIndex,0,this.deletedTemplate)
        this.deletedTemplate = undefined
        this.deletedTemplateIndex = null
        this.setState({templates: newTemplates})
        return;
      }
      if(this.categoryIndexOfDeletedTemplate !== null && this.categoryOfDeletedTemplate) {
        const newCategories = [...this.state.categories]
        newCategories[this.categoryIndexOfDeletedTemplate] = this.categoryOfDeletedTemplate
        this.categoryIndexOfDeletedTemplate = null
        this.categoryOfDeletedTemplate = undefined
        this.setState({categories: newCategories})
      }
      return;
    }

    if(deleteTemplateRes.errors?.[0]?.token) {
      await this.handleLogout()
    }

    if(deleteTemplateRes.message) {
      this.deletedTemplate = undefined
      this.deletedTemplateIndex = null
      this.categoryIndexOfDeletedTemplate = null
      this.categoryOfDeletedTemplate = undefined
    }
  }

  async handleAllCategoriesResponse(message: Message){
    const allCategoriesResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({showCategoriesLoading: false})
    if(!allCategoriesResponse) {
      toast.error("Failed to fetch categories", {className: "error__toast"})
      return;
    }

    if(allCategoriesResponse.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(allCategoriesResponse.data) {
      const newCategories: Category[] = allCategoriesResponse.data.map((category) => {
        const newTemplates: Template[] = category.attributes.template_categories.filter(Boolean).map((template) => ({
          id: template.data.attributes.id,
          name: template.data.attributes.name,
          nodes: template.data.attributes.connector_cards.filter(Boolean).map((card) => {
            return {
              id: `${card.data.attributes.id}`,
              source: card.data.attributes.source
            }
          })
        }))
        return {
          id: category.attributes.id,
          name: category.attributes.name,
          templates: newTemplates
        }
      })
      this.setState({categories: newCategories})
    }
  }

  async handleAllTemplatesResponse(message: Message){
    const allTemplatesResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!allTemplatesResponse) {
      toast.error("Failed to fetch templates", {className: "error__toast"})
      return;
    }

    if(allTemplatesResponse.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(allTemplatesResponse.data) {
      const transformedTemplates: Template[] = allTemplatesResponse.data.map((template) => ({
        id: template.attributes.id,
        name: template.attributes.name,
        nodes: template.attributes.connector_cards.filter(Boolean).map((card) => ({
          id: `${card.data.attributes.id}`,
          source: card.data.attributes.source
        }))
      }))
      this.setState({templates: transformedTemplates})
    }
  }

  getAllCategoriesOrTemplates = async(token: string, isTemplate: boolean) => {
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isTemplate ? configJSON.allTemplatesEndpoint : configJSON.allCategoriesEndpoint
    )

    if(isTemplate) {
      this.allTemplatesCallId = requestMessage.messageId
    } else {
      this.allCategoriesCallId = requestMessage.messageId
    }

    this.send(requestMessage)
  }

  handleToggleTemplatePopup = () => {
    const templateLengthsInCategory = this.state.categories.reduce((accumulator, current) => {
      return accumulator + current.templates.length
    }, this.state.templates.length)

    if(templateLengthsInCategory > 12) {
      this.setState({showMaxTemplateError: true, isCreateTemplateButtonDisabled: true})
      return;
    }

    this.setState({isTemplateModalOpen: !this.state.isTemplateModalOpen})
  }

  handleCloseMaxTemplateError = () => {
    this.setState({showMaxTemplateError: false})
  }

  handleToggleCategoryPopup = () => {
    this.categoryEditName = ""
    this.setState({isCategoryModalOpen: !this.state.isCategoryModalOpen})
  }

  handleCreateTemplateOrCategoryRequest = (value: string, isCategory?: boolean) => {
    this.setState({isCreateTemplateOrCategoryLoading: true})

    const header = {
      token: this.state.token,
      "Content-Type": "application/json",
    }

    let body = { 
      card_template: {
          name: value,
      }
    }
    let endpoint = configJSON.createTemplateEndpoint
    const newReqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    if(isCategory) {
      body = {
        category: {
            name: value,
        }
      }
      endpoint = configJSON.createCategoryEndpoint
      this.createCategoryCallId = newReqMessage.messageId
    } else {
      this.createTemplateCallId = newReqMessage.messageId
    }

    newReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    newReqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    newReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )

    newReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )

    this.send(newReqMessage)
  }

  handleEditCategoryRequest = (body: unknown) => {
    this.setState({isCreateTemplateOrCategoryLoading: true})

    const header = {
      token: this.state.token,
      "Content-Type": "application/json",
    }

    let endpoint = `${configJSON.createCategoryEndpoint}/${this.selectedCategoryId}`
    const newReqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.editCategoryCallId = newReqMessage.messageId

    newReqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    newReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    newReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )

    newReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    )

    this.send(newReqMessage)
  }

  handleTemplateSubmit = (value: string) => {
    this.handleCreateTemplateOrCategoryRequest(value)
  }

  handleCategorySubmit = (value: string) => {
    if(!this.selectedCategoryId) {
      this.handleCreateTemplateOrCategoryRequest(value, true)
    } else {
      const body = {
        category: {
            name: value
        }
      }
      this.handleEditCategoryRequest(body)
    }
  }

  handleEditCategory = (category: Omit<Category, "templates">) => {
    this.selectedCategoryName = category.name
    this.selectedCategoryId = category.id
    this.setState({isCategoryModalOpen: true})
  }

  handleDeleteCategoryOrTemplateRequest = (isCategory: boolean) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    }

    let endpoint = `${configJSON.allTemplatesEndpoint}/${this.selectedTemplateId}`
    const newReqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    if(isCategory) {
      endpoint = `${configJSON.allCategoriesEndpoint}/${this.selectedCategoryId}`
      this.deleteCategoryCallId = newReqMessage.messageId
    } else {
      this.deleteTemplateCallId = newReqMessage.messageId
    }
    newReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    newReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )

    newReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    )

    this.send(newReqMessage)
  }

  handleDeleteCategory = (categoryId: number) => {
    this.selectedCategoryId = categoryId
    const categoryIndex = this.state.categories.findIndex(category => category.id === categoryId)
    this.selectedCategory = this.state.categories[categoryIndex]
    this.selectedCategoryIndex = categoryIndex
    this.templatesInDeletedCategory = this.state.categories[categoryIndex].templates
    const newTemplates = [...this.state.templates, ...this.templatesInDeletedCategory]
    const newCategories = this.state.categories.filter(category => category.id !== categoryId)
    this.setState({categories: newCategories, templates: newTemplates})
    this.handleDeleteCategoryOrTemplateRequest(true)
  }

  handleEditTemplate = (templateId: number) => {
    const navigationMessage = new Message(getName(MessageEnum.NavigationProjectTemplatesEditMessage))
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    navigationMessage.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      `${templateId}`
    )
    this.send(navigationMessage)
  }

  handleDeleteTemplate = (templateId: number, categoryId?: number) => {
    this.selectedTemplateId = templateId
    if(!categoryId) {
      this.deletedTemplateIndex = this.state.templates.findIndex(template => template.id === templateId)
      this.deletedTemplate = this.state.templates[this.deletedTemplateIndex]
      const newTemplates = this.state.templates.filter(temp => temp.id !== templateId )
      this.setState({templates: newTemplates})
      this.handleDeleteCategoryOrTemplateRequest(false)
      return;
    }
    this.deletedTemplate = undefined
    this.deletedTemplateIndex = undefined
    const newCategories = this.state.categories.reduce<Category[]>((accumulator, current, currentIndex) => {
      if(current.id !== categoryId) {
        accumulator.push(current)
        return accumulator
      }
      this.categoryOfDeletedTemplate = current;
      this.categoryIndexOfDeletedTemplate = currentIndex
      const newTemplates = current.templates.filter(temp => temp.id !== templateId)
      accumulator.push({
        ...current,
        templates: newTemplates
      })
      return accumulator
    }, [])
    this.setState({categories: newCategories})
    this.handleDeleteCategoryOrTemplateRequest(false)
  }

  handleMoveTemplateFromCategoryToAnotherCategory = (args: MoveTemplateFromCategoryToAnotherCategory) => {
    const {template, fromCategory, toCategory} = args
    this.isTemplateBeingMoved = true
    this.currentMovedTemplateIndex = this.state.templates.findIndex(temp => temp.id === template.id)
    let newCategories = [...this.state.categories]
    let newTemplates = [...this.state.templates]

    const newTemplatesInToCategory = [
      ...toCategory.templates.map(temp => temp.id),
      template.id
    ]

    this.templateMovedFromCategory = fromCategory
    this.templateMovedToCategory = toCategory
    this.currentMovedTemplate = template

    if(this.currentMovedTemplateIndex !== -1) {
      newTemplates = newTemplates.filter(temp => temp.id !== template.id)
    }

    if(fromCategory) {
      newCategories = newCategories.map((category) => {
        if(category.id === fromCategory.id) {
          return {
            ...category,
            templates: category.templates.filter(temp => temp.id !== template.id)
          }
        }
        return category
      })
    }
    newCategories = newCategories.map((category) => {
      if(category.id === toCategory.id) {
        return {
          ...category,
          templates: [...category.templates, template]
        }
      }
      return category
    })

    this.setState({templates: newTemplates, categories: newCategories})
    this.selectedCategoryId = toCategory.id
    const body = {
      category: {
        template_categories: newTemplatesInToCategory
      },
    }
    this.handleEditCategoryRequest(body)
  }

  async handleLogout(){
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()

    const newNavigationMessage = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
    newNavigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {navigation: this.props.navigation}
    )
    this.send(newNavigationMessage)
  }

  // Customizable Area End
}