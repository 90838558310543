import { Link, url_regex, linkTypeRegex } from "./components/ShareLink.web";
import {NodeData} from "../../../components/src/CardNode.web";
import { ConnectorCard } from "./Chatbot5Controller";

const checkLinkNameAndURLError = (links: Link[]) => {
    const hasError = links.some((link) => {
        let hasError = false;
        if(link.linkType === "regular") {
          hasError = !url_regex.test(link.url!)
        } else if(link.linkType === "mailTo") {
          hasError = !linkTypeRegex.email.test(link.email!)
        } else {
          hasError = !linkTypeRegex.phone.test(link.phone!)
        }
        const nameError = link.name.trim().length === 0
        return nameError || hasError
    })
    return hasError
}

const addOrEditActions = (actions: NodeData[], newAction: NodeData) => {
  let redirectIdsSet = new Set<string>()
  if(newAction.type === "openai") {
    newAction.followUpActions!.forEach((followUpAction) => {
      if(followUpAction.redirectCardId) {
        redirectIdsSet.add(followUpAction.redirectCardId)
      }
    })
  }
  const newActions = actions.map((action) => {
    if(redirectIdsSet.has(`${action.id}`)) {
      const newNode = {...action}
      newNode.source.push(`${newAction.id}`)
      newNode.status = "connected"
      return newNode
    }
    const newSource = [...action.source]
    return {
      ...action,
      source: newSource
    }
  })
  const actionIndex = newActions.findIndex(action => action.id === newAction.id)
  if(actionIndex === -1) {
    newActions.push(newAction)
  } else {
    newActions[actionIndex] = { ...newActions[actionIndex], ...newAction }
  }
  return newActions
}

const getCriteriaRoutings = (criteriaRoutings: ConnectorCard["attributes"]["criteria_routings"]) => {
  return criteriaRoutings.map((criteriaRouting) => {
    return {
      title: criteriaRouting.criteria_routing_title,
      criteria_routing_id: criteriaRouting.criteria_routing_id,
      nextCardId: criteriaRouting.next_card_id ? `${criteriaRouting.next_card_id}`: null,
      rules: criteriaRouting.rules.map(rule => ({
        rule_id: rule.rule_id,
        field_name: rule.field_name,
        comparator: rule.comparator,
        values: rule.values,
        operator_with_previous_index: rule.operator_with_previous_index,
        sub_field: rule.sub_field ?? undefined
      }))
    }
  })
}

const getFollowUpActions = (actions: ConnectorCard["attributes"]["follow_ups"]) => {
  const newActions: NodeData["followUpActions"] = actions.map(action => {
    return {
      id: action.follow_up_id,
      buttonText: action.button_text,
      redirectCardId: action.redirect_user,
    }
  })
  return newActions
}

const getContextVariableFromNodes = (nodes: NodeData[]) => {
  const subFields: string[] = []
  const subFieldValue:Record<string, string[]> = {}
  nodes.forEach((node) => {
    if(node.fieldName) {
      subFields.push(node.fieldName)
      let options: string[] = [];
      if(node.type === "slider_scale") {
        const minValue = +node.minValue!
        const maxValue = +node.maxValue!
        for(let value = minValue; value <= maxValue; value++) {
          options.push(`${value}`)
        }
        subFieldValue[node.fieldName] = options
      } else if(node.type === "rating") {
        options = ["1","2","3","4","5"]
      } else if(node.type === "single_choice" || node.type === "multi_choice" || node.type ==="dropdown") {
        options = node.options!
      }
      subFieldValue[node.fieldName] = options
    }
  })

  return {subFields, subFieldValue}
}

const getNodeDataFromConnectorCardAttributes = (connectorCardData: ConnectorCard) => {
  const {attributes} = connectorCardData
        const newAction: NodeData = {
          id: attributes.id,
          title: attributes.name,
          message: attributes.message ?? "",
          type: attributes.card_type,
          status: attributes.status ?? "disconnected",
          source: [...new Set(attributes.source)],
          fieldName: attributes.field_name ?? undefined,
          minValue: attributes.minimum_selection ? `${attributes.minimum_selection}` : undefined,
          maxValue: attributes.maximum_selection ? `${attributes.maximum_selection}` : undefined,
          videoURL: attributes.video_link ?? undefined,
          waitTime: attributes.card_wait_time ?? undefined,
          targetCard: attributes.target_card ?? undefined,
          cardType: attributes.connection_type ?? undefined,
          userGuides: attributes.user_guides,
          embedForms: attributes.embeded_forms,
          options: attributes.option,
          connectorCardType: attributes.connector_card_type,
          links: attributes.connector_card_links.map((link) => ({
            id: link.id,
            name: link.link_name,
            linkType: link.link_type,
            openLink: link.open_link,
            icon: link.connector_icon_image_link,
            url: link.link_url ?? undefined,
            destination: link.destination ?? undefined
          })),
          criteriaRoutings: getCriteriaRoutings(attributes.criteria_routings),
          followUpActions: getFollowUpActions(attributes.follow_ups)
        }
        return newAction
}

const getTargetCardOptionsForGoToTileCard = (actions: NodeData[], source: number) => {
  return actions.filter(action => action.id !== source).map(action => ({label: action.title, value: `${action.id}`}))
}

const getAllActionCards = (actions: NodeData[]) => {
  return actions.map(action => ({label: action.title, value: `${action.id}`}))
}

const getFilteredNodeData = (nodeId: number | null, nodeData: NodeData[]) => {
  const filteredNodes = nodeData.filter(node => node.id !== nodeId)
  const filteredNodesWithoutNodeIdSource: NodeData[] = filteredNodes.map(node => {
    const isDeleteNodePartOfCurrentNode = node.source.includes(`${nodeId}`)
    const newSource = node.source.filter(sourceId => sourceId !== `${nodeId}`)
    const status = newSource.length > 0 ? "connected" : "disconnected"
    const criteriaRoutings = (node.criteriaRoutings ?? []).map(criteriaRouting => {
      const newCriteriaRouting = {...criteriaRouting}
      if(newCriteriaRouting.nextCardId === `${nodeId}`) {
        newCriteriaRouting["nextCardId"] = null
      }
      return newCriteriaRouting
    })

    const followUpActions = (node.followUpActions ?? []).map(action => {
      const newAction = {...action}
      if(newAction.redirectCardId === `${nodeId}`) {
        newAction["redirectCardId"] = null
      }
      return newAction
    })

    return {
      ...node,
      source: newSource,
      status: !isDeleteNodePartOfCurrentNode ? node.status : status,
      criteriaRoutings: criteriaRoutings,
      followUpActions: followUpActions
    }
  })
  return filteredNodesWithoutNodeIdSource
} 

const getTransformedLinks = (linkExpandedStatus: Map<string, boolean>,links?: NodeData["links"]): Link[] => {
  if(!links) {
      return [
        {
          linkId: "link1",
          title: "Link 1",
          name: "Link 1",
          linkType: "regular",
          openLink: "_blank",
          url: "https://",
          isExpanded: true,
          email: "",
          phone: "",
        }
      ]
  }

  const newLinks: Link[] = links.map((link, linkIndex) => ({
      id: link.id,
      linkId: `link${linkIndex + 1}`,
      title: `Link ${linkIndex + 1}`,
      name: link.name,
      linkType: link.linkType,
      openLink: link.openLink,
      icon: link.icon,
      url: link.url ?? "",
      email: link.linkType === "mailTo" ? link.destination : "",
      phone: link.linkType === "phone" ? link.destination : "",
      isExpanded: !!linkExpandedStatus.get(link.name)
  }))

  return newLinks
}

const detectOS = () => {
  const userAgent = window.navigator.userAgent

  // Check for Android
  if (/android/i.test(userAgent)) {
    return "android";
  }

  // Check for Blackberry
  if (/blackberry|bb10|rim/i.test(userAgent)) {
    return "blackberry";
  }

  // Check for iOS (iPhone and iPod)
  if (/iPhone|iPod/i.test(userAgent)) {
    return "iOS";
  }

  // Check for iPadOS
  if (/iPad|Macintosh/i.test(userAgent)) {
    return "iPadOS";
  }

  // Check for Linux
  if (/linux/i.test(userAgent) && !/android/i.test(userAgent)) {
    return "linux";
  }

  // Check for Windows
  if (/windows/i.test(userAgent)) {
    return "windows";
  }

  // Check for Nokia
  if (/nokia/i.test(userAgent)) {
    return "nokia";
  }

  return "unknown";
}

const detectBrowser = () => {

  const userAgent = window.navigator.userAgent

  if(userAgent.includes("Edg")) {
    return "Microsoft Edge"
  }

  if(userAgent.includes("MSIE") || userAgent.includes("Trident")) {
    return "Internet Explorer"
  }

  if(userAgent.includes("Firefox")) {
    return "Mozilla Firefox"
  }

  if(userAgent.includes("OPR") || userAgent.includes("Opera")) {
    return "Opera"
  }

  if(userAgent.includes("Safari") && userAgent.includes("Macintosh")) {
    return "Safari"
  }

  return "Google Chrome"
}

export const utils = {
    checkLinkNameAndURLError,
    addOrEditActions,
    getNodeDataFromConnectorCardAttributes,
    getFilteredNodeData,
    getTargetCardOptionsForGoToTileCard,
    getTransformedLinks,
    getContextVariableFromNodes,
    getAllActionCards,
    detectOS,
    detectBrowser
}
