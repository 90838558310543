import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Checkbox,
  OutlinedInput,
  Dialog,
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Formik } from 'formik';
import { imgLogo } from './assets'

const DialogFooterButton = styled("button")({
  all: "unset",
  cursor: "pointer",
  textAlign: "center",
  padding: "10px 95px",
  backgroundColor: "#DBF0F2",
  borderRadius: 8,
  fontFamily: "Inter",
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "24px",
  color: "#51ABB3",
  width: "100%",
  boxSizing: "border-box",
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#51ABB3",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "24px",
      fontFamily: "Cairo",
      color: "#000000",
    },
    h1: {
      margin: 0,
      fontWeight: 700,
      fontSize: 24,
      lineHeight: "32px",
      fontFamily: "Cairo",
      color: "#000000",
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#334155',
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
        fontFamily: "Cairo",
        '&.rememberMe': {
          color: "#0F172A",
          fontWeight: 400,
          fontSize: 14,
          lineHeight: "22px",
        },
      }
    },
    MuiContainer: {
      root: {
        marginTop: 114,
        '@media (min-width: 600px)': {
          padding: 0,
        },
      },
      maxWidthSm: {
        '@media (min-width: 600px)': {
          maxWidth: 440,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        '&:hover $notchedOutline': {
            borderColor: "#CBD5E1",
        },
        '&.Mui-Focused $notchedOutline': {
            borderColor: "#51ABB3",
            borderWidth: 1,
        },
      },
      notchedOutline: {
          borderWidth: 1,
          borderColor: "#CBD5E1",
      },
      input: {
          padding: 0,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: "#FFFFFF",
        padding: "16px 8px",
      },
      input: {
          fontFamily: "Cairo",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "24px",
          color: "#64748B",
          height: 24,
      },
    },
    MuiButton: {
      root: {
          fontFamily: "Cairo",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "22px",
          color: "#51ABB3",
          textTransform: "none",
          borderRadius: 8,
          cursor: "pointer",
          '&:hover': {
              backgroundColor: "transparent",
              boxShadow: 'none',
          },
          '&.mr-2': {
            marginRight: 8,
          },
          '&.ml-auto': {
            marginLeft: "auto",
          },
          '&.loginBtn': {
            fontSize: 16,
            lineHeight: "24px",
            color: "#FFFFFF",
            backgroundColor: "#51ABB3",
            padding: 16,
            '&.Mui-disabled': {
              color: "#FFFFFF",
              backgroundColor: "#CBD5E1",
            },
          },
      },
      text: {
          padding: 0,
      },
      contained: {
        '&:hover': {
          boxShadow: "none",
          backgroundColor: "#51ABB3",
          color: "#FFFFFF",
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
      },
    },
    MuiBackdrop: {
      root: {
          backgroundColor: '#0F172A',
          opacity: "0.4 !important",
      },
    },
    MuiPaper: {
      root: {
          padding: 32,
          boxSizing: "border-box",
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          gap: 12,
          alignItems: "center",
      },
      rounded: {
          borderRadius: 8,
      },
      elevation24: {
          boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017"
      },
    },
  }
});
// Customizable Area End

import SignuploginController, {
  Props,
  configJSON,
} from "./SignuploginController";
// import CustomModalWeb from "../../../components/src/CustomModal.web";

export default class Signuplogin extends SignuploginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCheckedIcon = () => (
    <svg data-test-id="checkedIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="6" fill="#51ABB3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.061 5.42238C15.5182 5.73229 15.6375 6.35411 15.3276 6.81124L10.243 14.3112C10.077 14.5562 9.81098 14.715 9.51659 14.745C9.2222 14.7749 8.92965 14.673 8.71765 14.4665L4.80221 10.6536C4.40654 10.2683 4.39814 9.6352 4.78345 9.23953C5.16876 8.84386 5.80187 8.83546 6.19754 9.22077L9.25759 12.2007L13.6722 5.68895C13.9821 5.23182 14.6039 5.11247 15.061 5.42238Z" fill="white" />
    </svg>
  )

  renderUncheckedIcon = () => (
      <svg data-test-id="uncheckedIcon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="#64748B" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><rect width="20" height="20" rx="6" /></svg>
  )

  renderSuccessCheckedIcon = () => (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M40.5 80C62.5915 80 80.5 62.0915 80.5 40C80.5 17.9086 62.5915 0 40.5 0C18.4086 0 0.5 17.9086 0.5 40C0.5 62.0915 18.4086 80 40.5 80ZM59.0354 33.5355C60.9881 31.5829 60.9881 28.4171 59.0354 26.4645C57.0831 24.5118 53.9169 24.5118 51.9646 26.4645L35.5 42.9288L29.0355 36.4645C27.0829 34.5118 23.9171 34.5118 21.9645 36.4645C20.0118 38.4171 20.0118 41.5831 21.9645 43.5354L31.9645 53.5354C33.9171 55.4881 37.0829 55.4881 39.0354 53.5354L59.0354 33.5355Z" fill="#34D399"/>
    </svg>
  )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Dialog data-testid="successDialog" open={this.state.showSuccessDialog} onClose={this.handleCloseSuccessDialog} >
            {this.renderSuccessCheckedIcon()}
            <Typography variant="h1" component="h3" >Success!</Typography>
            <Typography variant="subtitle1" style={{color: "#0F172A", marginTop: 12}} >Your password has been successfully changed.</Typography>
            <DialogFooterButton data-testid="dialogCloseButton" onClick={this.handleCloseSuccessDialog} >{this.state.successDialogBtnTitle}</DialogFooterButton>
          </Dialog>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 12,
              padding: '24px 48px',
              height: 92,
              boxSizing: "border-box",
            }}
          >
            <img src={imgLogo} width={156} />
          </Box>
          <Formik
            data-test-id="FormikId"
            initialValues={{ email: "", password: "" }}
            validationSchema={this.SignUpValidation()}
            onSubmit={this.onLoginSubmit}
          >{({
            values, errors, touched, handleChange, handleSubmit
          }) => (
            <>
              <Container maxWidth="sm">
                <Box style={webStyle.loginFormContainer}>
                  <Typography variant="h1">{configJSON.labelTitle}</Typography>
                  <Box style={webStyle.formContainer} >
                  {
                    this.state.error &&
                    <Box
                      data-test-id={"errorTestId"}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: theme.spacing(1.5, 2),
                        backgroundColor: '#FEE2E2',
                        borderLeft: `4px solid #DC2626`,
                        borderRadius: theme.spacing(0.5),
                        maxWidth: '100%',
                      }}
                    >
                      <Typography
                        style={{ color: '#DC2626', fontSize: '12px', fontFamily: 'Cairo' }}
                      >
                        {this.state.error}
                      </Typography>
                    </Box>
                  }
                  {
                    (errors.email && touched.email) || (errors.password && touched.password) ? (
                      <Box
                        data-test-id={"errorTestId"}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          padding: theme.spacing(1.5, 2),
                          backgroundColor: '#FEE2E2',
                          borderLeft: `4px solid #DC2626`,
                          borderRadius: theme.spacing(0.5),
                          maxWidth: '100%',
                        }}
                      >
                        <Typography
                          style={{ color: '#DC2626', fontSize: '12px', fontFamily: 'Cairo' }}
                        >
                          {errors.email && touched.email ? errors.email : errors.password}
                        </Typography>
                      </Box>
                    ) : null
                  }
                    <Box style={webStyle.formControl} >
                      <InputLabel style={{ fontSize: '16px' }}>
                        Email Address
                      </InputLabel>
                      <OutlinedInput
                        data-test-id="txtInputEmail"
                        placeholder={"Enter your email"}
                        fullWidth={true}
                        onChange={handleChange('email')}
                        value={values.email}

                      />
                    </Box>
                    <Box style={webStyle.passwordWithRemeberContainer} >
                      <Box style={webStyle.formControl} >
                        <InputLabel>
                          Password
                        </InputLabel>
                        <OutlinedInput
                          data-test-id="txtInputPassword"
                          type={this.state.enablePasswordField ? "password" : "text"}
                          placeholder={"Enter password"}
                          fullWidth={true}
                          onChange={handleChange('password')}
                          value={values.password}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                data-test-id="btnPasswordVisibility"
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                edge="end"
                              >
                                {this.state.enablePasswordField ? (
                                  <Visibility htmlColor="#94A3B8" />
                                ) : (
                                  <VisibilityOff htmlColor="#94A3B8" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Box>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 10,
                        }}
                      >
                        <Checkbox
                          data-test-id={"btnRememberMe"}
                          onClick={() =>
                            this.setRememberMe(!this.state.checkedRememberMe)
                          }
                          checked={this.state.checkedRememberMe}
                          color="primary"
                          icon={this.renderUncheckedIcon()}
                          checkedIcon={this.renderCheckedIcon()}
                        />
                        <InputLabel className="rememberMe" >Remember me</InputLabel>
                        <Button
                          data-test-id={"btnForgotPassword"}
                          disableRipple
                          variant="text"
                          onClick={this.handleNavigate}
                          className="mr-2 ml-auto"
                        >
                          Forgot password?
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Button
                      data-test-id={"btnEmailLogIn"}
                      variant="contained"
                      className="loginBtn"
                      disabled={values.email == "" || values.password == ""}
                      onClick={() => handleSubmit()}
                    >
                      Log in
                    </Button>
                </Box>
              </Container>
            </>
          )}
          </Formik>
        </ThemeProvider>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: Record<string, React.CSSProperties> = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  loginFormContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "40px",
    boxSizing: 'border-box',
    gap: 24,
  },
  formContainer: {
    gap: 16,
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  passwordWithRemeberContainer: {
    display: "flex",
    gap: 8,
    flexDirection: "column",
  },
};
// Customizable Area End
