import React from "react";
import { makeStyles } from "@material-ui/core"

type ColumnProps = {
    className?: string;
    children: React.ReactNode;
}

const useStyles = makeStyles({
    column: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        '& .gap-16': {
            gap: "16px",
        },
    },
})

export default function Column({children, className = ""}: ColumnProps) {
    const classes = useStyles()
    return (
        <div className={`${classes.column} ${className}`} >{children}</div>
    )
}