import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import CustomSelect, { Option } from "../../../../components/src/CustomSelect.web";
import MultipleCustomSelect from "../../../../components/src/MultipleCustomSelect.web";
import { operatingSystemsObj, comparatorsObj } from "../Cfconnectorlogic.web"
import CriteriaFieldsWrapper from "../../../../components/src/CriteriaFieldsWrapper.web";
import { RemoveCircle } from "@material-ui/icons";


const url_regex = /^https?:\/\/(?:[\w-]+\.)+[a-z]{2,}(?:\/[a-zA-Z0-9-_.~%]*)*(?:\?[a-zA-Z0-9-_.~%=&,]*)?(?:#[\w-]*)?$/;

const useAudienceCriteriaClasses = makeStyles({
    heading5: {
        margin: 0,
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "22px",
        color: "#475569",
        '&.text-14': {
            fontSize: 14
        },
    },
    criteriaOperatorContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "4px 0",
        '& .horizontal-line': {
            width: 80,
            height: 2,
            backgroundColor: "#E2E8F0",
        },
        '& .operator-btn': {
            border: 0,
            backgroundColor: "#FFFFFF",
            padding: "0px 12px",
            fontFamily: "Cairo",
            fontSize: 14,
            lineHeight: "22px",
            fontWeight: 400,
            color: "#0F172A",
            cursor: "pointer",
            boxShadow: "0px 2px 8px 0px #00000014",
            '&[data-active = "true"]': {
                backgroundColor: "#51ABB3",
                color: "#FFFFFF",
            },
            '&:focus': {
                outline: 0,
            },
            '&.or': {
                borderRadius: "6px 0px 0px 6px",
            },
            '&.and': {
                borderRadius: "0px 6px 6px 0px",
            },
        },
    },
    ruleCriteriaContainer: {
        display: "flex",
        gap: 4,
        alignItems: "center",
        '& > div': {
            flexGrow: 1,
            borderRadius: 6,
            padding: 16,
            backgroundColor: "#F6F7FF",
            boxShadow: "0px 2px 8px 0px #00000014",
            cursor: "pointer",
            '& > p': {
                fontFamily: "Cairo",
                margin: 0,
                display: "flex",
                fontSize: 14,
                fontWeight: 400,
                color: "#51ABB3",
                lineHeight: "22px",
                gap: "0px 8px",
                flexWrap: "wrap",
                '& .subfield': {
                    color: "#51ABB3",
                },
                '& > span': {
                    color: "#0F172A"
                },
            },
        },
        '& > button': {
            all: "unset",
            cursor: "pointer",
        },
    },
    row: {
        display: "flex",
        gap: 4,
        '&.gap-8': {
            gap: 8,
        },
        '&.justify-between': {
            justifyContent: "space-between"
        },
        '&.items-center': {
            alignItems: "center",
        },
    },
    timeSelect: {
        padding: "8px 10px",
    },
    input: {
        border: "1px solid #51ABB3",
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        padding: "14px 6px",
        fontFamily: "Cairo",
        height: 56,
        fontWeight: 400,
        fontSize: "16px",
        color: "#64748B",
        lineHeight: "24px",
        borderRadius: 8,
        '&:placeholder': {
            opacity: 0.5
        },
        '&:focus': {
            outline: 0,
        },
        '&[aria-invalid="true"]': {
            borderColor: "#F59E0B",
        },
    },
    timeLabel: {
        margin: 0,
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "22px",
        color: "#334155",
        '&[data-error="true"]': {
            color: "#F59E0B",
        },
    },
    contextBox: {
        display: "flex",
        gap: 12,
        flexDirection: "column",
        backgroundColor: "#F6F7FF",
        padding: "16px 8px",
        borderRadius: 8,
    },
    dots: {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
        color: "#0F172A",
    },
    column: {
        display: "flex",
        flexDirection: "column",
        gap: 4,
        '&.gap-8': {
            gap: "8px",
        },
    },
    andOrDoneButton: {
        width: 93,
        boxSizing: "border-box",
        border: 0,
        backgroundColor: "#E5EBFF",
        padding: "6px 10px",
        borderRadius: 8,
        color: "#51ABB3",
        fontFamily: "Cairo",
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 700,
        cursor: "pointer",
        '&:focus': {
            ouline: 0,
        },
        '&.primary': {
            backgroundColor: "#51ABB3",
            color: "#FFFFFF",
        },
        '&[aria-disabled="true"': {
            pointerEvents: "none",
        },
    }
})

const allOperatingSystemsOptions = [
    {
        label: "Android",
        value: "android"
    },
    {
        label: "Blackberry",
        value: "blackberry",
    },
    {
        label: "iOS",
        value: "iOS",
    },
    {
        label: "iPadOS",
        value: "iPadOS",
    },
    {
        label: "Linux",
        value: "linux",
    },
    {
        label: "Windows",
        value: "windows",
    },
    {
        label: "Nokia",
        value: "nokia",
    },
]

const allComparators = [
    {
        label: "Is",
        value: "is_equal_to",
    },
    {
        label: "Is not",
        value: "is_not_equal_to",
    },
    {
        label: "Contains",
        value: "contains"
    },
    {
        label: "Between",
        value: "between",
    },
    {
        label: "After",
        value: "after",
    },
    {
        label: "Before",
        value: "before",
    },
]

const hoursOptions = [
    { label: "00", value: "00" },
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" }
]

const minutesOptions = [
    { label: "00", value: "00" },
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
    { label: "31", value: "31" },
    { label: "32", value: "32" },
    { label: "33", value: "33" },
    { label: "34", value: "34" },
    { label: "35", value: "35" },
    { label: "36", value: "36" },
    { label: "37", value: "37" },
    { label: "38", value: "38" },
    { label: "39", value: "39" },
    { label: "40", value: "40" },
    { label: "41", value: "41" },
    { label: "42", value: "42" },
    { label: "43", value: "43" },
    { label: "44", value: "44" },
    { label: "45", value: "45" },
    { label: "46", value: "46" },
    { label: "47", value: "47" },
    { label: "48", value: "48" },
    { label: "49", value: "49" },
    { label: "50", value: "50" },
    { label: "51", value: "51" },
    { label: "52", value: "52" },
    { label: "53", value: "53" },
    { label: "54", value: "54" },
    { label: "55", value: "55" },
    { label: "56", value: "56" },
    { label: "57", value: "57" },
    { label: "58", value: "58" },
    { label: "59", value: "59" }
]

const criteriaOptions = [
    {
        label: "Context variable",
        value: "bot_contexts",
    },
    {
        label: "Operating system",
        value: "operating_system",
    },
    {
        label: "Time of the day",
        value: "time_of_the_day",
    },
    {
        label: "Current page URL",
        value: "current_page_url",
    }
]

const criteriaOptionObj: { [Key: string]: string } = {
    operating_system: "Operating system",
    time_of_the_day: "Time of the day",
    current_page_url: "Current page URL",
    bot_contexts: "Context variable"
}

export type Criteria = {
    selectedOperatingSystems?: string[];
    comparator: string;
    currentPageURL?: string;
    time?: string[];
    fieldName: string;
    error?: boolean;
    criteriaOperatorWithPreviousCriteria?: string;
    fieldNameError?: boolean;
    criteriaId?: number;
    subField?: string;
    selectedSubFieldOptions?:string[];
    selectedSubFieldOptionsError?: boolean;
}

const comparatorOptions: Record<string, Option[]> = {
    operating_system: allComparators.slice(0, 2),
    time_of_the_day: [allComparators[0], ...allComparators.slice(3, 6)],
    current_page_url: allComparators.slice(0, 3),
    bot_contexts: allComparators.slice(0,2)
}

const timeLabel = ["Start Time", "End Time"]

const getCurrentMinutesAndHours = () => {
    const currentDate = new Date()
    const hours = currentDate.getHours().toString().padStart(2, "0")
    const minutes = currentDate.getMinutes().toString().padStart(2, "0")
    return `${hours}:${minutes}`
}

const getCurrentPageURLError = (currentPageURL: string | undefined, comparator: string) => {
    let hasError = false;
    if(currentPageURL?.trim().length === 0) {
        hasError = true
    }
    else if(comparator !== "contains" && currentPageURL) {
        hasError = !url_regex.test(currentPageURL)
    }

    return hasError
}

const getCriteriaError = (criteria: Criteria) => {
    let hasError = false;
    let newCriteria = { ...criteria }
    if (newCriteria.fieldName === "operating_system" && newCriteria.selectedOperatingSystems) {
        hasError = newCriteria.selectedOperatingSystems.length === 0
        newCriteria = {
            ...newCriteria,
            error: newCriteria.selectedOperatingSystems.length === 0
        }
    } else if (newCriteria.fieldName === "current_page_url") {
        const hasURLError = getCurrentPageURLError(newCriteria.currentPageURL, newCriteria.comparator)
        hasError = hasURLError
        newCriteria = {
            ...newCriteria,
            error: hasURLError
        }
    } else if (newCriteria.fieldName === "time_of_the_day" && newCriteria.comparator === "between" && newCriteria.time) {
        const timeError = newCriteria.time[0] === newCriteria.time[1]
        hasError = timeError
        newCriteria = {
            ...newCriteria,
            error: timeError
        }
    } else if(criteria.fieldName === ""){
        hasError = true
        newCriteria = {
            ...newCriteria,
            fieldNameError: true,
        }
    } else if(criteria.fieldName === "bot_contexts") {
        hasError = !criteria.subField || criteria.selectedSubFieldOptions?.length === 0
        newCriteria = {
            ...newCriteria,
            error: !criteria.subField,
            selectedSubFieldOptionsError: criteria.selectedSubFieldOptions?.length === 0
        }
    }

    return { hasError, newCriteria }
}

type AudienceCriteriaProps = {
    onAddRule: (criteria: Criteria[]) => void;
    initialCriteria?: Criteria[];
    contextVariables: {subFields: string[],subFieldValue: Record<string, string[]>};
    onDeleteCriteria: (criteriaId: number) => void;
}

type CriteriaTextProps = {
    criteria: Criteria;
    onExpandCriteriaFields: () => void;
    handleDeleteCriteria: () => void;
    isExpanded: boolean;
    subFields: string[];
    subFieldValue: Record<string, string[]>
}

type CriteriaFieldsProps = {
    criteria: Criteria;
    criteriaIndex: number;
    subFields: string[];
    subFieldValue: Record<string, string[]>;
    onFieldNameSelect: (fieldName: string, criteriaIndex: number) => void;
    onComparatorChange: (criteriaIndex: number) => (value: string) => void;
    onOperatingSystemsChange: (criteriaIndex: number) => (value: string[]) => void;
    onCurrentPageURLInputChange: (criteriaIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    onHourAndMinuteTimeChange: (timeIndex: number, criteriaIndex: number, isHour: boolean) => (value: string) => void;
    onCriteriaFieldsOutsideClick: () => void;
    onSubFieldChange: (criteriaIndex: number) => (value: string) => void;
    onSubFieldOptionsChange: (criteriaIndex: number) => (values: string[]) => void;
}

const defaultCriteria: Criteria[] = [
    {
        comparator: "",
        fieldName: "",
    }
]

const getSubFieldOptions = (subFieldValue: Record<string, string[]>, subField: string | undefined) => {
    if(!subField || !subFieldValue[subField]) {
        return []
    }
    return subFieldValue[subField].map(field => ({label: field, value: field}))
}

export default function AudienceCriteria({
    onAddRule,
    initialCriteria = defaultCriteria,
    contextVariables,
    onDeleteCriteria,
}: AudienceCriteriaProps) {
    const classes = useAudienceCriteriaClasses()
    const [allCriterias, setAllCriterias] = useState<Criteria[]>(initialCriteria)
    const [expandedCriteriaFieldsIndex, setExpandedCriteriaFieldsIndex] = useState<number | null>(() => initialCriteria[0].fieldName ? null : 0)

    const handleDoneClick = () => {
        const hasSomeError = allCriterias.some(criteria => {
            const { hasError } = getCriteriaError(criteria)
            return hasError
        })
        if (hasSomeError) {
            const newAllCriterias = allCriterias.map(criteria => {
                const { newCriteria } = getCriteriaError(criteria)
                return newCriteria
            })
            setAllCriterias(newAllCriterias)
            return;
        }
        onAddRule(allCriterias)
    }

    const onFieldNameSelect = (fieldName: string, criteriaIndex: number) => {
        let newCriteria = allCriterias[criteriaIndex]
        switch (fieldName) {
            case "operating_system":
                newCriteria = {
                    ...newCriteria,
                    selectedOperatingSystems: [],
                    comparator: "is_equal_to",
                    fieldName: "operating_system",
                    error: false,
                    fieldNameError: false,
                }
                break;
            case "time_of_the_day":
                const timeInString = getCurrentMinutesAndHours()
                newCriteria = {
                    ...newCriteria,
                    time: [timeInString, timeInString],
                    comparator: "between",
                    fieldName: "time_of_the_day",
                    fieldNameError: false,
                    error: false,
                }
                break;
            case "current_page_url":
                newCriteria = {
                    ...newCriteria,
                    comparator: "is_equal_to",
                    currentPageURL: "",
                    fieldName: "current_page_url",
                    error: false,
                    fieldNameError: false,
                }
                break;
            case "bot_contexts": 
                newCriteria = {
                    ...newCriteria,
                    comparator: contextVariables.subFields.length > 0 ? "is_equal_to" : "",
                    fieldName: "bot_contexts",
                    error: contextVariables.subFields.length === 0,
                    selectedSubFieldOptions: [],
                    fieldNameError: false,
                    selectedSubFieldOptionsError: false,
                    subField: "",
                }
        }

        const newAllCriterias = [...allCriterias]
        newAllCriterias[criteriaIndex] = newCriteria

        setAllCriterias(newAllCriterias)
    }

    const handleCurrentContextChange = (criteria: Partial<Criteria>, criteriaIndex: number) => {
        const currentCriteria = allCriterias[criteriaIndex]
        const newAllCriterias = [...allCriterias]
        newAllCriterias[criteriaIndex] = {
            ...currentCriteria,
            ...criteria
        }
        setAllCriterias(newAllCriterias)
    }

    const onComparatorChange = (criteriaIndex: number) => (value: string) => {
        let newCriteria: Partial<Criteria> = { comparator: value };
        const currentCriteria = allCriterias[criteriaIndex]
        if (currentCriteria.fieldName === "time_of_the_day") {
            const timeInString = getCurrentMinutesAndHours()
            newCriteria = {
                ...newCriteria,
                time: value === "between" ? [timeInString, timeInString] : [timeInString],
                error: value === "between"
            }
        }
        handleCurrentContextChange(newCriteria, criteriaIndex)
    }

    const onOperatingSystemsChange = (criteriaIndex: number) => (value: string[]) => {
        handleCurrentContextChange(
            {
                selectedOperatingSystems: value,
                error: value.length === 0,
            },
            criteriaIndex
        )
    }

    const onCurrentPageURLInputChange = (criteriaIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        let hasURLError = false
        const currentCriteria = allCriterias[criteriaIndex]
        if(currentCriteria.comparator !== "contains" && !url_regex.test(value)) {
            hasURLError = true
        }
        handleCurrentContextChange({
            currentPageURL: value,
            error: value.trim().length === 0 || hasURLError
        }, criteriaIndex)
    }

    const onSubFieldChange = (criteriaIndex: number) => (value: string) => {
        handleCurrentContextChange({
            subField: value,
            selectedSubFieldOptions: [],
            error: false,
        }, criteriaIndex)
    }

    const onSubFieldOptionsChange = (criteriaIndex: number) => (values: string[]) => {
        handleCurrentContextChange({
            selectedSubFieldOptions: values,
            selectedSubFieldOptionsError: values.length === 0
        }, criteriaIndex)
    }

    const onHourAndMinuteTimeChange = (timeIndex: number, criteriaIndex: number, isHour: boolean) => (value: string) => {
        const contextTime = allCriterias[criteriaIndex].time!
        const newTime = [...contextTime]
        const currentTime = contextTime[timeIndex].split(":")
        const hour = isHour ? `${value}:${currentTime[1]}` : `${currentTime[0]}:${value}`
        newTime[timeIndex] = hour
        handleCurrentContextChange({ time: newTime }, criteriaIndex)
    }

    const handlePopLastCriteria = () => {
        const newAllCriterias = [...allCriterias]
        newAllCriterias.pop()
        setAllCriterias(newAllCriterias)
    }

    const onCriteriaOperatorChange = (operator: "and" | "or", criteriaIndex: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if(expandedCriteriaFieldsIndex !== null) {
            handlePopLastCriteria()
            setExpandedCriteriaFieldsIndex(null)
            return;
        }
        handleCurrentContextChange({ criteriaOperatorWithPreviousCriteria: operator }, criteriaIndex)
    }

    const handleSetCriteriaErrorOrResetExpandedCriteriaIndex = (criteriaIndex: number) => {
        let currentExapandedCriteria = allCriterias[criteriaIndex]
        const { hasError, newCriteria } = getCriteriaError(currentExapandedCriteria)
        const newAllCriterias = [...allCriterias]
        newAllCriterias[criteriaIndex] = newCriteria
        if (hasError) {
            setAllCriterias(newAllCriterias)
        } else {
            setExpandedCriteriaFieldsIndex(null)
        }
    }

    const onCriteriaFieldsOutsideClick = () => {
        if (expandedCriteriaFieldsIndex !== null) {
            handleSetCriteriaErrorOrResetExpandedCriteriaIndex(expandedCriteriaFieldsIndex)
        }
    }

    const handleAddOperator = (operator: "and" | "or") => () => {
        if(expandedCriteriaFieldsIndex !== null) {
            handleSetCriteriaErrorOrResetExpandedCriteriaIndex(expandedCriteriaFieldsIndex)
            return;
        }
        
        const newCriteria: Criteria = {
            fieldName: "",
            comparator: "",
            criteriaOperatorWithPreviousCriteria: operator
        }
        setAllCriterias([...allCriterias, newCriteria])
        setExpandedCriteriaFieldsIndex(allCriterias.length)
    }

    const onExpandCriteriaFields = (criteriaIndex: number) => {
        if(expandedCriteriaFieldsIndex !== null) {
            handlePopLastCriteria()
            setExpandedCriteriaFieldsIndex(criteriaIndex)
            return;
        }
        setExpandedCriteriaFieldsIndex(criteriaIndex)
    }

    const handleDeleteCriteria = (criteriaIndex: number) => {
        const newAllCriterias = [...allCriterias]
        const currentCriteria = allCriterias[criteriaIndex]
        if(currentCriteria.criteriaId) {
            onDeleteCriteria(currentCriteria.criteriaId)
        }
        if(newAllCriterias[criteriaIndex + 1]) {
            newAllCriterias[criteriaIndex + 1] = {
                ...newAllCriterias[criteriaIndex + 1],
                criteriaOperatorWithPreviousCriteria: undefined
            }
        }
        if(allCriterias.length === 1) {
            setAllCriterias(defaultCriteria)
            setExpandedCriteriaFieldsIndex(0)
            return;
        }
        setExpandedCriteriaFieldsIndex(null)
        newAllCriterias.splice(criteriaIndex,1)
        setAllCriterias([...newAllCriterias])
    }

    const hasError = allCriterias.some(criteria => criteria.error)

    return (
        <>
            <div className={`${classes.row} justify-between`} >
                <h5 className={classes.heading5} >Audience criteria</h5>
                <button data-testid="done-btn" onClick={handleDoneClick} className={`${classes.andOrDoneButton} primary`} aria-disabled={hasError} >Done</button>
            </div>
            <div className={`${classes.column} gap-8`} data-testid="criteria" >
                <h5 className={`${classes.heading5} text-14`} >{`Rules - (${allCriterias.length})*`}</h5>
                {
                    allCriterias.map((criteria, criteriaIndex) => {
                        return (
                            <React.Fragment key={criteriaIndex}>
                                {
                                    criteria.criteriaOperatorWithPreviousCriteria && (
                                        <div className={classes.criteriaOperatorContainer} >
                                            <div className="horizontal-line" />
                                            <button data-testid={`change-to-or-operator-${criteriaIndex}`} className="operator-btn or" data-active={criteria.criteriaOperatorWithPreviousCriteria === "or"} onClick={onCriteriaOperatorChange("or", criteriaIndex)} >or</button>
                                            <button data-testid={`change-to-and-operator-${criteriaIndex}`} className="operator-btn and" data-active={criteria.criteriaOperatorWithPreviousCriteria === "and"} onClick={onCriteriaOperatorChange("and", criteriaIndex)} >and</button>
                                            <div className="horizontal-line" />
                                        </div>
                                    )
                                }
                                {
                                    expandedCriteriaFieldsIndex === criteriaIndex ? (
                                        <CriteriaFields
                                            key={criteriaIndex}
                                            criteria={criteria}
                                            criteriaIndex={criteriaIndex}
                                            subFields={contextVariables.subFields}
                                            subFieldValue={contextVariables.subFieldValue}
                                            onFieldNameSelect={onFieldNameSelect}
                                            onComparatorChange={onComparatorChange}
                                            onOperatingSystemsChange={onOperatingSystemsChange}
                                            onCurrentPageURLInputChange={onCurrentPageURLInputChange}
                                            onHourAndMinuteTimeChange={onHourAndMinuteTimeChange}
                                            onCriteriaFieldsOutsideClick={onCriteriaFieldsOutsideClick}
                                            onSubFieldChange={onSubFieldChange}
                                            onSubFieldOptionsChange={onSubFieldOptionsChange}
                                        />
                                    ) : (
                                        <CriteriaText 
                                            criteria={criteria} 
                                            onExpandCriteriaFields={() => onExpandCriteriaFields(criteriaIndex)}
                                            isExpanded={criteriaIndex === expandedCriteriaFieldsIndex} 
                                            handleDeleteCriteria={() => handleDeleteCriteria(criteriaIndex)}
                                            subFields={contextVariables.subFields}
                                            subFieldValue={contextVariables.subFieldValue}
                                        />
                                    )
                                }
                            </React.Fragment>
                        )
                    })
                }
                <div className={`${classes.row} gap-8`} >
                    <button data-testid="add-or-operator" aria-disabled={expandedCriteriaFieldsIndex === null} className={classes.andOrDoneButton} onClick={handleAddOperator("or")} >or</button>
                    <button data-testid="add-and-operator" aria-disabled={expandedCriteriaFieldsIndex === null} className={classes.andOrDoneButton} onClick={handleAddOperator("and")} >and</button>
                </div>
            </div>
        </>
    )
}

function CriteriaFields({
    criteria,
    criteriaIndex,
    subFields,
    subFieldValue,
    onFieldNameSelect,
    onComparatorChange,
    onOperatingSystemsChange,
    onCurrentPageURLInputChange,
    onHourAndMinuteTimeChange,
    onCriteriaFieldsOutsideClick,
    onSubFieldChange,
    onSubFieldOptionsChange
}: CriteriaFieldsProps) {
    const classes = useAudienceCriteriaClasses()

    return (
        <CriteriaFieldsWrapper onOutsideClickCallback={onCriteriaFieldsOutsideClick} >
            <div className={classes.contextBox}>
                <CustomSelect
                    selectBoxWidth={320}
                    id="select-criterion"
                    placeholder="Select criterion"
                    value={criteria.fieldName}
                    onChange={(value) => onFieldNameSelect(value, criteriaIndex)}
                    options={criteriaOptions}
                    hasSearch={false}
                    error={!!criteria.fieldNameError}
                />
                {
                    criteria.fieldName === "bot_contexts" && (
                        <CustomSelect
                            selectBoxWidth={320}
                            id="select-subfield"
                            placeholder="Select"
                            value={criteria.subField ?? ""}
                            onChange={onSubFieldChange(criteriaIndex)}
                            options={subFields.map(field => ({label: field, value: field}))}
                            hasSearch={false}
                            error={subFields.length === 0 || criteria.error}
                            disabled={subFields.length === 0}
                        />
                    )
                }
                {
                    criteria.fieldName && criteria.comparator && (
                        <CustomSelect
                            key={criteriaOptionObj[criteria.fieldName]}
                            selectBoxWidth={174}
                            id="select-comparator"
                            placeholder="Select comparator"
                            value={criteria.comparator}
                            onChange={onComparatorChange(criteriaIndex)}
                            options={comparatorOptions[criteria.fieldName]}
                            hasSearch={false}
                        />
                    )
                }
                {
                    criteria.fieldName === "operating_system" && criteria.selectedOperatingSystems && (
                        <MultipleCustomSelect
                            selectBoxWidth={320}
                            id="select-operating-system"
                            placeholder="Select operating system"
                            value={criteria.selectedOperatingSystems}
                            options={allOperatingSystemsOptions}
                            hasSearch={false}
                            onChange={onOperatingSystemsChange(criteriaIndex)}
                            error={criteria.error}
                        />
                    )
                }
                {
                    criteria.fieldName === "bot_contexts" && subFields.length > 0 && (
                        <MultipleCustomSelect
                            selectBoxWidth={320}
                            id="select-subfield-option"
                            placeholder="Select"
                            value={criteria.selectedSubFieldOptions ?? []}
                            options={getSubFieldOptions(subFieldValue, criteria.subField)}
                            hasSearch={false}
                            onChange={onSubFieldOptionsChange(criteriaIndex)}
                            error={criteria.selectedSubFieldOptionsError}
                            disabled={!criteria.subField}
                        />
                    )
                }
                {
                    criteria.fieldName === "current_page_url" && (
                        <input
                            type="text"
                            placeholder="Type the current page URL"
                            value={criteria.currentPageURL}
                            onChange={onCurrentPageURLInputChange(criteriaIndex)}
                            className={classes.input}
                            aria-invalid={criteria.error}
                        />
                    )
                }
                {
                    criteria.fieldName === "time_of_the_day" && criteria.time && (
                        criteria.time.map((time, timeIndex) => {
                            const timeSplit = time.split(":")
                            return (
                                <div key={timeIndex} className={`${classes.column} gap-4`}>
                                    <p className={classes.timeLabel} data-error={timeIndex === 1 && criteria.error} >{criteria.comparator !== "between" ? "Time" : timeLabel[timeIndex]}</p>
                                    <div className={`${classes.row} items-center`}>
                                        <CustomSelect
                                            selectBoxWidth={79}
                                            id={`hour-time-${timeIndex}`}
                                            placeholder="Hour"
                                            value={timeSplit[0]}
                                            onChange={onHourAndMinuteTimeChange(timeIndex, criteriaIndex, true)}
                                            options={hoursOptions}
                                            hasSearch={false}
                                            selectClassName={classes.timeSelect}
                                        />
                                        <div className={classes.dots} >:</div>
                                        <CustomSelect
                                            selectBoxWidth={79}
                                            id={`minute-time-${timeIndex}`}
                                            placeholder="Minute"
                                            value={timeSplit[1]}
                                            onChange={onHourAndMinuteTimeChange(timeIndex, criteriaIndex, false)}
                                            options={minutesOptions}
                                            hasSearch={false}
                                            selectClassName={classes.timeSelect}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    )
                }
            </div>
        </CriteriaFieldsWrapper>
    )
}

function CriteriaText({ criteria, onExpandCriteriaFields, isExpanded, handleDeleteCriteria, subFields, subFieldValue }: CriteriaTextProps) {
    const classes = useAudienceCriteriaClasses()
    let value = "";
    let subField:string | undefined = undefined
    if (criteria.fieldName === "operating_system" && criteria.selectedOperatingSystems) {
        value = criteria.selectedOperatingSystems.map((systemVal) => operatingSystemsObj[systemVal]).join(", ")
    }

    if (criteria.fieldName === "time_of_the_day" && criteria.time) {
        value = criteria.time.join(" and ")
    }

    if (criteria.fieldName === "current_page_url" && criteria.currentPageURL) {
        value = criteria.currentPageURL
    }

    if(criteria.fieldName === "bot_contexts" && criteria.selectedSubFieldOptions) {
        if(subFields.includes(criteria.subField ?? "")) {
            const subFieldOptions = new Set(subFieldValue[criteria.subField!])
            value = criteria.selectedSubFieldOptions.filter(option => subFieldOptions.has(option)).join(", ")
        }
    }

    if(criteria.subField) {
        subField = criteria.subField
    }

    return (
        <div className={classes.ruleCriteriaContainer} onClick={onExpandCriteriaFields} >
            <div>
                <p>
                    {criteriaOptionObj[criteria.fieldName]}
                    {subField &&  <span className="subfield" >{subField}</span>}
                    <span>{`${comparatorsObj[criteria.comparator]} ${value}`}</span>
                </p>
            </div>
            {
                !isExpanded && (
                    <button 
                        aria-label={`remove ${criteriaOptionObj[criteria.fieldName]} ${comparatorsObj[criteria.comparator]} ${value}`} 
                        onClick={(event) => {
                            event.stopPropagation()
                            handleDeleteCriteria()
                        }}  
                    >
                        <RemoveCircle htmlColor="#DC2626" />
                    </button>
                )
            }
        </div>
    )
}
