import { 
    ButtonClassKey, 
    FormLabelClassKey, 
    InputBaseClassKey, 
    OutlinedInputClassKey, 
    StyleRules, 
    styled,
    FormControlClassKey, 
} from "@material-ui/core";

const formLabel: Partial<StyleRules<FormLabelClassKey>> = {
    root: {
        fotnFamily: "Cairo",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
        color: "#334155",
        '&.Mui-focused': {
            color: "#334155",
        },
    },
}

const button: Partial<StyleRules<ButtonClassKey>>  = {
    root: {
        fontFamily: "Cairo",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#51ABB3",
        textTransform: "none",
        borderRadius: 8,
        cursor: "pointer",
        '&:hover': {
            backgroundColor: "transparent",
            boxShadow: 'none',
        },
        '&.mt-4': {
            marginTop: 16,
        },
    },
    text: {
        padding: 0,
    },
    contained: {
      color: "#FFFFFF",
      backgroundColor: "#51ABB3",
      lineHeight: "24px",
      padding: 16,
      '&:hover': {
        boxShadow: "none",
        backgroundColor: "#51ABB3",
        color: "#FFFFFF",
      },
      '&.Mui-disabled': {
        color: "#FFFFFF",
        backgroundColor: "#CBD5E1",
      },
    }
}

const outlinedInput: Partial<StyleRules<OutlinedInputClassKey>>  = {
    root: {
        borderRadius: 8,
        '&:hover $notchedOutline': {
            borderColor: "#CBD5E1",
        },
        '&.Mui-Focused $notchedOutline': {
            borderColor: "#CBD5E1",
            borderWidth: 1,
        },
        "&.Mui-error $notchedOutline": {
            borderColor: "#F87171",
        },
        "& .visibility-button": {
            padding: 0, 
            color: "#94A3B8", 
            '&:hover': {
                backgroundColor: "transparent",
            },
        },
    },
    notchedOutline: {
        borderWidth: 1,
        borderColor: "#CBD5E1",
    },
    input: {
        padding: 0,
    },
}

const inputBase: Partial<StyleRules<InputBaseClassKey>> = {
    root: {
        backgroundColor: "#FFFFFF",
        padding: "16px 8px",
    },
    input: {
        fontFamily: "Cairo",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        height: 24,
        color: "#0F172A",
        '&::placeholder': {
            color: "#64748B",
        },
    },
}

const formControl: Partial<StyleRules<FormControlClassKey>> = {
    root: {
        display: "flex",
        flexDirection: "column",
        gap: 4,
    },
}

export const PasswordMainContainer = styled("main")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 29,
})

export const PasswordImageContainer = styled("div")({
    padding: "24px 48px",
    display: "flex",
    justifyContent: "center",
})

export const PasswordContainer = styled("div")({
    boxSizing: "border-box",
    minWidth: 440,
    maxWidth: 460,
    padding: 40,
    display: "flex",
    flexDirection: "column",
    gap: 24,
})

export const PasswordHeadingTitle = styled("h1")({
    margin: 0,
    color: "#000000",
    fontFamily: "Cairo",
    fontweight: 700,
    fontSize: 24,
    lineHeight: "32px",
})

export const PasswordHeadingSubTitle = styled("h2")({
    fontFamily: "Cairo",
    color: "#0F172A",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    margin: "16px 0 0",
})



export const BackToLoginContainer = styled("div")({
    display: "flex",
    marginTop: 16,
    gap: 8,
    '& .back-to-title': {
        fontFamily: "Cairo",
        color: "#0F172A",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "22px",
    },
    '& .login': {
        all: "unset",
        cursor: "pointer",
        fontFamily: "Cairo",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#51ABB3",
    },
})


export const theme = {
    formLabel,
    button,
    outlinedInput,
    inputBase,
    formControl,
}

