import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { configJSON } from "../Chatbot5Controller"
import { toast } from "react-toastify";

export type ShareUserGuideOrEmbedFormValues = {
    name: string;
    message: string;
    selectedOptions: string[];
    type: 'share_user_guide' | 'embed_form';
    id?: number;
}

type FetchDataArgs = {
    title: string;
    endPoint: string;
}

type FormResponse = {
    id: string;
    form_name: string;
    active: boolean;
    practice: string | null;
    is_global: boolean;
};

export type GuidesResponse = {
    id: number;
    title: string;
    view: number;
    last_update: string;
    active: boolean;
}

type ConstObject = {
    title: string;
    namePlaceholder: string;
    selectLabel: string;
    selectPlaceholder: string;
    selectedText: string;
    createNewText: string;
    message: MessageEnum;
}

type Option = {
    label: string;
    value: string;
}

export interface ShareUserGuideOrEmbedFormProps {
    navigation: {
        navigate: (to: string, params: Record<string, string | number>) => void;
        getParam: (param: string, alternative?: string) => string;
        goBack: () => void;
    };
    onSave: (values: ShareUserGuideOrEmbedFormValues) => void;
    onClose: () => void;
    type: 'share_user_guide' | 'embed_form';
    title?: string;
    message?: string;
    id?: number;
    selectedOptions?: string[];
    onDrawerClose: () => void;
    token: string;
    handleLogout(): Promise<void>;
    onSaveLoading: boolean;
    botContext: string[];
}

interface S {
    options: Option[];
    cardLoading: boolean;
}

interface SS { }

export default class ShareUserGuideOrEmbedFormController extends BlockComponent<ShareUserGuideOrEmbedFormProps, S, SS> {
    perPage: number = 15;

    constVal: Record<'share_user_guide' | 'embed_form', ConstObject> = {
        "share_user_guide": {
            title: "Share user guide",
            namePlaceholder: "Send user guide card",
            selectLabel: "User guide*",
            selectPlaceholder: "Select user guide",
            selectedText: "Selected user guide",
            createNewText: "Create new user guide",
            message: MessageEnum.NavigationUserGuideMessage,
        },
        "embed_form": {
            title: "Embed form",
            namePlaceholder: "Add Embed form",
            selectLabel: "Embed form*",
            selectPlaceholder: "Select embed form",
            selectedText: "Selected embed form",
            createNewText: "Create new embed form",
            message: MessageEnum.NavigationAutomaticFormCreationMessage,
        },
    }

    constructor(props: ShareUserGuideOrEmbedFormProps) {
        super(props)
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            options: [],
            cardLoading: true,
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.handleRestApiResponse = this.handleRestApiResponse.bind(this)
        this.handleGetAllFormsResponse = this.handleGetAllFormsResponse.bind(this)
        this.handleGetAllUserGuidesResponse = this.handleGetAllUserGuidesResponse.bind(this)
    }

    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            this.handleRestApiResponse(message)
        }
    }

    async componentDidMount() {
        if (this.props.type === "share_user_guide") {
            this.handleFetchUserGuidesOrForms({
                endPoint: `${configJSON.userGuideEndPoint}`,
                title: "UserGuide",
            })
        }
        if (this.props.type === "embed_form") {
            this.handleFetchUserGuidesOrForms({
                endPoint: `${configJSON.formsEndPoint}`,
                title: "Forms",
            })
        }
    }

    handleRestApiResponse(message: Message) {
        const responseHandlers: Record<string, (newMsg: Message) => void> = {}
        const { title } = message.getData(getName(MessageEnum.NavigationPropsMessage))

        if (title === "Forms") {
            responseHandlers["Forms"] = this.handleGetAllFormsResponse
        }

        if (title === "UserGuide") {
            responseHandlers["UserGuide"] = this.handleGetAllUserGuidesResponse
        }

        const handler = responseHandlers[title]
        if (handler) {
            handler(message)
        }
    }

    async handleGetAllFormsResponse(message: Message) {
        const formsRes = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        this.setState({ cardLoading: false })
        if (!formsRes) {
            toast.error("Failed to fetch forms", { className: "error__toast" })
            return;
        }
        if (formsRes.errors?.[0]?.token) {
            await this.props.handleLogout()
            return;
        }

        if (Array.isArray(formsRes)) {
            const data = formsRes as FormResponse[]
            const tranformedOptions: Option[] = data.map(option => ({ label: option.form_name, value: `${option.id}` }))
            this.setState({
                options: tranformedOptions,
            })
        }
    }

    async handleGetAllUserGuidesResponse(message: Message) {
        const userGuidesRes = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        this.setState({ cardLoading: false })
        if (!userGuidesRes) {
            toast.error("Failed to fetch user guides", { className: "error__toast" })
            return;
        }
        if (userGuidesRes.errors?.[0]?.token) {
            await this.props.handleLogout()
            return;
        }

        if (Array.isArray(userGuidesRes)) {
            const data = userGuidesRes as GuidesResponse[]
            const tranformedUserGuidesOptions: Option[] = data.map(option => ({ label: option.title, value: `${option.id}` }))
            this.setState({options: tranformedUserGuidesOptions})
        }
    }

    handleFetchUserGuidesOrForms({ endPoint, title }: FetchDataArgs) {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.props.token
        }

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        reqMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        )
        reqMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        )
        reqMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        reqMessage.addData(
            getName(MessageEnum.NavigationPropsMessage),
            { title }
        )
        this.send(reqMessage)
    }

    handleCreateUserGuideNavigation = () => {
        const botId = this.props.navigation.getParam("navigationBarTitleText")
        sessionStorage.setItem("redirectTo", "bot")
        sessionStorage.setItem("botId", `${botId}`)
        const message: Message = new Message(
            getName(this.constVal[this.props.type].message)
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), { navigation: this.props.navigation });
        runEngine.sendMessage(message.id, message)
    }
}