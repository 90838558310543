import React from "react";
import { imgLogo } from '../assets';
import {
  PasswordContainer,
  PasswordMainContainer,
  PasswordImageContainer,
  PasswordHeadingTitle,
  PasswordHeadingSubTitle,
} from "../theme.web";
import PasswordFormFields from "./PasswordFormFields.web";
import ChangePasswordController, { ChangePasswordProps } from "./ChangePasswordController";


export default class ChangePasswordWeb extends ChangePasswordController {
    constructor(props: ChangePasswordProps) {
        super(props)
    }

    render() {
        return (
            <PasswordMainContainer>
                <PasswordImageContainer>
                <img src={imgLogo} alt="Maxchat-bot logo" width="156" height="38" />
                </PasswordImageContainer>
                <PasswordContainer>
                    <PasswordHeadingTitle>Change password</PasswordHeadingTitle>
                    <PasswordHeadingSubTitle>For security purposes, please update your password</PasswordHeadingSubTitle>
                    <PasswordFormFields 
                        onSubmit={this.handleChangePassword} 
                        error={this.state.error}
                        loading={this.state.loading} 
                    />
                </PasswordContainer>
            </PasswordMainContainer>
        )
    }
}