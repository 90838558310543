import React, { useRef, useState } from "react";
import { Popover, createTheme, ThemeProvider, makeStyles } from "@material-ui/core";
import { MoreVert, Edit, DeleteOutlineRounded, ArrowRightAltRounded } from "@material-ui/icons";
import { theme } from "../../blocks/projecttemplates/src/theme.web";
import { Category, MoveTemplateFromCategoryToAnotherCategory, Template } from "../../blocks/projecttemplates/src/ProjectTemplatesController";


const useStyles = makeStyles({
    card: {
        display: "flex",
        flexDirection: "column",
        gap: 6,
        width: 100,
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
        '& > p': {
            margin: "0px 6px",
            fontFamily: "Cairo",
            fontWeight: 600,
            fontSize: 14,
            lineHeight: "18px",
            color: "#0F172A",
            textAlign: "center",
        },
    },
    templateBox: {
        position: "relative",
        width: 100,
        height: 100,
        boxShadow: "0px 2px 8px 0px #00000014",
        display: "grid",
        placeItems: "center",
        border: "1px solid #CBD5E1",
        borderRadius: 12,
    },
    verticalIconButton: {
        all: "unset",
        cursor: "pointer",
        position: "absolute",
        zIndex: 2,
        top: 4,
        right: 4,
        height: 32,
        width: 32,
        borderRadius: 24,
        padding: 4,
        boxSizing: "border-box",
        color: "#475569",
        '&[aria-haspopup="true"]': {
            backgroundColor: "#0F172A8C",
            color: '#FFFFFF',
        },
    },
    moveToCardContainer: {
        position: "fixed",
        zIndex: -1,
        backgroundColor: "#FFFFFF",
        padding: 4,
        border: "1px solid #E2E8F0",
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
        display: "flex",
        flexDirection: "column",
        opacity: 0,
        gap: 2,
        maxWidth: 200,
        boxSizing: "border-box",
        borderRadius: 8,
        '& > button': {
            all: "unset",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            gap: 8,
            padding: "7px 8px",
            fontFamily: "Cairo",
            fontSize: 14,
            lineHeight: "22px",
            color:"#0F172A",
            fontWeight: 400,
            '&:hover': {
                borderRadius: 8,
                backgroundColor: "#F1F5F9",
            },
        },
        '&[data-show="true"]': {
            opacity: 1,
            zIndex: 2000,
        },
    },
})

const cardTheme = createTheme({
    overrides: {
        MuiPopover: theme.MuiPopover,
        MuiPaper: theme.MuiPaper,
    },
})

type Nodes = Array<{id: string, source: string[]}>;

type TemplateCardProps = {
    id: number;
    name: string;
    nodes: Array<{id: string, source: string[]}>;
    onDeleteTemplate: (templateId: number) => void;
    onEditTemplate: (templateId: number) => void;
    categories: Category[];
    handleAddConnectorCardsFromTemplateToChatbot: (nodeIds: number[]) => void;
    onTemplateMoveToAnotherCategory: (args: Omit<MoveTemplateFromCategoryToAnotherCategory, "fromCategory">) => void;
}

function buildChain(startNode: Nodes[number], sourceToNode: Map<string, Nodes>, visited: Set<string| number>) {
    const chain = [startNode];
    let queue = [startNode];
    visited.add(startNode.id)


    while (queue.length > 0) {
        const currentNode = queue.shift();
        const currentId = currentNode!.id;

        if (sourceToNode.has(currentId)) {
            sourceToNode.get(currentId)!.forEach((nextNode) => {
                if (!visited.has(nextNode.id)) {
                    chain.push(nextNode);
                    queue.push(nextNode);
                    visited.add(nextNode.id)
                }
            });
        }
    }

    return chain;
}

function buildChains(nodes: Nodes, sourceToNode: Map<string, Nodes>) {
    const chains: number[] = [];
    const visited = new Set<string>();

    nodes.forEach((node) => {
        if (!visited.has(node.id)) {
            const chain = buildChain(node, sourceToNode, visited);
            const newChain = chain.filter((node) => node.id !== "start").map(node => +node.id)
            chains.push(...newChain);
        }
    });

    return chains;
}

export default function TemplateCard({
    id, 
    name, 
    onDeleteTemplate,
    onEditTemplate,
    categories,
    onTemplateMoveToAnotherCategory,
    nodes,
    handleAddConnectorCardsFromTemplateToChatbot
}: TemplateCardProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const classes = useStyles()
    const moveToBtnRef = useRef<HTMLButtonElement>(null)
    const [showMoveToCatgeories, setShowMoveToCategories] = useState(false)
    const categoriesRef = useRef<HTMLDivElement>(null)
    const [categoriesPosition, setCategoriesPosition] = useState<{top: undefined | number, left: undefined | number}>({top: undefined,left: undefined})

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClosePopover = () => {
        setAnchorEl(null)
        setShowMoveToCategories(false)
    }

    const handleShowCategoryOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        const domRect = moveToBtnRef.current!.getBoundingClientRect?.()
        const domRectRight = domRect.right
        const domRectTop= domRect.top
        const categoriesOffsetHeight = categoriesRef.current?.offsetHeight ?? 0
        const top = window.innerHeight - domRectTop > categoriesOffsetHeight + 30 ? domRectTop : domRectTop - categoriesOffsetHeight + 42
        const left = window.innerWidth - domRectRight > 200 ? domRectRight + 7 : domRectRight - 212
        setShowMoveToCategories(true)
        setCategoriesPosition({top,left})
    }

    const onTemplateClick = () => {
        const sourceToNode = new Map<string, TemplateCardProps["nodes"]>();
        nodes.forEach((node) => {
            node.source.forEach((src) => {
                if (!sourceToNode.has(src)) {
                    sourceToNode.set(src, []);
                }
                sourceToNode.get(src)!.push(node);
            });
        });
        const nodeIds = buildChains([{id: "start", source: []}, ...nodes], sourceToNode)
        handleAddConnectorCardsFromTemplateToChatbot(nodeIds)
    }

    
    return (
        <ThemeProvider theme={cardTheme} >
            <div className={classes.card} onClick={onTemplateClick} >
                <div className={classes.templateBox} >
                    <button 
                        aria-controls={`template-${id}`}
                        aria-haspopup={Boolean(anchorEl)}
                        aria-label={`show ${name} action list`}
                        data-testid={`show ${name}`}
                        onClick={handleOpenPopover}
                        className={classes.verticalIconButton} 
                    >
                        <MoreVert htmlColor="currentColor" />
                    </button>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V21C3 22.1 3.9 23 5 23H19C20.1 23 21 22.1 21 21V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM5 20C5 20.55 5.45 21 6 21H18C18.55 21 19 20.55 19 20V6C19 5.45 18.55 5 18 5H17V6C17 7.1 16.1 8 15 8H9C7.9 8 7 7.1 7 6V5H6C5.45 5 5 5.45 5 6V20Z" fill="#475569" />
                    </svg>
                </div>
                <p>{name}</p>
            </div>
            <Popover
                open={Boolean(anchorEl)}
                id={`template-${id}`}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                data-testid={`${name} popover`}
            >
                <button 
                    data-testid={`edit ${name}`} 
                    onClick={(event) => {
                        event.stopPropagation()
                        onEditTemplate(id)
                        handleClosePopover()
                    }}
                >
                    <Edit htmlColor="#0F172A" />
                    Edit
                </button>
                <button  
                    onClick={() => {
                        onDeleteTemplate(id)
                        handleClosePopover()
                    }}
                    data-testid={`delete ${name}`}
                >
                    <DeleteOutlineRounded htmlColor="#0F172A" />
                    Delete
                </button>
                <button data-testid={`move ${name} to category`} data-active={showMoveToCatgeories} ref={moveToBtnRef} onClick={handleShowCategoryOptions} >
                    <ArrowRightAltRounded htmlColor="#0F172A" />
                    Move
                </button>
                <div ref={categoriesRef} data-testid="move to categories list" className={classes.moveToCardContainer} data-show={showMoveToCatgeories} style={{...categoriesPosition}} >
                    {
                        categories.map((category) => (
                            <button 
                                key={category.id} 
                                data-testid={`move to ${category.name}`}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    const newTemplate: Template = {id, name, nodes}
                                    onTemplateMoveToAnotherCategory({template: newTemplate, toCategory: category})
                                }} 
                            >
                                {category.name}  
                            </button>
                        ))
                    }
                </div>
            </Popover>
        </ThemeProvider>
    )
}
