import React, { useState } from "react";
import { IconButton, Popover, makeStyles } from "@material-ui/core";
import { ChevronLeft, ChevronRight, KeyboardArrowDown } from '@material-ui/icons';
import {
    startOfMonth,
    endOfMonth,
    startOfYear,
    endOfYear,
    startOfWeek,
    endOfWeek,
    subMonths,
    subWeeks,
    subYears,
    eachDayOfInterval,
    format,
    subDays,
} from "date-fns";
import { Calendar, DateObject } from "react-multi-date-picker";

const useStyles = makeStyles({
    container: {
        display: "flex",
        gap: 4,
        padding: 4,
        backgroundColor: "#F8FAFC",
        borderRadius: 50,
    },
    filterBtn: {
        all: "unset",
        cursor: "pointer",
        padding: "2px 12px",
        borderRadius: 50,
        display: "flex",
        alignItems: "center",
        gap: 4,
        border: "1px solid #F8FAFC",
        color: "#64748B",
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "22px",
        '&[aria-selected="true"]': {
            borderColor: "#51ABB3",
            backgroundColor: "#E3EFFD",
            color: "#51ABB3"
        },
    },
    calendarPopover: {
        boxSizing: "border-box",
        width: 364,
        maxWidth: 364,
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
        border: "1px solid #E2E8F0",
        backgroundColor: "#FFFFFF",
        borderRadius: 8,
        padding: 24,
        scrollbarWidth: "none",
        '&::-webkit-scrollbar': {
            width: 0,
        },
        '& > div': {
            display: "flex",
            flexDirection: "column",
            gap: 16,
            '& > h4': {
                margin: 0,
                fontFamily: "Cairo",
                fontWeight: 700,
                fontSize: 14,
                lineHeight: "22px",
                color: "#64748B"
            },
        },
    },
    calendarSelectBtnContainer: {
        display: "flex",
        gap: 10,
        padding: 2,
        borderRadius: 50,
        backgroundColor: "#F8FAFC",
        '& > button': {
            all: "unset",
            cursor: "pointer",
            flex: 1,
            fontFamily: "Cairo",
            textAlign: "center",
            fontSize: 14,
            fontWeight: 700,
            color: "#64748B",
            lineHeight: "22px",
            padding: "3px 30px",
            '&[aria-selected="true"]': {
                color: "#51ABB3",
                backgroundColor: "#E4F0FE",
                borderRadius: 50
            },
        },
    },
    calendarFooterBtn: {
        display: "flex",
        gap: 8,
        '& > button': {
            all: "unset",
            cursor: "pointer",
            flex: 1,
            textAlign: "center",
            color: "#51ABB3",
            backgroundColor: "#E5EBFF",
            borderRadius: 8,
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: 16,
            LineHeight: "24px",
            padding: "6px 10px",
            '&.primary': {
                backgroundColor: "#51ABB3",
                color: "#FFFFFF",
            },
        },
    },
    calendar: {
        '& .rmdp-wrapper': {
            width: "auto",
            borderRadius: 0,
        },
        '& .rmdp-border': {
            border: 0,
        },
        '& .rmdp-calendar': {
            padding: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
        },
        '& .rmdp-ym': {
            height: "auto",
            gap: 2,
        },
        '& .rmdp-day, & .rmdp-week-day': {
            height: 44,
            width: "auto",
            flex: 1,
            cursor: "pointer",
            position: "relative",
            color: "#0F172A",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        '& .rmdp-week-day': {
            height: 20,
            color: "#64748B",
            fontSize: 14,
            fontFamily: "Cairo",
            fontWeight: 400,
            lineHeight: "22px",
        },
    },
    arrowIconBtn: {
        padding: 0,
        '&:hover': {
            backgroundColor: "transparent"
        },
    },
})

type DateRange = {
    startDate: Date;
    endDate: Date;
} | undefined;

type ActiveFilter = null | "last-month" | "last-week" | "last-year" | "custom"

type PatientFilterByDateProps = {
    onLastYearSelect: (dates: string[]) => void;
    onDateRangeSelect: (startDate: Date, endDate: Date) => void;
}

export default function PatientFilterByDate({
    onDateRangeSelect,
    onLastYearSelect
}: PatientFilterByDateProps) {
    const classes = useStyles()
    const [activeFilter, setActiveFilter] = useState<ActiveFilter>(null)
    const [dateRange, setDateRange] = useState<DateRange>(undefined)
    const [selectedPicker, setSelectedPicker] = useState<"days" | "week" |"month">("days")
    const [calendarPopoverAnchorEl, setCalendarPopoverAnchorEl] = useState<HTMLButtonElement | null>(null)


    const onLastMonthClick = () => {
        const currentDate = new Date()
        const lastMonth = subMonths(currentDate, 1)
        const startOfLastMonth = startOfMonth(lastMonth)
        const endOfLastMonth = endOfMonth(lastMonth)
        setDateRange({ startDate: startOfLastMonth, endDate: endOfLastMonth })
        setActiveFilter("last-month")
        onDateRangeSelect(startOfLastMonth, endOfLastMonth)
    }

    const onLastWeekClick = () => {
        const currentDate = new Date()
        const lastWeek = subWeeks(currentDate, 1)
        const startOfLastWeek = startOfWeek(lastWeek)
        const endOfLastWeek = endOfWeek(lastWeek)
        setDateRange({ startDate: startOfLastWeek, endDate: endOfLastWeek })
        setActiveFilter("last-week")
        onDateRangeSelect(startOfLastWeek, endOfLastWeek)
    }

    const onLastYearClick = () => {
        setDateRange(undefined)
        setActiveFilter("last-year")
        onLastYearSelect(["year"])
    }

    const onOverAllClick = () => {
        setActiveFilter(null)
        const endDate = new Date();
        const startDate = subDays(endDate, 9)
        onDateRangeSelect(startDate, endDate)
    }

    const onCustomButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setActiveFilter("custom")
        setCalendarPopoverAnchorEl(event.currentTarget)
        setDateRange(undefined)
    }

    const onCalendarPopoverClose = () => {
        setCalendarPopoverAnchorEl(null)
        setDateRange(undefined)
    }

    const renderButton = (direction: "left" | "right", handleClick: React.MouseEventHandler<HTMLButtonElement>) => {
        return (
            <IconButton className={classes.arrowIconBtn} onClick={handleClick}>
                {direction === "left" ? (
                    <ChevronLeft htmlColor="#94A3B8" />
                ) : (
                    <ChevronRight htmlColor="#94A3B8" />
                )}
            </IconButton>
        )
    }

    const onSelectPicker = (picker: typeof selectedPicker) => () => {
        setSelectedPicker(picker)
        setDateRange(undefined)
    }

    const onWeekOrDaysChange = (date: DateObject | DateObject[] | null) => {
        if(!date ||  !Array.isArray(date)) {
            return;
        }

        if(date.length !== 2) {
            return;
        }

        const startDate = new Date(date[0].format())
        const endDate = new Date(date[1].format())
        setDateRange({startDate, endDate})
    }

    const onMonthChange = (date: DateObject | DateObject[] | null) => {
        if(date && !Array.isArray(date)) {
            const {year, month: {number}} = date
            const newDate = new Date(year, number-1)
            const newMonth = subMonths(newDate, 0)
            const startDateOfNewMonth = startOfMonth(newMonth)
            const endDateOfNewMonth = endOfMonth(newMonth)
            setDateRange({startDate: startDateOfNewMonth, endDate: endDateOfNewMonth})
        }
    }

    const onDoneClick = () => {
        if(!dateRange) {
            return;
        }
        setCalendarPopoverAnchorEl(null)
        onDateRangeSelect(dateRange.startDate, dateRange.endDate)
    }


    return (
        <div className={classes.container} >
            <button aria-selected={activeFilter === null} className={classes.filterBtn} onClick={onOverAllClick} >Overall</button>
            <button aria-selected={activeFilter === "last-month"} className={classes.filterBtn} onClick={onLastMonthClick} >Last month</button>
            <button aria-selected={activeFilter === "last-week"} className={classes.filterBtn} onClick={onLastWeekClick} >Last week</button>
            <button aria-selected={activeFilter === "last-year"} className={classes.filterBtn} onClick={onLastYearClick} >Last year</button>
            <button aria-selected={activeFilter === "custom"} className={classes.filterBtn} onClick={onCustomButtonClick} >
                Custom
                <KeyboardArrowDown htmlColor="currentColor" style={{ height: 22, width: 22 }} />
            </button>
            <Popover
                open={Boolean(calendarPopoverAnchorEl)}
                anchorEl={calendarPopoverAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={onCalendarPopoverClose}
                PaperProps={{className: classes.calendarPopover}}
            >
                <div>
                    <h4>Select Date</h4>
                    <div className={classes.calendarSelectBtnContainer} >
                        <button aria-selected={selectedPicker === "days"} onClick={onSelectPicker("days")} >Days</button>
                        <button aria-selected={selectedPicker === "week"} onClick={onSelectPicker("week")} >Week</button>
                        <button aria-selected={selectedPicker === "month"} onClick={onSelectPicker("month")} >Month</button>
                    </div>
                    <div className={classes.calendar} >
                        {
                            (selectedPicker === "days" || selectedPicker === "week") ? (
                                <Calendar 
                                    key={selectedPicker}
                                    range={true}
                                    showOtherDays={true}
                                    shadow={false}
                                    highlightToday={false}
                                    hideWeekDays={false}
                                    monthYearSeparator={" "}
                                    weekStartDayIndex={1}
                                    renderButton={renderButton}
                                    rangeHover={true}
                                    disableMonthPicker={true}
                                    disableYearPicker={true}
                                    weekPicker={selectedPicker === "week"}
                                    onChange={onWeekOrDaysChange}
                                />
                            ) : null
                        }
                        {
                            selectedPicker === "month" ? (
                                <Calendar 
                                    key={selectedPicker}
                                    range={false}
                                    shadow={false}
                                    highlightToday={false}
                                    renderButton={renderButton}
                                    disableMonthPicker={false}
                                    disableYearPicker={true}
                                    onlyMonthPicker
                                    hideMonth
                                    onChange={onMonthChange}
                                />
                            ) : null
                        }
                    </div>
                    <div className={classes.calendarFooterBtn} >
                        <button onClick={onCalendarPopoverClose} >Cancel</button>
                        <button className="primary" onClick={onDoneClick} >Done</button>
                    </div>
                </div>
            </Popover>
        </div>
    )
}
