import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import SocialMediaAccountLoginAdapter from "../../blocks/adapters/src/SocialMediaAccountLoginAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import SignupLoginAdapter from "../../blocks/adapters/src/SignupLoginAdapter";
import NewPasswordAdapter from "../../blocks/adapters/src/NewPasswordAdapter";
import BotsAdapter from "../../blocks/adapters/src/BotsAdapter";
import IndividualBotAdapter from "../../blocks/adapters/src/IndividualBotAdapter";
import BlogpostsManagementAdapter from "../../blocks/adapters/src/BlogpostsManagementAdapter";
import UserGuideAdapter from "../../blocks/adapters/src/UserGuideAdapter";
import UserProfileAdapter from "../../blocks/adapters/src/UserProfileAdapter";
import VisualAnalyticsAdapter from "../../blocks/adapters/src/VisualAnalyticsAdapter";
import AuditAdapter from "../../blocks/adapters/src/AuditAdapter";
import ChatsAdapter from "../../blocks/adapters/src/ChatsAdapter";
import DashboardAdapter from "../../blocks/adapters/src/DashboardAdapter";
import FormsAdapter from "../../blocks/adapters/src/FormsAdapter";
import QAAdapter from "../../blocks/adapters/src/QAAdapter";
import SignpostingAdapter from "../../blocks/adapters/src/SignpostingAdapter";
import AutomaticFormCreationAdapter from "../../blocks/adapters/src/AutomaticFormCreationAdapter";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { getStorageData } from "../../framework/src/Utilities";
import AutomaticEditFormAdapter from "../../blocks/adapters/src/AutomaticEditFormAdapter";
import SettingsAdapter from "../../blocks/adapters/src/SettingsAdapter";
import SignpostingCreateAdapter from "../../blocks/adapters/src/SignPostingCreateAdapter";
import SignpostingEditAdapter from "../../blocks/adapters/src/SignPostingEditAdapter";
import EditUserGuideAdapter from "../../blocks/adapters/src/EditUserGuideAdapter";
import QAEditAdapter from "../..//blocks/adapters/src/QAEditAdapter";
import QACreateAdapter from "../../blocks/adapters/src/QACreateAdapter";
import ProjectTemplatesEditAdapter from "../../blocks/adapters/src/ProjectTemplatesEditAdapter";
import TrainingHubAdapter from "../../blocks/adapters/src/TrainingHubAdapter";
import EndUserChatAdapter from "../../blocks/adapters/src/EndUserChatBotAdapter";

//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const socialMediaAccountLoginAdapter = new SocialMediaAccountLoginAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
const signupLoginAdapter = new SignupLoginAdapter();
const newPasswordAdapter = new NewPasswordAdapter();
const botsAdapter = new BotsAdapter();
const blogpostsManagementAdapter = new BlogpostsManagementAdapter();
const individualBotAdapter = new IndividualBotAdapter();
const userGuideAdapter = new UserGuideAdapter();
const userProfileAdapter = new UserProfileAdapter();
const visualAnalyticsAdater = new VisualAnalyticsAdapter();
const auditAdapter = new AuditAdapter();
const chatsAdapter = new ChatsAdapter();
const dashboardAdapter = new DashboardAdapter();
const formsAdapter = new FormsAdapter();
const qaAdapter = new QAAdapter();
const qaCreateAdapter = new QACreateAdapter();
const qaEditAdapter = new QAEditAdapter();
const signpostingAdapter = new SignpostingAdapter();
const automaticFormCreationAdapter = new AutomaticFormCreationAdapter()
const automaticEditFormController = new AutomaticEditFormAdapter()
const signPostingCreateAdapter = new SignpostingCreateAdapter()
const signPostingEditAdapter = new SignpostingEditAdapter()
const settingsAdapter = new SettingsAdapter()
const editUserGuideAdapter = new EditUserGuideAdapter()
const projectTemplatesEditAdapter = new ProjectTemplatesEditAdapter()
const trainingHubAdapter = new TrainingHubAdapter()
const endUserChat = new EndUserChatAdapter()
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;

    this.subScribedMessages = [];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    const msg: Message = new Message(
      getName(token ? MessageEnum.NavigationDashboardMessage : MessageEnum.NavigationSignupLoginMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to MaxchatbotApp!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'blogpostsmanagement'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'utilities'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'CustomProfileHeader'}  onPress={() => navigation.navigate("CustomProfileHeader")} />
<CustomTextItem content={'Maps'}  onPress={() => navigation.navigate("Maps")} />
<CustomTextItem content={'ThemeBlock'}  onPress={() => navigation.navigate("ThemeBlock")} />
<CustomTextItem content={'LandingPage'}  onPress={() => navigation.navigate("LandingPage")} />
<CustomTextItem content={'NavigationMenu'}  onPress={() => navigation.navigate("NavigationMenu")} />
<CustomTextItem content={'SocialMediaAccountRegistrationScreen'}  onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
<CustomTextItem content={'SocialMediaAccountLogin'}  onPress={() => navigation.navigate("SocialMediaAccountLogin")} />
<CustomTextItem content={'EmailAccountLoginBlock'}  onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'CountryCodeSelector'}  onPress={() => navigation.navigate("CountryCodeSelector")} />
<CustomTextItem content={'ForgotPassword'}  onPress={() => navigation.navigate("ForgotPassword")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'SocialMediaAccountLoginScreen'}  onPress={() => navigation.navigate("SocialMediaAccountLoginScreen")} />
<CustomTextItem content={'AdvancedSearch'}  onPress={() => navigation.navigate("AdvancedSearch")} />
<CustomTextItem content={'dashboard'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'VideoEmbeddingYoutube'}  onPress={() => navigation.navigate("VideoEmbeddingYoutube")} />
<CustomTextItem content={'TermsConditions'}  onPress={() => navigation.navigate("TermsConditions")} />
<CustomTextItem content={'PostCreation'}  onPress={() => navigation.navigate("PostCreation")} />
<CustomTextItem content={'Categoriessubcategories'}  onPress={() => navigation.navigate("Categoriessubcategories")} />
<CustomTextItem content={'contexttagging'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'UserProfileBasic'}  onPress={() => navigation.navigate("UserProfileBasic")} />
<CustomTextItem content={'EducationalUserProfile'}  onPress={() => navigation.navigate("EducationalUserProfile")} />
<CustomTextItem content={'multipageforms'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'contentmanagement'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'productdescription'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'projecttemplates'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'customform'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'Translation'}  onPress={() => navigation.navigate("Translation")} />
<CustomTextItem content={'Analytics'}  onPress={() => navigation.navigate("Analytics")} />
<CustomTextItem content={'reviewprompt'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'ImportExportData'}  onPress={() => navigation.navigate("ImportExportData")} />
<CustomTextItem content={'Adminconsole2'}  onPress={() => navigation.navigate("Adminconsole2")} />
<CustomTextItem content={'Uploadmedia3'}  onPress={() => navigation.navigate("Uploadmedia3")} />
<CustomTextItem content={'Settings2'}  onPress={() => navigation.navigate("Settings2")} />
<CustomTextItem content={'Chatbot5'}  onPress={() => navigation.navigate("Chatbot5")} />
<CustomTextItem content={'Cfprojecttemplates2'}  onPress={() => navigation.navigate("Cfprojecttemplates2")} />
<CustomTextItem content={'Cftoggledraftvspublishedchatbot'}  onPress={() => navigation.navigate("Cftoggledraftvspublishedchatbot")} />
<CustomTextItem content={'Cfconnectorlogic'}  onPress={() => navigation.navigate("Cfconnectorlogic")} />
<CustomTextItem content={'Cfgoogledialogflowapiintegration'}  onPress={() => navigation.navigate("Cfgoogledialogflowapiintegration")} />
<CustomTextItem content={'Cfpowerbiapiintegration'}  onPress={() => navigation.navigate("Cfpowerbiapiintegration")} />
<CustomTextItem content={'Catalogue1'}  onPress={() => navigation.navigate("Catalogue1")} />
<CustomTextItem content={'Adminconsole'}  onPress={() => navigation.navigate("Adminconsole")} />
<CustomTextItem content={'Settings'}  onPress={() => navigation.navigate("Settings")} />
<CustomTextItem content={'Signuplogin'}  onPress={() => navigation.navigate("Signuplogin")} />
<CustomTextItem content={'Termsandconditions'}  onPress={() => navigation.navigate("Termsandconditions")} />
<CustomTextItem content={'Contentmanagement1'}  onPress={() => navigation.navigate("Contentmanagement1")} />
<CustomTextItem content={'Dashboard4'}  onPress={() => navigation.navigate("Dashboard4")} />
<CustomTextItem content={'Analytics1'}  onPress={() => navigation.navigate("Analytics1")} />

<CustomTextItem content={'chatbot'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Customiseinterface2'}  onPress={() => navigation.navigate("Customiseinterface2")} />

          </View>
        </ScrollView>  
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;