Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customiseinterface2";
exports.labelBodyText = "customiseinterface2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.practiceDataEndpoint = "bx_block_catalogue/tags/practice";
exports.designSettingsEndpoint = "bx_block_customiseinterface2/chatbots";
exports.generalSettingsEndpoint = "bx_block_settings/settings";
exports.designSettingsUpdateEndpoint = "bx_block_customiseinterface2/chatbots";
exports.generalSettingsUpdateEndpoint = "bx_block_settings/settings";
// Customizable Area End