import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  OutlinedInput,
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CardHeader from "../../chatbot5/src/components/CardHeader.web";
import CardFooter from "../../chatbot5/src/components/CardFooter.web";
import MessageTextArea from "../../chatbot5/src/components/MessageTextArea.web";
import { Formik } from "formik";
import {
  theme as cardTheme,
} from "../../chatbot5/src/theme.web";
import {AddCircleOutlineRounded, DeleteOutlineRounded} from "@material-ui/icons";
import CustomSelect from "../../../components/src/CustomSelect.web";
import { shareLinkValidationSchema } from "../../chatbot5/src/components/ShareLink.web"

const {
  MuiFormLabelRootStyle,
  MuiTypographyStyle,
  MuiOutlinedInputStyle,
  MuiInputBaseRootStyle,
  drawerCard,
} = cardTheme

const FormErrorOrInfo = styled("div")({
  display: "flex",
  gap: 4,
  justifyContent: "space-between",
  '&.error': {
      color: "#D97706",
  },
})

const ActionBox = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 2,
  padding: "16px",
  backgroundColor: "#F6F7FF",
  borderRadius: 6,
  boxShadow: "0px 2px 8px 0px #00000014",
  '& .flex': {
    display: "flex",
  },
  '& .gap-4': {
    gap: 4
  },
  '& .items-center': {
    alignItems: "center",
  },
  '& .flex-column': {
    flexDirection: "column",
  },
  '& .title': {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 14,
    color: "#334155",
    lineHeight: "14px",
  },
  '& .action-text': {
    margin: 0,
    color: "#0F172A",
    fontFamily: "Cairo",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "24px",
  },
  '&[aria-expanded="true"]': {
    gap: 8,
    '& .title': {
      lineHeight: "24px"
    },
  },
})

const openaiTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: MuiTypographyStyle,
  overrides: {
    MuiFormLabel: {
      root: MuiFormLabelRootStyle,
    },
    MuiOutlinedInput: MuiOutlinedInputStyle,
    MuiInputBase: MuiInputBaseRootStyle,
    MuiIconButton: {
        root: {
            padding: 0,
        },
    },
    MuiContainer: {
      root: {
        width: 440,
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: "#FFFFFF",
        display: "flex",
        height: "100%",
        overflowY: "auto",
        flexDirection: "column",
        scrollbarWidth: "none",
        paddingRight: 0,
        paddingLeft: 0,
        '&::-webkit-scrollbar': {
          width: 0,
        },
        "@media (min-width: 600px)": {
          paddingLeft: 0,
          paddingRight: 0,
        },
        '& .content': {
          flexGrow: 1,
          backgroundColor: "#FFFFFF",
          display: "flex",
          gap: 12,
          padding: "0px 48px 24px",
          flexDirection: "column",
        },
        '& .form-control': {
          display: "flex",
          gap: 4,
          flexDirection: "column",
          '&.gap-8': {
            gap: 8,
          },
          '&.items-start': {
            alignItems: "flex-start",
          },
          '& .add-action': {
            ...drawerCard.addLinkOrOptionButton,
            fontFamily: "Cairo",
          },
        },
      },
    },
  },
});
// Customizable Area End

import Cfmicrosoftazureopenaiintegration1Controller, {
  Props,
  configJSON,
} from "./Cfmicrosoftazureopenaiintegration1Controller";

export default class Cfmicrosoftazureopenaiintegration1 extends Cfmicrosoftazureopenaiintegration1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  hasError = (error: string | string[] | undefined, touched: boolean | undefined) => {
    return !!error && !!touched
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={openaiTheme}>
        <Container>
          <CardHeader id={this.props.cardId} onClose={this.props.onClose} title=" Open AI integration" />
          <Formik
            initialValues={{name: this.props.name ?? "", message: this.props.message ?? ""}}
            onSubmit={this.handleOpenAiSubmit}
            validationSchema={shareLinkValidationSchema}
            data-testid="formik"
          >
            {({handleSubmit, getFieldProps, errors, touched, values, setFieldValue}) => (
              <>
                <div className="content" >
                  <div className="form-control" >
                    <InputLabel htmlFor="name" >Name*</InputLabel>
                    <OutlinedInput
                      inputProps={{ ...getFieldProps("name"), maxLength: 50 }}
                      placeholder="Open AI tile"
                      error={this.hasError(errors.name, touched.name)}
                      fullWidth
                      id="name"
                      name="name"
                    />
                    <FormErrorOrInfo>
                      {
                          this.hasError(errors.name, touched.name) && <Typography className="error" variant="subtitle2" >{errors.name}</Typography>
                      }
                      <Typography variant="subtitle2" className="ml-auto" >{values.name.trim().length}/50</Typography>
                    </FormErrorOrInfo>
                  </div>
                  <MessageTextArea 
                    data-testid="openai-message"
                    getFieldProps={getFieldProps}
                    hasMessageError={this.hasError(errors.message, touched.message)}
                    messageErrorText={errors.message}
                    value={values.message}
                    botContext={this.props.botContext}
                    setFieldValue={setFieldValue}
                    description="This card will enable the user to enter free text input, and the chatbot will respond to it based on its training"
                  />
                  <div className="form-control gap-8" data-testid="actions" >
                    <InputLabel htmlFor="name" >Follow-up action(s) ({this.state.followUpActions.length})*</InputLabel>
                    <Typography variant="subtitle2" >
                      Here you can re-direct users back to the main chatbot flow, without the use of AI
                    </Typography>
                    {
                      this.state.followUpActions.map((action, actionIndex) => (
                        <ActionBox key={action.id} aria-expanded={action.isExpanded} onClick={this.onActionExpandToggle(action.id)} >
                          <div className="flex gap-4 items-center">
                            <div className="title" >{`Follow-up action ${actionIndex + 1}`}</div>
                            {!action.isExpanded && (
                              <IconButton aria-label={`delete action ${actionIndex + 1}`} onClick={this.onActionDelete(action.id)} >
                                <DeleteOutlineRounded htmlColor="#0F172A"style={{height: 18, width: 18}} />
                              </IconButton>
                            )}
                          </div>
                          {
                            action.isExpanded ? (
                              <>
                                <div className="flex flex-column gap-4">
                                  <InputLabel id={`action-${action.id}`} >Redirect user to card*</InputLabel>
                                  <CustomSelect 
                                    id={`action-${action.id}`}
                                    hasSearch={true}
                                    searchPlaceholder="Search..."
                                    selectBoxWidth={312}
                                    placeholder="Select a card"
                                    value={action.redirectCardId}
                                    options={this.actionCards}
                                    labelId={`action-${action.id}`}
                                    onChange={this.onActionCardChange(action.id)}
                                    error={action.cardIdError}
                                  />
                                </div>
                                <div className="flex flex-column gap-4" >
                                  <InputLabel htmlFor={`action-text-${action.id}`} >Button Text*</InputLabel>
                                  <OutlinedInput
                                    inputProps={{ maxLength: 50 }}
                                    placeholder="Please add a button text"
                                    error={!!action.buttonTextError}
                                    fullWidth
                                    id={`action-text-${action.id}`}
                                    name={`action-text-${action.id}`}
                                    value={action.buttonText}
                                    onChange={this.onActionButtonTextChange(action.id)}
                                    onClick={(event) => {event.stopPropagation()}}
                                  />
                                </div>
                              </>
                            ) : (
                              <p className="action-text">{action.buttonText}</p>
                            )
                          }
                        </ActionBox>
                      ))
                    }
                    <button type="button" className="add-action" onClick={this.onAddAction} >
                      <AddCircleOutlineRounded htmlColor="#64748B" style={{height: 20, width: 20}} />
                      Add Follow-up action
                    </button>
                  </div>
                </div>
                <CardFooter 
                  onClose={this.props.onDrawerClose} 
                  onSaveClick={() => handleSubmit()} 
                  loading={this.props.loading} 
                />
              </>
            )}
          </Formik>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
