import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  forgotPasswordError: string | null;
  emailSent: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  apiForgotPasswordCallId:any;
  otpToken: any;
  isChangePassword: boolean = false;

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      forgotPasswordError: null,
      emailSent: false,
    };

    this.handleAuthTokenBavigationPayloadMessage = this.handleAuthTokenBavigationPayloadMessage.bind(this)
    this.handleValidationApiResponse = this.handleValidationApiResponse.bind(this)
    this.handleOtpResponse = this.handleOtpResponse.bind(this)
    this.handleGoToConfirmationCall = this.handleGoToConfirmationCall.bind(this)
    this.handleCountryCodeMessage = this.handleCountryCodeMessage.bind(this)
    this.handleRestAPIResponse = this.handleRestAPIResponse.bind(this)
    this.handleForgotPasswordResponse = this.handleForgotPasswordResponse.bind(this)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const handlers = {
      [getName(MessageEnum.NavigationPayLoadMessage)]: this.handleAuthTokenBavigationPayloadMessage,
      [getName(MessageEnum.CountryCodeMessage)]: this.handleCountryCodeMessage,
      [getName(MessageEnum.RestAPIResponceMessage)]: this.handleRestAPIResponse,
    };
    const handler = handlers[message.id];
    if (handler) {
      handler(message);
    } 
    // Customizable Area End
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : "",
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : "",
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    // console.log("entered phone validation code");
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    console.log(this.state.countryCodeSelected);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : "",
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue,
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password,
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area Start

  handleRestAPIResponse(message: Message) {
    const responseId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseHandlers: Record<string, (newMsg: Message) => void> = {}
    if (this.validationAPICallId != null) {
      responseHandlers[this.validationAPICallId] = this.handleValidationApiResponse;
    }
    if (this.requestEmailOtpCallId != null) {
      responseHandlers[this.requestEmailOtpCallId] = (msg) => this.handleOtpResponse(msg, "email");
    }
    if (this.requestPhoneOtpCallId != null) {
      responseHandlers[this.requestPhoneOtpCallId] = (msg) => this.handleOtpResponse(msg, "phone");
    }
    if (this.requestGoToConfirmationCallId != null) {
      responseHandlers[this.requestGoToConfirmationCallId] = this.handleGoToConfirmationCall;
    }
    if (this.apiForgotPasswordCallId != null) {
      responseHandlers[this.apiForgotPasswordCallId] = this.handleForgotPasswordResponse;
    }
  
    const responseHandler = responseHandlers[responseId];
    if (responseHandler) {
      responseHandler(message);
    }
  }
  

  handleAuthTokenBavigationPayloadMessage(message: Message) {
    const otpAuthTkn = message.getData(
      getName(MessageEnum.AuthTokenDataMessage)
    );

    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ token: otpAuthTkn });
      if (this.isChangePassword) {
        this.setState({ accountStatus: "ChangePassword" });
      }
      this.otpToken = this.state.token;
    } else {
      const accountType = message.getData(
        getName(MessageEnum.NavigationForgotPasswordPageInfo)
      );
      if (accountType) {
        this.startForgotPassword(accountType);
      }
    }
  }

  handleValidationApiResponse(message: Message) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson) {
      return;
    }

    if (responseJson?.data[0]) {
      const passRegex = RegExp(
        responseJson.data[0].password_validation_regexp
      );
      const emailRegex = RegExp(responseJson.data[0].email_validation_regexp);
      const passwordRulesFromValidation =
        responseJson.data[0].password_validation_rules;

      this.setState({
        //email schema
        emailSchema: {
          email: Yup.string()
            .email(configJSON.pleaseEnterAValidEmail)
            .required(configJSON.emailIsRequired)
            .matches(emailRegex, configJSON.invalidEmailAddress),
        },
        //password schema
        passwordSchema: {
          password: Yup.string()
            .required(configJSON.pleaseEnterAPassword)
            .matches(passRegex, configJSON.invalidPassword),

          confirmPassword: Yup.string()
            .required(configJSON.pleaseConfirmYourPassword)
            .when("password", {
              is: (val) => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref("password")],
                configJSON.passwordsMustMatch
              ),
            }),
        },
        passwordRules: passwordRulesFromValidation,
      });
    }
  }

  handleOtpResponse(message: Message, type: string){
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (responseJson !== undefined && responseJson.meta && responseJson.meta.token) {
      this.otpToken = responseJson.meta.token;
      this.setState({ token: this.otpToken });
  
      const msg: Message = new Message(getName(MessageEnum.NavigationMobilePhoneOTPMessage));
      msg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.token);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  
      if (type === "email") {
        msg.addData(getName(MessageEnum.AuthTokenEmailMessage), this.state.emailValue);
      } else if (type === "phone") {
        msg.addData(getName(MessageEnum.AuthTokenPhoneNumberMessage), this.state.phoneValue);
      }
  
      msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);
      this.send(msg);
    } else if (responseJson && responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleGoToConfirmationCall(message: Message) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson !== undefined && responseJson.data) {
      this.setState({
        accountStatus: "Confirmation",
      });
    } else if (responseJson !== undefined && responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleCountryCodeMessage(message: Message) {
    const selectedCode = message.getData(
      getName(MessageEnum.CountyCodeDataMessage)
    );

    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode,
      });
    }
  }

  handleForgotPasswordResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson === undefined) {
      this.setState({ forgotPasswordError: "Internal server error" });
      return;
    }
    if (responseJson?.errors?.length > 0) {
      this.setState({ forgotPasswordError: "Email Id doesn't exist." });
      return;
    }
    this.setState({ forgotPasswordError: null, emailSent: true });
  }

  SignUpValidation = () => {
    const Regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return Yup.object().shape({
      emailValue: Yup.string()
        .matches(Regex, "Email ID is invalid")
        .email('Email ID is invalid')
        .required('This field is required'),
     
    });
  };
  sendRecoveryLink=()=>{
    const header = {
      "Content-Type": configJSON.forgotApiContentType,
    };

    const httpBody = {
      email:this.state.emailValue
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForgotPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleNavigateToLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSignupLoginMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  onForgotPasswordSubmit = (value: { emailValue: string }) => {
    this.setState({ emailValue: value.emailValue })
    this.sendRecoveryLink()
  }
  // Customizable Area End
}
