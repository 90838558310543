import { Modal, Switch, styled } from "@material-ui/core";

const FormFieldsContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    gap: 12,
    borderRadius: 12,
    boxShadow: "0px 2px 8px 0px #00000014",
    backgroundColor: "#FFFFFF",
    '& .title': {
        margin: 0,
        padding: 0,
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 20,
        lineHeight: "28px",
        color: "#0F172A",
    },
})
export const SearchContainer = styled("div")({
    border: "1px solid #CBD5E1",
    borderRadius: 98,
    backgroundColor: "#FFFFFF",
    padding: "10px 8px",
    width: 431,
    height: 44,
    display: "flex",
    gap: 8,
    boxSizing: "border-box",
})
export const SearchInput = styled("input")({
    border: 0,
    outline: 0,
    fontWeight: 400,
    fontSize: 16,
    fontFamily: "Cairo",
    lineHeight: 24,
    color: "#64748B",
    caretColor: "#94A3B8",
})
const StyledRow = styled("div")({
    display: "flex",
    '&.flex-1': {
        flex: 1,
    },
    '&.space-between': {
        justifyContent: "space-between",
    },
    '&.items-center': {
        alignItems: "center",
    },
    '&.flex-column':{
        flexDirection: "column"
    }
})
const Column = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    '&.gap-6': {
        gap: 6,
    },
    '&.gap-8': {
        gap: 8,
    },
    '&.gap-12': {
        gap: 12,
    },
})

export const DialogBox = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300,

    "& .dialog-body": {
        width: "430px",
        height: "276px",
        textAlign: "center",
        borderRadius: "8px",
        padding: "30px",
        boxSizing: "border-box",
        overflowY: "auto",
        background: "#fff",
        position: "relative",
    },
    "& .dialog-body h4": {
        fontSize: "24px",
        fontFamily: "Cairo",
        lineHeight: "32px",
        fontWeight: 700,
        color: "#0F172A",
        marginBottom: "40px"
    },
    "& .dialog-body .closePopup": {
        top: "30px",
        right: "30px",
        position: "absolute",
        background: "transparent",
        border: "none",
        padding: "0",
        cursor: "pointer"
    },
    "& .dialog-body p": {
        fontSize: "16px",
        fontFamily: "Cairo",
        color: "#000000",
        lineHeight: "24px",
        fontWeight: 400,
        marginBottom: "40px"
    },
    "& .dialog-body .Btn": {
        display: "inline-block",
        width: "167px",
        height: "44px",
        textAlign: "center",
        background: "#DBF0F2",
        borderRadius: "8px",
        padding: "10px",
        fontSize: "16px",
        fontFamily: "Cairo",
        lineHeight: "24px",
        fontWeight: 700,
        color: "#51ABB3",
        textTransform: "capitalize",
        margin: "5px"
    }
});


export const CustomSwitch = styled(Switch)(({ theme }) => ({
    padding: '8px',
    position: 'relative',
    width: "52px",
    height: "34px",
  
    '& .MuiSwitch-switchBase': {
      '&:not(.Mui-checked)': {
        color: '#FFFFFF'
      },
      '&:not(.Mui-checked) + .MuiSwitch-track': {
        backgroundColor: '#E2E8F0',
        borderColor: "#E2E8F0",
      },
    },
    '& span:.MuiButtonBase-root': {
      position: 'absolute',
      left: 0,
      top: 0,
    },
    '& .MuiIconButton-root': {
      padding: "11px !important",
      left: "-1 !important",
    }
  }));

export const theme = {
    SearchContainer,
    SearchInput,
    DialogBox,
    Column,
    FormFieldsContainer,
    StyledRow,
    CustomSwitch
}