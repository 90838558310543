import React from "react";
import {
    createTheme,
    ThemeProvider,
    makeStyles,
    Dialog,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

export type ChatbotErrorDialogProps = {
    children: React.ReactNode;
    open: boolean;
    onClose: () => void;
}

const qrcodeTheme = createTheme({
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: '#0F172A',
            },
        },
        MuiPaper: {
            root: {
                padding: 32,
                boxSizing: "border-box",
                backgroundColor: "#FFFFFF",
                '& .close-button': {
                    all: "unset",
                    cursor: "pointer",
                    padding: 4,
                    position: "absolute",
                    top: 32,
                    right: 32,
                },
            },
            rounded: {
                borderRadius: 8,
            },
            elevation24: {
                boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017"
            },
        },
        MuiDialog: {
            paperWidthSm: {
                width: 411,
                maxWidth: 411,
            },
        },
    },
})

const useStyles = makeStyles({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 8,
        '& h4, & p': {
            margin: 0,
        },
        '& h4': {
            fontSize: 24,
            fontFamily: 'Cairo',
            color: "#0F172A",
            lineHeight: "32px",
            fontWeight: 700,
            textAlign: "center",
        },
        '& p': {
            fontSize: 16,
            fontFamily: 'Cairo',
            color: "#000000",
            lineHeight: "24px",
            fontWeight: 400,
            textAlign: "center",
        },
        '& button': {
            all: "unset",
            cursor: "pointer",
            boxSizing: "border-box",
            width: 167,
            textAlign: 'center',
            padding: "10px 0",
            backgroundColor: "#DBF0F2",
            borderRadius: 8,
            color: "#51ABB3",
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: 16,
            lineHeight: "24px",
        },
        '& .primary-btn': {
            color: "#FFFFFF",
            backgroundColor: "#51ABB3",
        },
    },
    actionButton: {
        all: "unset",
        cursor: "pointer",
        boxSizing: "border-box",
        width: 105,
        padding: 16,
        borderRadius: 8,
        textAlign: "center",
        backgroundColor: "#E5F6FF",
        color: "#51ABB3",
        '&.primary': {
            backgroundColor: "#51ABB3",
            color: "#FFFFFF",
        },
    },
})

const ChatbotErrorDialog = ({
    children,
    open,
    onClose,
}: ChatbotErrorDialogProps) => {
    const classes = useStyles()

    return (
        <ThemeProvider theme={qrcodeTheme}>
            <Dialog
                open={open}
                PaperProps={{ className: "rounded-lg" }}
                BackdropProps={{ style: { opacity: 0.4 } }}
                onClose={onClose}
            >
                <button onClick={onClose} className="close-button" aria-label="close chatbot error dialog">
                    <Close htmlColor="#334155" />
                </button>
                <div className={classes.dialogContent} >
                    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" width="80" height="80" rx="40" fill="#DC2626" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.16602 40C7.16602 21.6 22.066 6.66669 40.466 6.66669C58.8994 6.66669 73.8327 21.6 73.8327 40C73.8327 58.4 58.8994 73.3334 40.466 73.3334C22.066 73.3334 7.16602 58.4 7.16602 40ZM43.8327 26.6667C43.8327 24.8334 42.3327 23.3334 40.4994 23.3334C38.666 23.3334 37.166 24.8334 37.166 26.6667V40C37.166 41.8334 38.666 43.3334 40.4994 43.3334C42.3327 43.3334 43.8327 41.8334 43.8327 40V26.6667ZM40.4993 66.6667C25.766 66.6667 13.8327 54.7334 13.8327 40C13.8327 25.2667 25.766 13.3334 40.4993 13.3334C55.2327 13.3334 67.166 25.2667 67.166 40C67.166 54.7334 55.2327 66.6667 40.4993 66.6667ZM37.166 50V56.6667H43.8327V50H37.166Z" fill="white" />
                    </svg>
                    {children}
                </div>
            </Dialog>
        </ThemeProvider>
    )
}

export default ChatbotErrorDialog
