// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { IProject } from "./types";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
const { NavigationActions, StackActions } = require("react-navigation");

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface SignPosting {
  id: number;
  type: string;
  attributes: {
    type_id: string;
    organisation_name: string;
    organisation_code: string;
    service_type: string;
    access_route: string;
    website_url: string;
    contact_number: string;
    street: string;
    town: string;
    postcode: string;
    country: string;
    criteria: string;
    tag_ids: number[];
    opening_times: string;
    practice: number[];
    is_global: boolean;
  }
}

interface S {
  isModalVisible: boolean;
  filterValue: string;
  isDeleteModalOpen: boolean;
  projects: IProject[];
  token: string;
  searchInputText: string;
  deleteProjectID: string;
  isFilterMenuOpen: Element | null;
  isDialogOpen: boolean;
  loading: boolean;
  pageNumber: number | null;

  signPostingData: SignPosting[];
  paginatedData: SignPosting[];
  isLoading: boolean;
  pagination: {
    total: number
  };
  showDeleteRowbtn: any;
  rowToDelete: any;
  rowToEdit: any;
  switchStates: any;
  openPopup: boolean;
  currentPage: number;
  itemsPerPage: number;
  query: string;
  initialRender: boolean;
  showMenu: boolean;
  anchorEl: any;
}

interface SS {
  id: any;
}

export default class ProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetSignPostingId: string | null = "";
  apiDeleteSignPostingId: string | null = "";
  timeout: ReturnType<typeof setTimeout> | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      initialRender: false,
      isLoading: false,
      isModalVisible: false,
      filterValue: "All Projects",
      isDeleteModalOpen: false,
      projects: [],
      token: "",
      searchInputText: "",
      deleteProjectID: "",
      isFilterMenuOpen: null,
      isDialogOpen: false,
      loading: false,
      pageNumber: 1,

      signPostingData: [],
      paginatedData: [],
      pagination: { total: 0 },
      showDeleteRowbtn: {},
      rowToDelete: null,
      rowToEdit: null,
      anchorEl: null,
      switchStates: {},
      openPopup: false,
      currentPage: 1,
      itemsPerPage: 16,
      query: "",
      showMenu: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    this.handleResponseForGetSignPostingData(from, message)
    this.handleResponseForDeleteRow(from, message)
  }

  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({ token, initialRender: true })
    this.getSignPostingData();
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (prevState.query !== this.state.query) {
      this.timeout = setTimeout(() => { this.getSignPostingData(); }, 500)
    }
  }

  getSignPostingData = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    const newFormDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSignPostingId = newFormDataMessage.messageId;

    if (this.state.initialRender) {
      this.setState({ isLoading: true })
    }

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllSignPostingAPIEndPoint}?search=${this.state.query}&page=${this.state.currentPage}&per_page=${this.state.itemsPerPage}`
    );

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(newFormDataMessage.id, newFormDataMessage);
  };

  deleteSignPostingData = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    const newFormDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteSignPostingId = newFormDataMessage.messageId;

    if (this.state.initialRender) {
      this.setState({ isLoading: true })
    }

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllSignPostingAPIEndPoint}/${this.state.rowToDelete}`
    );

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );

    runEngine.sendMessage(newFormDataMessage.id, newFormDataMessage);
  };

  handleResponseForDeleteRow = (from: string, message: Message) => {
    if (
      this.apiDeleteSignPostingId !== null &&
      this.apiDeleteSignPostingId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        let page = this.state.currentPage
        if (this.state.signPostingData.length === 1 && this.state.currentPage > 1) {
          page = this.state.currentPage - 1
        }
        this.setState({
          openPopup: false,
          showDeleteRowbtn: false,
          currentPage: page
        })
        toast.success(responseJson.message, { className: "success__toast" })
        this.getSignPostingData()
      }
    }
  }

  deleteProject = async () => {
    this.setState({ isDeleteModalOpen: false, loading: true, pageNumber: 1 });
    this.handleCloseDialog("dialog");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteSignPostingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteProjectAPIEndPoint + this.state.deleteProjectID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAbbreviatedText = (text: string) => {
    if (text?.length > 20) {
      return text.slice(0, 20).padEnd(23, "...")
    }
    return text
  }

  handleResponseForGetSignPostingData = async (from: string, message: Message) => {
    if (
      this.apiGetSignPostingId !== null &&
      this.apiGetSignPostingId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.sign_postings?.data) {
        this.setState({ signPostingData: responseJson?.sign_postings?.data, paginatedData:  responseJson, pagination: { total: responseJson.meta.total_items }, currentPage: responseJson.meta.current_page, isLoading: false, initialRender: false });
      } else if (responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
      }
    }
  }
  handlePageChange = (event: any, value: number) => {
    this.setState({ currentPage: value }, () => this.getSignPostingData());
  };
  handleClose = () => {
    this.setState({
      openPopup: false,
      rowToDelete: null,
    })
  }
  handleDeleteRow(event: any, id: any) {
    this.setState({
      anchorEl: event.currentTarget,
      showMenu: true,
      rowToDelete: id,
      rowToEdit: id
    });
  };
  showPopup = () => {
    this.setState(() => ({
      openPopup: true,
      showMenu: false,
      showDeleteRowbtn: {}
    }))
  }
  showMenuPopup = (id: number)=>{
    this.setState({
      showMenu: true,
      rowToEdit: id
    })
  }
  closeMenuPopup = ()=>{
    this.setState({
      showMenu: false,
      rowToEdit: null
    })
  }
  async handleLogout() {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const message: Message = new Message(
      getName(MessageEnum.NavigationSignupLoginMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleOpenDialog = (type: string, event?: React.MouseEvent<HTMLElement>) => {
    if (type === "filter" && event)
      this.setState({ isFilterMenuOpen: event.currentTarget });
    else if (type === "dialog") this.setState({ isDialogOpen: true });
  };

  handleCloseDialog = (type: string) => {
    if (type === "filter") this.setState({ isFilterMenuOpen: null });
    else if (type === "dialog") this.setState({ isDialogOpen: false });
  };

  handleSelectFilterValue = (type: string) => {
    this.setState(
      {
        filterValue: type === "all" ? "All Projects" : "My Projects",
        pageNumber: 1,
      },
      () => {
        this.getSignPostingData();
      }
    );
    this.handleCloseDialog("filter");
  };

  handleClickDeleteProject = (projectId: string) => {
    this.setState({ deleteProjectID: projectId });
    this.handleOpenDialog("dialog");
  };


  handleSearch(text: string) {
    if (text.length >= 3) {
      this.getSignPostingData();
    }
    this.setState({ searchInputText: text });
  }

  changeApi(type: string) {
    this.setState(
      {
        filterValue: type,
        isModalVisible: false,
        projects: [],
        searchInputText: "",
        pageNumber: 1,
      },
      () => {
        this.getSignPostingData();
      }
    );
  }

  goBack() {
    const message: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  openFilterMenu() {
    this.setState({ isModalVisible: true });
  }

  deleteModal(projectID: string) {
    this.setState({ isDeleteModalOpen: true, deleteProjectID: projectID });
  }

  closeDeleteModal() {
    this.setState({ isDeleteModalOpen: false });
  }

  filterModal() {
    this.setState({ isModalVisible: false });
  }

  naviagteToCreatePage = () => {
    const message: Message = new Message(
      //getName(MessageEnum.CreateProjectMessage)
      "Dummy text because Enum does not exist"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  naviagteToEditPage = (projectId: string) => {
    const message: Message = new Message(
      //getName(MessageEnum.CreateProjectMessage)
      "Dummy text because Enum does not exist"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      //getName(MessageEnum.PropsData)
      "Dummy text because Enum does not exist", projectId);
    this.send(message);
  };

  naviagteToViewPage = (projectId: string) => {
    const message: Message = new Message(
      //getName(MessageEnum.ViewProjectMessage)
      "Dummy text because Enum does not exist"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      //getName(MessageEnum.PropsData)
      "Dummy text because Enum does not exist", projectId);
    this.send(message);
  };
  loadMoreData = () => {
    if (this.state.pageNumber !== null) {
      this.setState(
        {
          pageNumber: Number(this.state.pageNumber) + 1,
        },
        () => this.getSignPostingData()
      );
    }
  };
  logOutFunction = () => {
    removeStorageData("token");
    this.props.navigation.dispatch(
      StackActions.reset({
        index: 0,
        actions: [
          NavigationActions.navigate({
            routeName: "EmailAccountLoginScreen",
          }),
        ],
      })
    );
  };

  navigateToSignPostingCreatePage = () => {
    const msg = new Message(getName(MessageEnum.NavigationSignpostingCreateMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SignpostingCreate");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToSignPostingEditPage = () => {
    const navigationMessage = new Message(getName(MessageEnum.NavigationSignpostingEditMessage))
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), { navigation: this.props.navigation })
    navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.rowToEdit)
    this.send(navigationMessage)
  }

  handleSearchQuery = (event: { target: { value: any; }; }) => {
    this.setState(() => ({
      query: event.target.value,
      currentPage: 1,
    }))
  }
}

// Customizable Area End
