import React from "react";
import {
    theme as cardTheme,
} from "../theme.web";
import {
    Button,
    CircularProgress,
    ThemeProvider,
    createTheme,
    makeStyles,
} from "@material-ui/core";

const {
    drawerCard,
    MuiButtonRootStyle,
} = cardTheme

const theme = createTheme({
    overrides: {
        MuiButton: {
            text: {
                padding: "10px 16px",
            },
            root: MuiButtonRootStyle,
        },
    },
})

const useStyles = makeStyles({
    stickyFooter: drawerCard.stickyFooter,
    drawerHeaderOrFooter: drawerCard.drawerHeaderOrFooter,
})

type CardFooterProps = {
    onClose: () => void;
    onSaveClick: () => void;
    loading: boolean;
}

export default function CardFooter({ onClose, loading, onSaveClick }: CardFooterProps) {
    const classes = useStyles()

    return (
        <ThemeProvider theme={theme} >
            <div className={`${classes.drawerHeaderOrFooter} ${classes.stickyFooter}`} >
                <Button onClick={onClose} className="Mui-secondary">
                    Cancel
                </Button>
                <Button
                    data-testid="saveChanges"
                    disabled={loading}
                    className="Mui-primary"
                    onClick={onSaveClick}
                >
                    {loading ? (
                        <CircularProgress
                            data-testid="progressbar"
                            style={{ height: 24, width: 24, color: "#FFFFFF" }}
                        />
                    ) : "Save"}
                </Button>
            </div>
        </ThemeProvider>
    )
}
