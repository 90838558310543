// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { OptionTypeBase, ValueType } from "react-select";
import { toast } from "react-toastify";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { Practice } from "../../../blocks/automaticformcreation/src/AutomaticFormCreationController.web";
// Customizable Area End

// Customizable Area Start
type TagsType = { id: number, type: string, name?: string, tagId: number | null }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: [];
  token: string;
  name: string;
  description: string;
  price: string;
  currency: string;
  category_id: string;
  image: string | undefined;
  uploadedImages: [];
  AllCategory: [];
  id: string;
  refresh: boolean;
  file: string;
  profileImageData: { data: string | null | undefined, content_type:string, filename:string };
  selectedCategory: ValueType<OptionTypeBase, false>;
  allCategories: { value: string, label: string}[];
  PractiseOption:string;
  openModal:boolean;
  postTitle:string;
  editorValue:string | undefined;
  practiceValue:string;
  errors: {
    postTitle: string,
    editorValue: string,
    practiceValue: string,
    tagName: string,
  }
  postId: any;
  selectedPracticeValue: number[];
  showPracticeError: boolean;
  selectPracticeError?: string;
  hasRightAlign: boolean;
  isGlobal: boolean;
  selectedTags: number[];
  options: any;
  hasUserInputFieldChanged: boolean;
  showUnsavedChangesDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PostCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: string = "";;
  addpostApiCallId: string = "";;
  updatePostApiCallId: string = "";
  getAllTagsApiCallID:string = "";
  getUserGuideApiCallID:string = "";
  postTagsApiCallID: string = "";
  deleteTagsApiCallID: string = "";
  updateTagsApiCallID: string = "";
  getAllPracticeApiCallID:string = "";
  getSubmitpostDetailCallID:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      isGlobal: false,
      token: "",
      PostData: [],
      name: "",
      description: "",
      price: "",
      currency: "$",
      category_id: "",
      image: "",
      id: "",
      uploadedImages: [],
      AllCategory: [],
      file: "",
      refresh: false,
      profileImageData: {data:null, content_type:"", filename:""},
      selectedCategory: {},
      allCategories: [],
      PractiseOption:"",
      openModal:false,
      postTitle:"",
      editorValue:"",
      practiceValue:"",
      errors: {
        postTitle: "",
        editorValue: "",
        practiceValue: "",
        tagName: ""
      },
      postId: null,
      selectedPracticeValue: [],
      showPracticeError: false,
      hasRightAlign: false,
      selectedTags: [],
      options: [],
      hasUserInputFieldChanged: false,
      showUnsavedChangesDialog: false,
    };
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    const postId = this.props.navigation.getParam("navigationBarTitleText")
    this.setState({ token, postId: postId })

    if(postId) {
      this.getUserGuideData()
    }
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.state.editorValue && !prevState.editorValue) {
      const classRegex = /class="([^"]*\bql-align-right\b[^"]*)"/;
      const match = this.state.editorValue.match(classRegex);
      if(match) {
        this.setState({hasRightAlign: true})
      } else {
        this.setState({hasRightAlign: false})
      }
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    this.handleResponseForPostCreationAPI(from, message)
    this.handleResponseForgetUserGuide(from, message)
    // Customizable Area End
  }
  // Customizable Area Start
  createPostCreation() {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert(configJSON.Error, configJSON.FieldsErrorMassage);
    } else {
      this.AddPostCreation();
    }
  }

  goToItemDetails(item:{attributes: { name:string, id:string}} | null, isEdit: boolean) {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      isEdit ? "PostCreation" : "PostDetails"
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.PostDetailDataMessage), item);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }

  editNavigation = (item:{attributes: { name:string, id:string}}) => {
    this.goToItemDetails(item, true);
  };

  navigateToDetails = (item:{attributes: { name:string, id:string}}) => {
    this.goToItemDetails(item, false);
  };

  AddPostCreation(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const attrs = {
      name: this.state.name,
      description: this.state.description,
      body: this.state.description,
      price: this.state.price,
      currency: "$",
      category_id: this.state.category_id,
      sub_category_id: 1,
      image: this.state.profileImageData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addpostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPostData(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateCreatePostData(recordId: string) {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert(configJSON.Error, configJSON.FieldsErrorMassage);
      return false;
    } else {
      const header = {
        "Content-Type": configJSON.postContentType,
        token: this.state.token
      };
      const attrs = {
        name: this.state.name,
        description: this.state.description,
        body: this.state.description,
        sub_category_id: 1,
        price: this.state.price,
        currency: "$",
        category_id: this.state.category_id,
        image: this.state.profileImageData
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePostApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postGetUrl + "/" + `${recordId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(attrs)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchPostAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  delete(recordId: string) {
    this.setState({ refresh: true });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl + "/" + `${recordId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getPostData();
    return true;
  }

  txtInputProductNameProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ name: text });
      this.txtInputProductNameProps.value = text;
    }
  };

  txtInputProductDiscripationProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ description: text });
      this.txtInputProductDiscripationProps.value = text;
    }
  };

  txtInputProductPriceProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ price: text });
      this.txtInputProductPriceProps.value = text;
    }
  };

  chooseImage = () => {
    this.showAlert("Error", "Image Picker Not Implemented");
  };

  handleCatChangeChange = (selectedOption: ValueType<OptionTypeBase, false>) => {
    this.setState({
      selectedCategory: selectedOption,
      category_id: selectedOption?.value
    });
  };
  // Customizable Area End

  // Customizable Area Start
  onFormCloseHandler = () => {
    const redirectTo = sessionStorage.getItem("redirectTo") || "user-guides"
    const botId = sessionStorage.getItem("botId")

    let navigationMessage = new Message(getName(MessageEnum.NavigationBlogpostsManagementMessage))

    if (redirectTo === "bot" && botId) {
      navigationMessage = new Message(getName(MessageEnum.NavigationIndividualBotMessage))
      navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), botId)
    }
    sessionStorage.removeItem("redirectTo")
    sessionStorage.removeItem("botId")

    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      { navigation: this.props.navigation }
    )

    this.send(navigationMessage)

  }

  handleCloseExitDialog = () => {
    this.setState({showUnsavedChangesDialog: false})
  }

  handleCrossClick = () => {
    if(this.state.hasUserInputFieldChanged) {
      this.setState({showUnsavedChangesDialog: true})
      return;
    }
    this.onFormCloseHandler()
  }

  getUserGuideData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserGuideApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postCreationEndPoint}/${this.state.postId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResponseForgetUserGuide = async(from: string, message: Message) => {
    if (
      this.getUserGuideApiCallID !== null &&
      this.getUserGuideApiCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson.errors?.[0]?.token) {
        await this.handleLogout()
        return;
      }

      if (responseJson.data) {
        const { title, content, practice, is_global, tag } = responseJson.data.attributes;
        this.setState({ postTitle: title, editorValue: content, selectedPracticeValue: practice, isGlobal: is_global, selectedTags: tag });
      }
    }
  }

  handleCloseModal = () => {
    this.setState({
      openModal: false,
    })
  }
  handleTitle = (event:any) =>{
    this.setState((prev)=>({
      postTitle: event.target?.value,
      errors: {...prev.errors, postTitle: ""},
      hasUserInputFieldChanged: true,
    }))
  }
  handleEditorChange = (value: string | undefined) => {
    this.setState((prev) => ({ editorValue: value, errors: {...prev.errors, editorValue: ""}, hasUserInputFieldChanged: true }));
  };
  handlePractice = (selectedItems: any) => {
    let error: string | undefined = undefined
    if (selectedItems.length === 0 && !this.state.isGlobal) {
      error = "Please select form association"
    }
    this.setState({ selectedPracticeValue: selectedItems, selectPracticeError: error, showPracticeError: true })
  };
  handleCloseError = (stateKey: "showOptionError" | "showPracticeError") => () => {
    this.setState({ ...this.state, [stateKey]: false })
  }
  handleChangeSelectedPractieValue = (value: number[]) => {
    let error: string | undefined = undefined
    if (value.length === 0 && !this.state.isGlobal) {
      error = "Please select form association"
    }
    this.setState({ selectedPracticeValue: value, selectPracticeError: error, showPracticeError: true, hasUserInputFieldChanged: true })
  }
  handleGolbalSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isGlobal: event.target.checked,
      selectedPracticeValue: [],
      showPracticeError: false,
      hasUserInputFieldChanged: true,
    })
  }

  saveUserProfileInfo = async () => {
    if (this.validateForm()) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token,
      };
      const httpBody ={
        data: {
          attributes: {
            title: this.state.postTitle.trim(),
            content:this.state.editorValue,
            practice: this.state.selectedPracticeValue,
            tag: this.state.selectedTags,
            is_global: this.state.isGlobal,
          }
        }
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      if (!this.state.postId) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.postCreationEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.PostAPiMethod
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.postCreationEndPoint}/${this.state.postId}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.putPostAPiMethod
        );
      }
      this.getSubmitpostDetailCallID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  handleResponseForPostCreationAPI = (from: string, message: Message) =>{
    if (
      this.getSubmitpostDetailCallID !== null &&
      this.getSubmitpostDetailCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.error) {
        toast.error(responseJson.error, { className: 'error__toast' });
      }
      if (responseJson) {
        this.onFormCloseHandler()
        toast.success(this.state.postId ? "User Guide updated successfully" : "User Guide created successfully");
        this.setState({
          postTitle: "",
          editorValue:"",
          practiceValue: "",
          isGlobal: false,
          hasUserInputFieldChanged: false,
        })
      }

    }
  }
  validateForm = () =>{

    const errors = {
      postTitle: '',
      editorValue: '',
      practiceValue: '',
      tagName: ''
    };
    let isValid = true;

    if (!this.state.postTitle.trim()) {
      errors.postTitle = 'Please enter a title';
      toast.error(errors.postTitle, { className: 'error__toast' });
      isValid = false;
    }

    // Editor Value validation
    if (!this.state.editorValue && this.state.postTitle.trim()) {
      errors.editorValue = 'Please enter user guide text';
      toast.error(errors.editorValue, { className: 'error__toast' });
      isValid = false;
    }
    if (this.state.editorValue && this.state.postTitle.trim()) {
      const text = this.state.editorValue.replace(/<[^>]*>?/gm, '').trim();
      if(!text){
        errors.editorValue = 'Please enter user guide text';
        toast.error(errors.editorValue, { className: 'error__toast' });

        this.setState({ errors });
        return
      }
    }

    // Practice Value validation
      if (this.state.postTitle.trim() && this.state.editorValue && !this.state.selectedPracticeValue.length && !this.state.isGlobal){
        errors.practiceValue = 'Please select user guide association';
        this.setState({showPracticeError: true, selectPracticeError: "Please select user guide association"})
        toast.error(errors.practiceValue, { className: 'error__toast' });
        isValid = false;
      }


    this.setState({ errors });
    return isValid;
  }

  handleApplyTags = (values: number[]) => {
    this.setState({selectedTags: values, hasUserInputFieldChanged: true})
  }

  handleLogout = async() => {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const navigationMessage = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(navigationMessage)
  }

  // Customizable Area End
}