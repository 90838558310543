import React from "react";
import { 
    ThemeProvider,
    createTheme, 
    Dialog, 
    DialogActions, 
    Typography,
    IconButton 
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';

const theme = createTheme({
    typography: {
        fontFamily: "Cairo",
        h5: {
            fontWeight: 700,
            fontSize: 24,
            lineHeight: "32px",
            color: "#0F172A",
            textAlign: "center",
        },
        body1: {
            textAlign: "center",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "24px",
            color: "#000000",
            margin: "36px 0",
        },
    },
    overrides: {
        MuiIconButton: {
            root: {
                padding: 4,
                position: "absolute",
                top: 32,
                right: 32,
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: "#0F172A",
                opacity: "0.4 !important",
                '&:hover': {
                    backgroundColor: "#FFFFFF",
                },
            }
        },
        MuiDialog: {
            paperWidthSm: {
                width: 411,
                maxWidth: 411,
            },
            paper: {
                margin: 0,
            },
        },
        MuiPaper: {
            root: {
                padding: 32,
            },
            elevation24: {
                boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
            },
            rounded: {
                borderRadius: 8,
            },
        },
        MuiDialogActions: {
            root: {
                padding: 0,
                gap: 12,
                justifyContent: "flex-start",
                '& .action-btn': {
                    all: "unset",
                    cursor: "pointer",
                    flex: 1,
                    textAlign: "center",
                    backgroundColor: "#DBF0F2",
                    color: "#51ABB3",
                    padding: 10,
                    borderRadius: 8,
                    fontFamily: "Inter",
                    fontWeight: 700,
                    fontSize: 16,
                    lineHeight: "24px",
                    '&.primary': {
                        backgroundColor: "#51ABB3",
                        color: "#FFFFFF"
                    },
                },
                '&:not(:first-child)': {
                    marginLeft: 0,
                },
            },
        },
    },
})

type ExitDialogProps = {
    open: boolean;
    onClose: () => void;
    onExit: () => void;
}

export default function ExitDialog({
    open,
    onClose,
    onExit
}: ExitDialogProps) {

    return (
        <ThemeProvider theme={theme} >
            <Dialog open={open} onClose={onClose} >
                <Typography variant="h5" component="h5" >Unsaved changes</Typography>
                <Typography variant="body1" component="p" >
                    Are you sure you want to exit? Your changes will not be saved
                </Typography>
                <IconButton onClick={onClose} >
                    <ClearIcon htmlColor="#334155" />
                </IconButton>
                <DialogActions>
                    <button className="action-btn" onClick={onClose} >Cancel</button>
                    <button className="action-btn primary" onClick={onExit} >Exit</button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}
