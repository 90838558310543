import React from "react";
import { 
    createTheme, 
    ThemeProvider, 
    OutlinedInput, 
    FormControl, 
    FormLabel,
    StyleRules,
    FormLabelClassKey,
    makeStyles,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormGroup,
    Checkbox,
    IconButton,
} from "@material-ui/core";
import CustomSelect from "../../../../components/src/CustomSelect.web";
import DatePicker from "react-multi-date-picker"
import { CalendarTodayRounded, ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Question } from "./EmbedForm.web";

const useStyles = makeStyles({
    dropdownSelect: {
        backgroundColor: "var(--card-button-background)",
        padding: 8,
        borderRadius: 12,
        border: "1px solid var(--card-button-border)",
        gap: 8,
        '& > svg': {
            color: "#21272A",
        },
        '& > span': {
            fontFamily: "var(--font-family,Roboto)",
            fontWeight: 400,
            fontSize: "calc(var(--font-size) * 1.4)",
            lineHeight: "19px",
            color: "#697077",
        },
        '&[data-error="true"]': {
            borderColor: "#F59E0B",
            backgroundColor: "#FEF3C7",
            '& > span': {
                color: "#D97706"
            },
            '& > svg': {
                color: "#D97706",
            },
        },
    },
    dropdownSelectOption: {
        fontSize: "calc(var(--font-size) * 1.4)",
        padding: "8px",
        fontFamily: "var(--font-family,Roboto)",
        color: "#697077",
        lineHeight: "16px",
        '&:hover': {
            backgroundColor: "#F8FAFC",
        },
        '&:first-child': {
            borderRadius: "12px 12px 0 0",
        },
        '&:last-child': {
            borderRadius: "0 0 12px 12px",
        },
    },
    dateContainer: {
        display: "flex",
        gap: 12,
        alignItems: "center",
        padding: 8,
        borderRadius: 12,
        backgroundColor: "#FFFFFF",
        border: "1px solid #F2F2F2",
        '& > input': {
            all: "unset",
            width: "100%",
            fontSize: "calc(var(--font-size) * 1.4)",
            fontFamily: "var(--font-family,Roboto)",
            color: "#697077",
            fontWeight: 400,
            lineHeight: "20px",
            "&::placeholder": {
                opacity: 1
            },
            '&[aria-invalid="true"]': {
                color: "#D97706"
            },
        },
        '&[data-error="true"]': {
            borderColor: "#F59E0B",
            backgroundColor: "#FEF3C7",
        },
    },
})

type LabelStyle = Partial<StyleRules<FormLabelClassKey>>["root"]

type ChatFormFieldsProps = {
    question: Question;
    value: string | string[];
    error: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const labelStyle: LabelStyle = {
    color: "var(--card-text)",
    fontFamily: "var(--font-family,Cairo)",
    fontSize: "calc(var(--font-size) * 1.4)",
    lineHeight: "24px",
    fontWeight: 700,
    '&.Mui-focused': {
        color: "var(--card-text)"
    },
}

const formInputTheme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
                display: "flex",
                gap: 4,
                flexDirection: "column",
                "& .multiline-input": {
                    width: "100%",
                    boxSizing: "border-box",
                    border: "1px solid #F2F2F2",
                    borderRadius: 12,
                    padding: 8,
                    fontFamily: "var(--font-family,Roboto)",
                    color: "#697077",
                    fontWeight: 400,
                    fontSize: "calc(var(--font-size) * 1.4)",
                    lineHeight: "20px",
                    outline: "none",
                    '&:focus': {
                        borderColor: "#51ABB3",
                    },
                    '&[aria-invalid="true"]': {
                        border: "1px solid #F59E0B",
                        backgroundColor: "#FEF3C7",
                        color: "#D97706",
                    },
                    '&::placeholder': {
                        opacity: 1,
                    },
                },
                '& .rmdp-shadow': {
                    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
                },
                '& .rmdp-wrapper': {
                    width: "252px",
                    borderRadius: 8,
                    padding: 4,
                },
                '& .rmdp-month-picker, & .rmdp-year-picker': {
                    bottom: "2px !important",
                    left: "2px !important",
                    position: "absolute !important",
                    right: "2px !important",
                    top: "2px !important",
                },
                '& .rmdp-month-picker > div': {
                    margin: "0px !important",
                },
                '& .rmdp-border': {
                    border: 0,
                },
                '& .rmdp-calendar': {
                    padding: 0,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                },
                '& .rmdp-ym': {
                    height: "25%",
                    gap: 2,
                },
                '& .rmdp-day, & .rmdp-week-day': {
                    height: 34,
                    width: "36px",
                    flex: 1,
                    cursor: "pointer",
                    position: "relative",
                    color: "#0F172A",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },
                '& .rmdp-week-day': {
                    height: 20,
                    color: "#64748B",
                    fontSize: "calc(var(--font-size) * 1.4)",
                    fontFamily: "var(--font-family,Cairo)",
                    fontWeight: 400,
                    lineHeight: "22px",
                },
                "& .rmdp-day.rmdp-selected span:not(.highlight)": {
                    boxShadow: "none",
                },
            },
        },
        MuiFormLabel: {
            root: labelStyle,
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 12,
                '& $notchedOutline': {
                    borderColor: "#F2F2F2"
                },
                '&:hover $notchedOutline': {
                    borderColor: "#51ABB3",
                },
                '&.Mui-Focused $notchedOutline': {
                    borderColor: "#51ABB3",
                },
                "&.Mui-error $notchedOutline": {
                    borderColor: "#F59E0B !important",
                },
                "&.Mui-error": {
                    backgroundColor: "#FEF3C7",
                },
            },
            input: {
                padding: 0,
            },
        },
        MuiInputBase: {
            root: {
                padding: "8px",
                backgroundColor: "#FFFFFF"
            },
            input: {
                fontFamily: "var(--font-family,Roboto)",
                fontWeight: 400,
                fontSize: "calc(var(--font-size) * 1.4)",
                lineHeight: "20px",
                color: "#697077",
                height: 20,
                '&[aria-invalid="true"]': {
                    color: "#D97706 !important",
                },
                '&::placeholder': {
                    opacity: 1,
                },
            },
        },
        MuiFormGroup: {
            root: {
                gap: 12,
                padding: 8,
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                border: "1px solid #F2F2F2",
                '&[data-error="true"]': {
                    borderColor: "#F59E0B",
                    backgroundColor: "#FEF3C7"
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: 0,
                '&:hover': {
                    backgroundColor: "transparent"
                },
            },
            edgeEnd: {
                marginRight: 0,
            },
        },
        MuiFormControlLabel: {
            root: {
                gap: 10,
                marginLeft: 0,
                marginRight: 0,
                '&[data-error="true"] $label': {
                    color: "#D97706",
                },
            },
            label: {
                fontFamily: "var(--font-family,Roboto)",
                fontWeight: 400,
                fontSize: "calc(var(--font-size) * 1.4)",
                lineHeight: "20px",
                color: "#697077",
            },
        },
        MuiRadio: {
            root: {
                padding: 0,
                color: "#64748B",
                '&[data-error="true"]': {
                    color: "#D97706",
                },
            },
        },
        MuiCheckbox: {
            root: {
                color: "#64748B",
                padding: 0,
                '&[data-error="true"]': {
                    color: "#D97706",
                },
            },
        },
    },
})

type SingleLineInputProps = {
    name: string;
    label: string;
    value: string;
    error?: boolean;
    isNumerical?: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type MultiLineInputProps = Omit<SingleLineInputProps , "isNumerical" | "handleChange"> & {
    setFieldValue: ChatFormFieldsProps["setFieldValue"];
}

type RadioFieldProps = MultiLineInputProps & {
    options: string[];
}

type CheckFieldProps = Omit<SingleLineInputProps, "isNumerical" | "handleChange" | "value"> & {
    options: string[];
    value: string[];
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

type DropdownProps = Omit<SingleLineInputProps, "isNumerical" | "handleChange"> & {
    options: string[];
    setFieldValue: ChatFormFieldsProps["setFieldValue"];
}

type DateFieldProps = Omit<SingleLineInputProps, "isNumerical" | "handleChange"> & {
    setFieldValue: ChatFormFieldsProps["setFieldValue"];
}

const SingleLineInput = ({name, label, error, isNumerical, value, handleChange}: SingleLineInputProps) => {
    return (
        <ThemeProvider theme={formInputTheme} >
            <FormControl>
                <FormLabel htmlFor={name} >{label}</FormLabel>
                <OutlinedInput 
                    name={name}
                    id={name}
                    error={!!error}
                    placeholder="Type here"
                    inputProps={{maxLength: isNumerical ? 10 : 255}}
                    value={value}
                    onChange={handleChange}
                />
            </FormControl>
        </ThemeProvider>
    )
}

const MultiLineInput = ({name, label, error, value, setFieldValue}: MultiLineInputProps) => {
    return (
        <ThemeProvider theme={formInputTheme} >
            <FormControl>
                <FormLabel htmlFor={name} >{label}</FormLabel>
                <textarea 
                    id={name}
                    name={name}
                    maxLength={1000}
                    placeholder="Type here"
                    aria-invalid={!!error} 
                    className="multiline-input"
                    value={value}
                    onChange={(event) => setFieldValue(name, event.target.value)}
                />
            </FormControl>
        </ThemeProvider>
    )
}

const DropdownField = ({name, label, options, error, value, setFieldValue}: DropdownProps) => {
    const classes = useStyles()
    const transformedOptions = options.map(option => ({label: option, value: option}))
    return (
        <ThemeProvider theme={formInputTheme} >
            <FormControl>
                <FormLabel>{label}</FormLabel>
                <CustomSelect
                    id={name}
                    placeholder="Select option"
                    selectBoxWidth={252}
                    value={value}
                    onChange={(selectedValue) => setFieldValue(name, selectedValue)}
                    options={transformedOptions}
                    selectClassName={classes.dropdownSelect}
                    paperStyle={{ border: 0, borderRadius: 12, padding: 0 }}
                    optionClassName={classes.dropdownSelectOption}
                    error={!!error}
                />
            </FormControl>
        </ThemeProvider>
    )
}

const RadioField = ({label, name, error, options, value, setFieldValue}: RadioFieldProps) => {
    return (
        <ThemeProvider theme={formInputTheme} >
            <FormControl component="fieldset" >
                <FormLabel component="legend" >{label}</FormLabel>
                <RadioGroup
                    aria-label={label}
                    name={name}
                    data-error={!!error}
                    onChange={(_, selectedValue) => setFieldValue(name, selectedValue)} 
                    value={value}
                    data-testid="radio-group"
                >
                    {
                        options.map((option) => (
                            <FormControlLabel
                                key={option}
                                value={option}
                                control={<Radio aria-checked={value === option} data-error={!!error} data-testid={`${name}-${option}`} disableRipple disableFocusRipple disableTouchRipple icon={<RadioUncheckedIcon />} checkedIcon={<RadioCheckedIcon />} />}
                                label={option}
                                data-error={!!error}
                            />
                        ))
                    }
                </RadioGroup>
            </FormControl>
        </ThemeProvider>
    )
}

const CheckboxField = ({error, label, options, value, setFieldValue, name}: CheckFieldProps) => {
    const valueSet = new Set(value)

    const onOptionCheck = (option: string) => () => {
        const selectedOptionsSet = new Set(value)
        if(selectedOptionsSet.has(option)){
            selectedOptionsSet.delete(option)
        } else {
            selectedOptionsSet.add(option)
        }
        setFieldValue(name, [...selectedOptionsSet])
    }
    
    return (
        <ThemeProvider theme={formInputTheme} >
            <FormControl component="fieldset" >
                <FormLabel component="legend" >{label}</FormLabel>
                <FormGroup data-error={!!error} >
                    {
                        options.map((option) => (
                            <FormControlLabel 
                                key={option}
                                value={option}
                                label={option}
                                data-error={!!error}
                                control={<Checkbox data-error={!!error} onChange={onOptionCheck(option)} checked={valueSet.has(option)} aria-checked={valueSet.has(option)} disableRipple disableFocusRipple disableTouchRipple name={option} checkedIcon={<CheckboxCheckedIcon />} icon={<CheckboxUncheckedIcon />} />}
                            />
                        ))
                    }
                </FormGroup>
            </FormControl>
        </ThemeProvider>
    )
}

const DateField = ({label, error, name, value, setFieldValue}: DateFieldProps) => {
    const classes = useStyles()

    const renderButton = (direction: "left" | "right", handleClick: React.MouseEventHandler<HTMLButtonElement>) => {
        return (
            <IconButton onClick={handleClick}>
                {direction === "left" ? (
                    <ChevronLeft htmlColor="#94A3B8" />
                ) : (
                    <ChevronRight htmlColor="#94A3B8" />
                )}
            </IconButton>
        )
    }

    return (
        <ThemeProvider theme={formInputTheme} >
            <FormControl>
                <FormLabel>{label}</FormLabel>
                <DatePicker 
                    value={value}
                    onChange={(date) => {
                        if(!date ||  Array.isArray(date)) {
                            return;
                        }
                        setFieldValue(name, date.format())
                    }}
                    render={(value, openCalendar, handleValueChange) => {
                        return (
                            <div data-error={!!error} className={classes.dateContainer}>
                                <input type="text" placeholder="dd/mm/yyyy" aria-invalid={!!error} value={value} onChange={handleValueChange} />
                                <IconButton onClick={openCalendar} aria-label={`open calendar for ${label}`} >
                                    <CalendarTodayRounded htmlColor={error? "#D97706" : "#475569"} />
                                </IconButton>
                            </div>
                        )
                    }}
                    highlightToday={false}
                    hideWeekDays={false}
                    monthYearSeparator={" "}
                    weekStartDayIndex={1}
                    renderButton={renderButton}
                    format="DD/MM/YYYY"
                    weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                />
            </FormControl>
        </ThemeProvider>
    )
}

function RadioUncheckedIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="currentColor"/>
        </svg>
    )
}

function RadioCheckedIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#51ABB3"/>
            <circle cx="10" cy="10" r="4" fill="white"/>
        </svg>
    )
}

function CheckboxCheckedIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="6" fill="#51ABB3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.061 5.42238C15.5182 5.73229 15.6375 6.35411 15.3276 6.81124L10.243 14.3112C10.077 14.5562 9.81098 14.715 9.51659 14.745C9.2222 14.7749 8.92965 14.673 8.71765 14.4665L4.80221 10.6536C4.40654 10.2683 4.39814 9.6352 4.78345 9.23953C5.16876 8.84386 5.80187 8.83546 6.19754 9.22077L9.25759 12.2007L13.6722 5.68895C13.9821 5.23182 14.6039 5.11247 15.061 5.42238Z" fill="white" />
        </svg>
    )
}

function CheckboxUncheckedIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
            <rect width="20" height="20" rx="6" />
        </svg>
    )
}

export const ChatFormFields = ({question, value, error, handleChange, setFieldValue}: ChatFormFieldsProps) => {
    const {question_type} = question
    const inputValue = typeof value === "string" ? value : ""
    if(question_type === "single_line") {
        return (
            <SingleLineInput 
                label={question.question}
                name={`question_${question.id}`}
                value={inputValue}
                error={error}
                handleChange={handleChange}
            />
        )
    }

    if(question_type === "numerical") {
        return (
            <SingleLineInput 
                isNumerical
                label={question.question}
                name={`question_${question.id}`}
                value={inputValue}
                error={error}
                handleChange={handleChange}
            />
        )
    }

    if(question_type === "multiline") {
        return (
            <MultiLineInput 
                label={question.question}
                name={`question_${question.id}`}
                value={inputValue}
                error={error}
                setFieldValue={setFieldValue}
            />
        )
    }

    if(question_type === "dropdown") {
        return (
            <DropdownField 
                label={question.question}
                name={`question_${question.id}`}
                options={question.options}
                value={inputValue}
                setFieldValue={setFieldValue}
                error={error}
            />
        )
    }

    if(question_type === "radio") {
        return (
            <RadioField 
                label={question.question}
                name={`question_${question.id}`}
                options={question.options}
                value={inputValue}
                error={error}
                setFieldValue={setFieldValue}
            />
        )
    }

    if(question_type === "date") {
        return (
            <DateField 
                label={question.question}
                name={`question_${question.id}`}
                value={inputValue}
                error={error}
                setFieldValue={setFieldValue}
            />
        )
    }

    return (
        <CheckboxField 
            label={question.question}
            name={`question_${question.id}`}
            options={question.options}
            value={typeof value == "string" ? [] : value}
            error={error}
            setFieldValue={setFieldValue}
        />
    )
}
