// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";

export const configJSON = require("./config");

type TrainingHubFile = {
  id: number;
  flag: string;
  data_id: number;
  created_at: string;
  updated_at: string;
  name: string;
  last_update: string;
}

export interface Props {
  navigation: any;
  id: string;
}


interface S {
  projectName: string;
  projectDescription: string;
  clientDetails: string;
  teamSize: string;
  projectUrl: string;
  token: string;
  startDate: string;
  endDate: string;
  images: { attributes: { public_url: string } }[];
  documents: { attributes: { file_name: string; public_url: string } }[];
  email: string;
  fullName: string;
  phoneNumber: string;
  loading: boolean;
  pagination: {
    total: number;
  };
  currentPage: number;
  itemsPerPage: number;
  search: string;
  file_sizes: any;
  all_files: TrainingHubFile[];
  selectedFileType: string;
}

interface SS {
  id: any;
}

export default class ProjectPortfolioDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      token: "",
      projectName: "",
      projectDescription: "",
      clientDetails: "",
      teamSize: "",
      projectUrl: "",
      startDate: "",
      endDate: "",
      images: [],
      documents: [],
      email: "",
      fullName: "",
      phoneNumber: "",
      loading: false,
      pagination: { total: 0 },
      currentPage: 1,
      itemsPerPage: 16,
      search: "",
      file_sizes: null,
      all_files: [],
      selectedFileType: "all_files"
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  apiGetProjectbyIDListCallId: string | null = "";
  getQAFileTypeId: string | null = "";
  saveQAFileTypeId: string | null = "";
  getFileSizeId: string | null = "";

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(
        //getName(MessageEnum.PropsData)
        "Dummy text because Enum does not exist");
      this.getProjectbyID(responseData);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId !== null) {
        if (apiRequestCallId === this.apiGetProjectbyIDListCallId) {
          const { data } = responseJson.data;
          if (responseJson && responseJson.data) {
            this.setState({
              projectName: data.attributes.project_name,
              projectDescription:
                data.attributes.project.data.attributes.description,
              email: data.attributes.client_account.email,
              fullName: data.attributes.client_account.full_name,
              phoneNumber: data.attributes.client_account.full_phone_number,
              clientDetails: data.attributes.client_details,
              teamSize: JSON.stringify(data.attributes.team_size),
              projectUrl: data.attributes.project.data.attributes.url,
              startDate: data.attributes.project.data.attributes.start_date,
              endDate: data.attributes.project.data.attributes.end_date,
              images: data.attributes.images.data,
              documents: data.attributes.documents.data,
              loading: false,
            });
          } else {
            this.setState({ loading: false });
            this.showAlert("Error", responseJson.errors);
          }
        }
      }

      if(this.getQAFileTypeId ===  message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.handleResponseForGetFileChange(message)
      }

      if(this.getFileSizeId ===  message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.handleResponseForGetFileSizeData(message)
      }

    }
  }

  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({ token })
    this.getFileChange()
    this.getFileSizeData()
  }
  getProjectbyID = async (projectID: string) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProjectbyIDListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewProjectsAPIEndPoint + projectID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  goBack() {
    this.props.navigation.goBack();
  }

  handleNavigate = (redirectTo: "user-guides" | "forms" | "qa" | "signposting") => {

    let navigationMessage = new Message(getName(MessageEnum.NavigationBlogpostsManagementMessage))
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      { navigation: this.props.navigation }
    )
    if (redirectTo === "forms") {
      navigationMessage = new Message(getName(MessageEnum.NavigationFormsMessage))
      navigationMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        { navigation: this.props.navigation })
    }
    else if (redirectTo === "qa") {
      navigationMessage = new Message(getName(MessageEnum.NavigationQAMessage))
      navigationMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        { navigation: this.props.navigation })
    }
    else if (redirectTo === "signposting") {
      navigationMessage = new Message(getName(MessageEnum.NavigationSignpostingMessage))
      navigationMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        { navigation: this.props.navigation })
    }
    this.send(navigationMessage)
  }

  handleSearch = (event: { target: { value: any; }; }) => {
    const text = event.target.value
    this.setState({ search: text }, () => this.getFileChange());
  }

  handlePageChange = (event: any, value: number) => {
    this.setState({ currentPage: value }, () => this.getFileChange());
  };

  getFileChange = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQAFileTypeId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.qaListingApi}/${this.state.selectedFileType}?keyword=${this.state.search}&page=${this.state.currentPage}&per_page=${this.state.itemsPerPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResponseForGetFileChange = async(message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(!responseJson) {
      toast.error("Failed to fetch!", {className: "error__toast"})
      return;
    }

    if(responseJson.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if (responseJson.all_files || responseJson.user_guides || responseJson.forms || responseJson.que_and_answer || responseJson.sign_postings) {
      const response: TrainingHubFile[] = responseJson.all_files || responseJson.user_guides || responseJson.forms || responseJson.que_and_answer || responseJson.sign_postings
      const files = response.map((list) => ({  ...list, updated_at: this.handleLastUpdate(list.updated_at) }))
      this.setState({ all_files: files, pagination: { total: responseJson.meta.total }, currentPage: responseJson.meta.page })
    }
  }
  
  handleLastUpdate(date: string) {
    const currentDate = new Date();
    const updatedAt = new Date(date);
    const minutesDiff = Math.floor((currentDate.getTime() - updatedAt.getTime()) / (1000 * 60));
    if (minutesDiff < 1) {
      return "Just now";
    } else if (minutesDiff < 60) {
      return `${minutesDiff} min ago`;
    } else if (updatedAt.toLocaleDateString() === currentDate.toLocaleDateString()) {
      return this.handleFormatTimeString(updatedAt)
    } else if (currentDate.getDay() - updatedAt.getDay() === 1) {
      return `Yesterday ${this.handleFormatTimeString(updatedAt)}`
    } else {
      return `${this.handleFormatDateString(updatedAt)} ${this.handleFormatTimeString(updatedAt)}`
    }
  }

  handleFormatDateString(date: Date) {
    return date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear()
  }

  handleFormatTimeString(date: Date) {
    const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
    const formattedTime = date.toLocaleTimeString('en-US', options);
    return formattedTime; // Output: 05:24 AM
  }
 
  getFileSizeData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFileSizeId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFileSizeEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResponseForGetFileSizeData = async(message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(!responseJson) {
      toast.error("Failed to fetch!", { className: "error__toast" })
      return;
    }

    if(responseJson.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if (responseJson?.data) {
      this.setState({ file_sizes: responseJson.data })
    } 
  }

  handleChangeFileType = (fileType: string) => () => {
    this.setState({selectedFileType: fileType, currentPage: 1}, () => this.getFileChange())
  }

  handleLogout = async() => {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const message = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(message)
  }
}

// Customizable Area End
