import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../../framework/src/Utilities";
import { configJSON } from "../Catalogue1Controller";
import { toast } from "react-toastify";

type Option = { label: string, value: string }

type Practice = {
    id: number;
    organisation_name: string;
  }

export interface CreateBotProps {
    isOpen: boolean;
    onClose: () => void;
    navigation: unknown,
    id: string;
}

interface S {
    loading: boolean;
    token: string;
    practicesOptions: Option[];
    practiceLoading: boolean;
}

interface SS {}

export default class CreateBotController extends BlockComponent<CreateBotProps, S, SS> {
    practiceApiCallId: string | null = null;
    createBotApiCallId: string | null = null;

    constructor(props: CreateBotProps) {
        super(props)

        this.receive = this.receive.bind(this)

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ]

        this.state = {
            loading: false,
            token: "",
            practicesOptions: [],
            practiceLoading: true,
        }

        this.handleRestApiResponse = this.handleRestApiResponse.bind(this)
        this.handleCreateBotResponse = this.handleCreateBotResponse.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
        this.handleFetchPracticeData = this.handleFetchPracticeData.bind(this)
        this.handlePracticeDataResponse = this.handlePracticeDataResponse.bind(this)

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        const handlers = {
            [getName(MessageEnum.RestAPIResponceMessage)]: this.handleRestApiResponse
        }
    
        const handler = handlers[message.id]
        if(handler) {
            handler(message)
        }
    }

    async componentDidMount() {
        const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
        this.setState({token})
        this.handleFetchPracticeData(token)
    }

    handleRestApiResponse(message: Message){
        const responseId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        const responseHandlers: Record<string, (newMsg: Message) => void> = {}

        if(this.createBotApiCallId !== null && this.createBotApiCallId === responseId) {
            responseHandlers[this.createBotApiCallId] = this.handleCreateBotResponse
        }

        if(this.practiceApiCallId !== null && this.practiceApiCallId === responseId) {
            responseHandlers[this.practiceApiCallId] = this.handlePracticeDataResponse
        }

        const responseHandler = responseHandlers[responseId];
        if (responseHandler) {
            responseHandler(message);
        }
    }

    async handlePracticeDataResponse(message: Message) {
        const practiceData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        this.setState({practiceLoading: false})
        if(!practiceData) {
            toast.error("Failed to fetch practices!", {className: "error__toast"})
            return;
        }

        if(practiceData.errors?.[0]?.token) {
            await this.handleLogout()
            return;
        }

        if(practiceData.organizations) {
            const newPractices: Option[] = practiceData.organizations.map((item: Practice) => ({label: item.organisation_name, value: `${item.id}`}))
            this.setState({practicesOptions: newPractices})
        }
    }

    async handleCreateBotResponse(message: Message) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        this.setState({loading: false})
        if(responseJson === undefined) {
            return toast.error("Failed to create bot", {className: "error__toast"});
        }

        if(responseJson?.errors?.[0]?.token === "Token has Expired") {
            return await this.handleLogout();
        }
        
        if(responseJson.data) {
            const navigationMessage = new Message(getName(MessageEnum.NavigationIndividualBotMessage))
            navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), {navigation: this.props.navigation, id: this.props.id})
            navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), responseJson.data.id)
            this.props.onClose()
            this.send(navigationMessage)
        }
    }

    handleFetchPracticeData(token: string) {
        const header = {
            token: token,
            "Content-Type": configJSON.validationApiContentType,
        }
    
        const apiReqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        apiReqMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        )
        apiReqMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        
        apiReqMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.practiceEndpoint
        )
        
        this.practiceApiCallId = apiReqMessage.messageId
    
        this.send(apiReqMessage)
    }

    handleCreateBot = (values: {chatbotName: string, practice: string}) => {
        this.setState({loading: true})

        const httpBody = {
            data: {
                attributes: {
                    name: values.chatbotName,
                    practice_association_id: +values.practice,
                }
            },
        }

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.post
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createBotApiEndpoint
        )

        this.createBotApiCallId = requestMessage.messageId

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    async handleLogout() {
        await removeStorageData("authToken")
        await removeStorageData("userId")
        sessionStorage.clear()
        const message = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
        message.addData(getName(MessageEnum.NavigationPropsMessage), {navigation: this.props.navigation, id: this.props.id})
        this.send(message)
    }
}
