import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,

  // Customizable Area Start
  OutlinedInput,
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import { Formik } from 'formik';
import { imgLogo } from './assets'

const ForgotPasswordError = styled("div")({
  borderRadius: 4,
  backgroundColor: "#FEE2E2",
  borderLeft: "4px solid #DC2626",
  boxSizing: "border-box",
  padding: "12px 16px",
  '& > p': {
    margin: 0,
    fontFamily: "Cairo",
    fontWeight: 400,
    lineHeight: "18px",
    fontSize: 12,
    color: "#DC2626",
  },
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#51ABB3",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      margin: 0,
      fontFamily: "Cairo",
      fontWeight: 700,
      lineHeight: "32px",
      color: "#000000",
      fontSize: 24,
    },
    h2: {
      fontFamily: "Cairo",
      fontWeight: 700,
      lineHeight: "26px",
      color: "#0F172A",
      fontSize: 18,
    },
    h3: {
      fontFamily: "Cairo",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#0F172A",
      fontSize: 16,
    },
    subtitle1: {
      fontFamily: "Cairo",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      fontSize: 16,
    },
    subtitle2: {
      fontFamily: "Cairo",
      fontWeight: 400,
      lineHeight: "18px",
      fontSize: 12,
      '&.error': {
        color: "#F87171",
      },
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#334155',
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
        fornFamily: "Cairo",
      }
    },
    MuiContainer: {
      root: {
        marginTop: 97,
        boxSizing: "border-box",
        '@media (min-width: 600px)': {
          padding: 0,
        },
        '&.vertical-center': {
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 0,
        },
      },
      maxWidthSm: {
        '@media (min-width: 600px)': {
          maxWidth: 440,
        },
      },
    },
    MuiButton: {
      root: {
          fontFamily: "Cairo",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "22px",
          color: "#51ABB3",
          textTransform: "none",
          borderRadius: 8,
          cursor: "pointer",
          '&:hover': {
              backgroundColor: "transparent",
              boxShadow: 'none',
          },
          '&.justify-start': {
            justifyContent: "flex-start",
          },
          '&.p-4': {
            padding: 16,
          },
      },
      text: {
          padding: 0,
      },
      contained: {
        color: "#FFFFFF",
        backgroundColor: "#51ABB3",
        lineHeight: "24px",
        padding: 16,
        '&:hover': {
          boxShadow: "none",
          backgroundColor: "#51ABB3",
          color: "#FFFFFF",
        },
        '&.Mui-disabled': {
          color: "#FFFFFF",
          backgroundColor: "#CBD5E1",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        '&:hover $notchedOutline': {
            borderColor: "#CBD5E1",
        },
        '&.Mui-Focused $notchedOutline': {
          borderWidth: 1,
          borderColor: "#51ABB3",
        },
        "&.Mui-error $notchedOutline": {
          borderColor: "#F87171 !important",
      },
      },
      input: {
        padding: 0,
      },
      notchedOutline: {
        borderColor: "#CBD5E1",
        borderWidth: 1,
      },
    },
    MuiInputBase: {
      root: {
        padding: "16px 8px",
        backgroundColor: "#FFFFFF",
      },
      input: {
        fontSize: "16px",
          fontFamily: "Cairo",
          fontWeight: 400,
          height: 24,
          lineHeight: "24px",
          color: "#0F172A",
      },
    },
  }
});
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

export default class ForgotPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box
          style={{
            display: 'flex',
            flexDirection: "column",
            alignItems: 'center',
            gap: 12,
            padding: '24px 48px',
            height: "100%",
          }}
        >
          <img src={imgLogo} width={156} />
          {this.state.emailSent ? (
           <Container maxWidth="sm" className="vertical-center">
           <Box style={webStyle.forgotPasswordContainer}>
             <Typography variant="h1">Password recovery</Typography>
             <Box>
                <Typography variant="h2">Check your email</Typography>
                <Typography variant="h3" style={{margin: "8px 0 16px"}} >
                  We have sent password reset link to {this.state.emailValue}
                </Typography>
               <Box style={{display:"flex",justifyContent:"space-between"}}>
                  <Button onClick={this.sendRecoveryLink} variant="text" className="justify-start" >Resend e-mail</Button>
                  <Box style={webStyle.backToLoginContainer} >
                    <Typography variant="subtitle1" >Back to</Typography>
                    <Button onClick={this.handleNavigateToLogin} variant="text" className="justify-start" >Log in</Button>
                  </Box>
               </Box>
             </Box>
           </Box>
         </Container>) : (
            <Formik
            data-test-id="FormikId"
            initialValues={{ emailValue: '' }}
            validationSchema={this.SignUpValidation()}
            onSubmit={this.onForgotPasswordSubmit}
          >{({
            values, errors, handleChange, handleSubmit
          }) => (
            <>
              <Container maxWidth="sm" style={{ height: '100%', marginTop: 100 }}>
                <Box style={webStyle.forgotPasswordContainer}>
                  <Typography variant="h1">Password recovery</Typography>
                  <Box style={webStyle.forgotPasswordFormContainer} >
                    <div>
                      <Typography variant="h2" >Forgot password?</Typography>
                      <Typography variant="h3" style={{marginTop: 8, width: "90%"}} >No worries, we'll send recovery link to your email</Typography>
                    </div>
                    { this.state.forgotPasswordError && (
                      <ForgotPasswordError>
                        <p>{this.state.forgotPasswordError}</p>
                      </ForgotPasswordError>
                    )}
                    <Box style={webStyle.formControl}>
                      <InputLabel>Email Address</InputLabel>
                      <OutlinedInput
                        data-test-id="txtInputEmail"
                        placeholder={"Enter your email"}
                        fullWidth={true}
                        onChange={handleChange('emailValue')}
                        value={values.emailValue}
                        error={!!errors.emailValue}
                      />
                      {errors.emailValue && <Typography variant="subtitle2" className="error" data-test-id="email-error" >{errors.emailValue}</Typography>}
                    </Box>
                  </Box>
                  <Button
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    fullWidth
                    disabled={values.emailValue == ""}
                    onClick={() => handleSubmit()}
                    className="p-4"
                  >
                    Send a recovery link
                  </Button>
                  <Box style={webStyle.backToLoginContainer} >
                    <Typography variant="subtitle1" >Back to</Typography>
                    <Button onClick={this.handleNavigateToLogin} variant="text" className="justify-start" >Log in</Button>
                  </Box>
                </Box>
              </Container>
            </>
          )}
            </Formik>)
          }
        </Box>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: Record<string, React.CSSProperties> = {
  forgotPasswordContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    padding: 40,
    boxSizing: "border-box",
  },
  forgotPasswordFormContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  formControl: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  backToLoginContainer: {
    display: "flex",
    gap: 8,
  },
};
// Customizable Area End
