import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { ChatFormFields } from "./ChatFormFields.web";
import { QuestionType } from "../AutomaticFormCreationController.web";
import * as yup from "yup";
import { useFormik } from "formik";
import { ArrowForwardRounded, ArrowBackRounded } from "@material-ui/icons";
import GenericError from "../../../../components/src/GenericError.web";
import {utils} from "../utils.web"


const useStyles = makeStyles({
    card: {
        backgroundColor: "var(--card-background)",
        border: "1px solid var(--card-border)",
        padding: "12px 14px",
        borderRadius: "12px",
        color: "var(--card-text)",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        fontWeight: 400,
        fontFamily: "var(--font-family,Cairo)",
        lineHeight: "24px",
        fontSize: "calc(var(--font-size) * 1.4)",
        width: 283,
        '& > p': {
            whiteSpaceCollapse: "preserve",
            fontFamily: "var(--font-family,Cairo)",
        },
    },
    separator: {
        border: "1px solid var(--border-seperator)",
        width: "100%",
    },
    submitBtn: {
        all: "unset",
        width: "max-content",
        cursor: "pointer",
        backgroundColor: "var(--card-button-background)",
        border: "2px solid var(--card-button-border)",
        padding: "8px 16px",
        borderRadius: 50,
        color: "var(--card-button-text)",
        fontFamily: "var(--font-family,Cairo)",
        fontSize: "calc(var(--font-size) * 1.4)",
        fontWeight: 400,
        display: "flex",
        gap: 6,
        alignItems: "center",
    },
    formFooter: {
        display: "flex",
        gap: 10,
        justifyContent: "space-between",
        alignItems: "center",
    },
    errorText: {
        fontSize: "calc(var(--font-size) * 1.2)",
    },
})


export type Question = {
    id: number;
    question: string;
    question_type: QuestionType;
    options: string[];
}

type QuestionWithAnswer = {
    id: number;
    question: string;
    answer: string;
}

export type EmbedFormProps = {
    message: string;
    questions: Question[];
    onConfirmClick: (questionsWithAnswers: QuestionWithAnswer[]) => void;
    reset: boolean;
    cardId: number;
}

type StepFormProps = Omit<EmbedFormProps, "message" | "reset" | "cardId"> & {
    allQuestions: Question[];
    currentQuestionGroupIndex: number;
    questionsGrouplength: number;
    setCurrentQuestionGroupIndex: React.Dispatch<React.SetStateAction<number>>;
    allQuestionValues: Record<string, string | string[]>;
    setAllQuestionValues: React.Dispatch<React.SetStateAction<Record<string, string | string[]>>>;
    setHide: React.Dispatch<React.SetStateAction<boolean>>;
}

const getQuestionsGroup = (questions: Question[]) => {
    const questionsGroup: Question[][] = []
    let tempQuestions: Question[] = []

    questions.forEach((question) => {
        tempQuestions.push(question)
        if (tempQuestions.length === 4) {
            questionsGroup.push(tempQuestions)
            tempQuestions = []
        }
    })

    const lastGroupOfQuestions = questions.slice(questionsGroup.length * 4)
    if(lastGroupOfQuestions.length > 0) {
        questionsGroup.push(lastGroupOfQuestions)
    }

    return questionsGroup
}

const buildValidationSchema = (questions: Question[]) => {
    const shape: Record<string, yup.StringSchema<string> | yup.NumberSchema<number> | yup.ArraySchema<unknown>> = {};

    questions.forEach(({ question, question_type, options, id }) => {

        const questionName = `question_${id}`

        switch (question_type) {
            case 'single_line':
                shape[questionName] = yup.string().required(`${question} is required`).min(1, `${question} is required`).max(255, "Answer must be max 255 characters");
                break;

            case 'numerical':
                shape[questionName] = yup.string().required(`${question} is required`).matches(/^[0-9]{1,10}$/, "Please add valid answer");
                break;

            case 'multiline':
                shape[questionName] = yup.string().required(`${question} is required`).min(1, `${question} is required`).max(1000, "Answer must be max 1000 characters");
                break;

            case 'date':
                shape[questionName] = yup.string()
                    .required(`${question} is required`);
                break;

            case 'dropdown':
                shape[questionName] = yup.string()
                    .oneOf(options, `Please select a valid option for ${question}`)
                    .required(`${question} is required`);
                break;

            case 'radio':
                shape[questionName] = yup.string()
                    .oneOf(options, `Please select a valid option for ${question}`)
                    .required(`${question} is required`);
                break;

            case 'checkbox':
                shape[questionName] = yup.array()
                    .of(yup.string().oneOf(options))
                    .min(1, `Please select at least one option`)
                    .required(`${question} is required`);
                break;

            default:
                break;
        }
    });

    return yup.object().shape(shape);
};

const getInitialValuesForQuestions = (questions: Question[], allQuestionValues: Record<string, string | string[]>) => {
    const initialValues: Record<string, string | string[]> = {}

    questions.forEach((question) => {
        const questionName = `question_${question.id}`
        initialValues[questionName] = allQuestionValues[questionName]
    })

    return initialValues
}

const getAllQuestionInitialValues = (questions: Question[]) => {
    let allQuestionValues: Record<string, string | string[]> = {}

    questions.forEach((question) => {
        const questionName = `question_${question.id}`
        if(question.question_type === "checkbox") {
            allQuestionValues[questionName] = []
        } else {
            allQuestionValues[questionName] = ""
        }
    })

    return allQuestionValues
}

export default function EmbedForm({ 
    message,
    questions, 
    onConfirmClick,
    reset,
}: EmbedFormProps) {
    const classes = useStyles()
    const [currentQuestionGroupIndex, setCurrentQuestionGroupIndex] = useState<number>(0)
    const [allQuestionValues, setAllQuestionValues] = useState<Record<string, string | string[]>>(getAllQuestionInitialValues(questions))
    const [hide, setHide] = useState(false)


    const questionsGroup = getQuestionsGroup(questions)

    const questionsGrouplength = questionsGroup.length

    const currentQuestionsGroup = questionsGroup[currentQuestionGroupIndex]

    return (
        <div className={classes.card}>
            <p>{message}</p>
            {
                (!hide || reset) && (
                    <StepForm 
                        key={currentQuestionGroupIndex}
                        allQuestions={questions}
                        questions={currentQuestionsGroup}
                        questionsGrouplength={questionsGrouplength}
                        onConfirmClick={onConfirmClick}
                        currentQuestionGroupIndex={currentQuestionGroupIndex}
                        setCurrentQuestionGroupIndex={setCurrentQuestionGroupIndex}
                        allQuestionValues={allQuestionValues}
                        setAllQuestionValues={setAllQuestionValues}
                        setHide={setHide}
                    />
                )
            }
        </div>
    )
}

function StepForm({ 
    allQuestions,
    questions,
    questionsGrouplength,
    onConfirmClick,
    currentQuestionGroupIndex,
    setCurrentQuestionGroupIndex,
    allQuestionValues,
    setAllQuestionValues,
    setHide    
}: StepFormProps) {
    const classes = useStyles()
    const [showError, setShowError] = useState(false)
    const {values, errors, handleChange, setFieldValue, handleSubmit, touched, isValid} = useFormik({
        initialValues: getInitialValuesForQuestions(questions, allQuestionValues),
        validationSchema: buildValidationSchema(questions),
        onSubmit: (values) => {
            if(currentQuestionGroupIndex < questionsGrouplength - 1) {
                setCurrentQuestionGroupIndex(currentQuestionGroupIndex + 1)
                setAllQuestionValues({...allQuestionValues, ...values})
                return;
            }

            const newAllQuestionValues = {...allQuestionValues, ...values}
            setAllQuestionValues(newAllQuestionValues)

            const questionsAndAsnwers = allQuestions.map((question) => {
                const currentQuestionAnswer = newAllQuestionValues[`question_${question.id}`]
                const questionWithAnswer = {
                    id: question.id,
                    question: question.question,
                    answer: Array.isArray(currentQuestionAnswer) ? currentQuestionAnswer.join(", ") : currentQuestionAnswer
                }
                return questionWithAnswer
            })
            setHide(true)
            onConfirmClick(questionsAndAsnwers)
        },
    })

    const onPreviousClick = () => {
        setCurrentQuestionGroupIndex(currentQuestionGroupIndex - 1)
    }

    return (
        <>
            {
                showError && !isValid && (
                    <GenericError 
                        error={utils.getErroMessageForEndUserForms(values, errors, questions)}
                        handleCloseError={() => setShowError(false)}
                        errorTextClassName={classes.errorText}
                    />
                )
            }
            {
                questions.map((question) => {
                    const questionName = `question_${question.id}`
                    const hasError = Array.isArray(errors[questionName]) ? errors?.[questionName]?.length === 0 : !!errors[questionName]
                    return (
                        <ChatFormFields
                            key={question.id}
                            question={question}
                            value={values[questionName]}
                            error={hasError && !!touched[questionName]}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                        />
                    )
                })
            }
            <div className={classes.separator} />
            <div className={classes.formFooter} >
                {
                    currentQuestionGroupIndex !== 0 && (
                        <button className={classes.submitBtn} onClick={onPreviousClick} >
                            <ArrowBackRounded htmlColor="#475569" />
                            Previous
                        </button>
                    )
                }
                {
                    currentQuestionGroupIndex < questionsGrouplength - 1 && (
                        <button 
                            className={classes.submitBtn} 
                            onClick={() => {
                                setShowError(true)
                                handleSubmit()
                            }} 
                        >
                            Continue
                            <ArrowForwardRounded htmlColor="#475569" />
                        </button>
                    )
                }
                {
                    currentQuestionGroupIndex === questionsGrouplength - 1 && (
                        <button 
                            className={classes.submitBtn} 
                            onClick={() => {
                                setShowError(true)
                                handleSubmit()
                            }} 
                        >
                            Confirm
                        </button>
                    )
                }
            </div>
        </>
    )
}

