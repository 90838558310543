import React from "react";
import UserProfileBasicController, { Props } from "./UserProfileBasicController";
import { 
    Avatar, 
    Box, 
    Button, 
    Dialog, 
    DialogActions, 
    IconButton, 
    InputAdornment, 
    InputLabel, 
    OutlinedInput, 
    ThemeProvider, 
    Typography, 
    createTheme,
    CircularProgress,
} from "@material-ui/core";
import { Close, ExitToApp, Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik } from "formik";
import Sidebar from "../../../components/src/Sidebar.web";
import PasswordCriteria from "./components/PasswordCriteria.web";
import ImageFileInput from "../../../components/src/ImageFileInput.web";
import ImageCropDialog from "../../../components/src/ImageCropDialog.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#51ABB3",
            contrastText: "#fff",
        },
        secondary: {
            main: "#0F172A",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Cairo",
        h1: {
            fontWeight: 700,
            fontSize: 24,
            lineHeight: "32px",
            letterSpacing: "-0.005em",
            color: "#0F172A",
        },
        subtitle1: {
            color: "#64748B",
            fontSize: 16,
            lineHeight: "24px",
            fontWeight: 700,
        },
        subtitle2: {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "18px",
            color: "#D97706",
        },
    },
    overrides: {
        MuiButton: {
            root: {
                fontFamily: "Cairo",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "22px",
                color: "#51ABB3",
                textTransform: "none",

                borderRadius: 8,
                '&:hover': {
                    backgroundColor: "transparent",
                    boxShadow: 'none',
                },
                '&.text-lg': {
                    fontSize: 16,
                    lineHeight: '24px',
                },
                '&.inter .MuiButton-label': {
                    fontFamily: "Inter",
                },
                '&.Mui-primary': {
                    backgroundColor: "#E5EBFF",
                    padding: "10px 16px 10px 16px"
                },
                '&.Mui-secondary': {
                    color: "#FFFFFF",
                    backgroundColor: "#51ABB3",
                    padding: "10px 16px 10px 16px"
                },
            },
            text: {
                padding: 0,
            },
        },
        MuiFormLabel: {
            root: {
                fontFamily: "Cairo",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "22px",
                color: "#64748B",
                "&.Mui-passwordLabel": {
                    fontSize: 16,
                    lineHeight: "24px",
                    color: "#334155",
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 8,
                '&:hover $notchedOutline': {
                    borderColor: "#CBD5E1",
                },
                '&.Mui-Focused $notchedOutline': {
                    borderColor: "#51ABB3",
                    borderWidth: 1,
                },
                "&.Mui-disabled $notchedOutline": {
                    borderColor: "#F6F0FF",
                },
                "&.Mui-error $notchedOutline": {
                    borderColor: "#F59E0B",
                },
            },
            notchedOutline: {
                borderWidth: 1,
                borderColor: "#CBD5E1",
            },
            input: {
                padding: 0,
            },
        },
        MuiInputBase: {
            root: {
                backgroundColor: "#FFFFFF",
                padding: "16px 8px",
            },
            input: {
                fontFamily: "Cairo",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                height: 24,
                color: "#0F172A",
                "&.Mui-disabled": {
                    color: "#94A3B8"
                },
                "&.Mui-passwordInput": {
                    color: "#64748B",
                },
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: '#0F172A',
            },
        },
        MuiPaper: {
            root: {
                '&.confirmDialog': {
                    padding: 32,
                    display: "flex",
                    flexDirection: "column",
                    gap: 12,
                },
            },
            elevation24: {
                boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017"
            },
            rounded: {
                borderRadius: 8,
                '&.rounded-lg': {
                    borderRadius: 24
                },
            }
        },
        MuiDialogActions: {
            root: {
                padding: "24px 40px",
                gap: 10,
                borderTop: "1px solid #CBD5E1" ,
                position: "sticky",
                bottom: 0,
                zIndex: 2,
                backgroundColor:"#FFFFFF",
            },
            spacing: {
                '& > :not(:first-child)': {
                    marginLeft: 0
                }
            }
        },
        MuiAvatar: {
            root: {
                width: 156, 
                height: 156,
                boxSizing: "border-box",
                border: "4px solid #E5E6FF",
                cursor: "pointer",
            },
            colorDefault: {
                color: "transparent",
                backgroundColor: "#E5E6FF",
            },
        },
        MuiDialog: {
            paper: {
                scrollbarWidth: "none",
                '&::-webkit-scrollbar': {
                    width: 0,
                }
            },
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: "#FFFFFF",
            },
        },
    }
});

export default class UserProfileBasic extends UserProfileBasicController {
    constructor(props: Props) {
        super(props)
    }

    inputEndorement = (ariaLabel: string, show: boolean, onClick: () => void) => (
        <InputAdornment position="end">
            <IconButton
                aria-label={ariaLabel}
                style={{ padding: 0, color: "#94A3B8" }}
                disableRipple
                onClick={onClick}
            >
                {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    )

    renderInputError = (error: string | undefined, touched: boolean | undefined, testId: string) => (
        <>
            {error && touched && <Typography data-testid={testId} variant="subtitle2" >{error}</Typography>}
        </>
    )

    render() {
        return (
            <ThemeProvider theme={theme} >
                <ImageCropDialog
                    data-test-id="image-crop-dialog"
                    open={this.state.showCropDialog}
                    handleClose={this.handleCropDialogClose}
                    selectedImage={this.filePickerImageRef}
                    onApply={this.onImageApply}
                    profileUpdateLoading={this.state.profileUpdateLoading}
                />
                <Dialog aria-labelledby="logoutTitle" open={this.state.showLogoutConfirmModal} PaperProps={{ className: "confirmDialog" }} BackdropProps={{ style: { opacity: "0.4" } }} >
                    <Box style={{ display: "flex", flexDirection: "column", gap: 12, alignItems: "center" }} >
                        <Typography id="logoutTitle" component={"h3"} variant="h1" >Log out</Typography>
                        <Typography variant="subtitle1" style={{ fontWeight: 400, color: "#000" }} >Are you sure you want to log out?</Typography>
                    </Box>
                    <Box style={{ display: "flex", gap: 12 }} >
                        <Button data-testid="cancelLogout" onClick={this.toggleConfirmLogoutModal} className="Mui-primary text-lg" style={{ padding: 16, width: "100%" }} >Cancel</Button>
                        <Button data-testid="userLogout" onClick={this.handleLogout} className="Mui-secondary text-lg inter" style={{ padding: 16, width: "100%" }} >Log out</Button>
                    </Box>
                </Dialog>
                <Dialog aria-labelledby="changePassword" open={this.state.showUpdatePasswordModal} onClose={this.toggleUpatePasswordModal} PaperProps={{ className: "rounded-lg", style: { width: 440 } }} BackdropProps={{ style: { opacity: "0.4" } }} >
                    <Box style={webStyle.updatePasswordDialogHeader} >
                        <Typography id="changePassword" component={"h3"} variant="h1" >Change password</Typography>
                        <IconButton data-test-id="closeBtn" onClick={this.toggleUpatePasswordModal} style={{ padding: 5, color: "#334155" }} >
                            <Close style={{ height: 14, width: 14 }} />
                        </IconButton>
                    </Box>
                    <Formik
                        initialValues={{ currentPassword: "", newPassword: "", confirmNewPassword: "" }}
                        onSubmit={this.updatePassword}
                        validationSchema={this.updatePasswordSchema()}
                        data-test-id="formik"
                    >
                        {
                            ({ errors, handleSubmit, getFieldProps, values: {newPassword}, touched }) => (
                                <>
                                    <Box style={{ padding: "24px 40px", display: "flex", flexDirection: "column", gap: 16 }} >
                                        <Box style={webStyle.passwordInputContainer} >
                                            <InputLabel htmlFor="currentPassword" className="Mui-passwordLabel">Current password</InputLabel>
                                            <OutlinedInput
                                                type={this.state.showCurrentPassword ? "text" : "password"}
                                                placeholder="Your current"
                                                fullWidth={true}
                                                error={!!errors.currentPassword && touched.currentPassword}
                                                inputProps={{ id: "currentPassword", className: "Mui-passwordInput", ...getFieldProps("currentPassword") }}
                                                endAdornment={this.inputEndorement("toggle your current password visibility", this.state.showCurrentPassword, this.toggleCurrentPasswordVisibility)}
                                            />
                                            {this.renderInputError(errors.currentPassword, touched?.currentPassword, "currentPasswordError")}
                                        </Box>
                                        <Box style={webStyle.passwordInputContainer} >
                                            <InputLabel htmlFor="newPassword" className="Mui-passwordLabel">New Password</InputLabel>
                                            <OutlinedInput
                                                type={this.state.showNewPassword ? "text" : "password"}
                                                placeholder="Your new password"
                                                fullWidth={true}
                                                error={!!errors.newPassword && touched.newPassword}
                                                inputProps={{ id: "newPassword", className: "Mui-passwordInput", ...getFieldProps("newPassword") }}
                                                endAdornment={this.inputEndorement("toggle new password visibility", this.state.showNewPassword, this.toggleNewPasswordVisibility)}
                                            />
                                            {this.renderInputError(errors.newPassword, touched?.newPassword, "newPasswordError")}
                                        </Box>
                                        <Box style={webStyle.passwordInputContainer}>
                                            <InputLabel htmlFor="confirmNewPassword" className="Mui-passwordLabel">Confirm New Password</InputLabel>
                                            <OutlinedInput
                                                type={this.state.showConfirmNewPassword ? "text" : "password"}
                                                placeholder="Confirm your new password"
                                                fullWidth={true}
                                                error={!!errors.confirmNewPassword && touched.confirmNewPassword}
                                                inputProps={{ id: "confirmNewPassword", className: "Mui-passwordInput", ...getFieldProps("confirmNewPassword") }}
                                                endAdornment={this.inputEndorement("toggle confirm new password visibility", this.state.showConfirmNewPassword, this.toggleConfirmNewPasswordVisibility)}
                                            />
                                            {this.renderInputError(errors.confirmNewPassword, touched?.confirmNewPassword, "confirmPasswordError")}
                                        </Box>
                                        <Box style={{ display: "flex", flexDirection: "column", gap: 8, marginTop: 8 }}>
                                            <Typography variant="subtitle1" color="secondary" style={{ fontWeight: 400 }} >
                                                Your password must contain
                                            </Typography>
                                            <PasswordCriteria password={newPassword} />
                                        </Box>
                                    </Box>
                                    <DialogActions>
                                        <Button data-test-id="cancel-update-password" type="button" onClick={this.toggleUpatePasswordModal} className="Mui-primary text-lg" style={{ padding: 16, width: 105, backgroundColor: "#E5F6FF" }} >Cancel</Button>
                                        <Button 
                                            disabled={this.state.updatePasswordLoading} 
                                            data-testid="save-new-password" 
                                            type="button" 
                                            onClick={() => handleSubmit()} 
                                            className="Mui-secondary text-lg" 
                                            style={{ padding: 16, width: 105 }}
                                        >
                                            {
                                                this.state.updatePasswordLoading ? <CircularProgress data-testid="update-password-loader" style={{height: 24, width: 24, color: "#FFFFFF"}} /> : "Save"
                                            }
                                        </Button>
                                    </DialogActions>
                                </>
                            )
                        }

                    </Formik>
                </Dialog>
                <Box
                    style={{
                        display: "flex",
                        alignItems: "flex-start",
                        backgroundColor: "#FAFAFA",
                    }}
                >
                    <Sidebar navigation={this.props.navigation} />
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 24,
                            width: "100%"
                        }}
                    >
                        <div style={{ padding: "24px 48px", display: "flex", gap: 12, alignItems: "center" }} >
                            <Typography
                                variant="h1"
                                style={{
                                    fontWeight: 700,
                                    fontSize: 24,
                                    lineHeight: "32px",
                                    letterSpacing: "-0.005em",
                                }}
                            >Account settings
                            </Typography>
                            <Button data-test-id="cancelChanges" onClick={this.handleCancel} className="Mui-primary text-lg" style={webStyle.cancelBtnStyle} >Cancel</Button>
                            <Button 
                                data-test-id="save-changes" 
                                onClick={this.saveUserProfileInfo} 
                                className="Mui-secondary text-lg inter" 
                                style={{width: 142}}
                                disabled={this.state.saveProfileLoading}
                            >
                                {
                                    this.state.saveProfileLoading ? <CircularProgress style={{height: 24, width: 24, color: "#FFFFFF"}} /> : "Save changes"
                                }
                            </Button>
                        </div>
                        <Box style={webStyle.profileContainerStyle} >
                            <div style={webStyle.profileBox} >
                                <Typography style={{ width: "62.23%", marginLeft: 10 }} variant="subtitle1" >Profile picture</Typography>
                                <div style={{ width: "63.12%", display: "flex", justifyContent: "space-between" }} >
                                    <Avatar alt="User profile" onClick={this.handleOpenFilePicker} src={this.state.profileImageURL} data-test-id="user-avatar">
                                        <span style={webStyle.avatarText} >{this.firstNameRef[0]}</span>
                                    </Avatar>
                                    <div style={webStyle.buttonGroup} >
                                        <Button data-test-id="delete-user-profile-pic" disableRipple variant="text" onClick={this.handleDeleteProfilePicture} >Delete</Button>
                                        <Button data-test-id="update-profile-pic" disableRipple variant="text" onClick={this.handleOpenFilePicker} >Update</Button>
                                    </div>
                                    <ImageFileInput 
                                        ref={this.fileInputRef} 
                                        name="user-profile"
                                        onImageLoad={this.onImageLoad} 
                                    />
                                </div>
                            </div>
                            <div style={webStyle.profileBox} >
                                <Typography style={{ width: "63.12%" }} variant="subtitle1" >Personal information</Typography>
                                <div style={{ width: "63.12%" }} >
                                    <div style={{ display: "flex", gap: 24, flexWrap: "wrap", }} >
                                        <Box style={webStyle.inputContainer} >
                                            <InputLabel htmlFor="firstName" >Name*</InputLabel>
                                            <OutlinedInput
                                                placeholder="User name"
                                                fullWidth
                                                error={!!this.state.firstNameError}
                                                value={this.state.firstName}
                                                onChange={this.onFirstNameInputChange}
                                                id= "firstName"
                                                name="firstName"
                                            />
                                            {this.state.firstNameError && <Typography data-test-id="firstNameError" variant="subtitle2" >{this.state.firstNameError}</Typography>}
                                        </Box>
                                        <Box style={webStyle.inputContainer} >
                                            <InputLabel htmlFor="lastName">Last Name*</InputLabel>
                                            <OutlinedInput
                                                placeholder="User Last name"
                                                fullWidth
                                                error={!!this.state.lastNameError}
                                                value={this.state.lastName}
                                                onChange={this.onLastNameChange}
                                                id= "lastName"
                                                name="lastName"
                                            />
                                            {this.state.lastNameError && <Typography data-test-id="lastNameError" variant="subtitle2" >{this.state.lastNameError}</Typography>}
                                        </Box>
                                        <Box style={webStyle.inputContainer} >
                                            <InputLabel id="organisation" >Organisation</InputLabel>
                                            <OutlinedInput
                                                key={this.organisation}
                                                fullWidth
                                                disabled
                                                id= "organisation"
                                                name="organisation"
                                                defaultValue={this.organisation}
                                            />
                                        </Box>
                                        <Box style={webStyle.inputContainer} >
                                            <InputLabel htmlFor="role" >Role</InputLabel>
                                            <OutlinedInput
                                                key={this.role}
                                                placeholder="User Role"
                                                fullWidth
                                                disabled
                                                id= "role"
                                                name="role"
                                                defaultValue={this.role}
                                            />
                                        </Box>
                                        <Box style={webStyle.inputContainer} >
                                            <InputLabel htmlFor="email">Email*</InputLabel>
                                            <OutlinedInput
                                                key={this.email}
                                                placeholder="useremail@mail.com"
                                                fullWidth
                                                disabled
                                                id= "email"
                                                name="email"
                                                defaultValue={this.email}
                                            />
                                        </Box>
                                        <Box style={webStyle.inputContainer} >
                                            <div style={{ display: "flex", justifyContent: "space-between" }} >
                                                <InputLabel htmlFor="password" >Password</InputLabel>
                                                <Button data-testid="update-password" disableRipple type="button" onClick={this.toggleUpatePasswordModal} variant="text" >Change password</Button>
                                            </div>
                                            <OutlinedInput
                                                placeholder="*********"
                                                fullWidth
                                                type="password"
                                                disabled
                                                id= "password"
                                                name="password"
                                            />
                                        </Box>
                                    </div>
                                </div>
                            </div>
                            <div style={webStyle.profileBox} >
                                <div style={webStyle.actionContainer} >
                                    <Typography variant="subtitle1" >Account actions</Typography>
                                    <Button data-testid="logout" disableRipple onClick={this.toggleConfirmLogoutModal} startIcon={<ExitToApp />} className="text-lg inter" >
                                        Log out
                                    </Button>
                                </div>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        )
    }
}

const webStyle: Record<string, React.CSSProperties> = {
    cancelBtnStyle: {
        width: 110,
        marginLeft: "auto",
    },
    profileContainerStyle: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 8px 0px #00000014",
        borderRadius: 12,
        margin: "0px 48px 48px",
        display: "flex",
        flexDirection: "column",
        gap: 24,
        padding: "48px 0"
    },
    profileBox: {
        padding: "0px 48px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 24,
    },
    profilePictureHeading: {
        width: "63.12%",
    },
    avatarText: {
        display: "inline-block",
        width: "100%",
        textAlign: "center",
        fontSize: 56,
        lineHeight: "48px",
        fontWeight: 600,
        color: "#51ABB3",
        fontFamily: "Inter"
    },
    buttonGroup: {
        display: "flex",
        gap: 12,
        alignItems: "flex-start",
    },
    actionContainer: {
        width: "63.12%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: 24,
        margin: "0 auto 22px",
    },
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 4,
        width: "calc(50% - 12px)",
    },
    passwordInputContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 4,
    },
    updatePasswordDialogHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px 40px",
        gap: 10,
        borderBottom: "1px solid #CBD5E1",
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: "#fff",
    } 
}