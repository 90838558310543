import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Option } from "../../../components/src/CustomSelect.web";
import React from "react";

type GeneralSettingsState = {
  chatInitiateOnMobile: string;
  chatInitiateOnDesktop: string;
  responseInterval: string;
  userAction: string[];
  chatbotURL: string;
  qrCodeImageFile: File | null;
  qrCodeImageURL: string | null;
  shouldUseOpenAI: boolean;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  chatInitiateOnMobile: string;
  chatInitiateOnDesktop: string;
  responseInterval: string;
  userAction: string[];
  chatbotURL: string;
  qrCodeImageFile: File | null;
  qrCodeImageURL: string | null;
  shouldUseOpenAI: boolean;
  handleGeneralSettingsStateChange: (value: GeneralSettingsState) => void
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  responseIntervalOptions: Option[] = [
    {
      label: "3 seconds",
      value: "3"
    },
    {
      label: "2 seconds",
      value: "2"
    },
    {
      label: "Immediate",
      value: "0"
    },
  ]
  
  imageInputRef = React.createRef<HTMLInputElement>()
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  getLatestProps = () => {
    const generalSettingsProp: Omit<Props, "navigation" | "id" | "handleGeneralSettingsStateChange"> = {
      chatInitiateOnMobile: this.props.chatInitiateOnMobile,
      chatInitiateOnDesktop: this.props.chatInitiateOnDesktop,
      responseInterval: this.props.responseInterval,
      userAction: this.props.userAction,
      chatbotURL: this.props.chatbotURL,
      qrCodeImageFile: this.props.qrCodeImageFile,
      shouldUseOpenAI: this.props.shouldUseOpenAI,
      qrCodeImageURL: this.props.qrCodeImageURL,
    }
    return generalSettingsProp
  }

  handleChatbotURLChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.props.handleGeneralSettingsStateChange({
      ...this.getLatestProps(),
      chatbotURL: event.target.value
    })
  }

  handleChatbotInitiateOnMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.handleGeneralSettingsStateChange({
      ...this.getLatestProps(),
      chatInitiateOnMobile: event.target.value,
    })
  }

  handleChatbotInitiateOnDesktopChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.handleGeneralSettingsStateChange({
      ...this.getLatestProps(),
      chatInitiateOnDesktop: event.target.value,
    })
  }

  handleResponseIntervalChange = (value: string) => {
    this.props.handleGeneralSettingsStateChange({
      ...this.getLatestProps(),
      responseInterval: value,
    })
  }

  handleUserActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    const props = this.getLatestProps();
    const newUserActionSet = new Set([...props.userAction])
    if(newUserActionSet.has(value)) {
      newUserActionSet.delete(value)
    } else {
      newUserActionSet.add(value)
    }
    this.props.handleGeneralSettingsStateChange({
      ...props,
      userAction: [...newUserActionSet],
    })
  }

  handleOpenAiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.handleGeneralSettingsStateChange({
      ...this.getLatestProps(),
      shouldUseOpenAI: event.target.checked,
    })
  }
  // Customizable Area End
}
