import React, { useRef, useState } from "react";
import {
    Popover,   
    ThemeProvider,
    FormLabel,
    Checkbox,
    Button,
    FormControl,
} from "@material-ui/core";
import { ChevronRightRounded, ExpandLessRounded } from "@material-ui/icons";
import {styledTheme} from "../../src/styledTheme.web";

const {
    theme, 
    styledComponent: { MenuContainer, MenuButton, StyledArrowButton, PopoverStyledFooter, PopoverStyledHeader, SubMenu, FormControlGroup, Column, ClearButton }
} = styledTheme

type IBCPopoverProps = {
    anchorEl: HTMLButtonElement | null;
    handleClose: () => void;
    pcnOptions: Option[];
    gpSurgeryOptions: Option[];
    chatbotOptions: Option[];
}

type IBCOption = {
    label: string;
    value: "ibc1" | "ibc2";
}

type Option = {
    label: string;
    value: string;
    subTitle?: string;
}

type SubSubMenuProps = {
    showSelectHeader?: boolean;
    title: string;
    options: Option[];
    handleCancel: () => void;
}

const ibcOptions: IBCOption[] = [
    {
        label: "IBC 1",
        value: "ibc1",
    },
    {
        label: "IBC 2",
        value: "ibc2",
    }
]

const ibc1Options: Option[] = [
    {
        label: "PCN",
        value: "pcn",
        subTitle: "All"
    },
    {
        label: "GP Surgery",
        value: "gp_surgery",
        subTitle: "All"
    },
    {
        label: "Chatbot",
        value: "chatbot",
        subTitle: "All",
    }
]


const ExpandArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.59 8.29498L12 12.875L7.41 8.29498L6 9.70498L12 15.705L18 9.70498L16.59 8.29498Z" fill="#475569" />
    </svg>
)

const UncheckedIcon = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="#64748B" />
    </svg>
);

const CheckedIcon = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="20" height="20" rx="6" fill="#51ABB3" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0611 5.42226C15.5183 5.73217 15.6376 6.35399 15.3277 6.81112L10.2432 14.3111C10.0771 14.5561 9.8111 14.7149 9.51671 14.7448C9.22232 14.7748 8.92977 14.6728 8.71777 14.4664L4.80234 10.6535C4.40667 10.2682 4.39827 9.63508 4.78358 9.23941C5.16888 8.84374 5.80199 8.83534 6.19766 9.22065L9.25771 12.2006L13.6723 5.68883C13.9822 5.2317 14.604 5.11235 15.0611 5.42226Z"
            fill="white"
        />
    </svg>
);

export default function FilterMenu (){
    const [ibcPopoverAnchor, setIbcPopoverAnchor] = useState<HTMLButtonElement | null>(null)
    const [selectedSubSubMenu, setSelectedSubSubMenu] = useState<null | {name: string, anchor: HTMLButtonElement}>(null)

    const handleOpenIbcPopoverAnchor = (e: React.MouseEvent<HTMLButtonElement>) => {
        setIbcPopoverAnchor(e.currentTarget)
    }

    const handleCloseIbcPopoverAnchor = () => {
        setIbcPopoverAnchor(null)
    }

    const handleOpenSubSubMenu = (name: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
        setSelectedSubSubMenu({name, anchor: e.currentTarget})
    }

    const handleCancelSubSubMenu = () => setSelectedSubSubMenu(null)

    const pcnOptions: Option[] = [
        {
            label: "Horizon PCN",
            value: "horizon"
        },
        {
            label: "Heinlein PCN",
            value: "heinlein"
        }
    ]

    const gpSurgeryOptions: Option[] = [
        {
            label: "Mile Lane",
            value: "1",
        },
        {
            label: "Peel",
            value: "2",
        },
        {
            label: "Red Bank",
            value: "3",
        }
    ]

    const chatbotOptions: Option[] = [
        {
            label: "Chatbot1",
            value: "1",
        },
        {
            label: "Chatbot2",
            value: "2",
        },
        {
            label: "Chatbot3",
            value: "3",
        }
    ]

    return (
        <ThemeProvider theme={theme}>
            <Popover
                id={"popover"}
                open={Boolean(selectedSubSubMenu?.anchor)}
                anchorEl={selectedSubSubMenu?.anchor}
                onClose={handleCancelSubSubMenu}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
            {
                selectedSubSubMenu?.name === "pcn" && (
                    <SubSubMenu 
                        options={pcnOptions} 
                        title="PCN"
                        handleCancel={handleCancelSubSubMenu}
                        showSelectHeader 
                    />
                )
            }
            {
                selectedSubSubMenu?.name === "gp_surgery" && (
                    <SubSubMenu 
                        options={gpSurgeryOptions} 
                        title="GP Surgery"
                        handleCancel={handleCancelSubSubMenu}
                        showSelectHeader 
                    />
                )
            }
            {
                selectedSubSubMenu?.name === "chatbot" && (
                    <SubSubMenu 
                        options={chatbotOptions} 
                        title="Chatbot"
                        handleCancel={handleCancelSubSubMenu}
                        showSelectHeader 
                    />
                )
            }
            </Popover>
            <IBCPopover 
                handleClose={handleCloseIbcPopoverAnchor} 
                anchorEl={ibcPopoverAnchor}
                pcnOptions={pcnOptions}
                gpSurgeryOptions={gpSurgeryOptions}
                chatbotOptions={chatbotOptions}
            />
            <MenuContainer >
                <span id="select-ibc" >ICB:</span>
                <MenuButton aria-labelledby="select-ibc" onClick={handleOpenIbcPopoverAnchor} >
                    All
                    <ExpandArrowIcon />
                </MenuButton>
            </MenuContainer>
            <MenuContainer >
                <span id="select-pcn" >PCN:</span>
                <MenuButton aria-labelledby="select-pcn" onClick={handleOpenSubSubMenu("pcn")} >
                    All
                    <ExpandArrowIcon />
                </MenuButton>
            </MenuContainer>
            <MenuContainer>
                <span id="select-gpsurgery" >GP Surgery:</span>
                <MenuButton aria-labelledby="select-gpsurgery" onClick={handleOpenSubSubMenu("gp_surgery")} >
                    All
                    <ExpandArrowIcon />
                </MenuButton>
            </MenuContainer>
            <MenuContainer>
                <span id="select-chatbot" >Chatbot:</span>
                <MenuButton aria-labelledby="select-chatbot" onClick={handleOpenSubSubMenu("chatbot")} >
                    All
                    <ExpandArrowIcon />
                </MenuButton>
            </MenuContainer>
        </ThemeProvider>
    )
}

const IBCPopover = ({ 
    anchorEl, 
    handleClose,
    pcnOptions,
    gpSurgeryOptions,
    chatbotOptions, 
}: IBCPopoverProps) => {
    const allIBCPopoverRef = useRef<HTMLDivElement>(null)
    const allIBC1or2Ref = useRef<HTMLDivElement>(null)
    const [selectedIBC, setSelectedIBC] = useState<"ibc1" | "ibc2" | null>(null);
    const [subSubMenu, setSubSubMenu] = useState<string | null>(null)
    const [selectedIBCIds, setSelectedIBCIds] = useState<Set<string>>(new Set([]))

    const handleIBCCheckboxChange = (checked: boolean, value: string) => {
        const newSelectedIBCIds = new Set([...selectedIBCIds])
        if(checked) {
            newSelectedIBCIds.add(value)
        } else {
            newSelectedIBCIds.delete(value)
        }
        setSelectedIBCIds(newSelectedIBCIds)
    }

    const handleAllIBCSelectionCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target

        if(checked) {
            const newSelectedIds = new Set(ibcOptions.map(option => option.value))
            setSelectedIBCIds(newSelectedIds)
        } else {
            setSelectedIBCIds(new Set([]))
        }
    }

    const handleIBCClearFilter = () => setSelectedIBCIds(new Set([]))

    const onIBCClick = (ibc: "ibc1" | "ibc2") => (e: React.MouseEvent<HTMLDivElement>) => {
        setSelectedIBC(ibc)
        setSubSubMenu(null)
    };

    const handleSelectSubSubMenu = (value: string) => () => {
        setSubSubMenu(value)
    }

    const handleIBCPopoverClose = () => {
        setSelectedIBC(null)
        setSubSubMenu(null)
        handleClose()
    }

    const handleIBCCancel = () => {
        setSelectedIBC(null)
        setSubSubMenu(null)
    }

    const handleSubSubMenuCancel = () => setSubSubMenu(null)

    const clientBoundingRect = allIBCPopoverRef.current?.getBoundingClientRect()
    const subSubMenuDomRect = allIBC1or2Ref.current?.getBoundingClientRect()

    const subSubMenuTop = subSubMenuDomRect?.top
    const subSubMenuLeft = (subSubMenuDomRect?.left ?? 0) + 194

    const isAllIBCChecked = ibcOptions.every(option => selectedIBCIds.has(option.value))

    return (
        <ThemeProvider theme={theme} >
            <Popover
                id={"popover"}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleIBCPopoverClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <PopoverStyledHeader>
                    <FormControl>
                        <FormLabel component="span">IBC: select</FormLabel>
                        <StyledArrowButton>
                            <ExpandLessRounded htmlColor="#0F172A" />
                        </StyledArrowButton>
                    </FormControl>
                </PopoverStyledHeader>
                <FormControlGroup>
                    <FormControl ref={allIBCPopoverRef}>
                        <Checkbox
                            icon={<UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                            id="all"
                            checked={isAllIBCChecked}
                            onChange={handleAllIBCSelectionCheckChange}
                            inputProps={{"aria-checked": isAllIBCChecked, "aria-label": "IBC-All"}}
                        />
                        <FormLabel htmlFor="all">All</FormLabel>
                    </FormControl>
                    {
                        ibcOptions.map((option) => (
                            <FormControl
                                key={option.value}
                                className="menu"
                                data-testid={option.value}
                                onClick={onIBCClick(option.value)}
                                aria-expanded={selectedIBC === option.value}
                                aria-haspopup={selectedIBC === "ibc1"}
                            >
                                <Checkbox
                                    icon={<UncheckedIcon />}
                                    checkedIcon={<CheckedIcon />}
                                    id={`ibc-${option.value}-checkbox`}
                                    checked={selectedIBCIds.has(option.value)}
                                    inputProps={{"aria-checked": selectedIBCIds.has(option.value)}}
                                    onChange={(event) => handleIBCCheckboxChange(event.target.checked, option.value)}
                                />
                                <FormLabel htmlFor={`ibc-${option.value}-checkbox`} style={{width: "100%"}} >{option.label}</FormLabel>
                                <StyledArrowButton>
                                    <ChevronRightRounded htmlColor="#0F172A" />
                                </StyledArrowButton>
                            </FormControl>
                        ))
                    }
                    <ClearButton onClick={handleIBCClearFilter} >Clear</ClearButton>
                    <PopoverStyledFooter>
                        <Button variant="contained" data-testid="cancelIBCPopover" onClick={handleIBCPopoverClose} >Cancel</Button>
                        <Button variant="contained" className="primary" >Apply</Button>
                    </PopoverStyledFooter>
                </FormControlGroup>
                {selectedIBC && (
                    <SubMenu data-testid={selectedIBC+"-popover"} style={{ top: clientBoundingRect?.top, left: (clientBoundingRect?.left ?? 0) + 195 }}>
                        <FormControlGroup>
                        <PopoverStyledHeader className="header" >
                            {selectedIBC === "ibc1" ? "IBC 1" : "IBC 2"}
                        </PopoverStyledHeader>
                        <FormControl ref={allIBC1or2Ref}>
                            <Checkbox
                                icon={<UncheckedIcon />}
                                checkedIcon={<CheckedIcon />}
                                id="all"
                                checked
                            />
                            <FormLabel htmlFor="all">All</FormLabel>
                        </FormControl>
                        {
                            ibc1Options.map((option) => (
                                <FormControl
                                    key={option.value}
                                    className="menu border-b"
                                    aria-expanded={subSubMenu === option.value}
                                    onClick={handleSelectSubSubMenu(option.value)}
                                    data-testid={`open-${option.value}-popover`}
                                >
                                    <Column>
                                        <FormLabel component="p" >{option.label}</FormLabel>
                                        <FormLabel component="p" className="sub-title" >{option.subTitle}</FormLabel>
                                    </Column>
                                    <StyledArrowButton>
                                        <ChevronRightRounded htmlColor="#0F172A" />
                                    </StyledArrowButton>
                                </FormControl>
                            ))
                        }
                        <ClearButton>Clear</ClearButton>
                        <PopoverStyledFooter>
                            <Button variant="contained" data-testid={`cancel-${selectedIBC}`} onClick={handleIBCCancel} >Cancel</Button>
                            <Button variant="contained" className="primary" >Apply</Button>
                        </PopoverStyledFooter>
                        </FormControlGroup>
                    </SubMenu>
                )}
                {
                    subSubMenu === "pcn" && (
                        <SubMenu data-testid={"PCN-popover"} style={{ top: subSubMenuTop, left: subSubMenuLeft }}>
                            <SubSubMenu 
                                options={pcnOptions}
                                title="PCN"
                                handleCancel={handleSubSubMenuCancel} 
                            />
                        </SubMenu>
                    )
                }
                {
                    subSubMenu === "gp_surgery" && (
                        <SubMenu data-testid={"GP Surgery-popover"} style={{ top: subSubMenuTop, left: subSubMenuLeft }}>
                            <SubSubMenu 
                                options={gpSurgeryOptions}
                                title="GP Surgery"
                                handleCancel={handleSubSubMenuCancel} 
                            />
                        </SubMenu>
                    )
                }
                {
                    subSubMenu === "chatbot" && (
                        <SubMenu data-testid={"Chatbot-popover"} style={{ top: subSubMenuTop, left: subSubMenuLeft }}>
                            <SubSubMenu 
                                options={chatbotOptions}
                                title="Chatbot"
                                handleCancel={handleSubSubMenuCancel} 
                            />
                        </SubMenu>
                    )
                }
            </Popover>
        </ThemeProvider>
    )
}

const SubSubMenu = ({ showSelectHeader, title, options, handleCancel }: SubSubMenuProps) => {
    const [selectedSubMenus, setSelectedSubMenus] = useState<Set<string>>(new Set([]))

    const handleClearFilterSelection = () => setSelectedSubMenus(new Set([]))

    const handleAllSelectionCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target

        if(checked) {
            const newSelectedSubMenus = new Set(options.map(option => option.value))
            setSelectedSubMenus(newSelectedSubMenus)
        } else {
            setSelectedSubMenus(new Set([]))
        }
    }

    const handleSubMenuSelectionChange = (checked: boolean, value: string) => {
        const newSelectedSubMenus = new Set([...selectedSubMenus])
        if(checked) {
            newSelectedSubMenus.add(value)
        } else {
            newSelectedSubMenus.delete(value)
        }
        setSelectedSubMenus(newSelectedSubMenus)
    }

    const isAllChecked = options.every(option => selectedSubMenus.has(option.value))

    return (
        <ThemeProvider theme={theme} >
            <>
                {
                    showSelectHeader && (
                        <PopoverStyledHeader>
                            <FormControl>
                                <FormLabel component="span">{title}: select</FormLabel>
                                <StyledArrowButton>
                                    <ExpandLessRounded htmlColor="#0F172A" />
                                </StyledArrowButton>
                            </FormControl>
                        </PopoverStyledHeader>
                    )
                }
                <FormControlGroup>
                    {
                        !showSelectHeader && (
                            <PopoverStyledHeader className="header" >
                                {title}
                            </PopoverStyledHeader>
                        )
                    }
                    <FormControl>
                        <Checkbox
                            icon={<UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                            id={`${title}-all`}
                            checked={isAllChecked}
                            onChange={handleAllSelectionCheckChange}
                            inputProps={{"aria-checked": isAllChecked, "aria-label": `Check ${title}-all`}}
                        />
                        <FormLabel htmlFor={`${title}-all`}>All</FormLabel>
                    </FormControl>
                    {
                        options.map((option) => (
                            <FormControl
                                key={option.value}
                                className="menu"
                            >
                                <Checkbox
                                    icon={<UncheckedIcon />}
                                    checkedIcon={<CheckedIcon />}
                                    id={`${title}-${option.value}`}
                                    checked={selectedSubMenus.has(option.value)}
                                    inputProps={{"aria-checked": selectedSubMenus.has(option.value)}}
                                    onChange={(event) => handleSubMenuSelectionChange(event.target.checked, option.value)}
                                />
                                <FormLabel htmlFor={`${title}-${option.value}`}>{option.label}</FormLabel>
                            </FormControl>
                        ))
                    }
                    <ClearButton onClick={handleClearFilterSelection} >Clear</ClearButton>
                    <PopoverStyledFooter>
                        <Button variant="contained" onClick={handleCancel} >Cancel</Button>
                        <Button variant="contained" className="primary" >Apply</Button>
                    </PopoverStyledFooter>
                </FormControlGroup>
            </>
        </ThemeProvider>
    )
}
