import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities"
import { configJSON } from "./ForgotPasswordController";

export interface NewPasswordProps {
    navigation: any;
}
  
interface S {
    loading: boolean;
    error: string | null;
    token: string;
}
  
interface SS {
    navigation: any;
}

export default class NewPasswordController extends BlockComponent<
  NewPasswordProps,
  S,
  SS
> {

    newPasswordApiCallId: string | null = null;

    constructor(props: NewPasswordProps) {
        super(props)

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            loading: false,
            error: null,
            token: "",
        }

        this.handleNewPasswordResponse = this.handleNewPasswordResponse.bind(this)
        this.handleRestApiResponse = this.handleRestApiResponse.bind(this)
        this.handleLogin = this.handleLogin.bind(this)
    }

    async receive(from: string, message: Message) {

        const handlers = {
            [getName(MessageEnum.RestAPIResponceMessage)]: this.handleRestApiResponse
        }
    
        const handler = handlers[message.id]
        if(handler) {
            handler(message)
        }
    }

    async componentDidMount() {
        const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
        this.setState({token})
    }

    handleRestApiResponse(message: Message){
        const responseId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        const responseHandlers: Record<string, (newMsg: Message) => void> = {}

        if(this.newPasswordApiCallId !== null) {
            responseHandlers[this.newPasswordApiCallId] = this.handleNewPasswordResponse
        }

        const responseHandler = responseHandlers[responseId];
        if (responseHandler) {
            responseHandler(message);
        }
    }

    async handleNewPasswordResponse(message: Message) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        if(responseJson === undefined) {
            this.setState({error: "Internal server error", loading: false})
        }
        else if(responseJson?.errors?.[0]?.token === "Invalid token") {
            this.setState({error: "Failed to update password", loading: false})
        }
        else if(responseJson?.message) {
            sessionStorage.setItem("from-page", "reset-password")
            await this.handleLogin()
        }
    }

    async handleLogin() {
        await removeStorageData("authToken")
        await removeStorageData("userId")
        sessionStorage.removeItem("authToken")
        sessionStorage.removeItem("userId")
        const loginMsg: Message = new Message(
            getName(MessageEnum.NavigationSignupLoginMessage)
        );
        loginMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(loginMsg);
    }

    handleSetNewPassword = (values: {
        newPassword: string;
        confirmNewPassword: string;
    }) => {
        this.setState({loading: true})
        const header = {
            "Content-Type": configJSON.forgotApiContentType,
            token: this.state.token,
        };

        const httpBody = {
            "new_password": values.newPassword,
            "confirm_password": values.confirmNewPassword
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.newPasswordApiCallId = requestMessage.messageId

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.newPasswordApiEndPoint)
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody))
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod)

        runEngine.sendMessage(requestMessage.id, requestMessage)

    }
}
