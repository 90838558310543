import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";


export type OpenAISaveArgs = {
  name: string;
  message: string;
  cardId?: number;
  follow_ups_attributes: Array<{
    id?: number;
    title: string;
    redirect_user: string;
    button_text: string;
  }>
}

export type FollowUpAction = {
  id: number;
  actionId?: number;
  redirectCardId: string;
  buttonText: string;
  cardIdError?: boolean;
  buttonTextError?: boolean;
  isExpanded: boolean;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  cardId?: number;
  onClose: () => void;
  onSaveClick: (args: OpenAISaveArgs) => void;
  onDrawerClose: () => void;
  loading: boolean;
  name?: string;
  message?: string;
  botContext: string[];
  initialFollowUpActions?: Array<{id: number, redirectCardId: string | null; buttonText: string}>;
  allActionCards: Array<{label: string, value: string}>;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  followUpActions: FollowUpAction[],
  selectedRedirectCardId: string;
  selectedActionId: number | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfmicrosoftazureopenaiintegration1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  actionCards: Props["allActionCards"] = []
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.actionCards = this.props.allActionCards
    if(this.props.cardId) {
      this.actionCards = this.props.allActionCards.filter((card) => card.value !== `${this.props.cardId}`)
    }
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      followUpActions: [{id: Date.now(),redirectCardId: "",buttonText: "", isExpanded: true}],
      selectedActionId:null,
      selectedRedirectCardId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    if(this.props.initialFollowUpActions) {
      const newFollowUpActions = this.props.initialFollowUpActions.map((action) => {
        const newAction: FollowUpAction = {
          id: action.id,
          actionId: action.id,
          redirectCardId: action.redirectCardId ?? "",
          buttonText: action.buttonText,
          isExpanded: false,
        }
        return newAction
      })
      this.setState({followUpActions: newFollowUpActions})
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if(prevState.selectedRedirectCardId !== this.state.selectedRedirectCardId) {
      if(this.state.selectedActionId) {
        this.onActionChange({id: this.state.selectedActionId, redirectCardId: this.state.selectedRedirectCardId, cardIdError: false})
        this.setState({selectedActionId: null, selectedRedirectCardId: ""})
      }
    }
  }


  onAddAction = () => {
    const newFollowUpAction: FollowUpAction = {
      id: Date.now(),
      redirectCardId: "",
      buttonText: "",
      isExpanded: true,
    }
    this.setState({followUpActions: [...this.state.followUpActions, newFollowUpAction]})
  }

  onActionChange = (args: Partial<FollowUpAction>) => {
    const newActions = [...this.state.followUpActions]
    const currentActionIndex = newActions.findIndex(action => action.id === args.id)
    newActions[currentActionIndex] = {
      ...newActions[currentActionIndex],
      ...args
    }
    this.setState({followUpActions: [...newActions]})
  }

  onActionCardChange = (actionId: number) => (cardId: string) => {
    this.setState({selectedActionId: actionId, selectedRedirectCardId: cardId})
  }

  onActionButtonTextChange = (actionId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    const {value} = event.target
    this.onActionChange({id: actionId, buttonText: value, buttonTextError: value.trim().length === 0})
  }

  onActionExpandToggle = (actionId: number) => (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    const newFollowUpActions = [...this.state.followUpActions]
    const currentActionIndex = newFollowUpActions.findIndex(action => action.id === actionId)
    const currentAction = newFollowUpActions[currentActionIndex]
    let isExpanded = !currentAction.isExpanded

    if(currentAction.buttonText.trim().length === 0 || !currentAction.redirectCardId) {
      isExpanded = true
    }

    newFollowUpActions[currentActionIndex] = {
      ...currentAction,
      isExpanded: isExpanded
    }
    this.setState({followUpActions: newFollowUpActions})
  }

  onActionDelete = (actionId: number) => (event: React.MouseEvent<HTMLButtonElement>)=> {
    event.stopPropagation()
    const newFollowUpActions = this.state.followUpActions.filter(action => action.id !== actionId)
    this.setState({followUpActions: newFollowUpActions})
  }

  handleOpenAiSubmit = (values: {name: string, message: string}) => {
    const newFollowUpActions = this.state.followUpActions.map((action) => {
      return {
        ...action,
        buttonTextError: action.buttonText.trim().length === 0,
        cardIdError: !action.redirectCardId
      }
    })

    const hasErrorInFollowUpActions = newFollowUpActions.some(action => action.buttonTextError || action.cardIdError)

    if(hasErrorInFollowUpActions) {
      this.setState({followUpActions: newFollowUpActions})
      return;
    }

    const followUpActionsAttributes = this.state.followUpActions.map((action, actionIndex) => {
      const newAction: OpenAISaveArgs["follow_ups_attributes"][number] =   {
        title: `Follow-up action ${actionIndex + 1}`,
        redirect_user: action.redirectCardId,
        button_text: action.buttonText
      }
      if(action.actionId) {
        newAction["id"] = action.actionId
      }
      return newAction
    })

    this.props.onSaveClick({
      name: values.name.trimStart().trimEnd(),
      message: values.message.trimStart().trimEnd(),
      follow_ups_attributes: followUpActionsAttributes,
      cardId: this.props.cardId
    })

  }

  
  // Customizable Area End
}
