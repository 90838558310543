import React from "react";
import {
    createTheme,
    ThemeProvider,
    Dialog,
    DialogActions,
    FormLabel,
    Typography,
    OutlinedInput,
    FormControl,
    styled,
    CircularProgress,
} from "@material-ui/core";
import * as yup from "yup";
import CustomSelect from "../../../../components/src/CustomSelect.web";
import { Formik } from "formik";
import CreateBotController, { CreateBotProps } from "./CreateBotController";


const qrcodeTheme = createTheme({
    typography: {
        subtitle1: {
            color: "#64748B",
            fontSize: "16px",
            lineHeight: "24px",
            fontFamily: "Cairo",
            fontWeight: 400,
        },
        subtitle2: {
            fontWeight: 400,
            fontSize: 12,
            lineHeight: "18px",
            color: "#94A3B8",
            '&.error': {
                color: "#D97706",
            },
        },
        h4: {
            margin: 0,
            fontFamily: "Cairo",
            fontWeight: 700,
            fontSize: 24,
            lineHeight: "32px",
            color: "#0F172A",
        },
    },
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: '#0F172A',
            },
        },
        MuiPaper: {
            root: {
                scrollbarWidth: "none",
                '&::-webkit-scrollbar': {
                    width: 0,
                },
            },
            elevation24: {
                boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017"
            },
            rounded: {
                borderRadius: 24,
            }
        },
        MuiDialog: {
            paperWidthSm: {
                width: 478,
            },
        },
        MuiDialogActions: {
            root: {
                gap: 10,
                borderTop: "1px solid #CBD5E1",
                padding: "24px 40px",
                zIndex: 2,
                backgroundColor: "#FFFFFF",
                bottom: 0,
                position: "sticky",
                boxSizing: "border-box",
            },
            spacing: {
                '& > :not(:first-child)': {
                    marginLeft: 0
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 8,
                '&:hover $notchedOutline': {
                    borderColor: "#CBD5E1",
                },
                '&.Mui-Focused $notchedOutline': {
                    borderColor: "#51ABB3",
                    borderWidth: 1,
                },
                "&.Mui-error $notchedOutline": {
                    borderColor: "#F59E0B",
                },
            },
            notchedOutline: {
                borderWidth: 1,
                borderColor: "#CBD5E1",
            },
            input: {
                padding: 0,
            },
        },
        MuiInputBase: {
            root: {
                backgroundColor: "#FFFFFF",
                padding: "12px 8px",
            },
            input: {
                fontFamily: "Cairo",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                color: "#64748B",
                height: 20,
            },
        },
        MuiFormLabel: {
            root: {
                fontFamily: "Cairo",
                fontSize: 16,
                lineHeight: "24px",
                fontWeight: 700,
                color: "#0F172A",
                '&.Mui-focused': {
                    color: "#0F172A",
                },
            },
        },
        MuiFormControl: {
            root: {
                display: "flex",
                flexDirection: "column",
                gap: 8,
            },
        },
    },
})

const DialogHeader = styled("div")({
    position: "sticky",
    top: 0,
    zIndex: 2,
    padding: "24px 40px",
    borderBottom: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    boxSizing: "border-box",
})

const DialogContent = styled("div")({
    padding: "24px 40px 230px 40px",
    display: "flex",
    flexDirection: 'column',
    gap: 24,
})

const DialogActionButton = styled("button")({
    all: "unset",
    boxSizing: "border-box",
    cursor: "pointer",
    borderRadius: 8,
    padding: 16,
    width: 105,
    backgroundColor: "#E5F6FF",
    color: "#51ABB3",
    textAlign: "center",
    fontFamily: "Cairo",
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: 16,
    '&.primary': {
        color: "#FFFFFF",
        backgroundColor: "#51ABB3",
    },
    '&[aria-disabled="true"]': {
        pointerEvents: "none",
    },
})

const SpinnerContainer = styled("div")({
    display: "flex",
    justifyContent: "center",
})

const createBotValidationSchema = yup.object().shape({
    chatbotName: yup.string().required("Please add a name"),
    practice: yup.string().required("Please select practice association")
})

export default class CreateBot extends CreateBotController {

    constructor(props: CreateBotProps) {
        super(props)
    }

    render() {
        return (
            <ThemeProvider theme={qrcodeTheme}>
                <Dialog
                    open={this.props.isOpen}
                    onClose={this.props.onClose}
                    BackdropProps={{ style: { opacity: 0.4 } }}
                >
                    <DialogHeader >
                        <Typography variant="h4" >Create Bot</Typography>
                    </DialogHeader>
                    <Formik
                        initialValues={{
                            chatbotName: "",
                            practice: "",
                        }}
                        validationSchema={createBotValidationSchema}
                        onSubmit={this.handleCreateBot}
                        data-testid="formik"
                    >
                        {({ getFieldProps, setFieldValue, values, errors, touched, handleSubmit }) => (
                            <>
                                <DialogContent>
                                    <FormControl>
                                        <FormLabel htmlFor="chatbotName" >Chatbot Name*</FormLabel>
                                        <OutlinedInput
                                            placeholder="Type here"
                                            fullWidth
                                            id="chatbotName"
                                            name="chatbotName"
                                            value={values.chatbotName}
                                            inputProps={{ ...getFieldProps("chatbotName") }}
                                            error={!!errors.chatbotName && touched.practice}
                                        />
                                        {
                                            errors.chatbotName && touched.chatbotName && (
                                                <Typography variant="subtitle2" className="error" data-testid="botname-error" >{errors.chatbotName}</Typography>
                                            )
                                        }
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Practice association*</FormLabel>
                                        <Typography variant="subtitle1" >Select the practice association to this bot</Typography>
                                        {
                                            this.state.practiceLoading ? (
                                                <SpinnerContainer data-testid="spinner" >
                                                    <CircularProgress style={{height: 40, width: 40, color: "#51ABB3"}} />
                                                </SpinnerContainer>
                                            ) : (
                                                <CustomSelect
                                                    key={this.state.practicesOptions.length === 0 ? "no-practice-data": "practice-data"}
                                                    labelId="practice"
                                                    id="select"
                                                    placeholder="Practice association"
                                                    value={values.practice}
                                                    onChange={(value) => setFieldValue("practice", value)}
                                                    hasSearch
                                                    searchPlaceholder="Search practices"
                                                    options={this.state.practicesOptions}
                                                    selectBoxWidth={401}
                                                    error={!!errors.practice && touched.practice}
                                                />
                                            )
                                        }
                                        {
                                            errors.practice && touched.practice && (
                                                <Typography variant="subtitle2" data-testid="practice-error" className="error" >{errors.practice}</Typography>
                                            )
                                        }
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <DialogActionButton onClick={this.props.onClose}>Cancel</DialogActionButton>
                                    <DialogActionButton data-testid="done-btn" aria-disabled={this.state.loading} onClick={() => handleSubmit()} className="primary">
                                        {
                                            this.state.loading ? (
                                                <CircularProgress style={{height: 24, width: 24, color: "#FFFFFF"}} />
                                            ) : "Done"
                                        }
                                    </DialogActionButton>
                                </DialogActions>
                            </>
                        )}
                    </Formik>

                </Dialog>
            </ThemeProvider>
        )
    }
}
