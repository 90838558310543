// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { AssociateFile } from "./types";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  file: string;
  setSelectedOption: any;
  openModal: boolean;
  postTitle: string;
  editorValue: string | undefined;
  AllTagsData: any;
  options: any;
  PracticeData: any;
  tagName: string;

  saveFormLoading: boolean;
  openBulkModal: boolean;
  hasUserInputFieldChanged: boolean;
  showPracticeError: boolean;
  isGlobal: boolean;
  selectedPracticeValue: number[];
  token: string;
  QAData: { question: string, response: string };
  QAId: any;
  selectPracticeError?: string;
  selectedTag: number[];
  showAssociatesDialog: boolean;
  allAssociatedDataFiles: AssociateFile[];
  errors: {
    questionError: string;
    responseError: string;
    practiceValue: string;
    tagName: string;
    fileError: string;
    associationError?: string;

    formDataError: string | undefined;
  }
  showError: boolean,
  fileData: any;
  fileName: string | null;
  fileUrl: string | null;
  initialFileDataId: number | undefined;
  associatedData: { id: number, file_name: string, user_name: string, profile_image: string | null, flag: string } | null
}

interface SS {
  id: any;
}
export default class CreateEditQAController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllTagsApiCallID: string = "";
  postTagsApiCallID: string = "";
  deleteTagsApiCallID: string = "";
  updateTagsApiCallID: string = "";
  getAllPracticeApiCallID: string = "";
  getSubmitpostDetailCallID: string = "";
  getCSVFileCallId: string = "";
  getQAFileTypeId: string = "";
  saveQAFileTypeId: string = "";
  getQACallID: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      file: "",
      setSelectedOption: null,
      openModal: false,
      postTitle: "",
      editorValue: "",

      AllTagsData: [],
      options: [],
      PracticeData: [],
      tagName: "",

      isGlobal: false,
      hasUserInputFieldChanged: false,
      openBulkModal: false,
      saveFormLoading: false,
      showPracticeError: false,
      selectedPracticeValue: [],
      selectedTag: [],
      token: "",
      QAData: {
        question: "",
        response: "",
      },
      QAId: null,
      showAssociatesDialog: false,
      allAssociatedDataFiles: [],
      errors: {
        formDataError: undefined,
        practiceValue: "",
        tagName: "",
        fileError: "",
        questionError: "",
        responseError: ""
      },
      showError: false,
      fileName: null,
      fileData: null,
      fileUrl: null,
      associatedData: null,
      initialFileDataId: undefined,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    this.handleResponseForPostCreationAPI(from, message)
    this.handleResponseForGetQA(from, message)
    this.handleResponseForSavingCSVData(message)
    this.handleResponseForGetFileChange(message)
    this.handleResponseForSaveFileChange(message)
  }
  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    const qaId = this.props.navigation.getParam("navigationBarTitleText")
    this.setState({ token, QAId: qaId })

    if(qaId) {
      this.getQA()
    }
    this.getFileChange("filtered_files")
  }
  

  showAsscociates = () => {
    this.setState({ showAssociatesDialog: true })
  }

  closeAsscociates = () => {
    this.setState({ showAssociatesDialog: false })
  }

  getFileChange = async (fileType: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQAFileTypeId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.qaListingApi}/${fileType}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResponseForGetFileChange = async (message: Message) => {
    if (
      this.getQAFileTypeId !== null &&
      this.getQAFileTypeId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
        return;
      }

      if (responseJson?.all_files) {
        const response = responseJson.all_files
        const files: AssociateFile[] = response.map((list: any) => ({ id: list.id, fileType: list.flag, title: list?.name || "-", data_id: list.data_id }))
        this.setState({ allAssociatedDataFiles: files })
      } else {
        toast.error(responseJson?.error || "Couldn't fetch data, please try again", { className: "error__toast" })
      }
    }
  }

  saveFileChange = async (fileId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveQAFileTypeId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.qaListingApi}/${fileId}/show_file`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResponseForSaveFileChange = async(message: Message) => {
    if (
      this.saveQAFileTypeId !== null &&
      this.saveQAFileTypeId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson.errors?.[0]?.token) {
        await this.handleLogout()
        return;
      }

      if (responseJson?.data) {
        if (responseJson.file_type === "form") {
          this.setState({ associatedData: { id: responseJson.data.id, flag: responseJson.file_type, file_name: responseJson.data.form_name, user_name: `${responseJson?.first_name} ${responseJson?.last_name}`, profile_image: responseJson.profile_image }, showAssociatesDialog: false, hasUserInputFieldChanged: true })
        }
        else if (responseJson.file_type === "user_guide") {
          this.setState({ associatedData: { id: responseJson.data.id, flag: responseJson.file_type, file_name: responseJson.data.title, user_name: `${responseJson?.first_name} ${responseJson?.last_name}`, profile_image: responseJson.profile_image }, showAssociatesDialog: false, hasUserInputFieldChanged: true })
        }
        else if (responseJson.file_type === "sign_posting") {
          this.setState({ 
            associatedData: { id: responseJson.data.id, flag: responseJson.file_type, file_name: responseJson.data.organisation_name, user_name: `${responseJson?.first_name} ${responseJson?.last_name}`, profile_image: responseJson.profile_image }, 
            showAssociatesDialog: false, 
            hasUserInputFieldChanged: true,
            initialFileDataId: responseJson.data_id 
          })
        }
      } else {
        toast.error(responseJson?.error || "Couldn't upload data, please try again", { className: "error__toast" })
      }
    }
  }

  handleChange = (key: string, value: string | undefined) => {
    this.setState((prev) => ({
      QAData: {
        ...prev.QAData,
        [key]: value,
      },
      errors: { ...prev.errors, questionError: "", responseError: "" },
      hasUserInputFieldChanged: true,
    }))
  }

  handleDisabled = () => {
    return !!this.state.associatedData && !this.state.QAId
  }

  handleFileChange = (event: { target: { files: any[]; }; }) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileType = file.type;
    const fileSize = file.size / 1024 / 1024; // size in MB

    if (fileType !== 'text/csv') {
      this.setState((prev) => ({ errors: { ...prev.errors, fileError: "Only CSV files are allowed." }, fileName: null, showError: true, fileData: null }));
      return;
    }

    if (fileSize > 10) {
      this.setState((prev) => ({ errors: { ...prev.errors, fileError: "File size should not exceed 10MB." }, fileName: null, showError: true, fileData: null }));
      return;
    }
    const url = URL.createObjectURL(file);
    this.setState((prev) => ({ errors: { ...prev.errors, fileError: "" }, fileName: file.name, showError: false, fileUrl: url, fileData: file }));
  };

  handleShowError = () => {
    this.setState({ showError: !this.state.showError })
  }

  saveCSVFileData = async () => {
    if (this.validateBulkUploadForm()) {
      const header = {
        "token": this.state.token,
      };
      const formdata = new FormData();
      formdata.append(
        "file",
        this.state.fileData
      );
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCSVFileCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.QABulkUploadApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPiEndMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleResponseForSavingCSVData = async(message: Message) => {
    if (
      this.getCSVFileCallId !== null &&
      this.getCSVFileCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson.errors?.[0]?.token) {
        await this.handleLogout()
        return;
      }

      if (responseJson?.message || responseJson?.created_records) {
        this.onFormCloseHandler()
        toast.success(responseJson?.message || "Your data was successfully uploaded")
      } else {
        toast.error(responseJson?.error || "Couldn't upload data, please try again", { className: "error__toast" })
      }
    }
  }

  handleViewFile = () => {
    const csvContent = `Question,Response,Global,Associated practice name,Tags,Status,Related Resource Type,Associated resource ID`

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'Q&A CSV - Sheet.csv';

    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  getQA = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQACallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.qaListingApi}/${this.state.QAId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResponseForGetQA = async(from: string, message: Message) => {
    if (
      this.getQACallID !== null &&
      this.getQACallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(!responseJson) {
        toast.error("Failed to fetch!", { className: "error__toast" })
        return;
      }

      if(responseJson.errors?.[0]?.token) {
        await this.handleLogout()
        return;
      }

      if (responseJson.data) {
        const { question, response, is_global, user_guides, forms, sign_postings, practice = [], tag = [] } = responseJson.data.attributes
        let initialFileDataId: number | undefined = undefined;
        if (user_guides.length) {
          initialFileDataId = user_guides[0].id
        }
        else if (forms.length) {
          initialFileDataId = forms[0].id
        }
        else if(sign_postings.length) {
          initialFileDataId = sign_postings[0].id;
        }
        this.setState({
          QAData: {
            question, response,
          },
          selectedPracticeValue: practice,
          isGlobal: is_global,
          selectedTag: tag,
          initialFileDataId,
        });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.allAssociatedDataFiles.length !== this.state.allAssociatedDataFiles.length && this.state.initialFileDataId) {
      const fileId = this.state.allAssociatedDataFiles.find(file => file.data_id === this.state.initialFileDataId)!.id
      this.saveFileChange(fileId)
    }
  }

  handleCloseModal = () => {
    this.setState({
      openModal: false,
    })
  }
  
  handleTitle = (event: any) => {
    this.setState({
      postTitle: event.target?.value
    })
  }

  handleEditorChange = (value: string | undefined) => {
    this.setState({ editorValue: value });
  };

  handlePractice = (selectedItems: any) => {
    this.setState({
      selectedPracticeValue: selectedItems
    })
  };

  onFormCloseHandler = () => {
    const redirectTo = sessionStorage.getItem("redirectTo") || "qa"
    const botId = sessionStorage.getItem("botId")

    let navigationMessage = new Message(getName(MessageEnum.NavigationQAMessage))

    if (redirectTo === "bot" && botId) {
      navigationMessage = new Message(getName(MessageEnum.NavigationIndividualBotMessage))
      navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), botId)
    }
    sessionStorage.removeItem("redirectTo")
    sessionStorage.removeItem("botId")

    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      { navigation: this.props.navigation }
    )

    this.send(navigationMessage)

  }

  handleSelectTag = (values: number[]) => {
    this.setState({ hasUserInputFieldChanged: true, selectedTag: values})
  };

  handleCloseError = (stateKey: Extract<keyof S, "showOptionError" | "showPracticeError">) => () => {
    this.setState({ ...this.state, [stateKey]: false })
  }

  showBulkModal = () => {
    this.setState(() => ({ openBulkModal: true }))
  }

  closeBulkModal = () => {
    this.setState({ openBulkModal: false })
  }

  handleGolbalSwitchChange = (event: { target: { checked: any; }; }) => {
    this.setState({
      isGlobal: event.target.checked,
      selectedPracticeValue: [],
      hasUserInputFieldChanged: true,
      showPracticeError: false
    })
  }

  handleChangeSelectedPractieValue = (value: number[]) => {
    let error: string | undefined = undefined
    if (value.length === 0 && !this.state.isGlobal) {
      error = "Please select form association"
    }
    this.setState({ selectedPracticeValue: value, selectPracticeError: error, hasUserInputFieldChanged: true, showPracticeError: true })
  }

  saveQAData = async () => {
    if (this.validateForm()) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token,
      };
      const association = this.state.associatedData
      const httpBody = {
        question_and_answer: {
          question: this.state.QAData.question,
          response: this.state.QAData.response,
          is_global: this.state.isGlobal,
          active: false,
          practice: this.state.selectedPracticeValue,
          tag: this.state.selectedTag,
          form_ids: association?.flag === "form" ? [association.id] : [],
          guide_ids: association?.flag === "user_guide" ? [association.id] : [],
          signposting_ids: association?.flag === "sign_posting" ? [association.id] : [],
        }
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getSubmitpostDetailCallID = requestMessage.messageId;

      if (!this.state.QAId) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.qaListingApi
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postAPiEndMethod
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.qaListingApi}/${this.state.QAId}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.putAPiEndMethod
        );
      }


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleResponseForPostCreationAPI = async(from: string, message: Message) => {
    if (
      this.getSubmitpostDetailCallID !== null &&
      this.getSubmitpostDetailCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson) {
        toast.error(responseJson?.errors || "Couldn't submit data, please try again", { className: 'error__toast' });
        return;
      }

      if(responseJson.errors?.[0]?.token) {
        await this.handleLogout()
        return;
      }

      if (responseJson) {
        const message = this.state.QAId ? "Q&A updated successfully" : "Q&A created successfully"
        toast.success(message);
        this.onFormCloseHandler()
      }
    }
  }

  validateForm = () => {

    let isValid = true;

    if (!this.state.QAData.question.trim()) {
      this.setState((prev) => ({ errors: { ...prev.errors, questionError: "Please add question" } }))
      toast.error("Please add question", { className: 'error__toast' });
      isValid = false
    }
    else if (!this.state.QAData.response && this.state.QAData.question) {
      this.setState((prev) => ({ errors: { ...prev.errors, responseError: "Please add response" } }))
      toast.error("Please add response", { className: 'error__toast' });
      isValid = false
    }
    else if(this.state.QAData.response && this.state.QAData.question.trim()) {
      const text = this.state.QAData.response.replace(/<[^>]*>?/gm, '').trim();
      if(!text){
        toast.error('Please add response', { className: 'error__toast' });    
        this.setState((prev) => ({ errors: { ...prev.errors, responseError: "Please add response" } }))
        return
      }
    }
    if (!this.state.selectedPracticeValue.length && !this.state.isGlobal && this.state.QAData.question && this.state.QAData.response) {
      this.setState((prev) => ({ selectPracticeError: "Please select form association", showPracticeError: true }))
      isValid = false
    }
    return isValid;
  }

  validateBulkUploadForm = () => {

    const errors = { practiceValue: "", tagName: "", formDataError: "", selectPracticeError: "", fileError: "", questionError: "", responseError: "" }
    let isValid = true;

    if (!this.state.fileData) {
      this.setState((prev) => ({ errors: { ...prev.errors, fileError: "Please select CSV file" }, showError: true }))
      return
    }

    this.setState({ errors });
    return isValid;
  }

  handleLogout = async() => {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const message: Message = new Message(
      getName(MessageEnum.NavigationSignupLoginMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

}
// Customizable Area End