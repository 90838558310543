import React from "react";
import { Avatar, CircularProgress, styled } from "@material-ui/core";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { getStorageData, removeStorageData } from "../../framework/src/Utilities";

const Header = styled("header")({
    padding: "24px 48px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    top: 0,
    backgroundColor: "#FAFAFA",
    zIndex: 50,
})

const HeaderTitle = styled("h1")({
    fontFamily: "Cairo",
    fontSize: 24,
    lineHeight: "32px",
    fontWeight: 700,
    color: "#0F172A",
    margin: 0,
})

const SearchContainer = styled("div")({
    border: "1px solid #CBD5E1",
    borderRadius: 98,
    backgroundColor: "#FFFFFF",
    padding: "10px 8px",
    width: 431,
    height: 44,
    display: "flex",
    gap: 8,
    boxSizing: "border-box",
})

const SearchInput = styled("input")({
    border: 0,
    outline: 0,
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 24,
    color: "#64748B",
    caretColor: "#94A3B8",
})

const UserInfoContainer = styled("div")({
    display: "flex",
    gap: 8,
    cursor: "pointer",
    '& p': {
        margin: 0,
        fontFamily: "Cairo",
    },
    '& .column': {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    '& .clip-text': {
        maxWidth: "20ch",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
})

const UserAvatar = styled(Avatar)({
    height: 44,
    width: 44,
    boxSizing: "border-box",
    border: "2px solid #E5E6FF",
    backgroundColor: "#E5E6FF",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "26px",
    color: "#64748B",
    textAlign: "center",
})

const UserName = styled("p")({
    color: "#334155",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "22px",
})

const UserRole = styled("p")({
    color: "#475569",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "18px",
})

interface Props {
    title: string;
    showSearchBox?: boolean;
    searchInputVal?: string;
    onSearchInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    navigation: {
        navigate: (to: string, params: Record<string, string | number>) => void;
        getParam: (param: string, alternative: string) => void;
        goBack: () => void;
    };
}

interface S {
    loading: boolean;
    user: {
        name: string;
        role: string;
        profile_picture: string | null;
    } | null;
}

interface SS { }


export default class CustomAppHeader extends BlockComponent<Props, S, SS> {

    showUserProfileApiId: string | null = null;
    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            loading: false,
            user: null,
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.handleGetUserProfile = this.handleGetUserProfile.bind(this)
        this.handleShowUserProfileResponse = this.handleShowUserProfileResponse.bind(this)
    }

    async receive(from: string, message: Message) {
        const restApiResponseMessage = getName(MessageEnum.RestAPIResponceMessage)
        if(message.id !== restApiResponseMessage) {
            return;
        }
        if(this.showUserProfileApiId === null) {
            return;
        }
        await this.handleShowUserProfileResponse(message)
    }

    async componentDidMount(): Promise<void> {
        const user = sessionStorage.getItem("user")

        if (user) {
            this.setState({ user: JSON.parse(user) })
            return;
        }

        const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
        this.handleGetUserProfile(token)
    }

    async handleShowUserProfileResponse(message: Message) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

        if(!responseJson) {
            return;
        }

        if(responseJson.errors) {
            await removeStorageData("authToken")
            await removeStorageData("userId")
            sessionStorage.clear()
            const message = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
            message.addData(getName(MessageEnum.NavigationPropsMessage), { navigation: this.props.navigation });
            this.send(message)
            return;
        }

        if(!responseJson.data) {
            return;
        }

        const attributes = responseJson.data.attributes
        const user = {
            name: attributes.first_name + " " + attributes.last_name,
            role: attributes.role,
            profile_picture: attributes.profile_image
        }
        this.setState({
            user: user,
            loading: false,
        })
        sessionStorage.setItem("user", JSON.stringify(user))
    }

    handleGetUserProfile(token: string) {
        this.setState({loading: true})
        const header = {
            "Content-Type": "application/json",
            token: token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.showUserProfileApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "account_block/accounts/show_profile"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleUserProfileNavigation = () => {
        if(this.state.loading) {
            return;
        }
        const message = new Message(getName(MessageEnum.NavigationUserProfileMessage))
        message.addData(getName(MessageEnum.NavigationPropsMessage), { navigation: this.props.navigation });
        this.send(message);
    }

    render() {
        return (
            <Header>
                <HeaderTitle>{this.props.title}</HeaderTitle>
                {
                    this.props.showSearchBox ? (
                        <SearchContainer>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.1872 14.4715H15.9772L20.2172 18.7315C20.6272 19.1415 20.6272 19.8115 20.2172 20.2215C19.8072 20.6315 19.1372 20.6315 18.7272 20.2215L14.4772 15.9715V15.1815L14.2072 14.9015C12.8072 16.1015 10.8972 16.7215 8.86719 16.3815C6.08719 15.9115 3.86719 13.5915 3.52719 10.7916C3.00719 6.56158 6.56719 3.00157 10.7972 3.52157C13.5972 3.86157 15.9172 6.08158 16.3872 8.86158C16.7272 10.8916 16.1072 12.8016 14.9072 14.2015L15.1872 14.4715ZM5.47719 9.97157C5.47719 12.4616 7.48719 14.4716 9.97719 14.4716C12.4672 14.4716 14.4772 12.4616 14.4772 9.97157C14.4772 7.48157 12.4672 5.47157 9.97719 5.47157C7.48719 5.47157 5.47719 7.48157 5.47719 9.97157Z" fill="#94A3B8" />
                            </svg>
                            <SearchInput type="text" value={this.props.searchInputVal} onChange={this.props.onSearchInputChange} placeholder="Search" />
                        </SearchContainer>
                    ) : null
                }
                <UserInfoContainer role="button" onClick={this.handleUserProfileNavigation} >
                    <UserAvatar  src={this.state.user?.profile_picture ?? undefined} >
                        {
                            this.state.loading ? (
                            <CircularProgress style={{height: 24, width: 24, color: "#64748B"}} />
                            ) : (
                                <span>{this.state.user?.name?.[0]}</span>
                            )
                        }
                    </UserAvatar>
                    <div className="column" >
                        {
                            this.state.loading ? (
                                <UserName>Loading...</UserName>
                            ) : (
                                <>
                                    <UserName className="clip-text" >{this.state.user?.name}</UserName>
                                    <UserRole>{this.state.user?.role}</UserRole>
                                </>
                            )
                        }
                    </div>
                </UserInfoContainer>
            </Header>
        )
    }
}
