import React, { useRef, useState } from "react";
import { 
    ThemeProvider, 
    createTheme, 
    Box, 
    Typography, 
    InputLabel, 
    OutlinedInput,
    Checkbox, 
    makeStyles,
    styled, 
} from "@material-ui/core";
import { AddCircleOutlineRounded, DragIndicatorOutlined, RemoveCircle } from "@material-ui/icons";
import { useFormik } from "formik";
import { DragDropContext, Draggable, DropResult, Droppable } from "@hello-pangea/dnd";
import {
    theme as cardTheme
} from "../theme.web";
import UncheckedIcon from "./UncheckedIcon.web";
import CheckedIcon from "./CheckedIcon.web";
import MaximumOptionError from "./MaximumOptionError.web";
import CardHeader from "./CardHeader.web";
import MessageTextArea from "./MessageTextArea.web";
import CardFooter from "./CardFooter.web";
import { schema } from "./schema"


const {
    MuiFormLabelRootStyle,
    MuiInputBaseRootStyle,
    MuiOutlinedInputRootStyle,
    drawerCard,
    MuiTypographyStyle,
} = cardTheme


type SingleOrMultiChoiceOption = {
    id: string;
    value: string;
    name: string;
}

type OptionListProps = {
    options: SingleOrMultiChoiceOption[];
    onValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onRemoveOption: (id: string) => () => void;
    handleSwapOptions: (newOptions: SingleOrMultiChoiceOption[]) => void
}

export type SingleOrMultiChoiceFormValues = {
    name: string;
    message: string;
    fieldName: string;
    options: string[];
    type: "single_choice" | "multi_choice";
    id?: number;
}

export type SingleOrMultiChoiceProps = {
    onClose: () => void;
    onSave: (values: SingleOrMultiChoiceFormValues) => void;
    loading: boolean;
    title: string;
    type: "single_choice" | "multi_choice";
    name?: string;
    message?: string;
    fieldName?: string;
    initialOptions?: string[];
    id?: number;
    onDrawerClose: () => void;
    botContext: string[];
}

const defaultOptions = (options: string[]): SingleOrMultiChoiceOption[] => {
    return options.map((option, index) => ({
        id: `option-${index + 1}`,
        value: option,
        name: `option-${index + 1}`,
    }))
}


const Option = styled("div")({
    display: "flex",
    gap: 4,
    alignItems: "center",
})

const OptionList = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 8,
})

const useStyles = makeStyles({
    container: drawerCard.container,
    drawerContent: drawerCard.drawerContent,
    botContainer: drawerCard.botContainer,
    formControl: drawerCard.formControl,
    addOptionButton: {
        ...drawerCard.addLinkOrOptionButton,
        fontFamily: "Cairo",
    },
    iconButton: drawerCard.iconButton,
})

const theme = createTheme({
    typography: MuiTypographyStyle,
    overrides: {
        MuiFormLabel: {
            root: {
                ...MuiFormLabelRootStyle,
                '&.Mui-bot': {
                    lineHeight: "24px",
                    color: "#0F172A",
                    fontWeight: 400,
                    fontSize: 16,
                },
            }
        },
        MuiInputBase: MuiInputBaseRootStyle,
        MuiOutlinedInput: {
            root: MuiOutlinedInputRootStyle,
            notchedOutline: {
                borderColor: "#CBD5E1",
                borderWidth: 1,
            },
            input: {
                padding: 0,
            },
        },
    }
})


export default function SingleOrMultiChoice({ 
    onClose, 
    onSave, 
    loading, 
    title, 
    type,
    name="",
    message = "",
    fieldName = "",
    initialOptions = ["Option 1", "Option 2", "Option 3"],
    id,
    onDrawerClose,
    botContext
}: SingleOrMultiChoiceProps) {
    const classes = useStyles()
    const [optionError, setOptionError] = useState<string | null>(null)
    const [options, setOptions] = useState<SingleOrMultiChoiceOption[]>(() => defaultOptions(initialOptions))
    const disableAddOption = useRef<boolean>(false)
    const optionLengthRef = useRef<number>(defaultOptions(initialOptions).length)
    const { values, getFieldProps, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik({
        initialValues: { 
            name: name, 
            message: message, 
            saveIntoBotContext: !!fieldName, 
            fieldName: fieldName, 
        },
        onSubmit: (values) => {
            if(options.length === 0) {
                setOptionError("Please add a option")
                return;
            }
            const hasInvalidOption = options.some(option => option.value.trim() === "")
            if(hasInvalidOption) {
                setOptionError("Please fill all options")
                return;
            }
            setOptionError(null)
            onSave({id, ...values, options: options.map(option => option.value.trim()), type})
        },
        validationSchema: schema.ratingValidationSchema(botContext.filter(ctxName => ctxName !== fieldName))
    })

    const handleAddMoreOptions = () => {
        if(options.length === 10) {
            disableAddOption.current = true
            setOptionError("You already added the maximum number of options")
            return;
        }
        optionLengthRef.current = optionLengthRef.current + 1
        const newOption: SingleOrMultiChoiceOption = {
            id: `option-${optionLengthRef.current}`,
            value: `Option ${optionLengthRef.current}`,
            name: `option-${optionLengthRef.current}`,
        }
        setOptions([...options, newOption])
    }

    const handleOptionInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target
        const newOptions = [...options]
        const currentOptionsIndex = newOptions.findIndex(option => option.name === name)
        newOptions[currentOptionsIndex] = {...newOptions[currentOptionsIndex], value}
        setOptions(newOptions)
    }

    const handledeleteOption = (id: string) => () => {
        const newOptions = options.filter((option) => option.id !== id)
        disableAddOption.current = false
        setOptions(newOptions)
    }

    const handleSwapOptions = (newOptions: SingleOrMultiChoiceOption[]) => setOptions(newOptions)


    const hasMessageError = !!errors.message && !!touched.message
    const hasNameError = !!errors.name && !!touched.name
    const hasFieldNameError = !!errors.fieldName && !!touched.fieldName

    return (
        <ThemeProvider theme={theme}>
            <Box className={classes.container} >
                <CardHeader id={id} onClose={onClose} title={title} data-testid="cardHeader" />
                <Box className={classes.drawerContent} >
                    <Box className={classes.formControl} >
                        <InputLabel htmlFor="title" >Name*</InputLabel>
                        <OutlinedInput
                            placeholder={type === "single_choice" ? "Single choice card" : "Multi choice card"}
                            fullWidth
                            id="name"
                            name="name"
                            inputProps={{ ...getFieldProps("name"), maxLength: 50 }}
                            error={hasNameError}
                        />
                        <div style={{ display: "flex", justifyContent: "space-between", gap: 4 }} >
                            {
                                hasNameError && <Typography className="error" variant="subtitle2" >{errors.name}</Typography>
                            }
                            <Typography variant="subtitle2" style={{ marginLeft: "auto" }} >{values.name.trimStart().trimEnd().length}/50</Typography>
                        </div>
                    </Box>
                    <MessageTextArea 
                        botContext={botContext}
                        getFieldProps={getFieldProps}
                        hasMessageError={hasMessageError}
                        messageErrorText={errors.message}
                        value={values.message}
                        setFieldValue={setFieldValue}
                    />
                    <Box className={classes.botContainer} >
                        <Box style={{ display: "flex", gap: 8, alignItems: "center" }} >
                            <Checkbox
                                icon={<UncheckedIcon />}
                                style={{ padding: 0 }}
                                id="saveIntoBotContext"
                                name="saveIntoBotContext"
                                checked={values.saveIntoBotContext}
                                onChange={(ctxEvent) => {
                                    handleChange(ctxEvent)
                                    if(!ctxEvent.target.checked) {
                                        setFieldValue("fieldName", "")
                                    }
                                }}
                                disableRipple
                                checkedIcon={<CheckedIcon />}
                            />
                            <InputLabel className="Mui-bot" htmlFor="saveIntoBotContext" >Save in bot context</InputLabel>
                        </Box>
                        {values.saveIntoBotContext && (
                            <Box className={classes.formControl} >
                                <InputLabel htmlFor="fieldName" >Field name*</InputLabel>
                                <OutlinedInput
                                    placeholder="Type here"
                                    error={hasFieldNameError}
                                    fullWidth
                                    id="fieldName"
                                    inputProps={{ ...getFieldProps("fieldName"), maxLength: 50 }}
                                    name="fieldName"
                                    className="secondary"
                                />
                                <div style={{ display: "flex", gap: 4, justifyContent: hasFieldNameError ? "space-between" : "flex-end" }} >
                                    {
                                        hasFieldNameError && <Typography className="error" variant="subtitle2" >{errors.fieldName}</Typography>
                                    }
                                    <Typography variant="subtitle2" >{values.fieldName.trimStart().trimEnd().length}/50</Typography>
                                </div>
                            </Box>
                        )}
                    </Box>
                    <Box className={classes.formControl} style={{gap: 8}} >
                        <InputLabel htmlFor="options" >{`Option(s) (${options.length})*`}</InputLabel>
                        <Typography variant="subtitle2" >Maximum 10 options</Typography>
                        <MaximumOptionError error={optionError} handleClose={() => setOptionError(null)} />
                        <button id="add-link" aria-disabled={disableAddOption.current} onClick={handleAddMoreOptions} className={classes.addOptionButton}>
                            <AddCircleOutlineRounded htmlColor="currentColor" style={{height: 20, width: 20}} />
                            Add option
                        </button>
                        {
                            options.length > 0 && (
                                <OptionsList 
                                    options={options} 
                                    onRemoveOption={handledeleteOption} 
                                    onValueChange={handleOptionInputChange}
                                    handleSwapOptions={handleSwapOptions}  
                                />
                            )
                        }
                    </Box>
                </Box>
                <CardFooter onClose={onDrawerClose} onSaveClick={() => handleSubmit()} loading={loading} />
            </Box>
        </ThemeProvider>
    )
}

function OptionsList({options, onValueChange, onRemoveOption, handleSwapOptions}: OptionListProps) {
    const classes = useStyles()

    const onDragEnd = (result: DropResult) => {
        if(!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }
        const newOptions = [...options]
        const [removed] = newOptions.splice(result.source.index, 1);
        newOptions.splice(result.destination.index, 0, removed);
        handleSwapOptions(newOptions)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd} >
            <Droppable droppableId="options" >
                {(droppableProvided) => (
                    <OptionList
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                    >
                        {
                            options.map((option, index) => (
                                <Draggable key={option.id} index={index} draggableId={option.id} >
                                    {(provided) => (
                                        <Option ref={provided.innerRef} {...provided.draggableProps}>
                                            <button {...provided.dragHandleProps} aria-label={`drag ${option.name}`} className={classes.iconButton} >
                                                <DragIndicatorOutlined style={{cursor: "grab"}} htmlColor="#64748B" />
                                            </button>
                                            <OutlinedInput
                                                placeholder="Type your option here"
                                                fullWidth
                                                id={option.id}
                                                name={option.name}
                                                value={option.value}
                                                onChange={onValueChange}
                                                inputProps={{"aria-label": option.id}}
                                            />
                                            <button aria-label={`remove ${option.id}`} onClick={onRemoveOption(option.id)} className={`${classes.iconButton} pointer`} >
                                                <RemoveCircle htmlColor="#DC2626" />
                                            </button>
                                        </Option>
                                    )}
                                </Draggable>
                            ))
                        }
                    </OptionList>
                )}
            </Droppable>
        </DragDropContext>
    )
}


