// Customizable Area Start
import React from "react";

import {
  Button
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";

import { theme as styledTheme } from "./theme.web";
const { FormFieldsContainer, Column, StyledRow } = styledTheme
import Header from "./components/Header.web";
import FormInputWithLabel from "./components/FormInputWithLabel.web";
import BulkUploadModal from './components/BulkUploadModal.web'
import TrainingHubSettings from "../../../components/src/TrainingHubSettings.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 800,
    },
  },
});


import CreateEditProjectPortfolioController, {
  Props,
} from "./CreateEditProjectPortfolioController";

export default class CreateEditProjectPortfolio extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CreateEditProjectPortfolioContainer>
          <Sidebar navigation={this.props.navigation} activeMenu="signposting" />
          <Column>
            <Header loading={this.state.saveFormLoading} onSaveClick={this.saveSignPostingData} onExitForm={this.onFormCloseHandler} hasUserInputFieldChanged={this.state.hasInputFieldChanged} />
            <StyledRow className="flex-1" >
              <Column className="gap-8" style={{ padding: "12px 48px" }}>
                <FormFieldsContainer>
                  <Button className="bulk-upload-btn" onClick={this.showBulkModal}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z" fill="#51ABB3" />
                    </svg>
                    Bulk upload
                  </Button>
                  <FormInputWithLabel
                    label="ID"
                    placeholder="Type ID here"
                    id="type_id"
                    name="type_id"
                    value={this.state.organisation.type_id}
                    onValueChange={this.handleChange}
                    maxLength={50}
                  />
                  <Column style={{ gap: "24px" }}>
                  <StyledRow className="input-row space-between">
                    <FormInputWithLabel
                      label="Organisation Name"
                      placeholder="Type Organisation Name here"
                      id="organisation_name"
                      name="organisation_name"
                      value={this.state.organisation.organisation_name}
                      onValueChange={this.handleChange}
                      maxLength={50}
                    />
                    <FormInputWithLabel
                      label="Organisation Code"
                      placeholder="Type Organisation Code here"
                      id="organisation_code"
                      name="organisation_code"
                      value={this.state.organisation.organisation_code}
                      onValueChange={this.handleChange}
                      maxLength={50}
                    />
                  </StyledRow>
                  <StyledRow className="input-row space-between">
                      <FormInputWithLabel
                        label="Service Type"
                        placeholder="Type Service Type here"
                        id="service_type"
                        name="service_type"
                        value={this.state.organisation.service_type}
                        onValueChange={this.handleChange}
                        maxLength={50}
                      />
                      <FormInputWithLabel
                        label="Access Route"
                        placeholder="Type Access Route here"
                        id="access_route"
                        name="access_route"
                        value={this.state.organisation.access_route}
                        onValueChange={this.handleChange}
                        maxLength={50}
                      />
                    </StyledRow>
                    <StyledRow className="input-row space-between">
                      <FormInputWithLabel
                        label="Website URL"
                        placeholder="Type Website URL here"
                        id="website_url"
                        name="website_url"
                        value={this.state.organisation.website_url}
                        onValueChange={this.handleChange}
                        maxLength={50}
                      />
                      <FormInputWithLabel
                        label="Contact number"
                        placeholder="Type Contact number here"
                        id="contact_number"
                        name="contact_number"
                        value={this.state.organisation.contact_number}
                        onValueChange={this.handleChange}
                        maxLength={50}
                      />
                    </StyledRow>
                  </Column>
                  <div style={{ borderBottom: "1px solid #E2E8F0" }}></div>
                  <Column style={{ gap: "24px" }}>
                    <StyledRow className="input-row space-between">
                      <FormInputWithLabel
                        label="Street"
                        placeholder="Type Street here"
                        id="street"
                        name="street"
                        value={this.state.organisation.street}
                        onValueChange={this.handleChange}
                        maxLength={50}
                      />
                      <FormInputWithLabel
                        label="Town"
                        placeholder="Type Town here"
                        id="town"
                        name="town"
                        value={this.state.organisation.town}
                        onValueChange={this.handleChange}
                        maxLength={50}
                      />
                    </StyledRow>
                    <StyledRow className="input-row space-between">
                      <FormInputWithLabel
                        label="Postcode"
                        placeholder="Type Postcode here"
                        id="postcode"
                        name="postcode"
                        value={this.state.organisation.postcode}
                        onValueChange={this.handleChange}
                        maxLength={50}
                      />
                      <FormInputWithLabel
                        label="Country"
                        placeholder="Type Country here"
                        id="country"
                        name="country"
                        value={this.state.organisation.country}
                        onValueChange={this.handleChange}
                        maxLength={50}
                      />
                    </StyledRow>
                    <StyledRow className="input-row space-between">
                      <FormInputWithLabel
                        label="Criteria"
                        placeholder="Type Criteria here"
                        id="criteria"
                        name="criteria"
                        value={this.state.organisation.criteria}
                        onValueChange={this.handleChange}
                        maxLength={50}
                      />
                      <FormInputWithLabel
                        label="Opening Times"
                        placeholder="Type Opening Times  here"
                        id="opening_times"
                        name="opening_times"
                        type="time"
                        value={this.state.organisation.opening_times}
                        onValueChange={this.handleChange}
                        maxLength={50}
                      />
                    </StyledRow>
                  </Column>
                </FormFieldsContainer>
              </Column>
              <TrainingHubSettings 
                data-testid="settings"
                handleLogout={this.handleLogout}
                tagType="sign_posting"
                selectedPractices={this.state.selectedPracticeValue}
                showPracticeError={this.state.showPracticeError}
                handleClosePracticeError={() => this.handleCloseError("showPracticeError")}
                handlePracticeAssociationApply={this.handleChangeSelectedPractieValue}
                handleGlobalSwitchChange={this.handleGolbalSwitchChange}
                isGlobal={this.state.isGlobal}
                selectedTags={this.state.selectedTag}
                handleTagsApply={this.handleTagsApply}
              />
            </StyledRow>
          </Column>
        </CreateEditProjectPortfolioContainer>
        <BulkUploadModal handleViewFile={this.handleViewFile} saveCSVFileData={this.saveCSVFileData} open={this.state.openBulkModal} onClose={this.closeBulkModal} handleFileChange={this.handleFileChange} showError={this.state.showError} fileUrl={this.state.fileUrl} handleCloseError={this.handleShowError} errorMessage={this.state.errors.fileError} fileName={this.state.fileName} />
      </ThemeProvider>
    );
  }
}

const CreateEditProjectPortfolioContainer = styled("div")({
  display: "flex",
  backgroundColor: "#FAFAFA",
  alignItems: "stretch",
  justifyContent: "stretch",

  "& .bulk-upload-btn": {
    cursor: "pointer",
    width: "142px",
    height: "32px",
    padding: "6px 10px 6px 10px",
    gap: "6px",
    opacity: "0px",
    background: "#EDF6F6",
    color: "#51ABB3",
    fontFamily: "Inter",
    fontWeight: 700,
    textTransform: "capitalize",
    borderRadius: "8px"
  },
  "& .bulk-upload-btn span": {
    gap: "6px",
  },
  "& .input-row": {
    width: "100%",
    gap: "24px",

    "@media(max-width: 767px)": {
      flexDirection: "column"
    },
  },
  "& .input-row div": {
    width: "100%"
  }
})

// Customizable Area End
