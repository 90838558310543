import React from "react";
import { styled } from "@material-ui/core";
import { QuestionType } from "../AutomaticFormCreationController.web";
import { AddCircleOutlineRounded } from "@material-ui/icons";

const OptionsContainer = styled("div")({
    display: "flex",
    gap: 12,
    flexWrap: "wrap",
})

const OptionButton = styled("button")({
    all: "unset",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 6,
    '& .icon': {
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 98,
        height: 72,
        backgroundColor: "#FFFFFF",
        border: "1px solid #CBD5E1",
        boxShadow: "0px 2px 8px 0px #00000014",
        borderRadius: 12,
        color: "#64748B",
    },
    '& .label': {
        fontFamily: "Cairo",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "18px",
        color: "#0F172A",
    },
    '&:hover': {
        '& .icon': {
            color: "#51ABB3",
            borderColor: "#51ABB3",
        },
        '& .label': {
            color: "#51ABB3",
        },
    },
    '&[aria-disabled="true"]': {
        pointerEvents: "none",
    },
})

const options: Array<{ type: QuestionType, title: string }> = [
    {
        type: "single_line",
        title: "Single line"
    },
    {
        type: "multiline",
        title: "Multiline"
    },
    {
        type: "numerical",
        title: "Numerical",
    },
    {
        type: "date",
        title: "Date"
    },
    {
        type: "dropdown",
        title: "Dropdown"
    },
    {
        type: "radio",
        title: "Radio"
    },
    {
        type: "checkbox",
        title: "Checkbox"
    }
]

type OptionsProp = {
    questionsLength: number;
    handleAddField: (type: QuestionType) => () => void
}

export default function Options({ questionsLength, handleAddField }: OptionsProp) {
    return (
        <OptionsContainer>
            {
                options.map((option) => (
                    <OptionButton aria-disabled={questionsLength === 30} id={option.type} key={option.type} onClick={handleAddField(option.type)} >
                        <span className="icon" >
                            <AddCircleOutlineRounded htmlColor="currentColor" style={{ height: 17, width: 17 }} />
                        </span>
                        <span className="label" >{option.title}</span>
                    </OptionButton>
                ))
            }
        </OptionsContainer>
    )
}
