import React, { useState } from "react";
import { CircularProgress, styled } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import ExitDialog from "../../../../components/src/ExitDialog.web";

const StyledHeader = styled("header")({
    display: "flex",
    gap: 20,
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #E2E8F0",
    padding: "16px 48px",
    backgroundColor: "#FFFFFF",
    position: "sticky",
    top: 0,
    zIndex: 2,
    '& h1': {
        margin: 0,
        fontFamily: "Cairo",
        fontSize: 18,
        lineHeight: "26px",
        fontWeight: 700,
        color: "#0F172A",
    },
    '& button': {
        all: "unset",
        boxSizing: "border-box",
        cursor: "pointer",
    },
    '& .save': {
        width: 90,
        padding: "10px 16px",
        borderRadius: 8,
        backgroundColor: "#51ABB3",
        color: "#FFFFFF",
        boxShadow: "0px 2px 8px 0px #00000014",
        fontFamily: "Cairo",
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 700,
        marginLeft: "auto",
        textAlign: "center",
        '&[aria-disabled="true"]': {
            pointerEvents: "none",
        },
    },
})

type HeaderProps = {
    title?: string;
    loading?: boolean;
    onSaveClick: () => void;
    onExitForm: () => void;
    hasUserInputFieldChanged: boolean;
}

export default function Header({
    loading, 
    onSaveClick, 
    onExitForm, 
    title = "Signposting",
    hasUserInputFieldChanged
}: HeaderProps) {
    const [showExitDialog, setShowExitDialog] = useState(false)

    const onCrossBtnClick = () => {
        if(hasUserInputFieldChanged) {
            setShowExitDialog(true)
            return;
        }
        onExitForm()
    }

    return (
        <StyledHeader>
            <h1>{title}</h1>
            <button aria-disabled={loading} className="save" onClick={onSaveClick} >
                {loading ? <CircularProgress style={{ height: 24, width: 24, color: "#FFFFFF" }} /> : "Save"}
            </button>
            <button aria-label={`close ${title} creation page`} onClick={onCrossBtnClick} >
                <CloseRounded htmlColor="#0F172A" />
            </button>
            <ExitDialog 
                open={showExitDialog}
                onClose={() => setShowExitDialog(false)}
                onExit={onExitForm}
            />
        </StyledHeader>
    )
}
