import React from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { styled } from "@material-ui/core";
import { NodeData } from "./CardNode.web";

const StyledAddNodeButton = styled("button")({
    all: "unset",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 44,
    width: 44,
    borderRadius: 100,
    backgroundColor: "#51ABB3",
    boxShadow: "0px 2px 8px 0px #00000014",
})

export default function AddNodeButton({ data }: NodeProps<NodeData>) {

    const {status, onClick, ...rest} = data

    return (
        <>
            <Handle
                type="target"
                position={Position.Left}
                style={{ opacity: 0, left: 0, zIndex: -1 }}
            />
            <StyledAddNodeButton data-testid="open-card" onClick={() => onClick?.(rest)} aria-label="show card drawer" >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z" fill="white" />
                </svg>
            </StyledAddNodeButton>
        </>
    )
}
