import { Rule } from "./CfconnectorlogicController";

type CriteriaRoutingAttributes = {
    id?: number;
    criteria_routing_title: string;
    rules_attributes: Record<string, string | number | string[]>[];
    next_card_id?: number | null;
}

const transfromRulesToCriteriaRoutings = (rules: Rule[]) => {
    const criteriaRoutings = rules.map((rule) => {
        const newRule: CriteriaRoutingAttributes = {
            criteria_routing_title: rule.title,
            rules_attributes: rule.criteria.map((criteria) => {
                let value: string[] = [];
                let operatorWithPreviousIndex: string = ""
                let rule: Record<string, string | number | string[]> = {
                    field_name: criteria.fieldName,
                    comparator: criteria.comparator,
                    values: value,
                    operator_with_previous_index: operatorWithPreviousIndex,
                }
                if (criteria.selectedOperatingSystems) {
                    rule.values = criteria.selectedOperatingSystems
                }

                if (criteria.currentPageURL) {
                    rule.values = [criteria.currentPageURL]
                }
                if (criteria.time) {
                    rule.values = criteria.time
                }
                if (criteria.criteriaOperatorWithPreviousCriteria) {
                    rule.operator_with_previous_index = criteria.criteriaOperatorWithPreviousCriteria
                }

                if(criteria.criteriaId) {
                    rule["id"]= criteria.criteriaId
                }

                if(criteria.subField && criteria.selectedSubFieldOptions) {
                    rule.sub_field = criteria.subField
                    rule.values = criteria.selectedSubFieldOptions
                } 

                return rule
            })

        }
        if (rule.ruleId) {
            newRule.id = rule.ruleId
        }
        return newRule
    })

    return criteriaRoutings
}

export const utils = {
    transfromRulesToCriteriaRoutings
}
