import React from "react";
import { AddRounded, RemoveRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { useReactFlow } from 'reactflow';

const useStyles = makeStyles({
    control: {
        position: "absolute",
        zIndex: 5,
        right: 49,
        bottom: 24,
        display: "flex",
        flexDirection: "column",
    },
    controlButton: {
        all: "unset",
        cursor: "pointer",
        height: 44,
        width: 44,
        display: 'grid',
        placeItems: "center",
        boxShadow: "0px 2px 8px 0px #00000014",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px 8px 0 0",
        borderBottom: "1px solid #94A3B8",
        '&.minus': {
            borderRadius: "0 0 8px 8px",
            borderTop: "1px solid #94A3B8",
            borderBottom: 0,
        },
    },
})

export default function FlowControl(){
    const classes = useStyles()
    const {zoomIn, zoomOut} = useReactFlow()
    return (
        <div className={classes.control} >
            <button onClick={() => zoomIn()} className={classes.controlButton} >
                <AddRounded htmlColor="#0F172A" />
            </button>
            <button onClick={() => zoomOut()} className={`${classes.controlButton} minus`} >
                <RemoveRounded htmlColor="#0F172A" />
            </button>
        </div>
    )
}