import React from 'react'
import { LinearProgress, styled } from "@material-ui/core";
import { Props } from '../types';

export default function ProgressBar({ value, title, icon }: Props) {
    return (
        <Container>
            <div>
                {icon}
            </div>

            <div className='review-card'>
                <div>
                    <h1>{title}</h1>
                    <BorderLinearProgress variant="determinate" value={value} />
                </div>
                <div>
                    <p style={{ fontSize: "14px", color: "#0F172A", fontWeight: 700, marginRight: "6px" }}>{value}</p>
                </div>
            </div>
        </Container>
    )
}

const Container = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    width: "100%",
    height: '32px',
    margin: "12px 0",
    gap: 6,

    "& .review-card": {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    "& .review-card h1": {
        color: "#0F172A",
        fontSize: "12px",
        fontWeight: 400,
        textTransform: "capitalize",
        margin: 0,
        fontFamily: "Cairo",
    },
});

const BorderLinearProgress = styled(LinearProgress)(({ value }: { value: number }) => ({
    minWidth: "250px",
    height: "4px",
    borderRadius: 5,
    width: "100%",
    background: "#CBD5E1",
    '& .MuiLinearProgress-bar': {
        backgroundColor: () => {
            if (value < 20) return "#0F62FE"
            else if (value < 30) return "#059669"
            else return "F59E0B"
        },
    },
}));