import React from "react";
import { makeStyles } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import { TooltipItem } from "chart.js";

const useStyles = makeStyles({
    container: {
        display: "flex",
        gap: 40,
    },
    chatModeTitle: {
        color: "#0F172A", 
        lineHeight: "34px", 
        fontSize: "16px", 
        fontWeight: 700,
        margin: 0,
    },
    chatMode: {
        display: "flex",
        gap: 8,
        alignItems: "center",
        '& > span': {
            fontFamily: "Cairo",
            fontSize: 12,
            lineHeight: "16px",
            fontWeight: 400,
            '&:first-child': {
                display: "inline-block",
                height: 12,
                width: 12,
                borderRadius: 20,
                backgroundColor: "#51ABB3",
                '&[data-mode="web"]': {
                    backgroundColor: "#314A84"
                },
            },
            '&:last-child': {
                fontWeight: 700
            },
        },
    },
})

const drawPlugin = {
    id: 'drawPlugin',
    afterDraw: (chart: { ctx: any; data: { datasets: any; }; getDatasetMeta: (arg0: any) => any; }) => {
        const ctx = chart.ctx;
        const datasets = chart.data.datasets;

        datasets.forEach((_dataset: any, i: any) => {
            const meta = chart.getDatasetMeta(i);
            meta.data.forEach((element: { tooltipPosition: () => { x: any; y: any; }; _index: any; $context: any }, _index: any) => {
                const dataset = datasets[i];
                const dataValue = dataset.data[element.$context.dataIndex];

                if (dataValue === 0) {
                    return;
                }

                const { x, y } = element.tooltipPosition();
                const radius = 6;
                ctx.beginPath();
                ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
                ctx.fillStyle = 'transparent';
                ctx.fill();
                ctx.lineWidth = 2;
                ctx.strokeStyle = '#FFFFFF';
                ctx.stroke();
            });
        });
    },
};

const callbacks = function (data: { labels: any; datasets: any; }) {
    return {
        labelTextColor: function () {
            return '#0F172A';
        },
        label: function (tooltipItem: TooltipItem<"doughnut">) {
            let label = data.labels[tooltipItem.dataIndex] || '';
            if (label) {
                label += ': ';
            }
            label += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex] + '%';
            return label;
        }
    }
}

type ModeChartProps = {
    mobilePercentage: number;
    desktopPercentage: number;
}


export default function ModeChart({ mobilePercentage, desktopPercentage }: ModeChartProps) {
    const classes = useStyles()

    const ChatsMode = [{ id: 1, mode: "mobile", percentage: mobilePercentage, label: "Mobile" }, { id: 2, mode: "web", percentage: desktopPercentage, label: "Web" }]

    const data = {
        labels: ChatsMode.map(chat => chat.label),
        datasets: [{
            label: 'My First Dataset',
            data: ChatsMode.map(chat => chat.percentage),
            backgroundColor: [
                "#4DA2AA",
                "#314A84"
            ],
            hoverOffset: 4
        }]
    };

    return (
        <div className={classes.container}>
            <div style={{ width: 149, height: 149 }}>
                <Doughnut
                    data={data}
                    plugins={[drawPlugin]}
                    options={{
                        cutout: "60%", 
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip: {
                                enabled: true,
                                backgroundColor: '#F1F5F9',
                                bodyColor: '#F1F5F9',
                                borderColor: '#F1F5F9',
                                borderWidth: 1,
                                titleFont: { weight: 'bold' },
                                callbacks: callbacks(data)
                            },
                            datalabels: {
                                display: false,
                            },
                        }
                    }}
                />
            </div>
            <div>
                <h3 className={classes.chatModeTitle} >Mode</h3>
                {ChatsMode.map(data => (
                    <div key={data.id} className={classes.chatMode} >
                        <span data-mode={data.mode} />
                        <span>{`${data.label} - `}</span>
                        <span>{`${data.percentage}%`}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}