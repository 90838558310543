import React, { useEffect, useState, useCallback, useRef } from "react";
import { Node, useStore, useOnSelectionChange, OnSelectionChangeParams } from "reactflow";
import { NodeData } from "./CardNode.web";
import { Popover, ThemeProvider, createTheme, makeStyles } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

type NodeDataSelectionProps = {
    children: (nodes: Node<NodeData>[], handleClosePopover: () => void) => React.ReactNode;
}

const nodesSelectionTheme = createTheme({
    overrides: {
        MuiPopover: {
            paper: {
                border: "1px solid #E2E8F0",
                minWidth: 0,
                maxWidth: "212px",
                width: 212,
                backgroundColor: "#FFFFFF",
                padding: 0,
                boxSizing: "border-box",
                flexDirection: "column",
                display: "flex",
                gap: 2,
                '& > button': {
                    all: "unset",
                    cursor: "pointer",
                    fontWeight: 400,
                    display: "flex",
                    gap: 8,
                    color:"#0F172A",
                    alignItems: "center",
                    padding: "9px 16px",
                    fontSize: 14,
                    lineHeight: "22px",
                    fontFamily: "Cairo",
                },
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: 8,
            },
            elevation8: {
                boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
            },
        },
    },
})

const useStyles = makeStyles({
    moreIconButton: {
        all: "unset",
        cursor: "pointer",
        width: 46,
        height: 46,
        boxSizing: "border-box",
        padding: 11,
        borderRadius: 24,
        color: "#0F172A8C",
        '&[aria-haspopup="true"]': {
            backgroundColor: "#0F172A8C",
            color: "#FFFFFF",
        },
    },
})

export default function NodeDataSelection({children}: NodeDataSelectionProps) {
    const [selectedNodes, setSelectedNodes] = useState<Node<NodeData>[]>([])
    const [showMoreActionButton, setShowMoreActionButton] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const domRectRef = useRef<Pick<DOMRect, "top" | "left">>({top: 0, left: 0})
    const areNodesSelected = useStore(selector => selector.nodesSelectionActive);

    const classes = useStyles()

    const onSelectionCange = useCallback((params: OnSelectionChangeParams) => { 
        const nodesWithoutAddNode = params.nodes.filter(node => !node.id.startsWith("end"))
        setSelectedNodes(nodesWithoutAddNode)
     }, [])

    useOnSelectionChange({onChange: onSelectionCange})

    useEffect(() => {
        if(!areNodesSelected) {
            setShowMoreActionButton(false)
            return;
        }
        const selectedELement = document.querySelector(".react-flow__nodesselection-rect")
        if(!selectedELement) {
            return;
        }
        const domRect = selectedELement.getBoundingClientRect()
        domRectRef.current = {
            top: domRect.top,
            left: domRect.right + 10
        }
        setShowMoreActionButton(true)
    },[areNodesSelected])

    useEffect(() => {
        if(selectedNodes.length === 0) {
            setAnchorEl(null)
            setShowMoreActionButton(false)
        }
    }, [selectedNodes])

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClosePopover = () => setAnchorEl(null)

    const isOpen = Boolean(anchorEl)

    return (
        <ThemeProvider theme={nodesSelectionTheme} >
            {
                showMoreActionButton && (
                    <button 
                        className={classes.moreIconButton}
                        aria-controls="selected-nodes-popover"
                        aria-haspopup={isOpen}
                        style={{position: "fixed", zIndex: 12,...domRectRef.current }}
                        onClick={handleOpenPopover} 
                    >
                        <MoreVert htmlColor="currentColor" />
                    </button>
                )
            }
            <Popover  
                open={isOpen}
                onClose={handleClosePopover}
                id="selected-nodes-popover"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorEl}
            >
                {children(selectedNodes, handleClosePopover)}
            </Popover>
        </ThemeProvider>
    )
}