import React from "react";

import {
  IconButton,
  Typography,
  styled,
} from "@material-ui/core";


import ChatbotNameController, { ChatbotNameProps } from "./ChatbotNameController.web";
import { EditRounded } from "@material-ui/icons";

const BotEditContainer = styled("div")({
    gap: 8,
    display: "flex",
    alignItems: "center",
    '& .cursor-pointer': {
      cursor: "pointer",
    },
  })

const ChatbotNameInput = styled("input")({
    height: 26,
    fontSize: 18,
    fontFamily: "Cairo",
    lineHeight: "26px",
    fontWeight: 700,
    color: "#0F172A",
    border: 0,
    '&:focus': {
      outline: 0,
    },
})

const StyledTypography = styled(Typography)({
    fontFamily: "Cairo",
    fontSize: 18,
    lineHeight: "26px",
    color: "#0F172A",
    fontWeight: 700,
    maxWidth: "30ch",
    overflow: "hidden",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
})

export default class ChatbotName extends ChatbotNameController {
  constructor(props: ChatbotNameProps) {
    super(props);
  }

  render() {
    return (
        <BotEditContainer >
        {
          this.state.isEditingName ? (
            <ChatbotNameInput 
              name="chatbotName"
              aria-label="edit chatbot name"
              maxLength={50}
              value={this.state.chatbotName}
              onChange={this.handleChatbotNameChange}
              onBlur={this.handleChatbotNameBlur}
              autoFocus
            />
          ) : (
            <>
              <StyledTypography variant="body1">{this.state.chatbotName}</StyledTypography>
              <IconButton aria-label="Allow to edit chatbot name" style={{padding: 0}} onClick={this.handleEditName} >
                {this.state.chatbotName && <EditRounded htmlColor="#0F172A" />}
              </IconButton>
            </>
          )
        }
      </BotEditContainer>
    );
  }
}
