import React from "react";
import { 
    createTheme, 
    ThemeProvider,
    IconButton,
    Breadcrumbs,
    Button,
    Typography,
    styled, 
} from "@material-ui/core";
import { ArrowBackRounded, EditRounded, SettingsRounded } from "@material-ui/icons";


const StyledHeader = styled("header")({
    padding: "24px 48px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 8px 0px #00000014",
    display: "flex",
    justifyContent: "space-between",
})

const BreadCrumbContainer = styled("div")({
    display: "flex",
    gap: 16,
    alignItems: "center",
})

const ChatbotEditContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: 8,
})

const ActionContainer = styled("div")({
    display: "flex",
    gap: 32,
    alignItems: "center",
})

const theme = createTheme({
    typography: {
        body1: {
            fontFamily: "Cairo",
            fontSize: 18,
            fontWeight: 700,
            lineHeight: "26px",
            color: "#0F172A",
        },
        body2: {
            fontSize: 16,
            fontFamily: "Cairo",
            lineHeight: "24px",
            color: "#0F172A",
            fontWeight: 700,
        },
    },
    overrides: {
        MuiBreadcrumbs: {
            separator: {
              marginLeft: 0,
              marginRight: 0,
            },
            ol: {
              gap: 2,
            },
          },
          MuiIconButton: {
            root: {
              padding: 0,
            },
          },
          MuiButton: {
            root: {
                textTransform: "none",
                '&:hover': {
                    backgroundColor: "transparent",
                    boxShadow: 'none',
                },
            },
            text: {
                fontFamily: "Cairo",
                fontSize: 24,
                fontWeight: 400,
                lineHeight: "32px",
                padding: "4px 8px",
                color: "#475569",
                letterSpacing: "-0.005em",
                '&.active': {
                  fontWeight: 700,
                  color: "#0F172A"
                },
            },
            contained: {
                fontFamily: 'Cairo',
                fontWeight: 700,
                fontSize: 16,
                lineHeight: "24px",
                backgroundColor: "#E5F6FF",
                color: "#51ABB3",
                padding: "10px 16px",
                borderRadius: 8,
                boxShadow: "none",
                '&:hover': {
                    backgroundColor: "#E5F6FF",
                    color: "#51ABB3",
                    boxShadow: "none"
                },
                '&.primary': {
                    backgroundColor: "#51ABB3",
                    color: "#FFFFFF",
                    width: 100,
                },
                '&.primary:hover': {
                    backgroundColor: "#51ABB3",
                    color: "#FFFFFF",
                },
            },
          },
    },
})

const ArrowForward = () => (
    <svg width="24" height="24" id="arrow-forward" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99953 6.70978C8.60953 7.09978 8.60953 7.72978 8.99953 8.11978L12.8795 11.9998L8.99953 15.8798C8.60953 16.2698 8.60953 16.8998 8.99953 17.2898C9.38953 17.6798 10.0195 17.6798 10.4095 17.2898L14.9995 12.6998C15.3895 12.3098 15.3895 11.6798 14.9995 11.2898L10.4095 6.69978C10.0295 6.31978 9.38953 6.31978 8.99953 6.70978Z" fill="#64748B"/>
    </svg>
)

export default function BotHeader(){
    return (
        <ThemeProvider theme={theme} >
            <StyledHeader data-testid="botHeader" >
                <BreadCrumbContainer >
                    <IconButton>
                        <ArrowBackRounded htmlColor="#0F172A" />
                    </IconButton>
                    <Breadcrumbs data-test-id="breadcrumb" separator={<ArrowForward />} >
                        <Button variant="text" >Bots</Button>
                        <Button variant="text" className="active" >Add bot</Button>
                    </Breadcrumbs>
                </BreadCrumbContainer>
                <ActionContainer >
                    <ChatbotEditContainer >
                        <IconButton>
                            <SettingsRounded htmlColor="#0F172A" />
                        </IconButton>
                        <Typography variant="body2">Chatbot settings</Typography>
                    </ChatbotEditContainer>
                    <ChatbotEditContainer style={{gap: 12}} >
                        <Button
                            variant="contained"
                        >
                            Save draft
                        </Button>
                        <Button variant="contained" className="primary" >
                            Publish
                        </Button>
                    </ChatbotEditContainer>
                </ActionContainer>
            </StyledHeader>
        </ThemeProvider>
    )
}
