// @ts-nocheck
import React from "react";

// Customizable Area Start
import {
  Typography,
  // Customizable Area Start
  ThemeProvider,
  createTheme,
  styled,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

import { AddRounded, AddCircleOutlineRounded } from "@material-ui/icons";
import GenericError from "../../../components/src/GenericError.web";
import CategoryTemplatePopup from "./components/CategoryTemplatePopup.web";
import TemplateCard from "../../../components/src/TemplateCard.web";
import CategoryList from "./components/CategoryList.web";

const projectTheme = createTheme({
  overrides: {},
})

const CardLayout = styled("div")({
'&.card-layout': {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    padding: "24px 48px",
    '& .title': {
        fontWeight: 400,
        fontFamily: "Cairo",
        lineHeight: "28px",
        color: "#0F172A",
        fontSize: 16,
        margin: 0,
    },
  },
  '&.gap-12': {
    gap: 12
  },
  '&.border-t': {
      borderTop: "1px solid #CBD5E1"
  },
  '&.pt-0': {
      paddingTop: 0,
  },
})

const CardLayoutHeader = styled("div")({
  '&.card-layout-header': {
    display: "flex",
    justifyContent: "space-between",
    gap: 4,
    '&.justify-initial': {
        justifyContent: "initial"
    },
    '& .h5': {
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 20,
        lineHeight: "28px",
        color: "#0F172A",
        margin: 0,
        '&.font-400': {
            fontWeight: 400,
        },
    },
  },
})

const CreateTemplateButton = styled("button")({
  '&.create-template': {
    all: "unset",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 6,
    width: 100,
    '& .icon': {
        display: "grid",
        placeItems: "center",
        width: 100,
        height: 100,
        boxSizing: "border-box",
        border: "2px solid #51ABB3",
        borderRadius: 12,
        color: "#51ABB3",
    },
    '& .template-text': {
        fontFamily: "Cairo",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "18px",
        color: "#64748B",
        padding: 6,
        textAlign: "center",
    },
    '&:hover': {
        '& .template-text': {
            color: "#51ABB3"
        },
    },
    '&[aria-disabled="true"]': {
        '& .icon': {
            color: "#94A3B8",
            border: "1px solid #94A3B8",
        },
        '& .template-text': {
            color: "#64748B !important"
        },
    },
  },
})

const CreateCategoryButton = styled("button")({
  '&.create-category': {
    all: "unset",
    cursor: "pointer",
    display: "flex",
    gap: 8,
    alignItems: "center",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "14px",
    color: "#51ABB3"
  },
})

const LoaderContainer = styled("div")({
  '&.loader-container': {
    display: "grid",
    placeItems: "center",
  },
})

// Customizable Area End

import ProjectTemplatesController, {
  Props,
  configJSON,
} from "./ProjectTemplatesController";

export default class ProjectTemplates extends ProjectTemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={projectTheme} >
        <CategoryTemplatePopup 
          type="template" 
          open={this.state.isTemplateModalOpen} 
          handleClose={this.handleToggleTemplatePopup}
          onSubmit={this.handleTemplateSubmit} 
          isCreateOrUpdateLoading={this.state.isCreateTemplateOrCategoryLoading}
          data-testid="template-popup"
        />
        <CategoryTemplatePopup
          key={this.selectedCategoryName} 
          type="category"
          open={this.state.isCategoryModalOpen} 
          handleClose={this.handleToggleCategoryPopup}
          onSubmit={this.handleCategorySubmit}
          initialName={this.selectedCategoryName}
          isEdit={!!this.selectedCategoryId} 
          isCreateOrUpdateLoading={this.state.isCreateTemplateOrCategoryLoading}
          data-testid="category-popup"
        />
        <CardLayout className="card-layout gap-12 pt-0">
            <CardLayoutHeader className="card-layout-header" >
              <h5 className="h5" >New template</h5>
              <CreateCategoryButton className="create-category" data-testid="create-category-btn" onClick={this.handleToggleCategoryPopup} >
                <AddRounded htmlColor="#51ABB3" />
                Create category
              </CreateCategoryButton>
            </CardLayoutHeader>
            {this.state.showMaxTemplateError && <GenericError data-testid="max-template-error" error="You already added the maximum number of templates" handleCloseError={this.handleCloseMaxTemplateError} />}
            <CreateTemplateButton className="create-template" data-testid="create-template-btn" aria-disabled={this.state.isCreateTemplateButtonDisabled} onClick={this.handleToggleTemplatePopup} >
              <span className="icon" ><AddCircleOutlineRounded htmlColor="currentColor" /></span>
              <span className="template-text">Create Template</span>
            </CreateTemplateButton>
        </CardLayout>
          {
            this.state.showCategoriesLoading ? (
              <LoaderContainer className="loader-container" >
                <CircularProgress data-testid="loader"  style={{height: 40, width: 40, color: "#51ABB3"}} />
              </LoaderContainer>
            ) : (
              <>
                {
                  this.state.templates.length > 0 ? (
                    <CardLayout className="card-layout gap-12 border-t" >
                      <p className="title">Templates</p>
                        <div style={webStyle.templateLayout} >
                          {
                            this.state.templates.map((template) => (
                              <TemplateCard
                                key={template.id} 
                                {...template} 
                                onDeleteTemplate={this.handleDeleteTemplate}
                                onEditTemplate={this.handleEditTemplate}
                                data-testid={`Template ${template.id}`}
                                categories={this.state.categories} 
                                handleAddConnectorCardsFromTemplateToChatbot={this.props.handleAddConnectorCardsFromTemplateToChatbot}
                                onTemplateMoveToAnotherCategory={this.handleMoveTemplateFromCategoryToAnotherCategory}
                              />
                            ))
                          }
                        </div>
                    </CardLayout>
                  ): null
                }
                {
                  this.state.categories.length > 0 ? 
                    this.state.categories.map(category => (
                      <CategoryList
                        key={category.id} 
                        {...category}  
                        onEditCategory={this.handleEditCategory}
                        onDeleteCategory={this.handleDeleteCategory}
                        onDeleteTemplate={this.handleDeleteTemplate}
                        onEditTemplate={this.handleEditTemplate}
                        data-testid={`Category ${category.id}`} 
                        categories={this.state.categories}
                        handleAddConnectorCardsFromTemplateToChatbot={this.props.handleAddConnectorCardsFromTemplateToChatbot}
                        onMoveTemplateFromCategory={this.handleMoveTemplateFromCategoryToAnotherCategory}
                      />
                    ))
                  : null 
                }
              </>
            )
          }
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: Record<string, React.CSSProperties> = {
  templateLayout: {
    display: "flex",
    flexWrap: "wrap",
    gap: "24px 17px",
  },
}
// Customizable Area End