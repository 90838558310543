import { styled, createTheme } from "@material-ui/core";

const theme = createTheme({
    overrides: {
        MuiPaper: {
            root: {
                border: "1px solid #E2E8F0",
                width: 200,
                padding: 4,
                boxSizing: "border-box",
                scrollbarWidth: "none",
                '&::-webkit-scrollbar': {
                    width: 0,
                },
            },
            elevation8: {
                boxShadow: "0px 2px 8px 0px #00000014",
            },
            rounded: {
                borderRadius: 8,
            },
        },
        MuiCheckbox: {
            root: {
                padding: 0,
            },
        },
        MuiFormLabel: {
            root: {
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "22px",
                color: "#0F172A",
                margin: 0,
                '&.Mui-focused': {
                    color: "#0F172A",
                },
                '&.sub-title': {
                    fontSize: 10,
                    lineHeight: "15px",
                },
            },
        },
        MuiButton: {
            root: {
                padding: "8px 10px",
                borderRadius: 8,
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: 16,
                lineHeight: "24px",
                textTransform: "none",
            },
            contained: {
                backgroundColor: "#F8FAFC",
                color: "#51ABB3",
                boxShadow: "none",
                '&:hover': {
                    backgroundColor: "#F8FAFC",
                    color: "#51ABB3",
                    boxShadow: "none",
                },
                '&.primary': {
                    backgroundColor: "#F1F5F9",
                    color: "#64748B",
                },
                '&.primary:hover': {
                    backgroundColor: "#F1F5F9",
                    color: "#64748B",
                },
            },
        },
        MuiFormControl: {
            root: {
                display: "flex",
                flexDirection: "row",
                padding: "9px 0px 9px 16px",
                alignItems: "center",
                gap: 8,
                cursor: "pointer",
                '&.menu': {
                    '&[aria-expanded="true"]': {
                        borderRadius: 8,
                        backgroundColor: "#EEF7F7",
                    },
                },
                '&.border-b': {
                    borderBottom: "1px solid #E2E8F0",
                },
            },
        },
    },
})

const MenuContainer = styled("div")({
    display: "flex",
    gap: 5,
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    color: "#475569",
})

const MenuButton = styled("button")({
    display: "flex",
    gap: 8,
    border: 0,
    padding: 0,
    outline: 0,
    cursor: "pointer",
    backgroundColor: "transparent",
    fontWeight: 700,
    color: "#475569",
    '&:focus': {
        outline: 0,
    },
})

const StyledArrowButton = styled("button")({
    all: "unset",
    cursor: "pointer",
    marginLeft: "auto",
    height: 24,
})

const PopoverStyledHeader = styled("div")({
    borderBottom: "1px solid #E2E8F0",
    '&.header': {
        margin: 4,
        padding: "12px 16px",
        color: "#94A3B8",
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "18px",
    },
})

const PopoverStyledFooter = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    gap: 6,
    padding: 12,
})

const ClearButton = styled("button")({
    all: "unset",
    cursor: "pointer",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 14,
    linHheight: 22,
    color: "#0F172A",
    padding: "9px 16px",
})

const Column = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 2,
})

const SubMenu = styled("div")({
    position: "fixed",
    backgroundColor: "#FFFFFF",
    width: 200,
    boxSizing: "border-box",
    borderRadius: 8,
    padding: 4,
    border: "1px solid #E2E8F0",
    boxShadow: "0px 2px 8px 0px #00000014",
    zIndex: 2000,
})

const FormControlGroup = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 2,
})


export const styledTheme = {
    theme,
    styledComponent: {
        MenuContainer,
        MenuButton,
        StyledArrowButton,
        PopoverStyledFooter,
        PopoverStyledHeader,
        ClearButton,
        SubMenu,
        FormControlGroup,
        Column
    },
}
