import React from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  Link,
 
  // Customizable Area Start

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { imgLogo } from './assets';
import {
  PasswordContainer,
  PasswordMainContainer,
  PasswordImageContainer,
  PasswordHeadingTitle,
  PasswordHeadingSubTitle,
  BackToLoginContainer,
} from "./theme.web";
import PasswordFormFields from "./components/PasswordFormFields.web";
import NewPasswordController from "./NewPasswordController";
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

export default class NewPasswordWeb extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <PasswordMainContainer>
        <PasswordImageContainer>
          <img src={imgLogo} alt="Maxchat-bot logo" width="156" height="38" />
        </PasswordImageContainer>
        <PasswordContainer>
          <PasswordHeadingTitle>New password</PasswordHeadingTitle>
          <PasswordHeadingSubTitle>Enter a new password for {sessionStorage.getItem("email")}</PasswordHeadingSubTitle>
          <PasswordFormFields
            loading={this.state.loading} 
            onSubmit={this.handleSetNewPassword} 
            error={this.state.error}
            data-testid="form" 
          />
          <BackToLoginContainer>
            <span className="back-to-title" >Back to</span>
            <button className="login" onClick={this.handleLogin} >Log in</button>
          </BackToLoginContainer>
        </PasswordContainer>
      </PasswordMainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
