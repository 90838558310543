import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer"
import { CircularProgress } from "@material-ui/core"

type Props = {
    handleFetchMore: () => void;
}

export default function InfiniteScrollChatLists({handleFetchMore}: Props) {

    const {ref, inView} = useInView({
        threshold: 0
    })

    useEffect(() => {
        if(inView) {
            handleFetchMore()
        }
    }, [inView])

    return (
        <div ref={ref} style={{display: "flex", justifyContent: "center", margin: "10px 0px"}} >
            <CircularProgress style={{height: 40,width: 40, color: "#51ABB3"}} />
        </div>
    )
}