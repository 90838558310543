import { toast } from "react-toastify";

const copytoClipboard = async(text: string, successMessage: string) => {
    try {
        await navigator.clipboard.writeText(text)
        toast.success(successMessage, {className: "success__toast"})
    } catch (error) {
        toast.error("Failed to copy to clipboard", {className: "error__toast"})
    }
}

export const utils = {
    copytoClipboard,
}
