import React, { useRef, useState } from "react";
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { getStorageData } from "../../framework/src/Utilities";

import { Button, CircularProgress, Dialog, DialogActions, InputLabel, Switch, ThemeProvider, Typography, createTheme, makeStyles, styled } from "@material-ui/core";
import MultiSelectCheckbox from "./MultiSelectCheckbox.web";
import CustomSelect from "./CustomSelect.web";
import { AddCircleOutlineRounded, Close, RemoveCircle } from "@material-ui/icons";

interface S {
    allPractices: { label: string, value: number }[];
    allTags: { label: string, value: number }[];
    token: string;
    showManageTagModal: boolean;
    isTagUpdateLoading: boolean;
    tagsUpdateTime: number;
}

interface Props {
    tagType: "sign_posting" | "user_guide" | "question_and_answer";
    handlePracticeAssociationApply: (values: number[]) => void;
    handleTagsApply: (values: number[]) => void;
    selectedPractices: number[];
    selectedTags: number[];
    isGlobal: boolean;
    handleGlobalSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    showPracticeError: boolean;
    handleClosePracticeError: () => void;
    practiceError?: string;
    handleLogout: () => Promise<void>;
}

interface SS { }

type UpdateTag = {
    id?: number;
    name: string;
}

type ManageTagsProps = {
    open: boolean;
    onClose: () => void;
    allTags: { label: string, value: number }[];
    loading: boolean;
    handleUpdateTags: (deletedTagIds: number[], updateTags: Required<UpdateTag>[], newTags: string[]) => void;
}

type TagResponse = {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

type ManageTagOption = {
    id: number;
    name: string;
    value: string;
    isNew: boolean;
    error?: boolean;
}

const useManageTagStyles = makeStyles({
    dialogHeader: {
        padding: "24px 40px",
        borderBottom: "1px solid #CBD5E1",
        display: "flex",
        justifyContent: "space-between",
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: "#FFFFFF",
        '& > button': {
            all: "unset",
            cursor: "pointer",
        },
    },
    dialogContent: {
        padding: "24px 40px",
        display: "flex",
        flexDirection: "column",
        gap: 24,
        '& .option-list': {
            display: "flex",
            flexDirection: "column",
            gap: 8,
        },
        '& .option': {
            display: "flex",
            alignItems: "center",
            gap: 4,
            '& > button': {
                all: "unset",
                cursor: "pointer"
            },
        },
    },
    addOptionButton: {
        all: "unset",
        fontFamily: "Inter",
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 700,
        color: "#64748B",
        cursor: "pointer",
        display: "flex",
        gap: 8,
        alignItems: "center",
        '&[aria-disabled="true"]': {
            pointerEvents: "none",
            color: "#94A3B8"
        },
    },
    tagInput: {
        border: "1px solid #CBD5E1",
        borderRadius: 8,
        padding: "14px 7px",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px",
        color: "#64748B",
        width: "100%",
        '&:focus': {
            outline: 0,
            borderColor: "#51ABB3"
        },
        '&[aria-invalid="true"]': {
            borderColor: "#F59E0B",
        },
    },
})

const globalSwitchLabel: Record<Props["tagType"], string> = {
    sign_posting: "Make this user guide global",
    user_guide: "Make this user guide global",
    question_and_answer: "Make this Q&A global"
}

const settingsTheme = createTheme({
    typography: {
        h3: {
            fontFamily: "Cairo",
            fontWeight: 700,
            fontSize: 24,
            lineHeight: "32px",
            color: "#0F172A",
        },
        subtitle1: {
            fontFamily: "Cairo",
            fontWeight: 400,
            fontSize: 18,
            lineHeight: "26px",
            color: "#0F172A",
        },
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: "#475569",
                fontSize: 14,
                fontWeight: 700,
                lineHeight: "22px",
                fontFamily: "Cairo",
            },
        },
        MuiSwitch: {
            input: {
                left: 0,
                width: 48,
                height: 28,
            },
            root: {
                borderRadius: 40,
                padding: 0,
                width: 48,
                height: 28,
            },
            thumb: {
                width: 24,
                height: 24,
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 2px 8px 0px #00000014",
            },
            switchBase: {
                padding: 0,
                left: 2,
                top: 2,
                '&.Mui-checked': {
                    '& input': {
                        left: "-24px"
                    },
                },
            },
            colorSecondary: {
                '&.Mui-checked + $track': {
                    backgroundColor: "#51ABB3",
                    opacity: 1,
                },
            },
            track: {
                backgroundColor: "#E2E8F0",
                opacity: 1,
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(15, 23, 42, 0.4)',
            },
        },
        MuiPaper: {
            root: {
                width: 669,
                backgroundColor: "#FFFFFF",
                scrollbarWidth: "none",
                '&::-webkit-scrollbar': {
                    width: 0,
                },
            },
            elevation24: {
                boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017"
            },
            rounded: {
                borderRadius: 24,
            }
        },
        MuiDialogActions: {
            root: {
                padding: "24px 40px",
                gap: 10,
                borderTop: "1px solid #CBD5E1",
                position: "sticky",
                bottom: 0,
                zIndex: 2,
                backgroundColor: "#FFFFFF",
                boxSizing: "border-box",
            },
            spacing: {
                '& > :not(:first-child)': {
                    marginLeft: 0
                }
            }
        },
        MuiButton: {
            root: {
                fontFamily: "Cairo",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "24px",
                textTransform: "none",
                borderRadius: 8,
                width: 105,
                boxSizing: "border-box",
                backgroundColor: "#E5F6FF",
                color: "#51ABB3",
                '&:hover': {
                    backgroundColor: "#E5F6FF",
                    color: "#51ABB3",
                    boxShadow: 'none',
                },
                '&.Mui-primary': {
                    color: "#FFFFFF",
                    backgroundColor: "#51ABB3",
                    '&:hover': {
                        color: "#FFFFFF",
                        backgroundColor: "#51ABB3",
                        boxShadow: 'none',
                    },
                },
            },
            text: {
                padding: "16px",
            },
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: 669,
            },
        },
    },
})

const StyledRow = styled("div")({
    display: "flex",
    '&.flex-1': {
        flex: 1,
    },
    '&.space-between': {
        justifyContent: "space-between",
    },
    '&.items-center': {
        alignItems: "center",
    },
    '&.flex-column': {
        flexDirection: "column"
    }
})

const SettingsContainer = styled("div")({
    width: 416,
    boxSizing: "border-box",
    padding: "24px 40px",
    backgroundColor: "#FFFFFF",
    display: 'flex',
    flexDirection: "column",
    gap: "12px",
    boxShadow: "0px 2px 8px 0px #00000014",
    '& .makeStyles-root-72': {
        width: "100% !important"
    },
    '& h5': {
        margin: 0,
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 18,
        lineHeight: "26px",
        color: "#0F172A",
        marginBottom: 12,
    },
    '& .manageBtn': {
        width: "57px",
        height: "24px",
        gap: "0px",
        opacity: "0px",
        fontFamily: "Cairo",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        color: "#51ABB3",
        background: "#fff",
        border: "none",
        cursor: "pointer"
    }
})

const getManagedTags = (tags: { label: string, value: number }[]): ManageTagOption[] => {
    const newTags = tags.map((tag) => {
        return {
            id: tag.value,
            name: `tag_${tag.value}`,
            value: tag.label,
            isNew: false
        }
    })
    return newTags
}


export default class TrainingHubSettings extends BlockComponent<Props, S, SS> {

    apiGetAllPracticesCallId: string = "";
    getAllTagsApiCallID: string = "";
    updateTagsApiCallId: string = "";

    constructor(props: Props) {
        super(props)

        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ]

        this.state = {
            allPractices: [],
            allTags: [],
            token: "",
            showManageTagModal: false,
            isTagUpdateLoading: false,
            tagsUpdateTime: Date.now()
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.handleRestApiResponse = this.handleRestApiResponse.bind(this)
    }

    async receive(from: string, message: Message) {
        const handlers = {
            [getName(MessageEnum.RestAPIResponceMessage)]: this.handleRestApiResponse
        }

        const handler = handlers[message.id]
        if (handler) {
            handler(message)
        }
    }

    async componentDidMount() {
        const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
        this.getAllPractices(token)
        this.getAllTags(token)
        this.setState({ token: token })
    }

    handleRestApiResponse(message: Message) {
        const responseId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

        const responseHandlers: Record<string, (message: Message) => Promise<void>> = {}

        if (this.apiGetAllPracticesCallId === responseId) {
            responseHandlers[this.apiGetAllPracticesCallId] = this.handlePracticesResponse
        }

        if (this.getAllTagsApiCallID === responseId) {
            responseHandlers[this.getAllTagsApiCallID] = this.handleAllTagsResponse
        }

        if (this.updateTagsApiCallId === responseId) {
            responseHandlers[this.updateTagsApiCallId] = this.handleTagsUpdateResponse
        }


        const responseHandler = responseHandlers[responseId];
        if (responseHandler) {
            responseHandler(message);
        }
    }

    handlePracticesResponse = async (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        if (!responseJson) {
            toast.error("Failed to fetch practice data", { className: "error__toast" })
            return;
        }

        if (responseJson.errors?.[0]?.token) {
            await this.props.handleLogout()
            return;
        }

        if (responseJson.organizations) {
            const newPractices = responseJson.organizations.map((item: { organisation_name: string, id: number }) => ({ label: item.organisation_name, value: item.id }))
            this.setState({ allPractices: newPractices })
        }
    }

    handleAllTagsResponse = async (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (!responseJson) {
            toast.error("Failed to fetch tags", { className: "error__toast" })
            return;
        }

        if (responseJson.errors?.[0]?.token) {
            return await this.props.handleLogout()
        }

        if (responseJson.length) {
            const tags = responseJson as TagResponse[]
            const sortedTags = tags.sort((a,b) =>  {
                const aCreatedAt = new Date(a.created_at).getTime()
                const bCreatedAt = new Date(b.created_at).getTime()
                return bCreatedAt - aCreatedAt
            })
            const options = sortedTags.map((item) => ({ label: item.name, value: item.id }));
            this.setState({ allTags: options, tagsUpdateTime: Date.now() });
        }
    }

    handleTagsUpdateResponse = async (message: Message) => {
        this.setState({ isTagUpdateLoading: false })
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (!responseJson) {
            toast.error("Failed to update tags", { className: "error__toast" })
            return;
        }

        if (responseJson.errors?.[0]?.token) {
            return await this.props.handleLogout()
        }

        if(responseJson.message) {
            toast.success("Successfully updated tags")
            const {updated_tags, new_tags} = responseJson
            const tags = [...updated_tags, ...new_tags]
            const sortedTags = tags.sort((a,b) =>  {
                const aCreatedAt = new Date(a.created_at).getTime()
                const bCreatedAt = new Date(b.created_at).getTime()
                return bCreatedAt - aCreatedAt
            })

            const allTagsIdsSet = new Set<number>()

            const newAllTags: S["allTags"] = sortedTags.map((tag) => {
                allTagsIdsSet.add(tag.id)
                return {label: tag.name, value: tag.id}
            })
            const filteredSeletedTags = this.props.selectedTags.filter(tag => allTagsIdsSet.has(tag))
            this.props.handleTagsApply(filteredSeletedTags)
            this.setState({allTags: newAllTags, showManageTagModal: false, tagsUpdateTime: Date.now()})
        }
    }

    getAllTags = async (token: string) => {
        const header = {
            "Content-Type": "application/json",
            "token": token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllTagsApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_catalogue/tags?${this.props.tagType}=true`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        this.send(requestMessage)
    }

    getAllPractices = async (token: string) => {
        const header = {
            "Content-Type": "application/json",
            token: token,
        };
        const newRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

        newRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        newRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_catalogue/tags/practice"
        )
        newRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )

        this.apiGetAllPracticesCallId = newRequestMessage.messageId
        this.send(newRequestMessage)
    }

    handleUpdateTags = (deletedTagIds: number[], updateTags: Required<UpdateTag>[], newTags: string[]) => {
        this.setState({ isTagUpdateLoading: true })
        const header = {
            "Content-Type": "application/json",
            token: this.state.token
        }
        const body = {
            deletedTagIds: deletedTagIds,
            updateTags: updateTags,
            newTags: newTags,
            tagType: this.props.tagType
        }
        const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.updateTagsApiCallId = message.messageId
        message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        message.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        message.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_catalogue/tags/manage_tags"
        )
        message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        )
        this.send(message)
    }

    
    render() {
        return (
            <ThemeProvider theme={settingsTheme} >
                {
                    this.state.showManageTagModal && (
                        <ManageTags
                            open={this.state.showManageTagModal}
                            onClose={() => this.setState({ showManageTagModal: false })}
                            allTags={this.state.allTags}
                            loading={this.state.isTagUpdateLoading}
                            handleUpdateTags={this.handleUpdateTags}
                        />
                    )
                }
                <SettingsContainer>
                    <h5>Settings</h5>
                    <MultiSelectCheckbox
                        key={this.state.allPractices.length === 0 ? "no-practice" : "practice"}
                        label="Practice"
                        placeholder="Select a practice"
                        value={this.props.selectedPractices}
                        hasSearch={true}
                        options={this.state.allPractices}
                        onApply={this.props.handlePracticeAssociationApply}
                        disabled={this.props.isGlobal}
                        showError={this.props.showPracticeError}
                        handleCloseError={this.props.handleClosePracticeError}
                        error={this.props.practiceError}
                    />
                    <StyledRow className="space-between items-center">
                        <InputLabel htmlFor="global-switch" >{globalSwitchLabel[this.props.tagType]}</InputLabel>
                        <Switch
                            id="global-switch"
                            disableRipple
                            checked={this.props.isGlobal}
                            onChange={this.props.handleGlobalSwitchChange}
                        />
                    </StyledRow>
                    <StyledRow className="flex-column items-center" style={{ gap: "8px" }}>
                        <StyledRow className="space-between items-center" style={{ width: "100%" }}>
                            <label style={{ fontSize: "14px", lineHeight: "22px", color: "#475569", fontWeight: 700 }}>Tags</label>
                            <button className="manageBtn" onClick={() => this.setState({ showManageTagModal: true })} >Manage</button>
                        </StyledRow>
                        <StyledRow style={{ width: "100%", position: 'relative' }}>
                            <MultiSelectCheckbox
                                key={this.state.tagsUpdateTime}
                                label=""
                                placeholder="Select tag"
                                value={this.props.selectedTags}
                                hasSearch={true}
                                options={this.state.allTags}
                                onApply={this.props.handleTagsApply}
                            />
                        </StyledRow>
                    </StyledRow>
                </SettingsContainer>
            </ThemeProvider>
        )
    }

}


function ManageTags({
    open,
    onClose,
    allTags,
    loading,
    handleUpdateTags
}: ManageTagsProps) {
    const [tags, setTags] = useState<ManageTagOption[]>(() => getManagedTags(allTags))
    const deletedTagIdsRef = useRef<number[]>([])
    const classes = useManageTagStyles()

    const handleAddNewTag = () => {
        const newTagId = Date.now()
        const newTag: ManageTagOption = {
            id: newTagId,
            name: `tag_${newTagId}`,
            value: "",
            isNew: true
        }
        setTags([newTag, ...tags])
    }

    const handleTagInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        const currentTagIndex = tags.findIndex(tag => tag.name === name)
        let currentTag = {
            ...tags[currentTagIndex],
            value: value,
            error: value.trim().length === 0
        }
        const newTags = [...tags]
        newTags[currentTagIndex] = currentTag
        setTags(newTags)
    }

    const handleRemoveTag = (tagId: number, isNew: boolean) => () => {
        if (!isNew) {
            deletedTagIdsRef.current.push(tagId)
        }
        const newTags = tags.filter(tag => tag.id !== tagId)
        setTags(newTags)
    }

    const onSave = () => {
        const hasError = tags.some(tag =>tag.value.trim().length === 0)

        if (hasError) {
            const tagsWithError = tags.map((tag) => {
                const error = tag.value.trim().length === 0
                return {
                    ...tag,
                    error
                }
            })
            toast.error("Pleas fill all tags", { className: "error__toast" })
            setTags(tagsWithError)
            return;
        }

        const updateTags: Required<UpdateTag>[] = []
        const newTags: string[] = []

        tags.forEach((tag) => {
            if (!tag.isNew) {
                updateTags.push({ id: tag.id, name: tag.value })
            } else {
                newTags.push(tag.value)
            }
        })

        handleUpdateTags(deletedTagIdsRef.current, updateTags, newTags)
    }

    return (
        <ThemeProvider theme={settingsTheme} >
            <Dialog open={open} onClose={onClose} >
                <div className={classes.dialogHeader} >
                    <Typography variant="h3" >Manage tag</Typography>
                    <button aria-label="close manage tag dialog" onClick={onClose}>
                        <Close htmlColor="#334155" />
                    </button>
                </div>
                <div className={classes.dialogContent}>
                    <Typography variant="subtitle1" >
                        Edit the options to add or remove them
                    </Typography>
                    <button className={classes.addOptionButton} onClick={handleAddNewTag} >
                        <AddCircleOutlineRounded htmlColor="#64748B" style={{ height: 20, width: 20 }} />
                        Add option
                    </button>
                    <div className="option-list" >
                        {
                            tags.map((tag) => {
                                return (
                                    <div className="option" key={tag.id} >
                                        <input
                                            type="text"
                                            placeholder="Type here"
                                            name={tag.name}
                                            value={tag.value ?? ""}
                                            id={`${tag.id}`}
                                            aria-invalid={!!tag.error}
                                            onChange={handleTagInputChange}
                                            className={classes.tagInput}
                                        />
                                        <button aria-label={`remove ${tag.id}`} onClick={handleRemoveTag(tag.id, tag.isNew)} >
                                            <RemoveCircle htmlColor="#DC2626" />
                                        </button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <DialogActions>
                    <Button onClick={onClose} >Cancel</Button>
                    <Button className="Mui-primary" disabled={loading} onClick={onSave} >
                        {loading ? (
                            <CircularProgress style={{ width: 24, height: 24, color: "#FFFFFF" }} />
                        ) : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}
