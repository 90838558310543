import React from "react";
import { makeStyles } from "@material-ui/core";
import { CallSplitRounded, DeleteOutlineRounded, UpdateRounded } from "@material-ui/icons";
import { Handle, NodeProps, Position } from "reactflow";
import { CriteriaRouting } from "../../blocks/cfconnectorlogic/src/CfconnectorlogicController";

type SelectedCard = "send_message" | "share_user_guide" | "share_video" | "share_link" | "single_choice" | "multi_choice" | "dropdown" | "end_chat" | "go_to_tile" | "criteria_routing" | "slider_scale" | "rating" | "openai" | "embed_form" | "add_node";


const useCardStyles = makeStyles({
    card: {
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
        backgroundColor: "#94A3B8",
        borderRadius: 12,
        width: 327,
        cursor: "pointer",
        '&:hover': {
            backgroundColor: "#51ABB3",
            '& .cardContent': {
                border: "2px solid #51ABB3",
            },
        },
        '& .cardContent': {
            backgroundColor: "#FFFFFF",
            borderRadius: "12px",
            padding: 16,
            border: "2px solid transparent",
            '& > p': {
                margin: 0,
                fontFamily: "Cairo",
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "22px",
                wordWrap: "break-word",
                whiteSpaceCollapse: "preserve",
            },
            '& > div': {
                margin: "0 auto",
            },
        },
        '&[data-error="true"]': {
            backgroundColor: "#DC2626",
            '& .cardContent': {
                borderColor: "#DC2626",
                borderRadius: "12px 12px 0 0",
            },
            '&:hover': {
                backgroundColor: "#DC2626",
                '& .cardContent': {
                    border: "2px solid #DC2626",
                },
            },
        },
    },
    cardHeader: {
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px 16px",
        '& > p': {
            margin: 0,
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: 14,
            lineHeight: "22px",
            color: "#FFFFFF",
        },
        '& > button': {
            all: "unset",
            cursor: "pointer",
        },
    },
    cardFooter: {
        borderRadius: "0 0 12px 12px",
        padding: "8px 16px",
    },
    error: {
        border: "2px solid #DC2626",
        padding: "8px 16px",
        display: "flex",
        gap: 8,
        alignItems: "center",
        backgroundColor: "#FEE2E2",
        borderRadius: "0px 0px 12px 12px",
        '& > p': {
            margin: 0,
            fontFamily: "Cairo",
            fontWeight: 500,
            fontSize: 10,
            lineHeight: "18px",
            color: "#DC2626",
        },
    },
    icon: {
        display: "flex",
        justifyContent: "center",
    },
    highlight: {
        color: "#51ABB3"
    },
})

type Link = {
    id: number;
    name: string;
    linkType: string;
    openLink: string;
    icon?: string | null;
    iconFile?: File | null;
    url?: string;
    destination?: string;
}

export type NodeData = {
    id: number;
    title: string;
    message: string;
    type: SelectedCard;
    status: "connected" | "disconnected";
    connectorCardType: "chatbot" | "template";
    onClick?: (data: Omit<NodeData,"status" | "onClick">) => void;
    onCardNodeDelete?: (node: {id: number, title: string, source: string[]}) => void;
    waitTime?: number;
    fieldName?: string;
    videoURL?: string;
    minValue?: string;
    maxValue?: string;
    links?: Array<Link>;
    source: string[];
    cardType?: string;
    targetCard?: number;
    embedForms?: string[];
    userGuides?: string[];
    options?: string[];
    error?: string;
    className?: string;
    criteriaRoutings?: CriteriaRouting[];
    height?: number;
    criteriaRoutingId?: number;
    followUpActions?: Array<{id: number, redirectCardId: string | null; buttonText: string}>;
    followUpActionId?: number;
}

const iconType: Record<Extract<SelectedCard, "go_to_tile" | "criteria_routing">, React.ReactNode> = {
    go_to_tile: <CallSplitRounded htmlColor="#334155" />,
    criteria_routing: <UpdateRounded htmlColor="#334155" />,
}

const regex = /(%[^%]+%)/g;

const getClassNameForSourceHandle = (nextCardId: string | null | undefined, error: string | undefined) => {
    let className = "secondary-handle"
    if(!nextCardId) {
        className = "primary-handle"
    }
    if((!nextCardId && error) || error === "This tile is unconnected to the flow, please connect or delete it" || error === "This branch is incomplete, please add a 'end chat' tile before publication") {
        className = "danger-handle"
    }
    return className
}

export default function CardNode({ data }: NodeProps<NodeData>) {
    const classes = useCardStyles()

    const onCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        const {onClick, status, ...rest} = data
        onClick?.(rest)
    }

    const onDeleteNode = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        data.onCardNodeDelete?.({
            id: data.id,
            title: data.title,
            source: data.source,
        })
    }

    const messageParts = data.message.split(regex).map((part, index) => {
        if(regex.test(part)) {
            return <span key={index} className={classes.highlight} >{part}</span>
        }
        return part
    })

    let totalHeightOfHandle = 0
    const spaceForGaps = 90 - totalHeightOfHandle
    let totalNumberOfGap = 1
    let heightOfEachGap: number;
    if(data.type === "criteria_routing") {
        totalHeightOfHandle = data.criteriaRoutings!.length * 10
        totalNumberOfGap = data.criteriaRoutings!.length + 1
        heightOfEachGap = Math.floor(spaceForGaps / totalNumberOfGap) + data.criteriaRoutings!.length
    }
    if(data.type === "openai") {
        totalHeightOfHandle = data.followUpActions!.length * 10
        totalNumberOfGap = data.followUpActions!.length + 1
        heightOfEachGap = Math.floor(spaceForGaps / totalNumberOfGap) + data.followUpActions!.length
    }

    return (
        <>
            <Handle type="target" position={Position.Left} style={{ opacity: 0, left: 0, zIndex: -1 }} />
            <div className={classes.card} onClick={onCardClick} data-error={!!data.error} >
                <div className={classes.cardHeader}>
                    <p>{data.title}</p>
                    <button onClick={onDeleteNode} >
                        <DeleteOutlineRounded htmlColor="#FFFFFF" />
                    </button>
                </div>
                <div className="cardContent">
                    {
                        data.type === "go_to_tile" || data.type === "criteria_routing" ? (
                            <div className={classes.icon} >
                                {iconType[data.type]}
                            </div>
                        ) : <p>{messageParts}</p>
                    }
                </div>
                {
                    data.error && (
                        <div className={classes.error} >
                            <ErrorInfoIcon />
                            <p>{data.error}</p>
                        </div>
                    )
                }
            </div>
            {
                data.type === "criteria_routing" ? (
                    <>
                       { data.criteriaRoutings?.map((criteria, index) => (
                            <Handle
                                key={criteria.criteria_routing_id}
                                type="source"
                                position={Position.Right}
                                style={{
                                    width: 10,
                                    height: 10,
                                    borderRadius: 20,
                                    right: -7,
                                    top: 10 + heightOfEachGap * (index + 1)
                                }}
                                id={`${criteria.criteria_routing_id}`}
                                className={getClassNameForSourceHandle(criteria.nextCardId, data.error)}
                            />
                       ))}
                    </>
                ) : data.type === "openai" ? (
                    <>
                        {
                            data.followUpActions?.map((action, index) => (
                                <Handle
                                    key={action.id}
                                    type="source"
                                    position={Position.Right}
                                    style={{
                                        width: 10,
                                        height: 10,
                                        borderRadius: 20,
                                        right: -7,
                                        top: 10 + heightOfEachGap * (index + 1)
                                    }}
                                    id={`${action.id}`}
                                    className={getClassNameForSourceHandle(action.redirectCardId, data.error)}
                                />
                            ))
                        }
                    </>
                ) : (
                    <Handle
                        type="source"
                        position={Position.Right}
                        style={{
                            width: 10,
                            height: 10,
                            borderRadius: 20,
                            right: -7,
                        }}
                    />
                )
            }
        </>
    )
}

function ErrorInfoIcon() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.5 9C1.5 4.86 4.8525 1.5 8.9925 1.5C13.14 1.5 16.5 4.86 16.5 9C16.5 13.14 13.14 16.5 8.9925 16.5C4.8525 16.5 1.5 13.14 1.5 9ZM9.75 6C9.75 5.5875 9.4125 5.25 9 5.25C8.5875 5.25 8.25 5.5875 8.25 6V9C8.25 9.4125 8.5875 9.75 9 9.75C9.4125 9.75 9.75 9.4125 9.75 9V6ZM9 15C5.685 15 3 12.315 3 9C3 5.685 5.685 3 9 3C12.315 3 15 5.685 15 9C15 12.315 12.315 15 9 15ZM8.25 11.25V12.75H9.75V11.25H8.25Z" fill="#DC2626"/>
        </svg>
    )
}
