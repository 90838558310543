// Customizable Area Start
import React from "react";
import CreateEditQAController, { Props } from "./CreateEditQAController";
import { Button, Typography, styled, createTheme, ThemeProvider, FormControl } from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import CustomMDEditor from "../../../components/src/CustomMDEditor.web";
import Header from "./components/Header.web";
import { theme as styledTheme } from "./theme.web";
const { FormFieldsContainer, Column, StyledRow } = styledTheme
import AddAssociatesModal from "./components/AddAssociatesModal.web"
import BulkUploadModal from "./components/BulkUploadModal.web"
import FormInputWithLabel from "./components/FormInputWithLabel.web";
import TrainingHubSettings from "../../../components/src/TrainingHubSettings.web";

export default class CreateEditQA extends CreateEditQAController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={Theme}>
        <PostCreationBox>
          <Sidebar navigation={this.props.navigation} />
          <Column>
            <Header hasUserInputFieldChanged={this.state.hasUserInputFieldChanged} loading={this.state.saveFormLoading} title="Q&As" onSaveClick={this.saveQAData} onExitForm={this.onFormCloseHandler} />
            <StyledRow className="flex-1" >
              <Column className="rightside-blog-content gap-8" style={{ padding: "12px 48px" }}>
                <FormFieldsContainer>
                  <Button className="bulk-upload-btn" onClick={this.showBulkModal}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z" fill="#51ABB3" />
                    </svg>
                    Bulk upload
                  </Button>
                  <StyledRow className="flex-column form-controls">
                    <Typography style={{
                      margin: '20px 0', fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: 700,
                      marginBottom: "10px",
                      color: '#64748B',
                      fontFamily: "Cairo"
                    }}>Questions</Typography>
                    <FormControl className="form-controls">
                      <FormInputWithLabel
                          type="text"
                          label=""
                          placeholder="Type question 1"
                          id="question"
                          name="question"
                          value={this.state.QAData.question}
                          formError={this.state.errors.questionError}
                          onValueChange={this.handleChange}
                          maxLength={100}
                        />
                    </FormControl>
                  </StyledRow>

                  <StyledRow className="flex-column">
                    <Typography style={{
                      margin: '20px 0', fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: 700,
                      marginBottom: "10px",
                      color: '#64748B',
                      fontFamily: "Cairo"
                    }}>Response</Typography>
                    <CustomMDEditor
                      value={this.state.QAData.response}
                      onChange={(value) => this.handleChange("response", value)}
                      className={this.state.errors.responseError ? 'contenterror' : ''}
                      toolbar={[
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ["bold", "italic", "strike"],
                        ["bar"],
                        ["link"]
                      ]}
                      error={this.state.errors.responseError}
                      hasRightAlign={false}
                    />
                  </StyledRow>

                  <StyledRow className="flex-column" style={{ gap: "8px" }}>
                    <Typography style={{ marginBottom: 0, fontWeight: 700, fontSize: "14px", color: "#64748B", fontFamily: "Cairo" }}>Associated resource</Typography>
                    <Button data-testid="open-associate-modal" className="bulk-upload-btn" style={{ width: "172px", textAlign: "start" }} onClick={this.showAsscociates} disabled={!!this.state.associatedData && !this.state.QAId}>
                      {this.handleDisabled() ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z" fill="#94A3B8" />
                      </svg>
                        : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z" fill="#51ABB3" />
                        </svg>}
                      Add associated
                    </Button>
                    {this.state.associatedData && <StyledRow data-testid={`associated-${this.state.associatedData.id}`} className="associated-card flex-column">
                      <Typography style={{ color: "#0F172A", textTransform: "capitalize", fontWeight: 700, fontSize: "16px", fontFamily: "Cairo" }}>{this.state.associatedData?.file_name}</Typography>
                      <StyledRow className="items-center" style={{ gap: "8px" }}>
                        <Avatar>
                          {
                            this.state.associatedData?.profile_image ? <img src={this.state.associatedData.profile_image} alt="" /> : this.state.associatedData.user_name.charAt(0) || "W"
                          }
                        </Avatar>
                        <Typography style={{ marginBottom: 0, fontWeight: 400, fontSize: "14px", color: "#0F172A", fontFamily: "Cairo" }}>{this.state.associatedData?.user_name}</Typography>
                      </StyledRow>
                    </StyledRow>}
                  </StyledRow>
                </FormFieldsContainer>
              </Column>

              <TrainingHubSettings 
                data-testid="settings"
                handleLogout={this.handleLogout}
                tagType="question_and_answer"
                selectedPractices={this.state.selectedPracticeValue}
                showPracticeError={this.state.showPracticeError}
                handleClosePracticeError={() => this.handleCloseError("showPracticeError")}
                handlePracticeAssociationApply={this.handleChangeSelectedPractieValue}
                handleGlobalSwitchChange={this.handleGolbalSwitchChange}
                isGlobal={this.state.isGlobal}
                selectedTags={this.state.selectedTag}
                handleTagsApply={this.handleSelectTag}
              />
            </StyledRow>
          </Column>
          <BulkUploadModal 
            handleViewFile={this.handleViewFile} 
            saveCSVFileData={this.saveCSVFileData} 
            open={this.state.openBulkModal} 
            onClose={this.closeBulkModal} 
            handleFileChange={this.handleFileChange} 
            showError={this.state.showError} 
            fileUrl={this.state.fileUrl} 
            handleCloseError={this.handleShowError} 
            errorMessage={this.state.errors.fileError} 
            fileName={this.state.fileName} 
          />
          <AddAssociatesModal
            data-testid="add-associate-modal-wrapper"
            key={this.state.showAssociatesDialog ? "associate-popup-open" : "associate-popup-closed"} 
            open={this.state.showAssociatesDialog} 
            onClose={this.closeAsscociates} 
            allAssociatedDataFiles={this.state.allAssociatedDataFiles}
            handleFileAdd={this.saveFileChange} 
            initialFileDataId={this.state.initialFileDataId}
          />
        </PostCreationBox>
      </ThemeProvider>
    );
  }
}

const Avatar = styled("span")({
  background: "#E5E6FF", 
  height: "32px", 
  width: "32px", 
  borderRadius: "100%", 
  color: "#64748B", 
  fontWeight: 700, 
  fontFamily: "Inter", 
  fontSize: "14px", 
  border: "1px solid #E5E6FF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",

  "& img": {
    width: "100%",
    borderRadius: "100%"
  }
})
const Theme = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        display: "flex",
        flexDirection: "column",
        gap: 4,
        '& .error': {
          fontFamily: "Cairo",
          fontWeight: 400,
          fontSize: 12,
          lineHeight: "18px",
          color: "#D97706",
        }
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        '&:hover $notchedOutline': {
          borderColor: "#CBD5E1",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#CBD5E1",
        },
        '&.Mui-Focused $notchedOutline': {
          borderColor: "#51ABB3",
        },
        "&.Mui-error $notchedOutline": {
          borderColor: "#F59E0B !important",
        },
      },
      input: {
        padding: 0,
        height: "24px"
      },
    },
  },
});
const PostCreationBox = styled('div')({
  display: "flex",
  alignItems: "stretch",
  justifyContent: "stretch",
  background: '#fafafa',

  "& .contenterror": {
    border: "1px solid #F59E0B !important",
    background: "#FEF3C7 "
  },
  "& .contenterror .ql-toolbar": {
    borderBottom: "1px solid #F59E0B !important",
  },
  "& .Mui-disabled": {
    background: "#F8FAFC !important",
    color: "#94A3B8 !important"
  },
  "& .associated-card": {
    width: "410px",
    minHeight: "74px",
    background: "#F6F7FF",
    borderLeft: "4px solid #64748B",
    borderRadius: "6px",
    gap: "8px",
    padding: "16px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
  },
  "& .div-root-14 .quill": {
    width: "100%"
  },
  "& .div-root-14 .contenterror": {
    border: "1px solid #F59E0B !important"
  },
  "& .bulk-upload-btn": {
    cursor: "pointer",
    width: "142px",
    height: "32px",
    padding: "6px 10px 6px 10px",
    gap: "6px",
    opacity: "0px",
    background: "#EDF6F6",
    color: "#51ABB3",
    fontFamily: "Inter",
    fontWeight: 700,
    borderRadius: "8px",
    textTransform: "capitalize"
  },
  "& .bulk-upload-btn span": {
    gap: "6px",
  },
  "& .rightside-blog-content": {
    width: "calc(100% - 100px)",
  },
  "& .postContent-inner": {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "stretch",
    width: "100%",
    minHeight: 'calc(100% - 93px)',

  },
  "& .leftcontent": {
    width: "calc(100% - 400px)",
    padding: "24px 48px",
    background: "#fafafa",
  },
  "& .rightsidebar": {
    width: "400px",
    boxShadow: "0px 24px 20px 4px rgba(0,0,0,0.1)",
    padding: "24px 48px",
    borderLeft: "1px solid rgb(226, 232, 240)",
    background: '#fff',
  },
  "& .manageBtn": {
    color: "#51ABB3",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    background: "transparent",
    fontWeight: 700,
    lineHeight: "20px",
    paddingBottom: "0",
    paddingTop: "0",
    display: "inline-block"
  },
  "& .leftcontent-inner": {
    background: '#fff',
    width: '100%',
    padding: "25px",
    boxSizing: "border-box",
  },
  "& .leftcontent-inner h4": {
    fontSize: "20px",
    lineHeight: "28px",
    color: "#0F172A",
    fontWeight: 700,
    marginBottom: 20,
  },
  "& .form-controls p": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 700,
    marginBottom: "10px",
    color: '#64748B',
    fontFamily: "Cairo",
  },
  "& .form-controls input[type='text']": {
    width: "100%",
    height: "24px",
    padding: "10px 15px",
    borderRadius: "8px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0F172A",
    fontFamily: "Cairo",
    fontWeight: 400,

    "&:focus": { outline: 'none' },
    "&.titlerror": {
      background: '#FEF3C7',
      borderColor: '#D97706',
      color: '#D97706'
    }
  },

  "& .form-controls .MuiSwitch-root": {
    width: "48px",
    height: "28px",
    padding: "0",
    borderRadius: "40px"
  },
  "& .form-controls .MuiButtonBase-root": {
    padding: "2px"
  },
  "& .form-controls .MuiSwitch-track": {
    backgroundColor: '#E2E8F0',
    opacity: 1,
  },
  "& .form-controls .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: '#51ABB3',
    opacity: 1,
  },
  "& .form-controls .MuiSwitch-thumb": {
    width: "24px",
    height: "24px"
  },
  "& .saveBtn": {
    color: "#fff",
    border: "1px solid #E5F6FF",
    cursor: "pointer",
    margin: "0 5px",
    display: "inline-block",
    padding: "10px",
    fontSize: "16px",
    minWidth: "90px",
    background: "#51ABB3",
    textAlign: "center",
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    textTransform: "capitalize"
  },
  "& .Practicerror": {
    display: "flex",
    alignContent: "space-around",
    alignItems: "center",
    background: "#FEF3C7",
    height: "40px",
    borderLeft: "4px solid #F59E0B",
    borderRadius: "4px 0 0px 4px",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    color: "#F59E0B",
    padding: "0 10px",
    marginBottom: "10px",
    gap: "10px"
  }

});

// Customizable Area End
