import React from "react";
import { Bar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
import { PatientsData } from "../VisualAnalyticsController";

Chart.register(ChartDataLabels)

const chartSetting = {
    width: 500,
};

type PatientStackBarChartProps = {
    patientsData: PatientsData[];
    chartLabels: string[];
}


export default function PatientStackBarChart({ patientsData, chartLabels }: PatientStackBarChartProps) {

    const getFilteredQuestionTypePatientsData = (questionType: string) => {
        return patientsData.filter(data => data.question === questionType).map(data => data.patients)
    }

    const chartData = {
        labels: chartLabels,
        datasets: [
            {
                label: "Appointments",
                data: getFilteredQuestionTypePatientsData("Appointments"),
                backgroundColor: "#314A84",
                borderRadius: 4
            },
            {
                label: "General Enquiries",
                data: getFilteredQuestionTypePatientsData("General Enquiries"),
                backgroundColor: "#FF606B",
                borderRadius: 4
            },
            {
                label: "Manage/check referrals",
                data: getFilteredQuestionTypePatientsData("Manage/check referrals"),
                backgroundColor: "#F59E0B",
                borderRadius: 4
            },
            {
                label: "Prescriptions",
                data: getFilteredQuestionTypePatientsData("Prescriptions"),
                backgroundColor: "#34D399",
                borderRadius: 4
            },
            {
                label: "Sick/Fit Notes",
                data: getFilteredQuestionTypePatientsData("Sick/Fit Notes"),
                backgroundColor: "#C1C7CD",
                borderRadius: 4
            },
            {
                label: "Test Results",
                data: getFilteredQuestionTypePatientsData("Test Results"),
                backgroundColor: "#7D6AEB",
                borderRadius: 4
            },
        ]
    }

    return (
        <div style={{ flex: 1, overflowX: "hidden" }} >
            <Bar
                data={chartData}
                options={{
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: true,
                            backgroundColor: '#F1F5F9',
                            bodyColor: '#F1F5F9',
                            titleColor: "#0F172A",
                            borderColor: '#F1F5F9',
                            borderWidth: 1,
                            titleFont: { weight: 'bold' },
                            callbacks: {
                                labelTextColor: function (context) {
                                    return '#0F172A';
                                }
                            }
                        },
                        datalabels: {
                            display: function (context) {
                                return context.datasetIndex === context.chart.data.datasets.length - 1;
                            },
                            align: 'end',
                            anchor: 'end',
                            font: {
                                weight: 600,
                                family: "Cairo",
                                size: 12,
                                lineHeight: "16px", 
                            },
                            color: '#0F172A',
                            formatter: function (value, context) {
                                const total = context.chart.data.datasets
                                    .map(dataset => dataset.data[context.dataIndex])
                                    .reduce((a, b) => {
                                        const aNum = a as number
                                        const bNum = b as number
                                        return aNum + bNum
                                    }, 0) as number;
                                const formatter = new Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 });
                                return formatter.format(total);
                            },


                        },
                    },
                    scales: {
                        x: {
                            stacked: true,
                            grid: { display: false }
                        },
                        y: {
                            stacked: true,
                            grid: { borderDash: [5, 5], borderColor: "transparent", tickColor: "transparent" },
                            ticks: {
                                callback: function (value: string | number) {
                                    const formatter = new Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 });
                                    return formatter.format(+value);
                                }
                            }
                        }
                    },
                    responsive: true,
                }}
                {...chartSetting}
                className="stacked-canvas"
            />
        </div>
    )
}