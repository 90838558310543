import { styled } from "@material-ui/core";

const FormFieldsContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    padding: 24,
    gap: 12,
    borderRadius: 12,
    boxShadow: "0px 2px 8px 0px #00000014",
    backgroundColor: "#FFFFFF",
    '& .title': {
        margin: 0,
        padding: 0,
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 20,
        lineHeight: "28px",
        color: "#0F172A",
    },
})

const Column = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    '&.gap-6': {
        gap: 6,
    },
    '&.gap-8': {
        gap: 8,
    },
    '&.gap-12': {
        gap: 12,
    },
})

const StyledRow = styled("div")({
    display: "flex",
    '&.flex-1': {
        flex: 1,
    },
    '&.space-between': {
        justifyContent: "space-between",
    },
    '&.items-center': {
        alignItems: "center",
    },
})

export const theme = {
    FormFieldsContainer,
    Column,
    StyledRow,
}

