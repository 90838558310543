import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { configJSON } from "../ForgotPasswordController"
import { toast } from "react-toastify";

export interface ChangePasswordProps {
    navigation: any;
}
  
interface S {
    loading: boolean;
    error: string | null;
}
  
interface SS {
    navigation: any;
}

export default class ChangePasswordController extends BlockComponent<
ChangePasswordProps,
  S,
  SS
> {
    changePasswordId: string = ""
    constructor(props: ChangePasswordProps) {
        super(props)

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            loading: false,
            error: null,
        }

        this.handleNavigateToLogin = this.handleNavigateToLogin.bind(this)
        this.handleRestApiResponse = this.handleRestApiResponse.bind(this)
        this.handleChangePasswordResponse = this.handleChangePasswordResponse.bind(this)
    }

    async receive(from: string, message: Message) {

        const handlers = {
            [getName(MessageEnum.RestAPIResponceMessage)]: this.handleRestApiResponse
        }
    
        const handler = handlers[message.id]
        if(handler) {
            handler(message)
        }
    }

    handleRestApiResponse(message: Message){
        const responseHandlers: Record<string, (newMsg: Message) => void> = {}
        const {title} = message.getData(getName(MessageEnum.NavigationPropsMessage))

        if(title === this.changePasswordId) {
            responseHandlers[this.changePasswordId] = this.handleChangePasswordResponse
        }

        const handler = responseHandlers[title];
        if (handler) {
            handler(message);
        }
    }

    handleChangePasswordResponse(message: Message) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        this.setState({loading: false})
        if(!responseJson) {
            this.setState({error: "Internal server error"})
            return;
        }
        
        if(responseJson.error) {
            toast.error(responseJson.error, {className: "error__toast"})
            this.handleNavigateToLogin()
            return;
        }

        if(responseJson.message) {
            sessionStorage.setItem("from-page", "change-password")
            this.handleNavigateToLogin()
        }
    }

    handleChangePassword = (values: {
        newPassword: string;
        confirmNewPassword: string;
    }) => {
        const searchParams = new URLSearchParams(this.props.navigation.history.location.search)
        const token = searchParams.get("token")
        if(!token) {
            this.handleNavigateToLogin()
            return;
        }

        this.setState({loading: true})

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                'Content-Type': "application/json",
                token
            })
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.resetPasswordApiEndPoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({new_password: values.newPassword})
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        )
        requestMessage.addData(
            getName(MessageEnum.NavigationPropsMessage),
            {title: "ChangePassword"}
        )
        this.changePasswordId = "ChangePassword"
        this.send(requestMessage)
    }

    handleNavigateToLogin() {
        const navigationMessage = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
        navigationMessage.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        )
        this.send(navigationMessage)
    }
}
